import { Cancel, Delete, Edit, FilterList, Person, Refresh, Search, Style } from "@mui/icons-material";
import { Badge, Box, CircularProgress, Divider, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import $api from "../http";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import { PlusSquare } from "react-feather";
import TicketBulkEditDialog from "./TicketBulkEditDialog";
import TicketFilter from "./TicketFilter";

let searchTimeout = null;

const TicketsToolbar = (props) => {
    const { totalCount, onReload, isLoading, selectedCount, onFilter, selectedItems, onClearSelectedItems, onCreate } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [editDialogAnchorEl, setEditDialogAnchorEl] = useState(null);
    const [filter, setFilter] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleSearchTermChange = (e) => {
        const val = e.target.value;
        setSearchTerm(val);
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          onReload(val, true, filter);
        }, 500);
    };

    const handleFilterOpen = (e) => {
      setFilterAnchorEl(e.target);
    };

    const handleFilterClose = () => {
      setFilterAnchorEl(null);
    };

    const handleFilterSubmit = (data) => {
      setFilter(data);
      onFilter(searchTerm, true, data);
    };
    
    const clearSearchTerm = () => {
        setSearchTerm('');
        onReload('', true, filter);
    };

    const handleEditDialogSubmit = (params) => {
      $api.post('/ticket/bulk-update', {
        id: selectedItems,
        ...params
      })
        .catch((err) => {

        })
        .then((res) => {
          enqueueSnackbar(t('Selected items updated'), {
            variant: 'success'
          });
          setEditDialogAnchorEl();
          onReload(searchTerm, true, filter);
        })
        .finally(() => {

        })
    };

    const handleEditDialogOpen = (e) => {
      setEditDialogAnchorEl(e.target);
    };

    const handleEditDialogClose = () => {
      setEditDialogAnchorEl(null);
    };

    const handleDeleteSelected = () => {
      setDeleteDialogOpen(true);
    };

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <TicketFilter
                  anchorEl={filterAnchorEl}
                  onClose={handleFilterClose}
                  onSubmit={handleFilterSubmit}
                />
                <TicketBulkEditDialog
                  anchorEl={editDialogAnchorEl}
                  onClose={handleEditDialogClose}
                  onSubmit={handleEditDialogSubmit}
                />
                <ConfirmDialog
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  title={t('Remove selected items?')}
                  content={(
                    <>
                      <Typography>
                        {t('Do you really want to delete {{count}} selected item(s)?', {
                          count: selectedItems.length
                        })}
                      </Typography>
                      <Typography color="error">
                        {t("This action can't be undone!")}
                      </Typography>
                    </>
                  )}
                  fieldId={selectedItems}
                  onSubmit={(data) => {
                    onReload(searchTerm, true, filter);
                    onClearSelectedItems();
                    enqueueSnackbar(t('{{deleted}} item(s) deleted', data), {
                      variant: 'warning'
                    });
                  }}
                  actionUrl="/ticket/delete"
                />
                <Paper sx={{ pr: 1, pl: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }} elevation={0}>
                  <Box sx={{ display: 'flex' }}>
                    {(isLoading) ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Typography>
                            {selectedCount > 0 ? `${selectedCount}/` : ''}{totalCount} {t('tickets')}
                        </Typography>
                    )}
                  </Box>
                    <Box
                      sx={{
                        padding: '5px 0',
                        ml: 'auto',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 'auto', mr: 2, flex: 1 }}>
                          <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              placeholder={t('Search')}
                              onChange={handleSearchTermChange}
                              value={searchTerm}
                              sx={{
                                  minWidth: 320,
                                  '& .MuiInputBase-root': {
                                      pr: 0.4
                                  }
                              }}
                              InputProps={{
                                  startAdornment: (
                                  <InputAdornment position="start">
                                      {searchLoading ? <CircularProgress size={24} /> : <Search sx={{ color: 'text.secondary' }} />}
                                  </InputAdornment>
                                  ),
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <IconButton sx={{
                                              display: searchTerm === '' ? 'none' : 'inline-flex'
                                          }} onClick={clearSearchTerm} size="small">
                                              <Cancel sx={{ color: 'text.secondary', opacity: 0.8 }} />
                                          </IconButton>
                                      </InputAdornment>
                                  )
                              }}
                          />
                      </Box>
                      <Tooltip title={t('Edit selected')}>
                        <IconButton
                          onClick={handleEditDialogOpen}
                          disabled={selectedCount === 0}
                          sx={{ mr: 1 }}
                          aria-label="refresh"
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Delete selected')}>
                        <IconButton
                          disabled={selectedCount === 0}
                          sx={{ mr: 1 }}
                          onClick={handleDeleteSelected}
                          aria-label="refresh"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Add ticket')}>
                          <IconButton
                            onClick={onCreate}
                            sx={{ mr: 1 }}
                            aria-label="filter"
                          >
                            <PlusSquare />
                          </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Filter')}>
                          <IconButton
                            onClick={handleFilterOpen}
                            sx={{ mr: 1 }}
                            aria-label="filter"
                          >
                            <FilterList color={Object.keys(filter).length > 0 ? 'info' : ''} />
                          </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Reload data')}>
                        <IconButton
                          aria-label="refresh"
                          onClick={() => onReload(searchTerm, true, filter)}
                        >
                          <Refresh />
                        </IconButton>
                      </Tooltip>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default TicketsToolbar;