import {
    Box,
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
const Feature = lazy(() => import('../components/Feature'));

const FeaturePage = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageMeta title={t('Feature')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <Feature />
                </Suspense>
            </Box>
        </>
    );
};

export default FeaturePage;