import { Lock } from '@mui/icons-material';
import {
  Box
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import NoContent from 'src/components/app/NoContent';
import PageMeta from 'src/components/app/PageMeta';
import { useAppConext } from 'src/components/context/AppContext';
const GeneralConfig = lazy(() => import('../../components/settings/general/GeneralConfig'));

const General = () => {
  const { t } = useTranslation();
  const { user } = useAppConext();

  if ((user?.permissions?.settingsGeneral === false)) {
    return (
        <Box sx={{ height: 'calc(100vh - 164px)', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <NoContent
                containerSx={{
                    my: 'auto'
                }}
                icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                title={t('Forbidden')}
                content={t("You're don't have access to this page")}
            />
        </Box>
    );
  }

  return (
    <>
      <PageMeta title={t('Advanced settings')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: {
            xs: 1,
            md: 2
          }
        }}
      >
        <Suspense fallback={<LoadingProgress open />}>
          <GeneralConfig configUrl="/config/get" saveUrl="/config/save" modelName="GeneralConfig" />
        </Suspense>
      </Box>
    </>
  );
};

export default General;
