import PageMeta from "src/components/app/PageMeta";
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
import { Box, Container } from "@mui/material";

const Faq = lazy(() => import('./Faq'));

const FaqPage = () => {
    return (
        <>
            <PageMeta title="Frequently asked questions" />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    mb: 0
                }}
            >
                <Container sx={{ py: 3 }}>
                    <Suspense fallback={<LoadingProgress open />}>
                        <Faq />
                    </Suspense>
                </Container>
            </Box>
        </>
    );
};

export default FaqPage;