import { useEffect } from 'react';
import {
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListItem,
  Button
} from '@mui/material';
import { indigo } from '@mui/material/colors';
import NavItem from '../NavItem';
import { ContactSupport, Directions, Home, MenuBook, Payment, Quiz } from '@mui/icons-material';

const mainItems = [
  {
    href: '/',
    icon: Home,
    title: 'Home'
  },
  {
    href: 'https://docs.anychat.one/',
    icon: MenuBook,
    title: 'Docs'
  },
  {
    href: '/pricing',
    icon: Payment,
    title: 'Pricing'
  }
];

const MainSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  const handleSupportClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onMobileClose();
    window.contactUs.toggleMenu();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 0 }}>
        <List>
          {mainItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              backgroundColor={indigo[50]}
            />
          ))}
          <ListItem
            disableGutters
            sx={{
              display: 'flex',
              py: 0
            }}
          >
            <Button
              onClick={handleSupportClick}
              type="button"
              fullWidth
              style={{
                textAlign: 'left',
                justifyContent: 'left'
              }}
              sx={{
                textTransform: 'none',
                color: 'text.secondary',
                m: '1.6px 8px',
                minHeight: '39px',
                py: '10px',
                '& .MuiButton-startIcon': {
                  ml: 0,
                  '& svg': {
                    width: 24,
                    height: 24
                  }
                }
              }}
              startIcon={<ContactSupport />}
            >
              
              Support
            </Button>
          </ListItem>
          <NavItem
              href="/faq"
              title="FAQ"
              icon={Quiz}
              backgroundColor={indigo[50]}
          />
          <NavItem
              href="/roadmap"
              title="Roadmap"
              icon={Directions}
              backgroundColor={indigo[50]}
          />
        </List>
      </Box>
    </Box>
  );

  return (
    <Hidden mdUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 200
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

MainSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default MainSidebar;
