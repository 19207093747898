import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Comments from "src/components/comments";
import { useAppConext } from "src/components/context/AppContext";

const TaskNotes = (props) => {
    const { itemGuid } = props;
    const { t } = useTranslation();
    const { user } = useAppConext();

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', p: 2 }}>
            <Comments
                indexUrl={`/task/notes?guid=${itemGuid}`}
                saveUrl="/task/save-note"
                deleteUrl="/task/delete-note"
                user={user}
                id={itemGuid}
                bgColor='background.default'
                textFieldPlaceholder={t('Enter a note text')}
                deleteItemTitle={t('Delete a note?')}
                deleteItemContent={t('Do you want to delete note?')}
                itemPostedMessage={t('Note is added')}
                itemDeletedMessage={t('Note is deleted')}
                itemPostErrorMessage={t('Error posting note')}
            />
        </Box>
    );
};

export default TaskNotes;