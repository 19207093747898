import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import $api from "../http";
import { Box, Button, Dialog, DialogContent, LinearProgress, Typography } from "@mui/material";
import { CheckOutlined, Download, Refresh } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ExportDialog = (props) => {
    const {
        open,
        url,
        start = false,
        selected,
        pageSize = 50,
        format = 'csv',
        onClose = () => {},
        onStart = () => {},
        addParams = {},
        onSuccess = () => {}
    } = props;

    const [exportProgress, setExportProgress] = useState(0);
    const [exportTotal, setExportTotal] = useState(0);
    const [exportProcessed, setExportProcessed] = useState(0);
    const [exportDone, setExportDone] = useState(false);
    const [exportFile, setExportFile] = useState(null);
    const [exportInProgress, setExportInProgress] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    const handleExport = (id = null, limit = pageSize, offset = 0, processed = 0) => {
        setExportInProgress(true);
        onStart();
        $api.post(url, {
          selected: selected,
          format: format,
          limit: limit,
          offset: offset,
          processed: processed,
          id: id,
          ...addParams
        })
          .catch((err) => {
            enqueueSnackbar(t('Error exporting data'), {
              variant: 'error'
            });
            setExportInProgress(false);
          })
          .then((res) => {
            if (res) {
              if (res.data.continue === true) {
                handleExport(res.data.id, res.data.limit, res.data.offset, res.data.processed);
              } else {
                onSuccess();
                setExportDone(true);
                setExportInProgress(false);
              }
              setExportProgress(res.data.progress);
              setExportTotal(res.data.total);
              setExportProcessed(res.data.processed);
              setExportFile(res.data.url);
            }
          })
          .finally(() => {
            
          })
    };

    useEffect(() => {
        if (start) {
            handleExport();
        }
    }, [start]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            {(exportDone) ? (
                <DialogContent sx={{ textAlign: 'center' }}>
                    <CheckOutlined sx={{ width: 40, height: 40 }} color="success" />
                    <Typography sx={{ mb: 1 }}>
                        {t('Export complete')}
                    </Typography>
                    <Box>
                        <Button startIcon={<Download />} variant="outlined" component="a" href={exportFile}>
                            {t('Download')}
                        </Button>
                        <br />
                        <Button sx={{ fontSize: '0.75rem', py: 0, px: 1, mt: 2 }} startIcon={<Refresh />} size="small" onClick={() => {
                            handleExport();
                            setExportDone(false);
                            setExportProgress(0);
                            setExportTotal(0);
                            setExportProcessed(0);
                        }}>
                            {t('Start over')}
                        </Button>
                    </Box>
                </DialogContent>
            ) : (
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography sx={{ mb: 1 }}>
                        {t('Exporting data')}
                    </Typography>
                    <LinearProgress variant="determinate" value={exportProgress} />
                    <Typography color="text.secondary" variant="body2" sx={{ mt: 1 }}>
                    {t('{{processed}} of {{total}}', {
                        processed: exportProcessed,
                        total: exportTotal
                    })}
                    </Typography>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ExportDialog;