import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Typography } from "@mui/material";
import { useAppConext } from "../context/AppContext";
import { useTranslation } from "react-i18next";
import { Check, Close, Delete, KeyboardArrowLeft, KeyboardArrowRight, Logout } from "@mui/icons-material";
import { useEffect, useState } from "react";
import $api from "../http";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import LoadingProgress from "../app/LoadingProgress";
import useToken from "../app/useToken";
import { useNavigate } from "react-router";

const DeleteAccountConfirmationDialog = (props) => {
    const { open, onClose } = props;
    const [agree, setAgree] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const { logout } = useToken();
    const { loadBrandInfo } = useAppConext();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleLogout = () => {
        setIsSubmitting(true);
        logout().then(() => {
          loadBrandInfo();
          navigate('/login', { replace: true });
        });
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        $api.post('/auth/delete-immediately', {
            force: 1
        })
            .catch((err) => {
                enqueueSnackbar(t('Error submitting data'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    if (res?.data?.status === 3) {
                        enqueueSnackbar(t('Your account deleted'), {
                            variant: 'warning'
                        });
                    }
                    handleLogout();
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            })
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle color="error">
                {t('Permanently delete account')}
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mt: 2 }}>
                    {t('Are you certain you want to delete your account?')}
                </Typography>
                <Typography color="error">
                    {t('This action will permanently remove all associated data!')}
                </Typography>
                <FormControlLabel control={<Checkbox
                    onChange={(e) => {
                        const { checked } = e.target;
                        setAgree(checked);
                    }}
                    value={1}
                    name="agree"
                    color="error"
                    checked={agree} />}
                    label={t('I understand that all my data will be lost.')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <LoadingButton onClick={handleSubmit} loading={isSubmitting} startIcon={<Delete />} disabled={!agree} variant="contained" disableElevation color="error">
                    {t('Delete my account')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

const AgreementsDialog = () => {
    const { showAgreements } = useAppConext();
    const [currentStep, setCurrentStep] = useState(0);
    const [agree, setAgree] = useState([]);
    const [isFinishDisabled, setIsFinishDisabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [open, setOpen] = useState(true);
    const [success, setSuccess] = useState(false);
    const [agreementContent, setAgreementContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useToken();
    const { loadBrandInfo } = useAppConext();
    const navigate = useNavigate();

    const labels = {
        tos: t('Terms of Service'),
        pp: t('Privacy Policy'),
        gdpr: t('General Data Protection Regulation'),
        nda: t('Non-Disclosure Agreement'),
        dpa: t('Data Processing Agreement')
    };

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setAgree((old) => {
            if (checked) {
                if (!old.includes(name)) {
                    return [...old, name];
                }
            } else {
                return old.filter((i) => i !== name);
            }
        });
    };

    const loadAgreement = (id) => {
        setIsLoading(true);
        $api.get(`/user/agreement?id=${id}`)
            .catch((err) => {
                enqueueSnackbar(t('Error loading data'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    setAgreementContent(res.data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        console.log(agree);
        setIsFinishDisabled(false);
        showAgreements.forEach((i) => {
            if (!agree.includes(i)) {
                setIsFinishDisabled(true);
            }
        });
    }, [agree]);

    useEffect(() => {
        if (showAgreements[currentStep] !== undefined) {
            loadAgreement(showAgreements[currentStep]);
        }
    }, [currentStep, showAgreements]);

    const handleNext = () => {
        setCurrentStep((i) => i + 1);
    };

    const handleBack = () => {
        setCurrentStep((i) => i - 1);
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        $api.post('/user/submit-agreements', {
            data: agree
        })
            .catch((err) => {
                enqueueSnackbar(t('Error submitting data'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    setSuccess(true);
                    setTimeout(() => {
                        window.location.reload();
                        setOpen(false);
                    }, 2000);
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            })
    };

    if (showAgreements === undefined || showAgreements === null || showAgreements.length === 0) {
        return <></>;
    }

    return (
        <>
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle sx={{ position: 'relative' }}>
                    {labels[showAgreements[currentStep]]}
                    <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {(success === true) ? (
                        <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', pt: 4, pb: 2 }}>
                            <Check color="success" sx={{ width: 48, height: 48 }} />
                            <Typography sx={{ color: 'success.main', textTransform: 'uppercase' }}>
                                {t('Success')}
                            </Typography>
                        </Box>
                    ) : (
                        (showAgreements.map((i, idx) => (
                            <Box sx={{ display: currentStep === idx ? 'block' : 'none' }}>
                                <Typography component="div" sx={{
                                    height: 400,
                                    mt: 2,
                                    mb: 2,
                                    overflow: 'auto',
                                    bgcolor: 'background.default',
                                    borderRadius: 1,
                                    p: 2,
                                    '& p': {
                                        mb: 2
                                    },
                                    '& h1, h2, h3, h4, h5, h6': {
                                        mb: 1
                                    },
                                    '& ul': {
                                        ml: 3,
                                        mb: 1
                                    }
                                }}
                                    dangerouslySetInnerHTML={{
                                        __html: agreementContent
                                    }}
                                />
                                <FormControlLabel control={<Checkbox
                                    onChange={handleChange}
                                    value={1}
                                    name={i}
                                    checked={agree.includes(i)} />}
                                    label={t('I agree with "{{terms}}"', {
                                        terms: labels[showAgreements[currentStep]]
                                    })} />
                            </Box>
                        )))
                    )}
                    <LoadingProgress open={isLoading} />
                </DialogContent>
                <DialogActions sx={{ display: 'flex' }}>
                    <Button onClick={() => {
                        setDeleteDialogOpen(true);
                    }} disabled={success} color="error" startIcon={<Delete />}>
                        {t('Delete my account')}
                    </Button>

                    <Button onClick={() => {
                        logout().then(() => {
                            loadBrandInfo();
                            navigate('/login', { replace: true });
                        });
                    }} disabled={success} color="warning" sx={{ mr: 'auto' }} startIcon={<Logout />}>
                        {t('Logout')}
                    </Button>

                    <Button disabled={currentStep === 0 || success} onClick={handleBack} startIcon={<KeyboardArrowLeft />}>
                        {t('Back')}
                    </Button>

                    {(currentStep === (showAgreements.length - 1)) ? (
                        (success ? (
                            <Button variant="contained" disableElevation onClick={() => setOpen(false)}>
                                {t('Close')}
                            </Button>
                        ) : (
                            <LoadingButton loading={isSubmitting} disabled={isFinishDisabled} variant="contained" disableElevation onClick={handleSubmit} startIcon={<Check />}>
                                {t('Finish')}
                            </LoadingButton>
                        ))
                        
                    ) : (
                        <Button disabled={!agree.includes(showAgreements[currentStep])} variant="contained" disableElevation onClick={handleNext} startIcon={<KeyboardArrowRight />}>
                            {t('Next')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <DeleteAccountConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false);
                }}
            />
        </>
    );
};

export default AgreementsDialog;