import { Delete, Reply } from "@mui/icons-material";
import { Avatar, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import useDayjs from "../app/useDayjs";

const CommentItem = (props) => {
    const { comment, level, onReply, onDelete, onDeleteError, user, bgColor, deleteUrl, deleteItemTitle, deleteItemContent } = props;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    }

    return (
        <Card sx={{ mb: level === 1 ? 2 : 0, bgcolor: bgColor || 'background.paper' }}>
            <ConfirmDialog
                open={deleteDialogOpen}
                title={deleteItemTitle}
                content={deleteItemContent}
                submitButtonLabel={t('Delete')}
                actionUrl={deleteUrl}
                fieldId={comment.id}
                onError={onDeleteError}
                onSubmit={(data) => onDelete(data.comments)}
                onClose={() => setDeleteDialogOpen(false)}
            />
            <CardHeader 
                sx={{
                    opacity: comment.status === 0 ? 0.5 : 1,
                    '& .MuiCardHeader-avatar': {
                        mb: 'auto',
                        mt: 1,
                        mr: 2
                    }
                }}
                avatar={
                    <Avatar src={comment.user.img} sx={{ bgcolor: red[500], width: 32, height: 32 }} aria-label="recipe">
                        {comment.user.name.substring(0, 1)}
                    </Avatar>
                }
                action={
                    <>
                        <Tooltip title={t('Reply')}>
                            <IconButton disabled={(comment.status === 0)} onClick={() => onReply(comment)} sx={{ mr: 1 }} size="small" aria-label="settings">
                                <Reply fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Delete')}>
                            <IconButton onClick={handleDeleteClick} disabled={((comment.user_id !== user.id) && (comment.user_guid !== user.guid)) || (comment.status === 0)} size="small" aria-label="settings">
                                <Delete fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                title={(
                    <Typography sx={{
                        fontSize: '1rem',
                        mb: 0.5
                    }}>
                        <Typography component="span" sx={{ fontWeight: '500' }} variant="body2">
                            {comment.user.name}
                        </Typography>
                        <Typography component="span" color="text.secondary" variant="body2" sx={{ mx: 1 }}>
                            &#x2022;
                        </Typography>
                        <Typography component="span" color="text.secondary" variant="body2">
                            {dayjs.unix(comment.created_at).fromNow()}
                        </Typography>
                    </Typography>
                )}
                subheader={(
                    <Typography
                        variant="body2"
                        color={comment.status === 0 ? 'text.secondary' : 'text.primary'}
                            sx={{
                            fontStyle: comment.status === 0 ? 'italic' : 'normal'
                        }}
                        dangerouslySetInnerHTML={{
                            __html: comment.contentType === 'emailHTML' ? `<div class="comment-email-html">${comment.htmlContent}</div>` : comment.htmlContent
                        }}
                    />
                )}
                disableTypography
            />
            {(comment.comments !== undefined && comment.comments.length > 0) && (
                <CardContent sx={{ p: '0 !important', ml: level > 4 ? 0 : 6 }}>
                    {(comment.comments.map((i) => (
                        <CommentItem
                            onDeleteError={onDeleteError}
                            onReply={onReply}
                            key={i.id}
                            comment={i}
                            onDelete={onDelete}
                            user={user}
                            level={level + 1}
                            bgColor={bgColor}
                            deleteUrl={deleteUrl}
                            deleteItemTitle={deleteItemTitle}
                            deleteItemContent={deleteItemContent}
                        />
                    )))}
                </CardContent>
            )}
        </Card>
    );
};

export default CommentItem;