import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Checkbox, Chip, Table, TableBody, TableCell, TableHead, TableRow, Divider, TablePagination, Typography, Avatar, Badge, Tooltip, IconButton, AvatarGroup, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { invertColor } from "../app/Tools";
import UserChip from "../app/UserChip";
import { useState } from "react";
import $api from "../http";
import useDayjs from "../app/useDayjs";

const TasksTable = (props) => {
    const {
        items,
        selectedItems,
        handleSelectOne,
        handleSelectAll,
        handlePageChange,
        handleLimitChange,
        totalItems,
        page,
        limit,
        disablePagination,
        disableSelection,
        actions,
        compactView = false,
        allowInplaceEdit = false,
        onReload = () => {},
        onChange = () => {}
    } = props;
    const [statusMenuEl, setStatusMenuEl] = useState(null);
    const [priorityMenuEl, setPriorityMenuEl] = useState(null);
    const [currentItemGuid, setCurrentItemGuid] = useState(null);

    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    const statusOptions = [
        {
            id: 1,
            label: t('Not started'),
            color: 'error.main'
        },
        {
            id: 2,
            label: t('Deferred'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('In progress'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Completed'),
            color: 'success.main'
        },
        {
            id: 5,
            label: t('Waiting'),
            color: 'secondary.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    const handleUpdatePriority = (priority) => {
        handlePriorityMenuClose();
        $api.post('/task/update-priority', {
            guid: currentItemGuid,
            priority: priority
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                    onChange(true);
                }
            })

    };

    const handlePriorityMenuOpen = (e, currentGuid) => {
        if (!allowInplaceEdit) {
            return false;
        }
        setCurrentItemGuid(currentGuid);
        setPriorityMenuEl(e.currentTarget);
    };

    const handlePriorityMenuClose = () => {
        setCurrentItemGuid(null);
        setPriorityMenuEl(null);
    };

    const handleUpdateStatus = (status) => {
        handleStatusMenuClose();
        $api.post('/task/update-status', {
            guid: currentItemGuid,
            status: status
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                    onChange(true);
                }
            })

    };

    const handleStatusMenuOpen = (e, currentGuid) => {
        if (!allowInplaceEdit) {
            return false;
        }
        setCurrentItemGuid(currentGuid);
        setStatusMenuEl(e.currentTarget);
    };

    const handleStatusMenuClose = () => {
        setCurrentItemGuid(null);
        setStatusMenuEl(null);
    };

    return (
        <Card>
            <Menu open={!!statusMenuEl} onClose={handleStatusMenuClose} anchorEl={statusMenuEl}>
                {(statusOptions.map((i) => (
                    <MenuItem key={i.id} onClick={() => handleUpdateStatus(i.id)}>
                        <Box sx={{ width: 8, height: 8, borderRadius: 2, bgcolor: i.color, mr: 2 }} />
                        <Typography>
                            {i.label}
                        </Typography>
                    </MenuItem>
                )))}
            </Menu>
            <Menu open={!!priorityMenuEl} onClose={handlePriorityMenuClose} anchorEl={priorityMenuEl}>
                {(priorityOptions.map((i) => (
                    <MenuItem key={i.id} onClick={() => handleUpdatePriority(i.id)}>
                        <Box sx={{ width: 8, height: 8, borderRadius: 2, bgcolor: i.color, mr: 2 }} />
                        <Typography>
                            {i.label}
                        </Typography>
                    </MenuItem>
                )))}
            </Menu>
            <PerfectScrollbar>
            <Box sx={{ minWidth: 900 }}>
                <Table>
                <TableHead sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 3 }}>
                    <TableRow>
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.length === items.length}
                                    color="primary"
                                    indeterminate={
                                        selectedItems.length > 0
                                        && selectedItems.length < items.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                        )}
                    <TableCell>
                        {t('Title')}
                    </TableCell>
                    <TableCell>
                        {t('Contacts')}
                    </TableCell>
                    <TableCell>
                        {t('Status')}
                    </TableCell>
                    <TableCell>
                        {t('Priority')}
                    </TableCell>
                    {(compactView === false) && (
                        <TableCell>
                            {t('Deadline')}
                        </TableCell>
                    )}
                    <TableCell>
                        {t('Assigned to')}
                    </TableCell>
                    <TableCell>
                        {t('Created at')}
                    </TableCell>
                    {(compactView === false) && (
                        <TableCell>
                            {t('Last update')}
                        </TableCell>
                    )}
                    {(Array.isArray(actions)) && (
                        <TableCell />
                    )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                    <TableRow
                        hover
                        key={item.id}
                        selected={selectedItems.indexOf(item.id) !== -1}
                    >
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.indexOf(item.id) !== -1}
                                    onChange={(event) => handleSelectOne(event, item.id)}
                                    value="true"
                                />
                            </TableCell>
                        )}
                        <TableCell sx={{ maxWidth: 300 }}>
                            <Box component={NavLink} to={`/app/tasks/${item.guid}`} color="text.primary">
                                <Typography variant="body2" noWrap>
                                    {item.subject}
                                </Typography>
                                <Typography variant="body2" noWrap color="text.secondary">
                                    {item.description}
                                </Typography>
                                <Box>
                                    {(item.tags?.length > 0) && (
                                        (item.tags?.map((i) => (
                                            <Chip
                                                sx={{
                                                mr: 0.5,
                                                my: '2px',
                                                height: 18,
                                                bgcolor: i.color || '',
                                                color: i.color ? invertColor(i.color, true) : 'text.primary',
                                                '& .MuiSvgIcon-root': {
                                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                                    opacity: 0.4,
                                                    '&:hover': {
                                                        color: i.color ? invertColor(i.color, true) : '#000000',
                                                        opacity: 0.55,
                                                    }
                                                }
                                                }}
                                                label={i.label}
                                                size="small"
                                            />
                                        )))
                                    )}
                                </Box>
                                {(compactView === true) && (
                                    (item.due_to !== 0 && item.due_to !== null && item.due_to !== '') ? (
                                        <Box>
                                            <Typography component="span" variant="body2">
                                                {dayjs.unix(item.due_to).format('YYYY MMM DD')}
                                            </Typography>
                                            <Typography component="span" color="text.secondary" sx={{ mx: 1 }}>&#x2022;</Typography>
                                            <Typography component="span" variant="body2" color={item.overdue === 2 ? 'error.main' : item.overdue === 1 ? 'warning.main' : 'text.secondary'}>
                                                {dayjs.unix(item.due_to).fromNow()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                            {t('Not set')}
                                        </Typography>
                                    )
                                )}
                            </Box>
                        </TableCell>
                        <TableCell>
                            <AvatarGroup sx={{
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32
                                }
                            }}>
                                {(item.contacts?.map((i) => (
                                    <Tooltip title={i.name}>
                                        <Avatar src={i.img} />
                                    </Tooltip>
                                )))}
                            </AvatarGroup>
                        </TableCell>
                        <TableCell>
                            {(item.status === 1 ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'error.main' }} />} size="small" label={t('Not started')} color="error" variant="outlined" />) : 
                            (item.status === 2) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'info.main' }} />} size="small" label={t('Deferred')} color="info" variant="outlined" />) : 
                            (item.status === 3) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'warning.main' }} />} size="small" label={t('In progress')} color="warning" variant="outlined" />) : 
                            (item.status === 4) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'success.main' }} />} size="small" label={t('Completed')} color="success" variant="outlined" />) : 
                            (item.status === 5) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'secondary.main' }} />} size="small" label={t('Waiting')} color="secondary" variant="outlined" />) : 
                            (<></>))}
                        </TableCell>
                        <TableCell>
                            {(item.priority === 1 ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'success.main' }} />} size="small" label={t('Low')} color="success" variant="outlined" />) : 
                            (item.priority === 2) ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'warning.main' }} />} size="small" label={t('Middle')} color="warning" variant="outlined" />) : 
                            (item.priority === 3) ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'error.main' }} />} size="small" label={t('High')} color="error" variant="outlined" />) : 
                            (<></>))}
                        </TableCell>
                        {(compactView === false) && (
                            <TableCell>
                                {(item.due_to !== 0 && item.due_to !== null && item.due_to !== '') ? (
                                    <>
                                        <Typography variant="body2">
                                            {dayjs.unix(item.due_to).format('YYYY MMM DD')}
                                        </Typography>
                                        <Typography variant="body2" color={item.overdue === 2 ? 'error.main' : item.overdue === 1 ? 'warning.main' : 'text.secondary'}>
                                            {dayjs.unix(item.due_to).fromNow()}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                        {t('Not set')}
                                    </Typography>
                                )}
                            </TableCell>
                        )}
                        
                        <TableCell sx={{ width: 120 }}>
                            {(item.assignedTo !== null) ? (
                                <UserChip
                                    name={item.assignedTo.name}
                                    image={item.assignedTo.img}
                                    color="success"
                                    variant="outlined"
                                    size={compactView ? 'small' : 'medium'}
                                />
                            ) : (
                                <UserChip
                                    name={t('Not assigned')}
                                    image='no-img'
                                    variant="outlined"
                                    size={compactView ? 'small' : 'medium'}
                                />
                            )}
                        </TableCell>
                        
                        <TableCell sx={{ width: 120 }}>
                            {dayjs.unix(item.created_at).fromNow()}
                        </TableCell>
                        {(compactView === false) && (
                            <TableCell sx={{ width: 120 }}>
                                {dayjs.unix(item.updated_at).fromNow()}
                            </TableCell>
                        )}
                        {(Array.isArray(actions)) && (
                            <TableCell
                                align="right"
                                sx={{
                                    width: 120
                                }}
                            >
                                {actions.map((i) => (
                                    (i !== null) && (
                                        <Tooltip title={i.tooltip}>
                                            <IconButton onClick={(e) => i.onClick(e, item)}>
                                                {i.icon}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ))}
                            </TableCell>
                        )}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Box>
            {(disablePagination !== true) && (
                <TablePagination
                    sx={{
                        borderTopWidth: 1,
                        borderTopColor: 'divider',
                        borderTopStyle: 'solid',
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        bgcolor: 'background.paper',
                        zIndex: 3
                    }}
                    component="div"
                    count={totalItems}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                />
            )}
            </PerfectScrollbar>
        </Card>
    );
};

export default TasksTable;