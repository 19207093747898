import { Business, ConfirmationNumber, Duo, Person, TaskAlt } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { PlusSquare } from "react-feather";
import { useTranslation } from "react-i18next";
import { useAppConext } from "../context/AppContext";
import ContactDialog from "../contacts/ContactDialog";
import OrganizationDialog from "../organizations/OrganizationDialog";
import { useSnackbar } from "notistack";
import TicketDialog from "../tickets/TicketDialog";
import TaskDialog from "../tasks/TaskDialog";
import MeetingDialog from "../meetings/MeetingDialog";
import useDayjs from "../app/useDayjs";

const CrmMenu = () => {
    const [menuEl, setMenuEl] = useState();
    const [contactDialogOpen, setContactDialogOpen] = useState(false);
    const [orgDialogOpen, setOrgDialogOpen] = useState(false);
    const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
    const [taskDialogOpen, setTaskDialogOpen] = useState(false);
    const [meetingDialogOpen, setMeetingDialogOpen] = useState(false);

    const { t } = useTranslation();
    const { dayjs } = useDayjs();
    const { user, getFeatureValue } = useAppConext();
    const { enqueueSnackbar } = useSnackbar();

    // contact dialog
    const handleContactDialogOpen = () => {
        setContactDialogOpen(true);
        handleCloseMenu();
    };

    const handleContactDialogClose = () => {
        setContactDialogOpen(false);
    };

    const handleContactDialogSubmit = () => {
        enqueueSnackbar(t('Contact is created'), {
            variant: 'success'
        });
        handleContactDialogClose();
    };

    // ticket dialog
    const handleTicketDialogOpen = () => {
        setTicketDialogOpen(true);
        handleCloseMenu();
    };

    const handleTicketDialogClose = () => {
        setTicketDialogOpen(false);
    };

    const handleTicketDialogSubmit = () => {
        enqueueSnackbar(t('Ticket is created'), {
            variant: 'success'
        });
        handleTicketDialogClose();
    };

    // task dialog
    const handleTaskDialogOpen = () => {
        setTaskDialogOpen(true);
        handleCloseMenu();
    };

    const handleTaskDialogClose = () => {
        setTaskDialogOpen(false);
    };

    const handleTaskDialogSubmit = () => {
        enqueueSnackbar(t('Task is created'), {
            variant: 'success'
        });
        handleTaskDialogClose();
    };

    // meeting dialog
    const handleMeetingDialogOpen = () => {
        setMeetingDialogOpen(true);
        handleCloseMenu();
    };

    const handleMeetingDialogClose = () => {
        setMeetingDialogOpen(false);
    };

    const handleMeetingDialogSubmit = () => {
        enqueueSnackbar(t('Meeting is created'), {
            variant: 'success'
        });
        handleMeetingDialogClose();
    };

    // org dialog
    const handleOrgDialogOpen = () => {
        setOrgDialogOpen(true);
        handleCloseMenu();
    };

    const handleOrgDialogClose = () => {
        setOrgDialogOpen(false);
    };

    const handleOrgDialogSubmit = () => {
        enqueueSnackbar(t('Organization is created'), {
            variant: 'success'
        });
        handleOrgDialogClose();
    };

    // menu
    const handleOpenMenu = (e) => {
        setMenuEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuEl(null);
    };

    if (!getFeatureValue('contacts.enabled') && 
        !getFeatureValue('organizations.enabled') && 
        !getFeatureValue('tickets.enabled') &&
        !getFeatureValue('tasks.enabled') && 
        !getFeatureValue('meetings.enabled')) {
            return <></>;
    }

    if (user?.permissions?.contacts === true || user?.permissions?.organizations === true || user?.permissions?.meetings === true || user?.permissions?.tickets === true || user?.permissions?.tasks === true) {
        return (
            <>
                <IconButton onClick={handleOpenMenu} sx={{
                    ml: 1,
                    p: 0.5,
                    height: 40,
                    width: 40,
                    '& svg': {
                        color: 'primary.contrastText'
                    }
                }}>
                    <PlusSquare />
                </IconButton>
                <ContactDialog
                    open={contactDialogOpen}
                    onClose={handleContactDialogClose}
                    onSubmit={handleContactDialogSubmit}
                    currentItem={{}}
                />
                <OrganizationDialog
                    open={orgDialogOpen}
                    onClose={handleOrgDialogClose}
                    onSubmit={handleOrgDialogSubmit}
                    currentItem={{}}
                />
                <TicketDialog
                    open={ticketDialogOpen}
                    onClose={handleTicketDialogClose}
                    onSubmit={handleTicketDialogSubmit}
                    currentItem={{
                        status: 1,
                        priority: 1
                    }}
                />
                <TaskDialog
                    open={taskDialogOpen}
                    onClose={handleTaskDialogClose}
                    onSubmit={handleTaskDialogSubmit}
                    currentItem={{
                        status: 1,
                        priority: 1,
                        due_date: dayjs().add(1, 'day')
                    }}
                />
                <MeetingDialog
                    open={meetingDialogOpen}
                    onClose={handleMeetingDialogClose}
                    onSubmit={handleMeetingDialogSubmit}
                    currentItem={{
                        status: 1,
                        priority: 1,
                        from: dayjs().add(1, 'day'),
                        duration: 1800
                    }}
                />
                <Menu open={!!menuEl} anchorEl={menuEl} onClose={handleCloseMenu}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {(user?.permissions?.contacts === true) && (
                        <MenuItem onClick={handleContactDialogOpen}>
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <ListItemText primary={t('Add a contact')} />
                        </MenuItem>
                    )}
                    {(user?.permissions?.organizations === true) && (
                        <MenuItem onClick={handleOrgDialogOpen}>
                            <ListItemIcon>
                                <Business />
                            </ListItemIcon>
                            <ListItemText primary={t('Add an organization')} />
                        </MenuItem>
                    )}
                    {(user?.permissions?.tickets === true) && (
                        <MenuItem onClick={handleTicketDialogOpen}>
                            <ListItemIcon>
                                <ConfirmationNumber />
                            </ListItemIcon>
                            <ListItemText primary={t('Add a ticket')} />
                        </MenuItem>
                    )}
                    {(user?.permissions?.tasks === true) && (
                        <MenuItem onClick={handleTaskDialogOpen}>
                            <ListItemIcon>
                                <TaskAlt />
                            </ListItemIcon>
                            <ListItemText primary={t('Add a task')} />
                        </MenuItem>
                    )}
                    {(user?.permissions?.meetings === true) && (
                        <MenuItem onClick={handleMeetingDialogOpen}>
                            <ListItemIcon>
                                <Duo />
                            </ListItemIcon>
                            <ListItemText primary={t('Add a meeting')} />
                        </MenuItem>
                    )}
                </Menu>
            </>
        );
    }
    return (
        <></>
    );
};

export default CrmMenu;