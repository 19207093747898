import { lazy, Suspense, useEffect, useState } from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Hidden,
  LinearProgress,
  Button,
  Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import TabPanel from 'src/components/tabPanel/TabPanel';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { useAppConext } from 'src/components/context/AppContext';
import RestrictedFieldWrapper from 'src/components/app/RestrictedFieldWrapper';
import WidgetsMenu from 'src/components/widget/WidgetsMenu';
import NoContent from 'src/components/app/NoContent';
import { List, Lock } from '@mui/icons-material';

const MenuConfig = lazy(() => import('../../components/settings/menu/MenuConfig'));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Menu = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [emptyMenuItems, setEmptyMenuItems] = useState(false);
  const { user, getFeatureValue } = useAppConext();
  const { chatWebSocket } = useOutletContext();

  const scrollHandler = () => {
    if (document.getElementById('arcu-layout-content') === null || document.getElementById('arcontactus-preview') === null) {
      return false;
    }
    const el = document.getElementById('arcu-layout-content');
    const wh = document.getElementById('arcontactus-preview').clientHeight;
    let t = el.scrollTop + el.clientHeight - 93 - wh - 20 - 8 - 30;
    const maxHeight = document.getElementById('arcontactus-preview').parentElement.clientHeight - document.getElementById('arcontactus-preview').clientHeight - 16;
    if (t > maxHeight) {
      t = maxHeight;
    }
    document.getElementById('arcontactus-preview').style.top = `${t}px`;
    return t;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    scrollHandler();
  };

  useEffect(() => {
    if (document.getElementById('arcu-layout-content') !== null) {
      document.getElementById('arcu-layout-content').addEventListener('scroll', scrollHandler);
    }
    window.addEventListener('resize', scrollHandler);
    scrollHandler();
    return () => {
      if (document.getElementById('arcu-layout-content') !== null) {
        document.getElementById('arcu-layout-content').removeEventListener('scroll', scrollHandler);
      }
      window.removeEventListener('resize', scrollHandler);
    };
  }, []);

  const handleEmptyMenuItems = (empty) => {
    setEmptyMenuItems(empty);
  };

  if ((user?.permissions?.settingsMenu === false)) {
    return (
        <Box sx={{ height: 'calc(100vh - 164px)', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <NoContent
                containerSx={{
                    my: 'auto'
                }}
                icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                title={t('Forbidden')}
                content={t("You're don't have access to this page")}
            />
        </Box>
    );
  }

  return (
    <>
      <PageMeta title={t('Menu settings')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: {
            xs: 1,
            md: 2
          }
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            xl={8}
            md={12}
            xs={12}
          >
            <RestrictedFieldWrapper restricted={!getFeatureValue('menu.enabled')} fullWidth>
              <Card>
                <CardHeader
                  title={t('Menu settings')}
                  sx={{
                    '& .MuiCardHeader-action': {
                      my: 'auto'
                    }
                  }}
                  action={(
                    <>
                      <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'inline-flex', mr: 1 }}>
                        <WidgetsMenu chatWebSocket={chatWebSocket} configUrl="/widgets?includeAgency=0" onAddWidget={null} />
                      </Box>
                      {(user?.permissions?.docs) && (
                        <Tooltip title={t('Help')}>
                          <IconButton component="a" href="https://docs.anychat.one/multichannel-contact-button/customization/menu" target="_blank">
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                />
                <Divider />
                <CardContent sx={{ p: 0, pb: '0 !important' }}>
                    <Tabs
                      sx={{ mb: 2 }}
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="disabled tabs example"
                    >
                      <Tab label={t('Desktop')} {...a11yProps(0)} />
                      <Tab label={t('Mobile')} {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Box sx={{ px: 2 }}>
                        <Suspense fallback={<LoadingProgress open />}>
                          <MenuConfig onEmptyItems={handleEmptyMenuItems} onLoad={scrollHandler} onPreviewLoadingChange={(e) => setPreviewLoading(e)} visible={value === 0} configUrl="/config/get" saveUrl="/config/save" modelName="MenuConfig" />
                        </Suspense>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box sx={{ px: 2 }}>
                        <Suspense fallback={<LoadingProgress open />}>
                          <MenuConfig onEmptyItems={handleEmptyMenuItems} onLoad={scrollHandler} onPreviewLoadingChange={(e) => setPreviewLoading(e)} visible={value === 1} configUrl="/config/get" saveUrl="/config/save" modelName="MenuMobileConfig" isMobile />
                        </Suspense>
                      </Box>
                    </TabPanel>
                </CardContent>
              </Card>
            </RestrictedFieldWrapper>
          </Grid>
          <Hidden lgDown>
            <Grid item xl={4} lg={4} md={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <CardHeader
                    sx={{ minHeight: 72 }}
                    title={t('Widget preview')}
                  />
                  <Divider />
                  <CardContent
                    sx={{
                      position: 'relative',
                      flex: 1,
                      overflow: 'hidden',
                      transform: 'translate(0)'
                    }}
                  >
                    {(previewLoading) && (
                      <Box
                        sx={{
                          width: '100%',
                          position: 'absolute',
                          top: 0,
                          left: 0
                        }}
                      >
                        <LinearProgress />
                      </Box>
                    )}
                    <div id="arcontactus-preview" />
                    {(emptyMenuItems === true) && (
                      <NoContent
                        title={t('No channels')}
                        content={(
                          <>
                            <Typography>
                              {t('You have no channels for this widget.')}
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                              {t('To appear widget on your site, you need to add at least one channel.')}
                            </Typography>
                          </>
                        )}
                        buttonTitle={t('Manage channels')}
                        buttonProps={{
                          variant: 'contained',
                          color: 'primary',
                          disableElevation: true,
                          startIcon: <List />,
                          component: NavLink,
                          to: "/settings/channels"
                        }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </>
  );
};

export default Menu;
