import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import useWidget from 'src/components/app/useWidget';
import useToken from 'src/components/app/useToken';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import $api from '../http';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useWorkspace from './useWorkspace';

let listeners = [];
let authenticated = false;
let reconnectCount = 0;

const useChatWebSocket = () => {
  const { t } = useTranslation();
  const { getWidget } = useWidget();
  const { getToken } = useToken();
  const { getWorkspace } = useWorkspace();
  const { threadId } = useParams();
  const socketUrl = process.env.REACT_APP_LIVECHAT_WEBSOCKET_URL;
  const [userGuid, setUserGuid] = useState(null);
  const [threadInvites, setThreadInvites] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [connect, setConnect] = useState(true);

  const getUserGuid = () => localStorage.getItem('anychat-op-guid');

  useEffect(() => {
    setUserGuid(getUserGuid());
  }, []);

  useEffect(() => {
    localStorage.setItem('anychat-op-guid', userGuid);
  }, [userGuid]);

  const notify = (data) => {
    listeners.forEach((listener) => listener(data));
  };

  const handleNewMessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.action === 'auth') {
      setUserGuid(data.auth.guid);
      localStorage.setItem('online-status', data.onlineStatus);
    }
    if (data.action === 'inviteToThread') {
      const invites = threadInvites;
      invites.push(data.thread);
      setThreadInvites(invites);
    }
    if (data.action === 'wsError') {
      if (data.errorCode === 403) {
        refreshToken(data);
      } else {
        enqueueSnackbar(data.message, {
          variant: 'error'
        });
      }
    }
    notify(data);
  };

  const refreshToken = async (socketData) => {
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}v1/auth/refresh`, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            },
        });
        localStorage.setItem('accessToken', res.data.accessToken);
        return sendJsonMessage({
          ...socketData.origin,
          auth: {
            ...socketData.origin.auth,
            token: res.data.accessToken
          }
        })
    } catch (e) {
        console.log('Auth error', e);
        const back = encodeURIComponent(window.location.pathname + window.location.search);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('role');
        localStorage.removeItem('tariff');
        window.location.assign(`/login?back=${back}`);
    }
  };

  const getAuth = () => {
    return {
      guid: getUserGuid(),
      widget: getWidget(),
      token: getToken(),
      workspace: getWorkspace()
    };
  };

  const {
    sendJsonMessage,
    readyState
  } = useWebSocket(socketUrl, {
    onOpen: () => {
      if (!authenticated) {
        sendJsonMessage({
          action: 'auth',
          thread: threadId,
          auth: getAuth()
        });
        authenticated = true;
      }
      if (reconnectCount > 0) {
        enqueueSnackbar(t('Connected to the server'), {
          variant: 'success'
        });
        reconnectCount = 0;
      }
    },
    onClose: () => {
      authenticated = false;
    },
    onReconnectStop: () => {
      setConnect(false);
      if (reconnectCount > 0) {
        $api.post('/health/ws-fail', {});
      }
      enqueueSnackbar(t(t('Error connecting to server. Please retry or refresh the page.')), {
        variant: 'error',
        autoHideDuration: null,
        action: (
          <Button sx={{ color: 'primary.contrastText' }} onClick={() => {setConnect(true); closeSnackbar(); reconnectCount ++;}}>
            {t('Retry')}
          </Button>
        )
      });
    },
    onMessage: handleNewMessage,
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
    reconnectAttempts: 2
  }, connect);

  const subscribe = (listn) => {
    listeners.push(listn);
  };

  const unsubscribe = (listn) => {
    listeners = listeners.filter((entity) => entity !== listn);
  };

  const cleanUpListeners = () => {
    listeners = [];
  };

  const sendJson = (data) => {
    sendJsonMessage(data);
  };

  return {
    subscribe,
    unsubscribe,
    cleanUpListeners,
    notify,
    sendJsonMessage,
    sendJson,
    readyState,
    userGuid,
    getWidget,
    getToken,
    getWorkspace,
    getUserGuid,
    getAuth,
    authenticated
  };
};

export default useChatWebSocket;
