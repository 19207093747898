import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $api from "../http";
import { Cancel, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NoContent from "../app/NoContent";

let searchTimeout = null;

const RelatedContactDialog = (props) => {
    const { open, onClose, contactGuid, onSubmit, submitUrl } = props;
    const [error, setError] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [selectedItems, setSelectedItems] = useState({});

    const { t } = useTranslation();

    const searchContact = (s) => {
        setLoading(true);
        $api.post('/contact', {
            s: s,
            limit: 10
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setOptions(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const handleSearch = (e) => {
        setInputValue(e.target.value);
    };

    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            searchContact(inputValue)
        }, 500);
    }, [inputValue]);

    useEffect(() => {
        if (open === true) {
            setInputValue('');
            setSelectedItems({});
        }
    }, [open]);

    const handleSelectItem = (item) => {
        setSelectedItems((old) => {
            if (old[item.guid] !== undefined) {
                const {[item.guid]: undefined, ...newItems} = old;
                return newItems;
            } 
            return {...old, [item.guid]: item};
        });
    };

    const handleRemoveItem = (guid) => {
        setSelectedItems((old) => {
            const {[guid]: undefined, ...newObject} = old;
            return newObject;
        });
    };

    const handleSubmit = () => {
        const relatedGuids = Object.keys(selectedItems);
        $api.post(submitUrl, {
            guid: contactGuid,
            relatedGuid: relatedGuids
        })
            .catch((err) => {

            })
            .then((res) => {
                onSubmit();
            })
            .finally(() => {

            })
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                {t('Add related contacts')}
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Box sx={{ p: 2}}>
                            <TextField
                                error={!!error}
                                placeholder={t('Enter user name, email address or phone')}
                                helperText={error}
                                onChange={handleSearch}
                                value={inputValue}
                                fullWidth
                                sx={{ mt: '2px' }}
                                InputProps={{
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <Box sx={{ width: 40, height: 40, display: 'flex' }}>
                                                    <CircularProgress color="inherit" size={20} sx={{ m: 'auto' }} />
                                                </Box>
                                            ) : (
                                                <IconButton sx={{ display: inputValue === '' ? 'none' : 'flex' }} onClick={() => setInputValue('')}>
                                                    <Cancel />
                                                </IconButton>
                                            )}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                            <List dense sx={{ height: 400, overflow: 'auto', mt: 1, p: 0 }}>
                                {(options.map((i) => (
                                    <ListItemButton disabled={contactGuid === i.guid} selected={selectedItems[i.guid] !== undefined} sx={{ borderRadius: 1, my: '2px' }} onClick={() => handleSelectItem(i)} key={i.guid}>
                                        <ListItemAvatar>
                                            <Avatar src={i.img} />
                                        </ListItemAvatar>
                                        <ListItemText primary={i.name || t('Visitor')} secondary={i.email || 'Email not set'} />
                                    </ListItemButton>
                                )))}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: 'divider' }}>
                        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                            {(Object.keys(selectedItems).length > 0) ? (
                                <List dense sx={{ p: 0, mt: 0, height: 466, overflow: 'auto' }}>
                                    {(Object.keys(selectedItems).map((i) => (
                                        <ListItem key={i} sx={{ bgcolor: 'background.default', borderRadius: 1, my: '2px' }}>
                                            <ListItemAvatar>
                                                <Avatar src={selectedItems[i].img} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={selectedItems[i].name || t('Visitor')}
                                                secondary={selectedItems[i].email || t('Email not set')}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleRemoveItem(i)}>
                                                    <Close />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )))}
                                </List>
                            ) : (
                                <NoContent
                                    title={t('No items selected')}
                                    content={t('Select items in the left section')}
                                    containerSx={{
                                        my: 'auto'
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <LoadingButton onClick={handleSubmit} disabled={Object.keys(selectedItems).length === 0} variant="contained" color="primary" disableElevation>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default RelatedContactDialog;