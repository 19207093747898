import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import useToken from 'src/components/app/useToken';
import useWidget from 'src/components/app/useWidget';
import useTariff from 'src/components/app/useTariff';
import SocialLogin from 'src/components/app/SocialLogin';
import { LoadingButton } from '@mui/lab';
import useRole from '../app/useRole';
import { useSnackbar } from 'notistack';
import $api from '../http';
import moment from 'moment';
import PageMeta from '../app/PageMeta';
import { useAppConext } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import useWorkspace from '../app/useWorkspace';
import pjson from "../../../package.json";
import OTPInput from 'react-otp-input';
import { KeyboardArrowLeft } from '@mui/icons-material';

const Login = () => {
  const { t } = useTranslation();
  const { login, isLoggedIn } = useToken();
  const { setWidget } = useWidget();
  const { setWorkspace } = useWorkspace();
  const { setTariff, setIsAgency } = useTariff();
  const navigate = useNavigate();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentWidget, setCurrentWidget] = useState(null);
  const [currentTariff, setCurrentTariff] = useState(null);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [registerUrl, setRegisterUrl] = useState('/register');
  const [tfa, setTfa] = useState(false);
  const [tfaToken, setTfaToken] = useState(null);
  const [code, setCode] = useState('');
  const [validationError, setValidationError] = useState(false);
  const { brandInfo, loadBrandInfo } = useAppConext();
  const {setRole} = useRole();
  const { enqueueSnackbar } = useSnackbar();
  const { getDeviceID, loadUser } = useAppConext();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('back')) {
    }
  }, []);

  useEffect(() => {
    if (currentWidget) {
      setWidget(currentWidget);
    }
  }, [currentWidget]);

  useEffect(() => {
    if (currentTariff) {
      setTariff(currentTariff);
    }
  }, [currentTariff]);

  useEffect(() => {
    if (currentWorkspace) {
      setWorkspace(currentWorkspace);
    }
  }, [currentWorkspace]);

  const submit2FA = () => {
    setValidationError(false);
    setIsSubmitting(true);
    $api.post('/auth/2fa', {
      token: tfaToken,
      code: code,
      timezone: moment.tz.guess(),
      device: {
        type: 'browser',
        userAgent: window?.navigator?.userAgent,
        language: window?.navigator?.language,
        platform: window?.navigator?.platform,
        appVersion: pjson.version
      },
      deviceID: getDeviceID()
    })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message || t('Login error'), {
          variant: 'error'
        });
        setValidationError(true);
      })
      .then((res) => {
        if (res) {
          onSocialLoginSuccess(res);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  };

  const onSocialLoginSuccess = (response) => {
    if (response && response.data) {
      setIsSubmitting(false);
      if (response.data.error) {
        enqueueSnackbar(response.data.error, {
          variant: 'error'
        });
        return null;
      }
      if (response.data.tfa_required === true) {
        setTfa(true);
        setTfaToken(response.data.token);
        setCode('');
      } else if (response.data.accessToken) {
        setTfa(false);
        login(response.data.accessToken);
        setCurrentWidget(response.data.widgetUID);
        setCurrentTariff(response.data.tariff);
        setCurrentWorkspace(response.data.defaultWorkspace.guid);
        setRole(response.data.role);
        setIsAgency(response.data.is_agency);
        loadBrandInfo();
        loadUser();
        if (isLoggedIn()) {
          const back = new URLSearchParams(window.location.search).get('back');
          if (back) {
            navigate(decodeURIComponent(back), { replace: true });
          } else {
            navigate('/app', { replace: true });
          }
        }
      }
    }
    return true;
  };

  const onSocialLoginFailure = (err) => {
    if (err && err.response && err.response.status && err.response.status === 422) {
      setErrors(err.response.data);
    }
    if (err && err.response && err.response.data && err.response.data.error) {
      enqueueSnackbar(err.response.data.error, {
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Login failure', {
        variant: 'error'
      });
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true);
    $api.post('/auth/login', {
      username: username,
      password: password,
      timezone: moment.tz.guess(),
      device: {
        type: 'browser',
        userAgent: window?.navigator?.userAgent,
        language: window?.navigator?.language,
        platform: window?.navigator?.platform,
        appVersion: pjson.version
      },
      deviceID: getDeviceID()
    })
      .catch((err) => onSocialLoginFailure(err))
      .then((response) => onSocialLoginSuccess(response));
  };

  useEffect(() => {
    if (code.length === 6) {
        submit2FA();
    } else if (code.length === 0) {
        setValidationError(false);
    }
  }, [code]);

  return (
    <>
      <PageMeta title="Sign in" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          height: '100%',
          my: 'auto',
          justifyContent: 'center',
          pb: 3,
          pt: 3
        }}
      >
        {(window.location.hostname !== process.env.REACT_APP_HOSTNAME) && (
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img style={{ height: brandInfo.logoSize || 64, width: 'auto' }} src={brandInfo.logo} alt={brandInfo.title} />
          </Box>
        )}
        <Container maxWidth="sm">
          {(tfa === true) ? (
            <Box sx={{ maxWidth: 340, mx: 'auto' }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  sx={{ textAlign: 'center' }}
                >
                  {t('Two-factor authentication')}
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                  sx={{ textAlign: 'center', mb: 3, mt: 1 }}
                >
                  {t('Please enter your one-time password.')}
                </Typography>
                <OTPInput
                  value={code}
                  onChange={setCode}
                  numInputs={6}
                  renderSeparator={<span style={{ width: 10 }} />}
                  renderInput={(props) => <input disabled={isSubmitting} {...props} />}
                  inputType="tel"
                  containerStyle={{
                      justifyContent: 'center'
                  }}
                  inputStyle={{
                      padding: '8px 8px',
                      fontSize: '2rem',
                      width: '1.5em',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: validationError ? '#f50057' : 'rgba(0, 0, 0, 0.23)'
                  }}
                />
                <Box sx={{ py: 2, alignItems: 'center', display: 'flex' }}>
                    <Button onClick={() => {
                      setTfa(false);
                    }} size="large" sx={{ mr: 'auto' }} startIcon={<KeyboardArrowLeft />}>
                      {t('Back')}
                    </Button>
                    <LoadingButton
                      color="primary"
                      loading={isSubmitting}
                      onClick={submit2FA}
                      sx={{ minWidth: 160 }}
                      size="large"
                      type="button"
                      variant="contained"
                    >
                      {t('Submit')}
                    </LoadingButton>
                  </Box>
            </Box>
          ) : (
            <Formik
              noValidate
              onSubmit={handleSubmit}
            >
              {({
                handleBlur
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      {t('Sign in')}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      {t('Sign in on the internal platform')}
                    </Typography>
                  </Box>
                  {(process.env.REACT_APP_HOSTNAME === window.location.hostname) && (
                    <>
                      <SocialLogin
                        onSuccess={onSocialLoginSuccess}
                        onFailure={onSocialLoginFailure}
                      />
                      <Box
                        sx={{
                          pb: 1,
                          pt: 3
                        }}
                      >
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="body1"
                        >
                          {t('or login with email address')}
                        </Typography>
                      </Box>
                    </>
                  )}
                  
                  <TextField
                    error={Boolean(errors.username)}
                    fullWidth
                    helperText={errors.username}
                    label={t('Email Address')}
                    margin="normal"
                    required
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => setUserName(e.target.value)}
                    type="email"
                    value={username}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(errors.password)}
                    fullWidth
                    helperText={errors.password}
                    label={t('Password')}
                    required
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    value={password}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <LoadingButton
                      color="primary"
                      loading={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('Sign in now')}
                    </LoadingButton>
                  </Box>
                  {((window.location.hostname === process.env.REACT_APP_HOSTNAME) ||(brandInfo && brandInfo.allowSignup === 1)) && (
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      {t("Don't have an account?")}
                      {' '}
                      <Link
                        component={RouterLink}
                        to={registerUrl}
                        variant="h6"
                      >
                        {t('Sign up')}
                      </Link>
                    </Typography>
                  )}
                  
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    {t('Forget a password?')}
                    {' '}
                    <Link
                      component={RouterLink}
                      to="/reset-password"
                      variant="h6"
                    >
                      {t('Reset password')}
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          )}
          
          
        </Container>
      </Box>
    </>
  );
};

export default Login;
