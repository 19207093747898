import { AccountCircle, DataUsage, NotificationsActive, ReceiptLong, RocketLaunch } from "@mui/icons-material";
import { Avatar, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { useAppConext } from "../context/AppContext";

const AccountSidebar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { user } = useAppConext();

    return (
        <Paper elevation={0} sx={{ borderRadius: 1 }}>
            <List>
                <ListItemButton selected={!!matchPath({
                    path: '/app/account',
                    end: true,
                }, location.pathname)} component={NavLink} to="/app/account">
                    <ListItemIcon sx={{ minWidth: 36 }}>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('Account info')}
                    />
                </ListItemButton>
                {(user?.role === 'owner') && (
                    <ListItemButton selected={!!matchPath({
                        path: '/app/account/plan',
                        end: true,
                    }, location.pathname)} component={NavLink} to="/app/account/plan">
                        <ListItemIcon sx={{ minWidth: 36 }}>
                            <RocketLaunch />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('Plan & features')}
                        />
                    </ListItemButton>
                )}
                {(user?.role === 'owner') && (
                    <ListItemButton selected={!!matchPath({
                        path: '/app/account/usage',
                        end: true,
                    }, location.pathname)} component={NavLink} to="/app/account/usage">
                        <ListItemIcon sx={{ minWidth: 36 }}>
                            <DataUsage />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('Usage & limits')}
                        />
                    </ListItemButton>
                )}
                {(user?.role === 'owner' && user?.permissions?.upgrade === true) && (
                    <ListItemButton selected={!!matchPath({
                        path: '/app/account/billing',
                        end: true,
                    }, location.pathname)} component={NavLink} to="/app/account/billing">
                        <ListItemIcon sx={{ minWidth: 36 }}>
                            <ReceiptLong />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('Billing')}
                        />
                    </ListItemButton>
                )}
                <ListItemButton selected={!!matchPath({
                    path: '/app/account/notifications',
                    end: true,
                }, location.pathname)} component={NavLink} to="/app/account/notifications">
                    <ListItemIcon sx={{ minWidth: 36 }}>
                        <NotificationsActive />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('Notifications settings')}
                    />
                </ListItemButton>
            </List>
        </Paper>
    );
};

export default AccountSidebar;