import {
  Box,
  Container,
} from '@mui/material';

//import DashboardComponent from 'src/components/dashboard/Dashboard';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';

const DashboardComponent = lazy(() => import('../../../src/components/dashboard/Dashboard'));

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Dashboard')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
            xs: 1,
            md: 2
          },
          py: {
            xs: 1,
            md: 2
          }
        }}
      >
        <Suspense fallback={<LoadingProgress open />}>
          <DashboardComponent />
        </Suspense>
      </Box>
    </>
  );
};

export default Dashboard;
