import React, { useContext, useEffect } from "react";
import { useAppConext } from "./AppContext";
import { ColorModeContext } from "./ColorModeContext";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import theme from 'src/theme/theme';
import { lightPallette } from "src/theme/pallette";
import LoadingProgress from "../app/LoadingProgress";

export const useColorModeContext = () => {
    return useContext(ColorModeContext);
};

const ColorModeContextProvider = ({children}) => {
    const { brandInfo, brandingLoaded } = useAppConext();

    const [pallete, setPallete] = React.useState(lightPallette);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [mode, setMode] = React.useState('light');
    const [currentMode, setCurrentMode] = React.useState(null)

    useEffect(() => {
        if (brandingLoaded) {
            setPallete(mode === 'light' ? brandInfo?.colorTheme?.light : brandInfo?.colorTheme?.dark);
        }
    }, [mode, brandingLoaded, brandInfo]);

    const colorMode = React.useMemo(() => ({
        toggleColorMode: (mode) => {
            if (mode === 'auto') {
                setMode(prefersDarkMode ? 'dark' : 'light');
            } else {
                setMode(mode);
            }
            setCurrentMode(mode);
        },
        currentMode: currentMode
    }), [currentMode, prefersDarkMode]);

    useEffect(() => {
        if (currentMode !== null){
            if (currentMode === 'auto'){
                colorMode.toggleColorMode('auto');
            }
            localStorage.setItem('displayMode', currentMode);
        }
    }, [prefersDarkMode, currentMode, colorMode]);

    useEffect(() => {
        const displayMode = localStorage.getItem('displayMode');
        if (displayMode) {
            colorMode.toggleColorMode(displayMode);
        } else {
            colorMode.toggleColorMode('light');
        }
    }, [colorMode]);

    const appTheme = React.useMemo(() => {
        if (brandingLoaded) {
            return createTheme({
                ...theme,
                palette: {
                    ...pallete,
                    mode
                },
            });
        }
    }, [mode, pallete, brandingLoaded, brandInfo]);

    return (
        <ColorModeContext.Provider value={{
            appTheme,
            currentMode,
            colorMode,
            toggleColorMode: colorMode.toggleColorMode
        }}>
            {(brandingLoaded) ? (
                <ThemeProvider theme={appTheme}>
                    {children}
                </ThemeProvider>
            ) : (
                <LoadingProgress open />
            )}
        </ColorModeContext.Provider>
    );
};

export default ColorModeContextProvider;