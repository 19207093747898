import { Avatar, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Popover, TextField } from "@mui/material";
import $api from "../http";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cancel, PersonOff } from "@mui/icons-material";
import { useOutletContext } from "react-router";
import ConnectionOpenedBadge from "../chat/ConnectionOpenedBadge";

let searchTimeout = null;

const AgentMenu = (props) => {
    const { open, onClose, anchorEl, currentAgentGuid, onSelect } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const {chatWebSocket} = useOutletContext();
    const [onlineOperators, setOnlineOperators] = useState([]);

    const { t } = useTranslation();

    const chatListener = (socketData) => {
        if (socketData.action === 'onlineOperators' && socketData.workspace === chatWebSocket.getWorkspace()) {
            setOnlineOperators(socketData.operators);
        }
    };

    const checkOnline = () => {
        chatWebSocket.sendJson({
            action: 'getOnlineOperators',
            auth: chatWebSocket.getAuth()
        });
    };

    useEffect(() => {
        chatWebSocket.subscribe(chatListener);
        return () => {
            chatWebSocket.unsubscribe(chatListener);
        };
    }, []);

    const loadContent = (s) => {
        setIsLoading(true);
        $api.post(`/live-chat-agent`, {
            s: s
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            loadContent(searchTerm);
        }, 500);
    }, [searchTerm]);

    useEffect(() => {
        if (open) {
            loadContent('');
            checkOnline();
        }
    }, [open]);

    return (
        <Popover anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom'
        }} open={open} anchorEl={anchorEl} onClose={onClose}>
            <Box sx={{ p: 1, width: 320 }}>
                <TextField
                    fullWidth
                    name="search"
                    autoFocus
                    value={searchTerm}
                    placeholder={t('Search agent')}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? (
                                    <Box sx={{ width: 40, height: 40, display: 'flex' }}>
                                        <CircularProgress color="inherit" size={20} sx={{ m: 'auto' }} />
                                    </Box>
                                ) : (
                                    <IconButton sx={{ display: searchTerm === '' ? 'none' : 'flex' }} onClick={() => setSearchTerm('')}>
                                        <Cancel />
                                    </IconButton>
                                )}
                            </React.Fragment>
                        ),
                    }}
                />
            </Box>
            <Divider />
            <List sx={{ maxHeight: 400, overflow: 'auto' }} dense>
                {(items.length > 0) ? (
                    <>
                        {(items.map((i) => (
                            <ListItemButton onClick={() => onSelect(i.guid)} selected={currentAgentGuid === i.guid}>
                                <ListItemAvatar>
                                    {(onlineOperators.includes(i.guid) === true) ? (
                                        <ConnectionOpenedBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            <Avatar sx={{ my: 'auto' }} src={i.image} />
                                        </ConnectionOpenedBadge>
                                    ) : (
                                        <Avatar sx={{ my: 'auto' }} src={i.image} />
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primaryTypographyProps={{
                                        noWrap: true
                                    }}
                                    secondaryTypographyProps={{
                                        noWrap: true
                                    }}
                                    primary={i.name}
                                    secondary={i.email}
                                />
                            </ListItemButton>
                        )))}
                        <ListItemButton onClick={() => onSelect(null)} selected={currentAgentGuid === null || currentAgentGuid === '' || currentAgentGuid === undefined}>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonOff />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true,
                                    sx: {
                                        color: 'error.main'
                                    }
                                }}
                                secondaryTypographyProps={{
                                    noWrap: true
                                }}
                                primary={t('Un-assign contact')}
                                secondary={t('Remove assignment')}
                            />
                        </ListItemButton>
                    </>
                ) : (
                    ((isLoading === false) && (
                        <ListItem>
                            {t('No agents found')}
                        </ListItem>
                    ))
                )}
            </List>
        </Popover>
    );
};

export default AgentMenu;