import { Dns, Email, Instagram, Telegram, WhatsApp } from "@mui/icons-material";
import { Box, Chip, SvgIcon, Tooltip } from "@mui/material";
import { useAppConext } from "../context/AppContext";
import GoogleBcIcon from "../icons/GoogleBcIcon";

const ParentElement = ({chip, label, color, icon, children}) => {
    return (
        chip ? <Chip size="small" sx={{ bgcolor: color, color: '#FFF' }} label={label} icon={(
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                {icon}
            </Box>
        )} /> : <>{children}</>
    );
};

export const getChannelTitle = (channel) => {
  return channel === 'livechat' ? 'LiveChat'
      : channel === 'whatsapp' ? 'WhatsApp'
      : channel === 'whatsappqr' ? 'WhatsApp'
      : channel === 'messenger' ? 'Messenger'
      : channel === 'instagram' ? 'Instagram'
      : channel === 'telegram' ? 'Telegram'
      : channel === 'line' ? 'Line'
      : channel === 'viber' ? 'Viber'
      : channel === 'form' ? 'Form'
      : channel === 'manual' ? 'Manual'
      : channel === 'email' ? 'Email'
      : channel === 'googlebc' ? 'Google BC'
      : 'unknown';
};

export const getChannelColor = (channel) => {
  return channel === 'livechat' ? '#536dfe'
      : channel === 'whatsapp' ? '#25d366'
      : channel === 'whatsappqr' ? '#25d366'
      : channel === 'messenger' ? '#0084ff'
      : channel === 'instagram' ? '#C13584'
      : channel === 'telegram' ? '#0088cc'
      : channel === 'line' ? '#06C755'
      : channel === 'viber' ? '#8f5db7'
      : channel === 'form' ? '#7B1FA2'
      : channel === 'manual' ? '#2e7d32'
      : channel === 'email' ? '#2e7d32'
      : channel === 'googlebc' ? '#3B78E7'
      : '#00000014';
};

const SourceIcon = (props) => {
    const {
        channel,
        size = 16,
        chip = false,
        white = true,
        rounded = true,
        tooltipPlacement = 'left'
    } = props;
    const { brandInfo } = useAppConext();
    
    const icon = (
        <>
            {(channel === 'livechat') && (
              brandInfo?.headerLogo ? (
                <Tooltip title="Source: livechat" placement={tooltipPlacement} arrow>
                  <img src={white ? brandInfo.headerLogo : brandInfo.liveChatIcon} style={{ width: size - 2, height: size - 2, display: 'block', borderRadius: rounded ? '50%' : 0 }} />
                </Tooltip>
              ) : (
                <Tooltip title="Source: livechat" placement={tooltipPlacement} arrow>
                  <SvgIcon sx={{ fillRule: 'evenodd', clipRule: 'evenodd', color: chip ? '#FFF' : '#536dfe', width: size, height: size }} viewBox="0 0 21000 21000">
                    <path fill="currentColor" d="M4427.37 7165.26l0.12 0.31c21.75,45.26 41.89,91.44 60.35,138.47 619.02,1245.93 3114.75,1931.06 3621.9,1095.63 753.74,-1441.59 2263.14,-2425.59 4002.59,-2425.59 602.16,0 1176.63,118.2 1701.89,332.19l-0.77 -0.68c1716.41,555.85 3059.47,-1164.02 3059.47,-2847.34 0,-872.55 707.36,-1579.91 1579.91,-1579.91 872.53,0 1579.9,707.36 1579.9,1579.91 0,872.53 -707.35,1579.9 -1579.9,1579.9 -1344.71,0 -2964.8,1911.24 -2395.66,3254l-0.75 -0.67c362.92,650.11 569.97,1399.18 569.97,2196.67 0,728.12 -172.62,1415.82 -478.81,2024.81l0.59 -0.56c-529.15,1418.63 1656.95,3855.9 2908.36,3910.85 672.44,29.53 1218.79,545.69 1218.79,1218.79 0,673.11 -545.69,1218.78 -1218.79,1218.78 -673.1,0 -1214.28,-545.68 -1218.8,-1218.78 -8.71,-1302.38 -2566.99,-3549.3 -3984.18,-2987.91l0.37 -0.35c-535.89,224.35 -1124.25,348.42 -1741.59,348.42 -701.23,0 -1364.98,-160.09 -1957.02,-445.41l0.76 0.65c-2441.44,877.71 -2482.42,861.74 -2294.14,253.3l541.26 -1749.14 0.92 0.79c-492.66,-708.35 -787.59,-1564.58 -804.77,-2488.74 -11.47,-1053.06 -2585.61,-1668.16 -3710.05,-939.95 -49.5,41.27 -100.99,80.22 -154.27,116.76l-0.98 0.82 0.03 -0.17c-330.08,226.1 -729.44,358.44 -1159.73,358.44 -1134.91,0 -2054.93,-920.02 -2054.93,-2054.93 0,-1134.91 920.02,-2054.93 2054.93,-2054.93 816.33,0 1521.47,476.02 1853.03,1165.61l0 -0.04zm7684.95 2591.5c403.93,0 731.38,327.45 731.38,731.38 0,403.93 -327.45,731.38 -731.38,731.38 -403.93,0 -731.38,-327.45 -731.38,-731.38 0,-403.93 327.45,-731.38 731.38,-731.38zm2126.97 0c403.93,0 731.38,327.45 731.38,731.38 0,403.93 -327.45,731.38 -731.38,731.38 -403.93,0 -731.37,-327.45 -731.37,-731.38 0,-403.93 327.44,-731.38 731.37,-731.38zm-4276.81 0c403.93,0 731.38,327.45 731.38,731.38 0,403.93 -327.45,731.38 -731.38,731.38 -403.93,0 -731.38,-327.45 -731.38,-731.38 0,-403.93 327.45,-731.38 731.38,-731.38z" />
                  </SvgIcon>
                </Tooltip>
              )
            )}
            {(channel === 'manual') && (
              brandInfo?.headerLogo ? (
                <Tooltip title="Source: livechat" placement={tooltipPlacement} arrow>
                  <img src={white ? brandInfo.headerLogo : brandInfo.liveChatIcon} style={{ width: size - 2, height: size - 2, display: 'block', borderRadius: rounded ? '50%' : 0 }} />
                </Tooltip>
              ) : (
                <Tooltip title="Source: livechat" placement={tooltipPlacement} arrow>
                  <SvgIcon sx={{ fillRule: 'evenodd', clipRule: 'evenodd', color: chip ? '#FFF' : '#536dfe', width: size, height: size }} viewBox="0 0 21000 21000">
                    <path fill="currentColor" d="M4427.37 7165.26l0.12 0.31c21.75,45.26 41.89,91.44 60.35,138.47 619.02,1245.93 3114.75,1931.06 3621.9,1095.63 753.74,-1441.59 2263.14,-2425.59 4002.59,-2425.59 602.16,0 1176.63,118.2 1701.89,332.19l-0.77 -0.68c1716.41,555.85 3059.47,-1164.02 3059.47,-2847.34 0,-872.55 707.36,-1579.91 1579.91,-1579.91 872.53,0 1579.9,707.36 1579.9,1579.91 0,872.53 -707.35,1579.9 -1579.9,1579.9 -1344.71,0 -2964.8,1911.24 -2395.66,3254l-0.75 -0.67c362.92,650.11 569.97,1399.18 569.97,2196.67 0,728.12 -172.62,1415.82 -478.81,2024.81l0.59 -0.56c-529.15,1418.63 1656.95,3855.9 2908.36,3910.85 672.44,29.53 1218.79,545.69 1218.79,1218.79 0,673.11 -545.69,1218.78 -1218.79,1218.78 -673.1,0 -1214.28,-545.68 -1218.8,-1218.78 -8.71,-1302.38 -2566.99,-3549.3 -3984.18,-2987.91l0.37 -0.35c-535.89,224.35 -1124.25,348.42 -1741.59,348.42 -701.23,0 -1364.98,-160.09 -1957.02,-445.41l0.76 0.65c-2441.44,877.71 -2482.42,861.74 -2294.14,253.3l541.26 -1749.14 0.92 0.79c-492.66,-708.35 -787.59,-1564.58 -804.77,-2488.74 -11.47,-1053.06 -2585.61,-1668.16 -3710.05,-939.95 -49.5,41.27 -100.99,80.22 -154.27,116.76l-0.98 0.82 0.03 -0.17c-330.08,226.1 -729.44,358.44 -1159.73,358.44 -1134.91,0 -2054.93,-920.02 -2054.93,-2054.93 0,-1134.91 920.02,-2054.93 2054.93,-2054.93 816.33,0 1521.47,476.02 1853.03,1165.61l0 -0.04zm7684.95 2591.5c403.93,0 731.38,327.45 731.38,731.38 0,403.93 -327.45,731.38 -731.38,731.38 -403.93,0 -731.38,-327.45 -731.38,-731.38 0,-403.93 327.45,-731.38 731.38,-731.38zm2126.97 0c403.93,0 731.38,327.45 731.38,731.38 0,403.93 -327.45,731.38 -731.38,731.38 -403.93,0 -731.37,-327.45 -731.37,-731.38 0,-403.93 327.44,-731.38 731.37,-731.38zm-4276.81 0c403.93,0 731.38,327.45 731.38,731.38 0,403.93 -327.45,731.38 -731.38,731.38 -403.93,0 -731.38,-327.45 -731.38,-731.38 0,-403.93 327.45,-731.38 731.38,-731.38z" />
                  </SvgIcon>
                </Tooltip>
              )
            )}
            {(channel === 'email') && (
              <Tooltip title="Source: Email" placement={tooltipPlacement} arrow>
                <Email sx={{ color: chip ? '#FFF' : '#25d366', width: size, height: size }} />
              </Tooltip>
            )}
            {(channel === 'whatsapp' || channel === 'whatsappqr') && (
              <Tooltip title="Source: WhatsApp" placement={tooltipPlacement} arrow>
                <WhatsApp sx={{ color: chip ? '#FFF' : '#25d366', width: size, height: size }} />
              </Tooltip>
            )}
            {(channel === 'telegram') && (
              <Tooltip title="Source: Telegram" placement={tooltipPlacement} arrow>
                <Telegram sx={{ color: chip ? '#FFF' : '#0088cc', width: size, height: size }} />
              </Tooltip>
            )}
            {(channel === 'viber') && (
              <Tooltip title="Source: Viber" placement={tooltipPlacement} arrow>
                <SvgIcon sx={{ color: chip ? '#FFF' : '#8f5db7', width: size, height: size }} viewBox="0 0 512 512">
                  <path fill="currentColor" d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z" />
                </SvgIcon>
              </Tooltip>
            )}
            {(channel === 'messenger') && (
              <Tooltip title="Source: Messenger" placement={tooltipPlacement} arrow>
                <SvgIcon sx={{ color: chip ? '#FFF' : '#0084ff', width: size, height: size }} viewBox="0 0 512 512">
                  <path fill="currentColor" d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z" />
                </SvgIcon>
              </Tooltip>
            )}
            {(channel === 'instagram') && (
              <Tooltip title="Source: Instagram" placement={tooltipPlacement} arrow>
                <Instagram sx={{ color: chip ? '#FFF' : '#C13584', width: size, height: size }} />
              </Tooltip>
            )}
            {(channel === 'line') && (
              <Tooltip placement={tooltipPlacement} arrow title="Source: Line">
                <SvgIcon sx={{ color: chip ? '#FFF' : '#06C755', width: size, height: size }} viewBox="0 0 512 512">
                  <path fill="currentColor" d="M311 196.8v81.3c0 2.1-1.6 3.7-3.7 3.7h-13c-1.3 0-2.4-.7-3-1.5l-37.3-50.3v48.2c0 2.1-1.6 3.7-3.7 3.7h-13c-2.1 0-3.7-1.6-3.7-3.7V196.9c0-2.1 1.6-3.7 3.7-3.7h12.9c1.1 0 2.4 .6 3 1.6l37.3 50.3V196.9c0-2.1 1.6-3.7 3.7-3.7h13c2.1-.1 3.8 1.6 3.8 3.5zm-93.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 2.1 1.6 3.7 3.7 3.7h13c2.1 0 3.7-1.6 3.7-3.7V196.8c0-1.9-1.6-3.7-3.7-3.7zm-31.4 68.1H150.3V196.8c0-2.1-1.6-3.7-3.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 1 .3 1.8 1 2.5c.7 .6 1.5 1 2.5 1h52.2c2.1 0 3.7-1.6 3.7-3.7v-13c0-1.9-1.6-3.7-3.5-3.7zm193.7-68.1H327.3c-1.9 0-3.7 1.6-3.7 3.7v81.3c0 1.9 1.6 3.7 3.7 3.7h52.2c2.1 0 3.7-1.6 3.7-3.7V265c0-2.1-1.6-3.7-3.7-3.7H344V247.7h35.5c2.1 0 3.7-1.6 3.7-3.7V230.9c0-2.1-1.6-3.7-3.7-3.7H344V213.5h35.5c2.1 0 3.7-1.6 3.7-3.7v-13c-.1-1.9-1.7-3.7-3.7-3.7zM512 93.4V419.4c-.1 51.2-42.1 92.7-93.4 92.6H92.6C41.4 511.9-.1 469.8 0 418.6V92.6C.1 41.4 42.2-.1 93.4 0H419.4c51.2 .1 92.7 42.1 92.6 93.4zM441.6 233.5c0-83.4-83.7-151.3-186.4-151.3s-186.4 67.9-186.4 151.3c0 74.7 66.3 137.4 155.9 149.3c21.8 4.7 19.3 12.7 14.4 42.1c-.8 4.7-3.8 18.4 16.1 10.1s107.3-63.2 146.5-108.2c27-29.7 39.9-59.8 39.9-93.1z" />
                </SvgIcon>
              </Tooltip>
            )}
            {(channel === 'form') && (
              <Tooltip placement={tooltipPlacement} arrow title="Source: Form">
                <Dns sx={{ color: chip ? '#FFF' : '#7B1FA2', width: size, height: size }} />
              </Tooltip>
            )}
            {(channel === 'googlebc') && (
              <Tooltip placement={tooltipPlacement} arrow title="Source: Google Business Messages">
                <GoogleBcIcon sx={{ color: chip ? '#FFF' : '#3B78E7', width: size, height: size }} />
              </Tooltip>
            )}
        </>
    );

    return (
        <ParentElement icon={icon} color={getChannelColor(channel)} label={getChannelTitle(channel)} chip={chip}>
            {icon}
        </ParentElement>
    );
};

export default SourceIcon;