import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import $api from 'src/components/http';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    $api.post('/auth/reset-password', {
      email
    })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrors(err.response.data);
        } 
        enqueueSnackbar('Error restoring password!', {
          variant: 'error'
        });
        return null;
      })
      .then((response) => {
        setIsSubmitting(false);
        if (response && response.data.success) {
          enqueueSnackbar('We have sent you email with password-reset link! Please check your inbox!', {
            variant: 'success'
          });
        }
        return null;
      });
  };

  return (
    <>
      <PageMeta title="Reset password" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          height: '100%',
          my: 'auto',
          justifyContent: 'center',
          pb: 3,
          pt: 3
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit} noValidate>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                {t('Reset password')}
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {t('Use your email to restore the password')}
              </Typography>
            </Box>
            <TextField
              error={!!errors.email}
              fullWidth
              helperText={errors.email}
              label={t('Email Address')}
              margin="normal"
              name="email"
              onChange={handleChange}
              type="email"
              value={email}
              variant="outlined"
              required
            />
            <Box sx={{ py: 2 }}>
              <LoadingButton
                color="primary"
                loading={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t('Reset password')}
              </LoadingButton>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {t('Have an account?')}
              {' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
              >
                {t('Sign in')}
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
