import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes, useSearchParams } from 'react-router-dom';
import { StyledEngineProvider} from '@mui/material';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import GlobalStyles from 'src/components/GlobalStyles';
import routes from 'src/routes';
import useToken from 'src/components/app/useToken';
import GdprBanner from 'src/components/gdpr/GdprBanner';
import './app.css';
import './i18n';
import AppContextProvider from './components/context/AppContext';
import ColorModeContextProvider from './components/context/ColorModeContextProvider';
import 'react-medium-image-zoom/dist/styles.css';

const Routes = () => {
  const { isLoggedIn } = useToken();
  const routing = useRoutes(routes(isLoggedIn()));
  return routing;
};

function App() {
  const [searchParams] = useSearchParams();
  if (process.env.REACT_APP_ENV !== 'dev') {
    ReactGA.initialize('G-LK6FCQX3N0');
    ReactGA.send('pageview');
    ReactPixel.init('747001199601720', {}, {
      autoConfig: true,
      debug: false
    });
    ReactPixel.pageView();
  }

  useEffect(() => {
    if (searchParams.get('ref')) {
      localStorage.setItem('anychat-ref', searchParams.get('ref'));
    }
  }, [searchParams]);

  return (
      <StyledEngineProvider injectFirst>
        <AppContextProvider>
          <ColorModeContextProvider>
            <GlobalStyles />
              <Routes />
            <GdprBanner />
          </ColorModeContextProvider>
        </AppContextProvider>
      </StyledEngineProvider>
  );
}

export default App;
