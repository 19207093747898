import { useEffect, useCallback } from 'react';
import useScript from 'src/components/app/useScript';
// import loadScript from './load-script';
import removeScript from './remove-script';

const useGoogleLogout = ({
  jsSrc = 'https://apis.google.com/js/api.js',
  onFailure,
  onScriptLoadFailure,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  fetchBasicProfile,
  discoveryDocs,
  uxMode,
  redirectUri,
  scope,
  accessType,
  onLogoutSuccess
}) => {
  // const [loaded, setLoaded] = useState(false);
  const [loaded] = useScript(jsSrc);

  const signOut = useCallback(() => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.then(
          () => {
            auth2.signOut().then(() => {
              auth2.disconnect();
              onLogoutSuccess();
            });
          },
          (err) => onFailure(err)
        );
      }
    }
  }, [onLogoutSuccess]);

  useEffect(() => {
    const onLoadFailure = onScriptLoadFailure || onFailure;
    if (window.gapi && loaded) {
      const params = {
        client_id: clientId,
        cookie_policy: cookiePolicy,
        login_hint: loginHint,
        hosted_domain: hostedDomain,
        fetch_basic_profile: fetchBasicProfile,
        discoveryDocs,
        ux_mode: uxMode,
        redirect_uri: redirectUri,
        scope,
        access_type: accessType
      };
      window.gapi.load('auth2', () => {
        if (!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.init(params).then(
            () => true,
            (err) => onLoadFailure(err)
          );
        }
      });
    }
  }, [loaded]);

  useEffect(() => (
    () => {
      removeScript(document, 'google-login');
    }
  ), []);

  return { signOut, loaded };
};

export default useGoogleLogout;
