import { Avatar, Box, CircularProgress, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import CommentItem from "./CommentItem";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import $api from "../http";
import { Close, Reply, Send } from "@mui/icons-material";
import EmojiTextField from "../app/EmojiTextField";
import { red } from "@mui/material/colors";

const Comments = (props) => {
    const {
        id,
        user,
        indexUrl,
        saveUrl,
        deleteUrl,
        bgColor,
        textFieldPlaceholder,
        deleteItemTitle,
        deleteItemContent,
        itemPostedMessage,
        itemPostErrorMessage,
        itemDeletedMessage
    } = props;
    const [items, setItems] = useState([]);
    const [replyTo, setReplyTo] = useState(null);
    const [commentContent, setCommentContent] = useState('');
    const [commentSubmitting, setCommentSubmitting] = useState(false);
    const [commentError, setCommentError] = useState();
    const inputRef = useRef(null);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleReply = (item) => {
        setReplyTo(item);
    };

    const handleDeleteComment = (newComments) => {
        setItems(newComments);
        enqueueSnackbar(itemDeletedMessage, {
            variant: 'success'
        });
    };

    const handleDeleteError = () => {
        enqueueSnackbar(t('Error deleting item'), {
            variant: 'error'
        });
    };

    const loadContent = () => {
        $api.get(indexUrl)
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || t('Error loading data'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                }
            })
            .finally(() => {

            })
    };

    const handleCancelReply = () => {
        setReplyTo(null);
    };

    const handlePostComment = () => {
        setCommentSubmitting(true);
        setCommentError(false);
        $api.post(saveUrl, {
            content: commentContent,
            replyTo: replyTo?.id || 0,
            guid: id
        })
            .catch((err) => {
                setCommentError(true);
                enqueueSnackbar(itemPostErrorMessage || t('Error posting comment'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    setItems(res.data.comments);
                    setCommentContent('');
                    setReplyTo(null);
                    enqueueSnackbar(itemPostedMessage, {
                        variant: 'success'
                    });
                }
            })
            .finally(() => {
                setCommentSubmitting(false);
            })
    };

    useEffect(() => {
        loadContent();
    }, []);

    const memoComments = useMemo(() => (
        items.map((comment) => (
            <CommentItem
                onDeleteError={handleDeleteError}
                key={comment.id}
                level={1}
                comment={comment}
                user={user}
                onReply={handleReply}
                onDelete={handleDeleteComment}
                bgColor={bgColor}
                deleteUrl={deleteUrl}
                deleteItemTitle={deleteItemTitle}
                deleteItemContent={deleteItemContent}
            />
        ))
    ), [items, user, bgColor, deleteUrl, deleteItemTitle, deleteItemContent]);

    return (
        <Box>
            {memoComments}
            {(replyTo !== null) && (
                <Paper elevation={0} sx={{ display: 'flex', width: '100%', alignItems: 'center', py: 1, bgcolor: bgColor || 'background.paper' }}>
                    <Box sx={{ borderRightWidth: 2, borderRightStyle: 'solid', borderRightColor: 'primary.main', p: 1, mr: 1 }}>
                        <Reply color="primary" />
                    </Box>
                    <Box sx={{ flex: 1, overflow: 'hidden' }}>
                        <Typography variant="body2" sx={{ fontWeight: '500' }}>
                            {replyTo.user.name}
                        </Typography>
                        <Box sx={{ overflow: 'hidden' }}>
                            <Typography variant="body2" color="text.secondary" noWrap>
                                {replyTo.content}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mr: 0.5 }}>
                        <IconButton onClick={handleCancelReply}>
                            <Close />
                        </IconButton>
                    </Box>
                </Paper>
            )}
            <Paper elevation={0} sx={{ p: 2, mt: '2px', mb: 1, display: 'flex', alignItems: 'center', bgcolor: bgColor || 'background.paper' }}>
                <Avatar src={user?.image} sx={{ bgcolor: red[500], width: 32, height: 32, mr: 2 }} aria-label="recipe">
                    {user?.name?.substring(0, 1)}
                </Avatar>
                <Box sx={{ flexGrow: 1 }}>
                    <EmojiTextField
                        size="small"
                        placeholder={textFieldPlaceholder}
                        multiline
                        fullWidth
                        emoji
                        error={commentError}
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)}
                        onEmojiInsert={(newValue) => setCommentContent(newValue)}
                        sx={{
                            '& .MuiInputBase-root': {
                                bgcolor: 'background.paper'
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter' && (e.ctrlKey === true || e.metaKey === true)) {
                                handlePostComment();
                            }
                        }}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <Tooltip title={t('Ctrl + Enter to send')}>
                        <IconButton disabled={commentSubmitting} onClick={handlePostComment} centerRipple={false} sx={{ borderRadius: 1 }}>
                            {commentSubmitting ? <CircularProgress size={24} /> : <Send color="primary" size="small" />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Paper>
        </Box>
    );
};

export default Comments;