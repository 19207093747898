import {
  Box,
  Container,
  Paper,
  Typography
} from '@mui/material';
import { Lock, Warning } from '@mui/icons-material';

const Error = (props) => {
  const {error} = props;
  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 96px)' }}>
      <Paper elevation={0} sx={{ py: 6, my: 'auto' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          {(error.response && error.response.status && error.response.status === 403) ? (
            <Lock sx={{ m: 'auto', fontSize: 96 }} color="error" />
          ) : (
            <Warning sx={{ m: 'auto', fontSize: 96 }} color="error" />
          )}
        </Box>
        <Box>
          {(error.response && error.response.data && error.response.data.message) ? (
            <Typography
              align="center"
              color="textPrimary"
              variant="h4"
              component="div"
            >  
              {error.response.data.message}
            </Typography>
          ) : (
            <Typography
              align="center"
              color="textPrimary"
              variant="h4"
              component="div"
            >  
              {error.message}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Error;
