import { MoreVert, OpenInNew } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";

const MiniCard = (props) => {
    const {
        icon,
        title,
        description,
        guid,
        type,
        onMenuClick
    } = props;
    return (
        <Box sx={{ display: 'flex', p: 1, borderRadius: 1, bgcolor: 'background.default' }}>
            <Avatar sx={{
                '& .hover': {
                    display: 'none',
                },
                '& .non-hover': {
                    display: 'inline-block',
                },
                '&:hover .hover': {
                    display: 'inline-block'
                },
                '&:hover .non-hover': {
                    display: 'none'
                },
                width: 40,
                height: 40,
                mr: 1,
                my: 'auto'
            }} component="a" href={`/app/${type}s/${guid}`} target="_blank">
                {icon}
                <OpenInNew className="hover" />
            </Avatar>
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography noWrap>
                    {title}
                </Typography>
                <Typography noWrap variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </Box>
            {(onMenuClick !== undefined && onMenuClick !== null) && (
                <Box sx={{ my: 'auto' }}>
                    <IconButton onClick={(e) => onMenuClick(e, type, guid)}>
                        <MoreVert />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default MiniCard;