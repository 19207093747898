import { LoadingButton } from "@mui/lab";
import { Autocomplete, Avatar, Box, Button, Chip, DialogActions, DialogContent, FormGroup, Grid, IconButton, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, SvgIcon, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import { useAppConext } from "../context/AppContext";
import TagsPopup from "../TagsPopup";
import { Add, ChatBubble, Check, Close, Place, Telegram, Videocam, WhatsApp } from "@mui/icons-material";
import { invertColor } from "../app/Tools";
import { grey } from "@mui/material/colors";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useDayjs from "../app/useDayjs";

const MeetingDialogContent = (props) => {
    const { open, onClose, contact, currentItem, currentThread, onSubmit } = props;
    const [config, setConfig] = useState(currentItem);
    const [contacts, setContacts] = useState([]);
    const [agents, setAgents] = useState([]);
    const [assignedAgent, setAssignedAgent] = useState({});
    const [errors, setErrors] = useState({});
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);
    const [tags, setTags] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [includeThread, setIncludeThread] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const { user } = useAppConext();

    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    const handleSubmit = () => {
        setIsSubmit(true);
        setErrors({});
        const data = {
            ...config,
            contacts: contacts.map((i) => i.guid),
            agents: agents.map((i) => i.guid),
            assigned_to: assignedAgent?.guid || null,
            tags: tags.map((i) => i.id)
        };
        $api.post( config.guid ? '/meeting/update' : '/meeting/create', {
            ...data,
            thread: (includeThread && currentThread !== undefined) ? currentThread.guid : undefined
        })
            .catch((err) => {
                if (err?.response?.status === 422) {
                    setErrors(err.response.data);
                }
            })
            .then((res) => {
                if (res) {
                    onSubmit(res.data);
                }
            })
            .finally(() => {
                setIsSubmit(false);
            })
    };

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    const handleAgentChange = (v) => {
        setConfig((old) => ({
            ...old,
            assigned_to: v?.guid
        }))
    }

    const handleUnselectAgent = (guid) => {
        setAgents((old) => old.filter((i) => i.guid !== guid));
    };

    const handleFormFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (open) {
            if (currentItem.guid) {
                setContacts(currentItem.contacts);
                setAgents(currentItem.agents);
                setSelectedChannel({
                    id: 999,
                    title: currentItem.channel_title
                });
                setAssignedAgent(currentItem.assignedTo);
            } else if (contact) {
                setContacts([contact]);
                setAgents([]);
                setSelectedChannel(null);
                setAssignedAgent(user);
            } else {
                setContacts([]);
                setAgents([]);
                setSelectedChannel(null);
                setAssignedAgent(user);
            }
            setConfig(currentItem);
            setTags(currentItem.tags || []);
        } else {
            setErrors({});
        }
    }, [open, contact, currentItem]);

    const statusOptions = [
        {
            id: 1,
            label: t('Not started'),
            color: grey['400']
        },
        {
            id: 2,
            label: t('Deferred'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('Re-scheduled'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Ongoing'),
            color: 'error.main'
        },
        {
            id: 5,
            label: t('Complete'),
            color: 'success.main'
        },
        {
            id: 6,
            label: t('Canceled'),
            color: 'secondary.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    const channelOptions = [
        {
            id: 1,
            title: 'Offline',
            icon: <Place />
        },
        {
            id: 2,
            title: 'WhatsApp',
            icon: <WhatsApp />
        },
        {
            id: 3,
            title: 'Messenger',
            icon: <SvgIcon viewBox="0 0 512 512">
                <path d="M256.6 8C116.5 8 8 110.3 8 248.6c0 72.3 29.7 134.8 78.1 177.9 8.4 7.5 6.6 11.9 8.1 58.2A19.9 19.9 0 0 0 122 502.3c52.9-23.3 53.6-25.1 62.6-22.7C337.9 521.8 504 423.7 504 248.6 504 110.3 396.6 8 256.6 8zm149.2 185.1l-73 115.6a37.4 37.4 0 0 1 -53.9 9.9l-58.1-43.5a15 15 0 0 0 -18 0l-78.4 59.4c-10.5 7.9-24.2-4.6-17.1-15.7l73-115.6a37.4 37.4 0 0 1 53.9-9.9l58.1 43.5a15 15 0 0 0 18 0l78.4-59.4c10.4-8 24.1 4.5 17.1 15.6z"/>
            </SvgIcon>
        },
        {
            id: 4,
            title: 'Telegram',
            icon: <Telegram />
        },
        {
            id: 5,
            title: 'Viber',
            icon: <SvgIcon viewBox="0 0 512 512">
                <path d="M444 49.9C431.3 38.2 379.9 .9 265.3 .4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9 .4-85.7 .4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9 .4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9 .6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4 .7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5 .9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9 .1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7 .5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1 .8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"/>
            </SvgIcon>
        },
        {
            id: 6,
            title: 'Zoom',
            icon: <Videocam />
        },
        {
            id: 7,
            title: 'Google meet',
            icon: <SvgIcon viewBox="0 0 48 48">
                <path fill="currentColor" d="M27.1543 23.7486L31.8336 29.0972 38.1257 33.1182 39.2229 23.7843 38.1257 14.656 31.7129 18.1888 27.1543 23.7486zM0 32.2514V40.2057C0 42.0242 1.47291 43.4971 3.29143 43.4971H11.2457L12.8914 37.4848 11.2457 32.2514 5.78742 30.6057 0 32.2514zM11.2457 4L0 15.2457 5.78742 16.8914 11.2457 15.2457 12.864 10.0809 11.2457 4z"></path>
                <path fill="currentColor" d="M11.2457 15.2457H0V32.2514H11.2457V15.2457zM45.3092 8.76161L38.1257 14.656V33.1182L45.3394 39.0345C46.4201 39.8793 48 39.1086 48 37.7372V10.0343C48 8.64367 46.3844 7.88115 45.3092 8.76161zM27.1543 23.7486V32.2514L11.2457 32.2514V43.4971L34.8343 43.4972C36.6528 43.4972 38.1257 42.0242 38.1257 40.2057V33.1182L27.1543 23.7486zM34.8343 4H11.2457V15.2457L27.1543 15.2457V23.7486L38.1257 14.656V7.29143C38.1257 5.47291 36.6528 4 34.8343 4z"></path>
            </SvgIcon>
        },
        {
            id: 8,
            title: 'Teams',
            icon: <SvgIcon  viewBox="0 0 24 24">
                <circle fill="currentColor" cx="20.288" cy="8.344" r="1.707"></circle>
                <path fill="currentColor" d="M18.581 11.513h3.413v3.656c0 .942-.765 1.706-1.707 1.706h-1.706v-5.362zM2.006 4.2v15.6l11.213 1.979V2.221L2.006 4.2zm8.288 5.411-1.95.049v5.752H6.881V9.757l-1.949.098V8.539l5.362-.292v1.364zm3.899.439v8.288h1.95c.808 0 1.463-.655 1.463-1.462V10.05h-3.413zm1.463-4.875c-.586 0-1.105.264-1.463.673v2.555c.357.409.877.673 1.463.673a1.95 1.95 0 0 0 0-3.901z"></path>
            </SvgIcon>
        },
        {
            id: 9,
            title: 'Skype',
            icon: <SvgIcon viewBox="0 0 448 512">
                <path d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z"/>
            </SvgIcon>
        }
    ];

    const getDurationList = () => {
        const start = 300;
        const res = [];
        let i = start;
        let step = 300;
        while (i < 86400) {
            res.push(i);
            if (i < 900) {
                step = 300;
            } else {
                step = 900;
            }
            i = i + step;
        }
        return res;
    };

    return (
        <>
            <DialogContent>
                <Box>
                    <Box sx={{ p: 1, mt: 2, borderRadius: 1, bgcolor: 'background.default' }}>
                        <Typography>
                            {t('Participants')}
                        </Typography>
                        <FormGroup sx={{ mt: 2 }}>
                            <AsyncAutocomplete
                                url="/contact"
                                idAttr="guid"
                                multiple
                                value={contacts}
                                onChange={(val) => {
                                    setContacts(val);
                                }}
                                textFieldProps={{
                                    label: t('Contacts'),
                                    placeholder: t('Search by name, email or phone number')
                                }}
                            />
                        </FormGroup>
                        <FormGroup sx={{ mt: 2 }}>
                            <AsyncAutocomplete
                                url="/live-chat-agent"
                                idAttr="guid"
                                multiple
                                value={agents}
                                onChange={(val) => {
                                    setAgents(val);
                                }}
                                textFieldProps={{
                                    label: t('Agents'),
                                    placeholder: t('Search by name or email')
                                }}
                                renderOption={(p, option) => (
                                    <MenuItem {...p} key={option.guid}>
                                        <ListItemAvatar>
                                            <Avatar src={option.image} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={option.name || t('Agent')}
                                            secondary={option.email || t('Email not set')}
                                        />
                                    </MenuItem>
                                )}
                                renderTags={(values) => (
                                    (values.map((v) => (
                                        <Chip
                                            key={v.guid}
                                            sx={{ mr: 0.5 }}
                                            avatar={<Avatar src={v.image} />}
                                            label={v.name || t('Agent')}
                                            onDelete={() => handleUnselectAgent(v.guid)}
                                        />
                                    )))
                                )}
                            />
                        </FormGroup>
                    </Box>
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/live-chat-agent"
                            idAttr="guid"
                            getOptionLabel={(option) => option.name || ''}
                            initialValue={user}
                            value={assignedAgent}
                            onChange={(v) => {
                                setAssignedAgent(v);
                            }}
                            textFieldProps={{
                                label: t('Responsible agent'),
                                placeholder: t('Search by name or email')
                            }}
                            renderOption={(p, option) => (
                                <MenuItem {...p} key={option.guid}>
                                    <ListItemAvatar>
                                        <Avatar src={option.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={option.name || t('Agent')}
                                        secondary={option.email || t('Email not set')}
                                    />
                                </MenuItem>
                            )}
                        />
                    </FormGroup>
                    {(currentThread !== undefined) && (
                        <FormGroup sx={{ mt: 2 }}>
                            <Box sx={{
                                bgcolor: 'background.default',
                                opacity: includeThread ? 1 : 0.6,
                                borderColor: includeThread ? 'success.light' : 'background.default',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                display: 'flex',
                                p: 1,
                                borderRadius: 1
                            }}>
                                <Avatar sx={{ height: 40, width: 40, mr: 1, my: 'auto' }}>
                                    <ChatBubble />
                                </Avatar>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography>
                                        {t('Attach current chat')}
                                    </Typography>
                                    <Typography color="text.secondary" variant="body2">
                                        {t('Attach current chat to this ticket')}
                                    </Typography>
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                    <IconButton onClick={() => setIncludeThread((i) => !i)} size="small">
                                        {includeThread ? <Close /> : <Check />}
                                    </IconButton>
                                </Box>
                            </Box>
                        </FormGroup>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormGroup sx={{ mt: 2 }}>
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    options={channelOptions}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => {
                                        setConfig((old) => ({...old, channel_title: newValue?.title}));
                                        setSelectedChannel(newValue);
                                    }}
                                    value={selectedChannel}
                                    renderOption={(params, option) => (
                                        <MenuItem {...params}>
                                            <ListItemIcon>
                                                {option.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={option.title} /> 
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Location')}
                                            value={config.channel_title || 'adasd'}
                                            onChange={(e) => setConfig((old) => ({
                                                ...old,
                                                channel_title: e.target.value
                                            }))}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="channel_info"
                                    label={t('Location link')}
                                    value={config.channel_info || ''}
                                    error={!!errors?.channel_info}
                                    helperText={errors?.channel_info}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormGroup sx={{ mt: 2 }}>
                                    <DateTimePicker
                                        label={t('From')}
                                        name="from"
                                        fullWidth
                                        required
                                        value={dayjs(config.from)}
                                        slotProps={{
                                            textField: {
                                                error: !!errors?.from,
                                                required: true,
                                                helperText: errors?.from
                                            }
                                        }}
                                        onAccept={(v) => setConfig((old) => ({
                                            ...old,
                                            from: v
                                        }))
                                    } />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup sx={{ mt: 2 }}>
                                    <TextField
                                        name="duration"
                                        label={t('Duration')}
                                        required
                                        value={config.duration}
                                        error={!!errors?.duration}
                                        helperText={errors?.duration}
                                        onChange={handleFormFieldChange}
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                sx: {
                                                    maxHeight: 300
                                                }
                                            }
                                        }}
                                    >
                                        {(getDurationList().map((i) => (
                                            <MenuItem key={i} value={i}>
                                                {dayjs.duration(i, 'seconds').format('HH:mm')}
                                            </MenuItem>
                                        )))}
                                    </TextField>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="title"
                            label={t('Title')}
                            required
                            value={config.title || ''}
                            error={!!errors?.title}
                            helperText={errors?.title}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="description"
                            label={t('Description')}
                            multiline
                            value={config.description || ''}
                            error={!!errors?.description}
                            helperText={errors?.description}
                            rows={8}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="status"
                                    label={t('Status')}
                                    value={config.status || ''}
                                    error={!!errors?.status}
                                    helperText={errors?.status}
                                    select
                                    onChange={handleFormFieldChange}
                                >
                                    {(statusOptions.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ width: 8, height: 8, bgcolor: i.color || '#DDDDDD', borderRadius: 5, mr: 1, my: 'auto' }} />
                                                {i.label}
                                            </Box>
                                        </MenuItem>
                                    )))}
                                </TextField>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="priority"
                                    label={t('Priority')}
                                    value={config.priority || ''}
                                    error={!!errors?.priority}
                                    helperText={errors?.priority}
                                    select
                                    onChange={handleFormFieldChange}
                                >
                                    {(priorityOptions.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ width: 8, height: 8, bgcolor: i.color || '#DDDDDD', borderRadius: 5, mr: 1, my: 'auto' }} />
                                                {i.label}
                                            </Box>
                                        </MenuItem>
                                    )))}
                                </TextField>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <FormGroup sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            {(tags.length > 0 ) ? (
                                <Box sx={{ my: 'auto' }}>
                                    {(tags.map((i) => (
                                        <Chip
                                            sx={{
                                            mr: 0.5,
                                            my: '2px',
                                            bgcolor: i.color || '',
                                            color: i.color ? invertColor(i.color, true) : 'text.primary',
                                            '& .MuiSvgIcon-root': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.4,
                                                '&:hover': {
                                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                                    opacity: 0.55,
                                                }
                                            }
                                            }}
                                            onDelete={() => handleRemoveTag(i.id)}
                                            label={i.label}
                                            size="small"
                                        />
                                    )))}
                                </Box>
                            ) : (
                                <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                    {t('No tags')}
                                </Typography>
                            )}
                            <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                                <Add />
                            </IconButton>
                        </Box>
                    </FormGroup>
                    <TagsPopup
                        anchorEl={tagsAnchorEl}
                        onClose={() => setTagsAnchorEl(null)}
                        onSelect={handleAddTag}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <LoadingButton loading={isSubmit} onClick={handleSubmit} variant="contained" disableElevation>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default MeetingDialogContent;