import { Apps, AssignmentInd, AttachMoney, Business, BusinessCenter, Close, Drafts, MarkChatUnread, More, MoreVert, NotificationsActive, Quickreply, School, Style } from "@mui/icons-material";
import { Box, Chip, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, SvgIcon, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';
import DnsIcon from '@mui/icons-material/Dns';
import EmailIcon from '@mui/icons-material/Email';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GridViewIcon from '@mui/icons-material/GridView';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { DashboardCustomize, Group, MarkChatRead, QuestionAnswer, Widgets } from '@mui/icons-material';
import { matchPath, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import LoadingProgress from "../app/LoadingProgress";
import { useTranslation } from "react-i18next";
import { useAppConext } from "../context/AppContext";
import { orange } from "@mui/material/colors";

const SettingsSidebar = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [userLoaded, setUserLoaded] = useState(false);
    const [settingsSectionVisible, setSettingsSectionVisible] = useState(true);
    const { user, getFeatureValue, workspace } = useAppConext();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const workspaceSettingsItems = [
      {
        href: '/settings/integrations',
        icon: <GridViewIcon />,
        title: t('Integrations'),
        restricted: !getFeatureValue('integrations.enabled'),
        permissionKey: 'settingsIntegration',
        isEnd: true
      },
      {
        href: '/settings/assistants',
        icon: <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={{ width: 24, height: 24 }} viewBox="0 0 24 24">
            <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path fill-rule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clip-rule="evenodd"></path><path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
        </SvgIcon>,
        title: t('AI assistants'),
        restricted: !getFeatureValue('integrations.enabled'),
        permissionKey: 'settingsIntegration',
        isEnd: true
      },
      {
        href: '/settings/forms',
        icon: <DnsIcon />,
        title: t('Forms'),
        restricted: !getFeatureValue('forms.enabled'),
        permissionKey: 'settingsForms',
        isEnd: true
      },
      {
        href: '/settings/emails',
        icon: <EmailIcon />,
        title: t('Email settings'),
        restricted: !getFeatureValue('email.enabled'),
        permissionKey: 'settingsEmails',
        isEnd: true
      },
      {
        href: '/settings/email-templates',
        icon: <Drafts />,
        title: t('Email templates'),
        restricted: !getFeatureValue('email.enabled'),
        permissionKey: 'settingsEmails',
        isEnd: true
      },
      {
        href: '/settings/tags',
        icon: <Style />,
        title: t('Tags'),
        restricted: false,
        permissionKey: 'settingsTags',
        isEnd: true
      },
      {
        href: '/settings/live-chat-integrations',
        icon: <DashboardCustomize />,
        title: t('Live chat integrations'),
        restricted: !getFeatureValue('livechat.integrations.enable'),
        permissionKey: 'settingsLiveChatIntegrations',
        isEnd: true
      },
      {
        href: '/settings/live-chat-quick-replies',
        icon: <Quickreply />,
        title: t('Live chat quick replies'),
        restricted: !getFeatureValue('livechat.quickReplies'),
        permissionKey: 'settingsLiveChatQuickReplies',
        isEnd: true
      },
      {
        href: '/settings/conversations',
        icon: <MarkChatUnread />,
        title: t('Conversations settings'),
        restricted: !getFeatureValue('livechat.conversationsSettings'),
        permissionKey: 'settingsConversations',
        isEnd: true
      },
      {
        href: '/settings/knowledge-base',
        icon: <School />,
        title: t('Knowledge base'),
        restricted: !getFeatureValue('kb.enabled'),
        permissionKey: 'settingsKb',
        isEnd: true
      },
      {
        href: '/settings/widgets',
        icon: <Widgets />,
        title: t('Widgets'),
        restricted: !getFeatureValue('widgets.enabled'),
        permissionKey: null,
        isEnd: true
      },
    ];

    const settingsItems = [
        {
          href: '/settings/channels',
          icon: <ListIcon />,
          title: t('Channels'),
          restricted: !getFeatureValue('channels.enabled'),
          permissionKey: 'settingsChannels',
          isEnd: true
        },
        {
          href: '/settings/button',
          icon: <SettingsApplicationsIcon />,
          restricted: !getFeatureValue('button.enabled'),
          title: t('Button settings'),
          permissionKey: 'settingsButton',
          isEnd: true
        },
        {
          href: '/settings/menu',
          icon: <PlaylistAddCheckIcon />,
          title: t('Menu settings'),
          restricted: !getFeatureValue('menu.enabled'),
          permissionKey: 'settingsMenu',
          isEnd: true
        },
        {
          href: '/settings/prompts',
          icon: <SpeakerNotesIcon />,
          title: t('Prompt messages'),
          restricted: !getFeatureValue('prompts.enabled'),
          permissionKey: 'settingsPrompts',
          isEnd: true
        },
        {
          href: '/settings/live-chats',
          icon: <QuestionAnswer />,
          restricted: !getFeatureValue('livechats.enabled'),
          title: t('Live chats'),
          permissionKey: 'settingsLiveChats',
          isEnd: true
        },
        {
          href: '/settings/live-chat-widget',
          icon: <ChatBubbleOutlineIcon />,
          title: t('Live chat widget'),
          restricted: !getFeatureValue('livechat.enabled'),
          permissionKey: 'settingsLiveChatSettings',
          isEnd: true
        },
        {
          href: '/settings/live-chat-settings',
          icon: <MarkChatRead />,
          title: t('Live chat settings'),
          restricted: !getFeatureValue('livechat.enabled'),
          permissionKey: 'settingsLiveChatSettings',
          isEnd: true
        },
        {
          href: '/settings/advanced',
          icon: <SettingsIcon />,
          title: t('Advanced settings'),
          restricted: !getFeatureValue('advanced.enabled'),
          permissionKey: 'settingsGeneral',
          isEnd: true
        }
    ];

    const otherSettingsItems = [
      {
        href: '/settings/notifications',
        icon: <NotificationsActive />,
        title: t('Notification settings'),
        restricted: !getFeatureValue('notifications.enabled'),
        permissionKey: 'settingsNotificationsSettings',
        isEnd: true
      },
      {
        href: '/settings/apps',
        icon: <Apps />,
        title: t('Apps and plugins'),
        restricted: false,
        permissionKey: 'apps',
        isEnd: true
      },
    ];
    
    useEffect(() => {
      if (user !== null) {
        const items = settingsItems.filter((i) => user.permissions[i.permissionKey] == true);
        if (items.length > 0) {
          setSettingsSectionVisible(true);
        } else {
          setSettingsSectionVisible(false);
        }
        setUserLoaded(true);
      }
    }, [user]);

    return (
      <>
      <IconButton onClick={() => setSidebarOpen((i) => true)} sx={{
        display: {
          xs: sidebarOpen ? 'none' : 'block',
          sm: sidebarOpen ? 'none' : 'block',
          md: 'none'
        },
        position: 'fixed',
        top: 76,
        left: 12,
        width: 40,
        height: 40,
        zIndex: 3
      }}>
        <MoreVert />
      </IconButton>
      <IconButton onClick={() => setSidebarOpen((i) => false)} sx={{
        display: {
          xs: sidebarOpen ? 'block' : 'none',
          sm: sidebarOpen ? 'block' : 'none',
          md: 'none'
        },
        position: 'fixed',
        top: 60,
        left: 257,
        width: 40,
        height: 40,
        zIndex: 3
      }}>
        <Close />
      </IconButton>
        <Box sx={{
          minWidth: 300,
          p: 0,
          mt: {
            xs: 0,
            sm: 0,
            md: 2
          },
          mb: {
            xs: 0,
            sm: 0,
            md: 2
          },
          ml: {
            xs: 0,
            sm: 0,
            md: 2
          },
          mr: 1,
          height: sidebarOpen ? '100%' : 'calc(100vh - 96px)',
          transition: '0.2s all',
          left: {
            xs: sidebarOpen ? 0 : -320,
            sm: sidebarOpen ? 0 : -320,
            md: 0
          },
          zIndex: 2,
          position: {
            xs: 'absolute',
            sm: 'absolute',
            md: 'relative'
          }
        }}>
            <Paper elevation={sidebarOpen ? 10 : 0} sx={{
              width: '100%',
              height: sidebarOpen ? 'calc(100vh - 56px)' : 'calc(100vh - 96px)',
              position: 'fixed',
              minWidth: 300,
              width: 300,
              overflow: 'auto',
              borderRadius: {
                xs: 0,
                sm: 0,
                md: 1
              }
            }}>
              <LoadingProgress open={!userLoaded} />
              <List dense sx={{
                  transition: '0.2s all',
                  opacity: userLoaded ? 1 : 0,
                  '& .MuiListItemIcon-root': {
                      minWidth: 36
                  },
                  '& .MuiButtonBase-root': {
                      borderLeftWidth: 3,
                      borderLeftStyle: 'solid',
                      borderColor: 'transparent'
                  },
                  '& .MuiButtonBase-root.Mui-selected': {
                      borderColor: 'success.light'
                  }
              }}>
                {(settingsSectionVisible) && (
                  <>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{ sx: { textTransform: 'uppercase', fontSize: '0.85rem' } }} primary={t('Workspace settings')} />
                    </ListItem>
                    {(workspaceSettingsItems.map((i) => (
                      (((i.permissionKey && user && user.permissions && user.permissions[i.permissionKey] === true) || i.permissionKey === null) && (
                        <ListItem key={i.title} sx={{ p: 0, position: 'relative' }}>
                            <ListItemButton
                                selected={!!matchPath({
                                    path: i.href,
                                    end: false,
                                }, location.pathname)}
                                onClick={() => setSidebarOpen(false)}
                                component={NavLink} to={i.href}>
                                <ListItemIcon>
                                    {i.icon}
                                </ListItemIcon>
                                <ListItemText primary={i.title} />
                                {(i.restricted === true) && (
                                  <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: '50%', right: 5, mt: '-12px' }}>
                                    <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                                  </SvgIcon>
                                )}
                            </ListItemButton>
                        </ListItem>
                      ))
                    )))}
                    <ListItem sx={{ pb: 0 }}>
                      <Divider sx={{ width: '100%' }} />
                    </ListItem>
                  </>
                )}
                {(settingsSectionVisible) && (
                  <>
                    <ListItem>
                      <ListItemText primaryTypographyProps={{ sx: { textTransform: 'uppercase', fontSize: '0.85rem' } }} primary={t('Widget settings')} />
                    </ListItem>
                    {(settingsItems.map((i) => (
                      ((user && user.permissions && user.permissions[i.permissionKey] === true) && (
                        <ListItem key={i.title} sx={{ p: 0, position: 'relative' }}>
                            <ListItemButton
                                selected={!!matchPath({
                                    path: i.href,
                                    end: false,
                                }, location.pathname)}
                                onClick={() => setSidebarOpen(false)}
                                component={NavLink} to={i.href}>
                                <ListItemIcon>
                                    {i.icon}
                                </ListItemIcon>
                                <ListItemText primary={i.title} />
                                {(i.restricted === true) && (
                                  <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: '50%', right: 5, mt: '-12px' }}>
                                    <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                                  </SvgIcon>
                                )}
                            </ListItemButton>
                        </ListItem>
                      ))
                    )))}
                    <ListItem sx={{ pb: 0 }}>
                      <Divider sx={{ width: '100%' }} />
                    </ListItem>
                  </>
                )}
                  
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ sx: { textTransform: 'uppercase', fontSize: '0.85rem' } }} primary={t('My team')} />
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                      <ListItemButton
                          selected={!!matchPath({
                              path: '/settings/chat-agents',
                              end: false,
                          }, location.pathname)}
                          onClick={() => setSidebarOpen(false)}
                          component={NavLink} to='/settings/chat-agents'>
                          <ListItemIcon>
                              <Group />
                          </ListItemIcon>
                          <ListItemText primary={t('Members')} />
                          {(!getFeatureValue('team.enabled')) && (
                            <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: '50%', right: 5, mt: '-12px' }}>
                              <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                            </SvgIcon>
                          )}
                      </ListItemButton>
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                      <ListItemButton
                          selected={!!matchPath({
                              path: '/settings/departments',
                              end: false,
                          }, location.pathname)}
                          onClick={() => setSidebarOpen(false)}
                          component={NavLink} to='/settings/departments'>
                          <ListItemIcon>
                              <Business />
                          </ListItemIcon>
                          <ListItemText primary={t('Departments')} />
                          {(!getFeatureValue('team.enabled')) && (
                            <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: '50%', right: 5, mt: '-12px' }}>
                              <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                            </SvgIcon>
                          )}
                      </ListItemButton>
                  </ListItem>
                  <ListItem sx={{ pb: 0 }}>
                    <Divider sx={{ width: '100%' }} />
                  </ListItem>
                  {(getFeatureValue('agency.enabled') && workspace?.is_agency === 1) && (
                    <>
                      <ListItem>
                        <ListItemText primaryTypographyProps={{ sx: { textTransform: 'uppercase', fontSize: '0.85rem' } }} primary={t('My agency')} />
                      </ListItem>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemButton
                            selected={!!matchPath({
                                path: '/settings/agency',
                                end: false,
                            }, location.pathname)}
                            onClick={() => setSidebarOpen(false)}
                            component={NavLink} to='/settings/agency'>
                            <ListItemIcon>
                                <BusinessCenter />
                            </ListItemIcon>
                            <ListItemText primary={t('Agency settings')} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemButton
                            selected={!!matchPath({
                                path: '/settings/agency-prices',
                                end: false,
                            }, location.pathname)}
                            onClick={() => setSidebarOpen(false)}
                            component={NavLink} to='/settings/agency-prices'>
                            <ListItemIcon>
                                <AttachMoney />
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              sx={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              primary={(
                                <>
                                  <Typography variant="body2" component="span">
                                    {t('Pricing & plans')}
                                  </Typography>
                                  <Chip size="small" sx={{ ml: 1, py: 0, height: 18, fontSize: '0.75rem' }} color="warning" label={t('beta')} />
                                </>
                              )}
                            />
                            {(getFeatureValue('agency.planBuilder') !== true) && (
                                <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: '50%', right: 5, mt: '-12px' }}>
                                  <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                                </SvgIcon>
                            )}
                        </ListItemButton>
                      </ListItem>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemButton
                            selected={!!matchPath({
                                path: '/settings/agency-clients',
                                end: false,
                            }, location.pathname)}
                            onClick={() => setSidebarOpen(false)}
                            component={NavLink} to='/settings/agency-clients'>
                            <ListItemIcon>
                                <AssignmentInd />
                            </ListItemIcon>
                            <ListItemText primary={t('Agency clients')} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem sx={{ pb: 0, px: 0 }}>
                        <Divider sx={{ width: '100%' }} />
                      </ListItem>
                    </>
                  )}
                  {(otherSettingsItems.map((i) => (
                    ((user && user.permissions && user.permissions[i.permissionKey] === true) && (
                      <ListItem key={i.title} sx={{ p: 0, position: 'relative' }}>
                          <ListItemButton
                              selected={!!matchPath({
                                  path: i.href,
                                  end: false,
                              }, location.pathname)}
                              onClick={() => setSidebarOpen(false)}
                              component={NavLink} to={i.href}>
                              <ListItemIcon>
                                  {i.icon}
                              </ListItemIcon>
                              <ListItemText primary={i.title} />
                              {(i.restricted === true) && (
                                <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: '50%', right: 5, mt: '-12px' }}>
                                  <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                                </SvgIcon>
                              )}
                          </ListItemButton>
                      </ListItem>
                    ))
                  )))}
              </List>
            </Paper>
        </Box>
        </>
    );
};

export default SettingsSidebar;