import { PersonOff } from "@mui/icons-material";
import { Avatar, Chip } from "@mui/material";

const UserChip = (props) => {
    const { name, image, defaultIcon, ...rest } = props;
    return (
        <Chip
            avatar={(
                <Avatar src={image}>
                    {defaultIcon ? defaultIcon : <PersonOff />}
                </Avatar>
            )}
            label={name}
            {...rest}
        />
    );
};

export default UserChip;