import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Grid, IconButton, ListItemAvatar, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import { useAppConext } from "../context/AppContext";
import TagsPopup from "../TagsPopup";
import { Add } from "@mui/icons-material";
import { invertColor } from "../app/Tools";

import ImageUploader from "../app/ImageUploader";

const OrganizationDialog = (props) => {
    const { open, onClose, currentItem, onSubmit } = props;
    const [config, setConfig] = useState(currentItem);
    const [contacts, setContacts] = useState([]);
    const [assignedAgent, setAssignedAgent] = useState({});
    const [errors, setErrors] = useState({});
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);
    const [tags, setTags] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const { user } = useAppConext();

    const { t } = useTranslation();

    const handleSubmit = () => {
        setIsSubmit(true);
        setErrors({});
        const data = {
            ...config,
            contacts: contacts.map((i) => i.guid),
            assigned_to: assignedAgent?.guid || null,
            tags: tags.map((i) => i.id)
        };
        $api.post( config.guid ? '/organization/update' : '/organization/create', data)
            .catch((err) => {
                if (err?.response?.status === 422) {
                    setErrors(err.response.data);
                }
            })
            .then((res) => {
                if (res) {
                    onSubmit(res.data);
                }
            })
            .finally(() => {
                setIsSubmit(false);
            })
    };

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    const handleFormFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (open === true) {
            if (currentItem.guid) {
                setContacts(currentItem.contacts);
                setAssignedAgent(currentItem.assignedTo);
            } else {
                setContacts([]);
                setAssignedAgent(user);
            }
            setConfig(currentItem);
            setTags(currentItem.tags || []);
        } else {
            setErrors({});
        }
    }, [open, currentItem, user]);

    const handleImageChange = (name, image) => {
        setConfig({
            ...config,
            [name]: image
        });
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>
                {config.guid ? t('Edit organization') : t('New organization')}
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Grid container sx={{ mt: 2 }} spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <ImageUploader
                                name="image"
                                value={config.image}
                                onChange={handleImageChange}
                                error={!!(errors.image)}
                                helperText={errors.image || null}
                                imageHelperText={t('Ratio 1:1')}
                                aspectRatio={1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormGroup>
                                <TextField
                                    name="title"
                                    label={t('Title')}
                                    required
                                    value={config.title || ''}
                                    error={!!errors?.title}
                                    helperText={errors?.title}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="description"
                                    label={t('Description')}
                                    multiline
                                    value={config.description || ''}
                                    error={!!errors?.description}
                                    helperText={errors?.description}
                                    rows={7}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="website"
                            label={t('Website')}
                            value={config.website || ''}
                            error={!!errors?.website}
                            helperText={errors?.website}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="phone"
                            label={t('Phone number')}
                            value={config.phone || ''}
                            error={!!errors?.phone}
                            helperText={errors?.phone}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="fax"
                            label={t('Fax number')}
                            value={config.fax || ''}
                            error={!!errors?.fax}
                            helperText={errors?.fax}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/contact"
                            idAttr="guid"
                            multiple
                            value={contacts}
                            onChange={(val) => {
                                setContacts(val);
                            }}
                            textFieldProps={{
                                label: t('Contacts'),
                                placeholder: t('Search by name, email or phone number')
                            }}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/live-chat-agent"
                            idAttr="guid"
                            getOptionLabel={(option) => option.name || ''}
                            initialValue={user}
                            value={assignedAgent}
                            onChange={(v) => {
                                setAssignedAgent(v);
                            }}
                            textFieldProps={{
                                label: t('Assigned agent'),
                                placeholder: t('Search by name or email')
                            }}
                            renderOption={(p, option) => (
                                <MenuItem {...p} key={option.guid}>
                                    <ListItemAvatar>
                                        <Avatar src={option.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={option.name || t('Agent')}
                                        secondary={option.email || t('Email not set')}
                                    />
                                </MenuItem>
                            )}
                        />
                    </FormGroup>
                    
                    <FormGroup sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            {(tags.length > 0 ) ? (
                                <Box sx={{ my: 'auto' }}>
                                    {(tags.map((i) => (
                                        <Chip
                                            sx={{
                                            mr: 0.5,
                                            my: '2px',
                                            bgcolor: i.color || '',
                                            color: i.color ? invertColor(i.color, true) : 'text.primary',
                                            '& .MuiSvgIcon-root': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.4,
                                                '&:hover': {
                                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                                    opacity: 0.55,
                                                }
                                            }
                                            }}
                                            onDelete={() => handleRemoveTag(i.id)}
                                            label={i.label}
                                            size="small"
                                        />
                                    )))}
                                </Box>
                            ) : (
                                <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                    {t('No tags')}
                                </Typography>
                            )}
                            <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                                <Add />
                            </IconButton>
                        </Box>
                    </FormGroup>
                    <TagsPopup
                        anchorEl={tagsAnchorEl}
                        onClose={() => setTagsAnchorEl(null)}
                        onSelect={handleAddTag}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <LoadingButton loading={isSubmit} onClick={handleSubmit} variant="contained" disableElevation>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default OrganizationDialog;