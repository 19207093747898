let listeners = [];

const useWorkspace = () => {
  const subscribe = (listn) => {
    listeners.push(listn);
  };

  const unsubscribe = (listn) => {
    listeners = listeners.filter((entity) => entity !== listn);
  };

  const cleanUpListeners = () => {
    listeners = [];
  };

  const getWorkspace = () => {
    const data = localStorage.getItem('workspaceGUID');
    return data || null;
  };

  const notify = (guid) => {
    listeners.forEach((listener) => listener(guid));
  };

  const setWorkspace = (guid) => {
    let changed = false;
    if (guid !== getWorkspace()) {
      changed = true;
    }
    if (guid) {
      localStorage.setItem('workspaceGUID', guid);
    } else {
      localStorage.removeItem('workspaceGUID');
    }
    if (changed) {
      notify(guid);
    }
  };

  return {
    getWorkspace,
    setWorkspace,
    subscribe,
    unsubscribe,
    cleanUpListeners,
    notify
  };
};

export default useWorkspace;
