import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Hidden,
  SvgIcon,
  Paper,
} from '@mui/material';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import TextTyping from 'src/components/home/TextTyping';
import { ChatBubble, Palette, RocketLaunch } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

const iconSx = {
  width: {
    xs: 42,
    sm: 64,
    md: 52,
    lg: 84
  },
  height: {
    xs: 42,
    sm: 64,
    md: 52,
    lg: 84
  }
};

const iconLabelSx = {
  fontSize: {
    xs: '0.75rem',
    sm: '0.85rem',
    md: '1rem'
  }
};

const HomePage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const { brandInfo } = useOutletContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.hostname !== process.env.REACT_APP_HOSTNAME) {
      navigate('/login', { replace: true });
      return false;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{brandInfo && brandInfo.title ? brandInfo.title : 'AnyChat'} - all in one support widget to communicate all channels</title>
        <meta name="description" content={brandInfo && brandInfo.title ? `${brandInfo.title} widget  displays on every page of your site and provide as many contact methods as you want.` : 'AnyChat widget  displays on every page of your site and provide as many contact methods as you want.'} />
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
        id="homepage"
        className="homepage-root"
      >
        <Box className="hero-section" sx={{
          backgroundImage: 'url(/static/media/main-bg.a7707e14.webp)',
          bgcolor: 'navbar.main',
          minHeight: 680,
          position: 'relative',
          color: '#FFF'
        }}>
          <Container sx={{ py: 8 }} maxWidth="lg">
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Box className="homepage-section" sx={{ textAlign: 'center', mt: '70px', mb: 0 }}>
                  <Typography variant="h1">
                    Let site visitors contact you via
                    <br />
                    <TextTyping
                      messages={[
                        'Messenger',
                        'WhatsApp',
                        'Viber',
                        'Telegram',
                        'their favorite messaging app'
                      ]}
                      loop
                      pause={1000}
                      finalPause={3000}
                      deletingSpeed={30}
                      typingSpeed={100}
                      onMessageComplete={(step) => setCurrentStep(step)}
                    />
                    &nbsp;
                  </Typography>
                  <Hidden mdDown>
                    <Typography className="bigtext" sx={{ mb: 4 }} component="p">
                      <b>AnyChat widget</b>
                      &nbsp;
                      displays on every page of your site
                      <br />
                      and provide as many contact methods as you want.
                    </Typography>
                    <Button color="primary" variant="contained"
                      sx={{
                        fontSize: 22,
                        borderRadius: 70,
                        bgcolor: '#FFF',
                        color: '#536dfe',
                        px: 4
                      }} component={NavLink} to="/register">
                      Get started
                    </Button>
                    <Typography className="small-note" component="p">
                      no credit card required
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                      <Box sx={{ maxWidth: 200, width: 200 }}>
                        <a style={{ display: 'block' }} target="_blank" href='https://play.google.com/store/apps/details?id=one.anychat.app&utm_source=anychat.one&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                          <img style={{ maxWidth: '100%', height: 60 }} alt='Get it on Google Play' src='/static/images/google-play-badge.png'/>
                        </a>
                      </Box>
                      <Box sx={{ maxWidth: 200, width: 200 }}>
                        <a style={{ display: 'block' }} target="_blank" href='https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6463009065'>
                          <img style={{ maxWidth: '100%', height: 60 }} alt='Download on the Apple AppStore' src='/static/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'/>
                        </a>
                      </Box>
                    </Box>
                  </Hidden>
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
                align="right"
              >
                  <Box
                    sx={{
                      '& .MuiBox-root': {
                        m: {
                          xs: 'auto',
                          sm: 'auto',
                          md: 0
                        },
                        left: {
                          xs: 0,
                          sm: 0,
                          md: 'auto'
                        },
                        right: {
                          xs: 0,
                          sm: 0,
                          md: 'auto'
                        },
                        width: {
                          xs: '60%',
                          sm: '60%',
                          md: '100%'
                        }
                      }
                    }}
                    className="main-img"
                  >
                    <Box className={currentStep === 0 ? 'active' : ''}>
                      <img loading="lazy" src="/static/images/02-main.svg" width="454" height="779" className="img-responsive" alt="Facebook messenger" />
                    </Box>
                    <Box className={currentStep === 1 ? 'active' : ''}>
                      <img loading="lazy" src="/static/images/03-main.svg" width="454" height="779" className="img-responsive" alt="WhatsApp" />
                    </Box>
                    <Box className={currentStep === 2 ? 'active' : ''}>
                      <img loading="lazy" src="/static/images/04-main.svg" width="454" height="779" className="img-responsive" alt="Viber" />
                    </Box>
                    <Box className={currentStep === 3 ? 'active' : ''}>
                      <img loading="lazy" src="/static/images/05-main.svg" width="454" height="779" className="img-responsive" alt="Telegram" />
                    </Box>
                    <Box className={currentStep === 4 ? 'active' : ''}>
                      <img loading="lazy" src="/static/images/01-main.svg" width="454" height="779" className="img-responsive" alt="Anychat Widget" />
                    </Box>
                  </Box>
              </Grid>
            </Grid>
          </Container>
          <Hidden mdUp>
            <Container sx={{ paddingTop: '0 !important' }} maxWidth="lg">
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={8}
                  md={8}
                  xs={12}
                >
                  <Box className="homepage-section" sx={{ textAlign: 'center', mt: '70px', mb: 0 }}>
                    <Typography className="bigtext" sx={{ mb: 4 }} component="p">
                      <b>AnyChat widget</b>
                      &nbsp;
                      displays on every page of your site
                      <br />
                      and provide as many contact methods as you want.
                    </Typography>
                    <Button color="primary" variant="contained"
                      sx={{
                        fontSize: 22,
                        borderRadius: 70,
                        bgcolor: '#FFF',
                        color: '#536dfe',
                        px: 4
                      }} component={NavLink} to="/register">
                      Get started
                    </Button>
                    <Typography className="small-note" component="p">
                      no credit card required
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                  align="right"
                />
              </Grid>
            </Container>
          </Hidden>
          <SvgIcon viewBox="0 0 1440 84" fill="none" preserveAspectRatio="none"
            sx={{
              width: '100%',
              height: 'auto',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              display: 'block',
              '& path': {
                fill: (theme) => theme.palette.background.default
              }
            }}>
            <path d="M0 83C454.33-24.774 928.696-27.898 1384.99 71.96L1440 83v2H0Z" />
          </SvgIcon>
        </Box>
          <Box>
            <Container maxWidth="lg" sx={{ pt: '0 !important', pb: '20px !important' }}>
              <Grid item md={12} className="homepage-section">
                <Typography
                  color="text.primary"
                  variant="h2"
                  sx={{
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontSize: {
                      xs: '3.5rem',
                      sm: '7rem'
                    },
                    position: 'relative',
                    color: 'neutral.light',
                    fontWeight: 700,
                    overflow: 'hidden',
                    '&:before': {
                      content: '"Features"',
                      position: 'absolute',
                      fontSize: '0.65em',
                      top: '23%',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      color: 'text.primary'
                    }
                  }}
                  lign="center"
                >
                  Features
                </Typography>
              </Grid>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="lg">
              <Paper elevation={0} sx={{ borderRadius: 3, p: 3 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={4}>
                      <Box sx={{ display: 'flex', mb: 2 }}>
                        <RocketLaunch sx={{ my: 'auto', mr: 2 }} />
                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mb: '0 !important' }}>
                          50+ contact channels
                        </Typography>
                      </Box>
                      
                      <Typography sx={{ fontSize: '1.2rem', mb: '0 !important' }}>
                        Let your customers contact you via their prefered messenger.
                        WhatsApp, Viber, Telegram, and over 50 communication channels covered.
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                      <Box sx={{ display: 'flex', mb: 2 }}>
                        <ChatBubble sx={{ my: 'auto', mr: 2 }} />
                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 0 }}>
                          Full-featured live chat
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '1.2rem', mb: '0 !important' }}>
                        Our service provides a full-featured live chat widget that can help you to communicate with your customers instantly.
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                      <Box sx={{ display: 'flex', mb: 2 }}>
                        <Palette sx={{ my: 'auto', mr: 2 }} />
                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mb: '0 !important' }}>
                          Fully-customizable widget
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '1.2rem', mb: '0 !important' }}>
                        Customize your widget color, style, icons, animation, position, etc... to fit your site branding.
                        You can set it up separately for desktop and mobile devices.
                      </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </Box>
          <Box sx={{ mb: { xs: 0, sm: 0 } }}>
            <Container maxWidth="lg">
              <Grid container spacing={6} className="homepage-section">
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant="h2" mb={2} sx={{ textAlign: { xs: 'center', md: 'left' } }} color="text.primary">
                      Messengers
                    </Typography>
                  </Grid>
              </Grid>
              <Grid container spacing={6} className="homepage-section">
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Typography className="bigtext" mb={2} component="p" color="text.primary">
                    Make it easy for your visitors to communicate in the way they prefer.
                    Choose the channels that can help you convert visitors into customers, such as <b>WhatsApp</b>, <b>Facebook Messenger</b>, chat, links, forms, and more.
                    You can also add the <b>live chat</b> for added convenience.
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#0084ff' }}>
                        <path d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Messenger
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 448 512" sx={{ ...iconSx, fill: '#128c7e' }}>
                        <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        WhatsApp
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#59267c' }}>
                        <path d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Viber
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 496 512" sx={{ ...iconSx, fill: '#0088cc' }}>
                        <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Telegram
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 5 }}>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#00aff0' }}>
                        <path d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Skype
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 640 512" sx={{ ...iconSx, fill: '#5865f2' }}>
                        <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Discord
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 448 512" sx={{ ...iconSx, fill: '#e01563' }}>
                        <path d="M94.12 315.1c0 25.9-21.16 47.06-47.06 47.06S0 341 0 315.1c0-25.9 21.16-47.06 47.06-47.06h47.06v47.06zm23.72 0c0-25.9 21.16-47.06 47.06-47.06s47.06 21.16 47.06 47.06v117.84c0 25.9-21.16 47.06-47.06 47.06s-47.06-21.16-47.06-47.06V315.1zm47.06-188.98c-25.9 0-47.06-21.16-47.06-47.06S139 32 164.9 32s47.06 21.16 47.06 47.06v47.06H164.9zm0 23.72c25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06H47.06C21.16 243.96 0 222.8 0 196.9s21.16-47.06 47.06-47.06H164.9zm188.98 47.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06h-47.06V196.9zm-23.72 0c0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06V79.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06V196.9zM283.1 385.88c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06v-47.06h47.06zm0-23.72c-25.9 0-47.06-21.16-47.06-47.06 0-25.9 21.16-47.06 47.06-47.06h117.84c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06H283.1z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Slack
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#00c300' }}>
                        <path d="M311 196.8v81.3c0 2.1-1.6 3.7-3.7 3.7h-13c-1.3 0-2.4-.7-3-1.5l-37.3-50.3v48.2c0 2.1-1.6 3.7-3.7 3.7h-13c-2.1 0-3.7-1.6-3.7-3.7V196.9c0-2.1 1.6-3.7 3.7-3.7h12.9c1.1 0 2.4 .6 3 1.6l37.3 50.3V196.9c0-2.1 1.6-3.7 3.7-3.7h13c2.1-.1 3.8 1.6 3.8 3.5zm-93.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 2.1 1.6 3.7 3.7 3.7h13c2.1 0 3.7-1.6 3.7-3.7V196.8c0-1.9-1.6-3.7-3.7-3.7zm-31.4 68.1H150.3V196.8c0-2.1-1.6-3.7-3.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 1 .3 1.8 1 2.5c.7 .6 1.5 1 2.5 1h52.2c2.1 0 3.7-1.6 3.7-3.7v-13c0-1.9-1.6-3.7-3.5-3.7zm193.7-68.1H327.3c-1.9 0-3.7 1.6-3.7 3.7v81.3c0 1.9 1.6 3.7 3.7 3.7h52.2c2.1 0 3.7-1.6 3.7-3.7V265c0-2.1-1.6-3.7-3.7-3.7H344V247.7h35.5c2.1 0 3.7-1.6 3.7-3.7V230.9c0-2.1-1.6-3.7-3.7-3.7H344V213.5h35.5c2.1 0 3.7-1.6 3.7-3.7v-13c-.1-1.9-1.7-3.7-3.7-3.7zM512 93.4V419.4c-.1 51.2-42.1 92.7-93.4 92.6H92.6C41.4 511.9-.1 469.8 0 418.6V92.6C.1 41.4 42.2-.1 93.4 0H419.4c51.2 .1 92.7 42.1 92.6 93.4zM441.6 233.5c0-83.4-83.7-151.3-186.4-151.3s-186.4 67.9-186.4 151.3c0 74.7 66.3 137.4 155.9 149.3c21.8 4.7 19.3 12.7 14.4 42.1c-.8 4.7-3.8 18.4 16.1 10.1s107.3-63.2 146.5-108.2c27-29.7 39.9-59.8 39.9-93.1z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        and more...
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="lg">
              <Grid container spacing={6} className="homepage-section">
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ 
                      display: {
                        xs: 'none',
                        md: 'block'
                      }
                    }}
                  />
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Typography variant="h2" mb={2} sx={{ textAlign: { xs: 'center', md: 'left' } }} color="text.primary">
                      Social networks
                    </Typography>
                  </Grid>
              </Grid>
              <Grid container spacing={6} className="homepage-section" sx={{ flexFlow: { xs: 'column-reverse', sm: 'column-reverse', md: 'row' } }}>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#1877f2' }}>
                        <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Facebook
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#833ab4' }}>
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Instagram
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#1da1f2' }}>
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Twitter
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 448 512" sx={{ ...iconSx, fill: '#0a66c2' }}>
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        LinkedIn
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 576 512" sx={{ ...iconSx, fill: '#ff0000' }}>
                        <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        YouTube
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 5 }}>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 448 512" sx={{ ...iconSx, fill: '#45668e' }}>
                        <path d="M31.4907 63.4907C0 94.9813 0 145.671 0 247.04V264.96C0 366.329 0 417.019 31.4907 448.509C62.9813 480 113.671 480 215.04 480H232.96C334.329 480 385.019 480 416.509 448.509C448 417.019 448 366.329 448 264.96V247.04C448 145.671 448 94.9813 416.509 63.4907C385.019 32 334.329 32 232.96 32H215.04C113.671 32 62.9813 32 31.4907 63.4907ZM75.6 168.267H126.747C128.427 253.76 166.133 289.973 196 297.44V168.267H244.16V242C273.653 238.827 304.64 205.227 315.093 168.267H363.253C359.313 187.435 351.46 205.583 340.186 221.579C328.913 237.574 314.461 251.071 297.733 261.227C316.41 270.499 332.907 283.63 346.132 299.751C359.357 315.873 369.01 334.618 374.453 354.747H321.44C316.555 337.262 306.614 321.61 292.865 309.754C279.117 297.899 262.173 290.368 244.16 288.107V354.747H238.373C136.267 354.747 78.0267 284.747 75.6 168.267Z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        VK
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 576 512" sx={{ ...iconSx, fill: '#1769ff' }}>
                        <path d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Behance
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 512 512" sx={{ ...iconSx, fill: '#ea4c89' }}>
                        <path d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Dribbble
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 384 512" sx={{ ...iconSx, fill: '#e60023' }}>
                        <path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        Pinterest
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                      <SvgIcon viewBox="0 0 448 512" sx={{ ...iconSx, fill: '#ff0050' }}>
                        <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                      </SvgIcon>
                      <Typography color="text.primary" sx={{ ...iconLabelSx, fontWeight: 500 }}>
                        TikTok
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Typography mb={2} className="bigtext" color="text.primary" component="p">
                    You can expand your reach and connect with audiences on a variety of social networks. 
                    Whether you prefer the visual focus of Instagram, the professional networking of LinkedIn, or the real-time conversations of Twitter, 
                    you can use custom icons and links to make it easy for users to find and follow you wherever you are online.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="lg">
              <Typography color="text.primary" variant="h2" sx={{ textAlign: 'center', mb: 2, fontSize: '2.5rem' }}>
                Predefined layouts
              </Typography>
              <Paper elevation={0} sx={{ borderRadius: 3, p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Default
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 0.5, borderRadius: 1 }}>
                        <img width="600" height="860" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/layout-1.webp" alt="Layout 1" />
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Personal
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 0.5, borderRadius: 1 }}>
                        <img width="600" height="860" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/layout-2.webp" alt="Layout 2" />
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        No-background
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 0.5, borderRadius: 1 }}>
                        <img width="600" height="860" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/layout-3.webp" alt="Layout 3" />
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Sidebar
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 0.5, borderRadius: 1 }}>
                        <img width="600" height="860" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/layout-4.webp" alt="Layout 4" />
                      </Paper>
                    </Grid>
                  </Grid>
              </Paper>
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Typography className='bigtext' color="text.primary">
                    Choose one which suits you best. <br />
                    You can set one layout for desktop and another for mobile device.
                  </Typography>
              </Box>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Paper elevation={0} sx={{ p: 0.5, borderRadius: 2 }}>
                    <img width="600" height="400" style={{ maxWidth: '400px', margin: 'auto', width: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/prompts.webp" alt="Prompt messages" />
                  </Paper>
                  <Typography className="bigtext" sx={{ mt: 1 }} color="text.primary">
                    Display attractive and automated greating messages
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper elevation={0} sx={{ p: 0.5, borderRadius: 2 }}>
                    <img width="600" height="400" style={{ maxWidth: '400px', margin: 'auto', width: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/schedule.webp" alt="Schedule" />
                  </Paper>
                  <Typography className="bigtext" sx={{ mt: 1 }} color="text.primary">
                    Set display time for each contact channel or whole widget
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper elevation={0} sx={{ p: 0.5, borderRadius: 2 }}>
                    <img width="600" height="400" style={{ maxWidth: '400px', margin: 'auto', width: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/mobile-desktop.webp" alt="Mobile desktop" />
                  </Paper>
                  <Typography className="bigtext" sx={{ mt: 1 }} color="text.primary">
                    Set devices for each contact channel or whole widget
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="lg">
              <Typography color="text.primary" variant="h2" sx={{ textAlign: 'center', mb: 2, fontSize: '2.5rem' }}>
                Full-featured built-in live chat system
              </Typography>
              <Box sx={{ filter: 'drop-shadow(0px 1px 1px rgba(2, 151, 180, 0.04)) drop-shadow(0px 12px 24px rgba(2, 151, 180, 0.16))' }}>
                <img loading="lazy" width="2952" height="1682" style={{ width: '100%', height: 'auto', borderRadius: 16 }} src="/static/images/livechat.webp" alt="LiveChat" />
              </Box>
              <Grid sx={{ mt: 2 }} container spacing={3}>
                <Grid item sm={6}>
                  <Typography color="text.primary" sx={{ mb: 2, fontSize: '1.75rem' }}>
                    Build your own customer support team
                  </Typography>
                  <Typography color="text.primary" sx={{ mb: 2 }} className="bigtext">
                    With chat agents functionality you can add your agents to handle all 
                    incoming live chat messages.
                  </Typography>
                  <Typography color="text.primary" sx={{ mb: 2 }} className="bigtext">
                    Each chat agent can login to his own agent dashboard to handle and reply the messages.
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Paper elevation={0} sx={{ p: 4, borderRadius: 2 }}>
                    <img width="541" height="459" style={{ width: '70%', height: 'auto', display: 'block' }} src="/static/images/agents.svg" alt="LiveChat" />
                  </Paper>
                </Grid>
              </Grid>
              <Box>
                <Typography color="text.primary" sx={{ mb: 0, fontSize: '1.75rem', textAlign: 'center', mt: 4 }}>
                  Telegram gateway
                </Typography>
                <Paper elevation={0} sx={{ p: 0, borderRadius: 4, bgcolor: 'transparent' }}>
                  <img loading="lazy" width="3371" height="2042" style={{ width: '100%', margin: 'auto', height: 'auto', display: 'block' }} src="/static/images/chat-telegram.webp" alt="LiveChat" />
                </Paper>
                <Typography color="text.primary" sx={{ mb: 2, textAlign: 'center' }} className="bigtext">
                  Receive and reply incoming messages in the Telegram app
                </Typography>
              </Box>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="lg">
              <Typography color="text.primary" variant="h2" sx={{ textAlign: 'center', mb: 2, fontSize: '2.5rem' }}>
                Built-in forms
              </Typography>
              <Paper elevation={0} sx={{ borderRadius: 3, p: 3, mb: 4 }}>
                  <Grid container spacing={3} sx={{ mb: 0 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Feedback form
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 4, borderRadius: 3 }}>
                        <img width="628" height="454" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/form-01.svg" alt="Layout 1" />
                      </Paper>
                      <Typography mt={2} sx={{ fontSize: '1.2rem' }}>
                        Gather customer feedback seamlessly within the page without the need to navigate away.
                        Share the collected entries through email, Telegram, or webhook.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Newslatter form
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 4, borderRadius: 3 }}>
                        <img width="628" height="454" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/form-02.svg" alt="Layout 1" />
                      </Paper>
                      <Typography mt={2} sx={{ fontSize: '1.2rem' }}>
                        Increase your newsletter signups by incorporating a registration form directly into a widget.
                        By using webhooks, you can seamlessly link the data collected to your newsletter service.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={0} sx={{ borderRadius: 3, p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Callback form
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 4, borderRadius: 3 }}>
                        <img width="628" height="454" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/form-03.svg" alt="Layout 1" />
                      </Paper>
                      <Typography mt={2} sx={{ fontSize: '1.2rem' }}>
                        This versatile call-back form can be used for various purposes.
                        Collect the names and phone numbers of potential customers requiring assistance, and reach out to them at your convenience.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', mb: 1 }}>
                        Direct email form
                      </Typography>
                      <Paper elevation={0} sx={{ bgcolor:  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.05)', p: 4, borderRadius: 3 }}>
                        <img width="628" height="454" style={{ maxWidth: '100%', height: 'auto', display: 'block', borderRadius: 8 }} src="/static/images/form-04.svg" alt="Layout 1" />
                      </Paper>
                      <Typography mt={2} sx={{ fontSize: '1.2rem' }}>
                        Integrate a contact form into your page, allowing visitors to ask questions without navigating away.
                        The form includes various customizable fields and an optional GDPR checkbox.
                      </Typography>
                    </Grid>
                  </Grid>
              </Paper>
              <Box elevation={0} sx={{ borderRadius: 3, p: 3, mb: 1, textAlign: 'center', mt: 3 }}>
                  <Typography className="bigtext" component="p" color="text.primary" mb={1}>
                    You can create your own forms using our form builder tool.
                  </Typography>
                  <Typography color="text.secondary">
                    Add or remove fields and buttons, change labels, placeholders, position, etc...
                  </Typography>
                  <Typography variant="h2" color="text.primary" mb={2} mt={4}>
                    Get notification about each form submission.
                  </Typography>
                  <Box>
                    <img width="883" height="732" style={{ width: '80%', height: 'auto' }} src="/static/images/form-notifications.svg" alt="Form notifications" />
                  </Box>
              </Box>
            </Container>
          </Box>
        <Box sx={{ bgcolor: 'background.paper' }}>
          <Container maxWidth="lg">
            <Grid item md={12} className="homepage-section" align="center">
              <Typography variant="h2" sx={{ fontSize: '2.5rem' }} color="text.primary" mb={2}>
                AnyChat for any site
              </Typography>
              <Typography className="bigtext" component="p" sx={{ mb: 3 }} color="text.primary">
                Integrate to any custom site, CMS or SiteBuilder via&nbsp;
                <b>Embed code</b>
                .
                <br />
                Or download dedicated plugin for CMS
              </Typography>
            </Grid>
            <Grid container spacing={6} className="homepage-section plugins-grid">
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img src="/static/icons/wordpress.svg" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>WordPress</b>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img src="/static/icons/shopify.svg" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Shopify</b>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img src="/static/icons/wix.svg" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>WIX</b>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img src="/static/icons/joomla.svg" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Joomla</b>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img src="/static/icons/opencart.svg" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>OpenCart</b>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img src="/static/icons/magento.svg" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Magento</b>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box>
          <Container maxWidth="lg">
            <Grid item md={12} className="homepage-section" align="center">
              <Typography variant="h2" sx={{ fontSize: '2.5rem' }} color="text.primary" mb={2}>
                Usefull integrations
              </Typography>
              <Typography className="bigtext" component="p" sx={{ mb: 3 }} color="text.primary">
                Connect with existing tools
              </Typography>
            </Grid>
            <Grid container spacing={6} className="homepage-section features-grid">
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img loading="lazy" src="/static/images/recaptcha.webp" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>reCaptcha V3</b>
                  <br />
                  <small>to prevent SPAM</small>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img loading="lazy" src="/static/images/telegram.webp" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Telegram</b>
                  <br />
                  <small>to receive notifications</small>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img loading="lazy" src="/static/images/twilio.webp" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Twilio</b>
                  <br />
                  <small>to receive SMS notifications</small>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img loading="lazy" src="/static/images/onesignal.webp" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>OneSignal</b>
                  <br />
                  <small>to receive WebPush notifications</small>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img loading="lazy" src="/static/images/google-analytics.webp" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Google Analytics</b>
                  <br />
                  <small>to track events</small>
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={4}
                align="center"
              >
                <img loading="lazy" src="/static/images/perfex-crm.webp" width="100" height="100" className="img-responsive" alt="placeholder" />
                <Typography className="bigtext" component="p" color="text.primary">
                  <b>Perfex CRM</b>
                  <br />
                  <small>to create leads</small>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
