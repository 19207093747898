import PageMeta from "src/components/app/PageMeta";
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
import { Box, Container } from "@mui/material";

const Changelog = lazy(() => import('./Changelog'));

const ChangelogPage = () => {
    return (
        <>
            <PageMeta title="Changelog" />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container sx={{ py: 3 }}>
                    <Suspense fallback={<LoadingProgress open />}>
                        <Changelog />
                    </Suspense>
                </Container>
            </Box>
        </>
    );
};

export default ChangelogPage;