import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useParams } from "react-router";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import { useAppConext } from "src/components/context/AppContext";

const PlansDetails = lazy(() => import('./upgrade/PlansDetails'));
const AgencyPlansDetails = lazy(() => import('./upgrade/agency/PlansDetails'));

const PlansComparison = () => {
    const { user } = useAppConext();
    const { setId } = useParams();

    return (
        <>
            <PageMeta title="Detailed plans comparison" />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: {
                        xs: 1,
                        md: 2
                    },
                }}
            >
                <Box sx={{ my: 5, mx: {
                    xs: 1,
                    ms: 1,
                    md: 5
                } }}>
                    {user?.rootUser !== undefined && (
                        (user?.rootUser === true && setId === undefined) ? (
                            <Suspense fallback={<LoadingProgress open />}>
                                <PlansDetails
                                    cancelURL="/app/upgrade?canceled=true&session_id={CHECKOUT_SESSION_ID}"
                                    successURL="/app/payment-success?session_id={CHECKOUT_SESSION_ID}"
                                    redirect={false}
                                />
                            </Suspense>
                        ) : (
                            <Suspense fallback={<LoadingProgress open />}>
                                <AgencyPlansDetails
                                    cancelURL="/app/upgrade?canceled=true&session_id={CHECKOUT_SESSION_ID}"
                                    successURL="/app/payment-success?session_id={CHECKOUT_SESSION_ID}"
                                    redirect={false}
                                />
                            </Suspense>
                        )
                    )}
                </Box>
            </Box>
        </>
    );
}

export default PlansComparison;