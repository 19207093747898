import { Box, Divider, List, ListItemButton, ListItemText, Menu, MenuItem, Popover, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $api from "./http";

const TagsPopup = (props) => {
    const { anchorEl, onClose, onSelect, allowAdd = true } = props;
    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [items, setItems] = useState([]);
    const { t } = useTranslation();

    const handleSelectTag = (item) => {
        onSelect(item);
        onClose();
    };

    const loadTags = () => {
        $api.get('/tag')
            .catch((err) => {

            })
            .then((res) => {
                setItems(res.data);
            })
            .finally(() => {

            })
    };

    const handleSelectTagByIndex = (idx) => {
        const item = filteredItems[idx];
        if (item === undefined) {
            handleAddTag();
        } else {
            handleSelectTag(item);
        }
    };

    const handleAddTag = () => {
        if (allowAdd === false) {
            return false;
        }
        $api.post('/tag/create', {
            label: search
        })
            .catch((err) => {

            })
            .then((res) => {
                loadTags();
                handleSelectTag(res.data);
            })
            .finally(() => {

            })
    };

    useEffect(() => {
        if (search === '') {
            setFilteredItems(items);
        } else {
            const fi = items.filter((i) => i.label.toLowerCase().indexOf(search.toLowerCase()) !== -1);
            setFilteredItems(fi);
        }
    }, [search, items]);

    useEffect(() => {
        setSelectedIndex(0);
    }, [items]);

    useEffect(() => {
        if (!!anchorEl) {
            loadTags();
        } else {
            setSearch('');
        }
    }, [anchorEl]);

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ p: 1 }}>
                <TextField
                    autoFocus
                    placeholder={t('Search or create tag')}
                    name="tag"
                    size="small"
                    value={search}
                    fullWidth
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyUp={(e) => {
                        if (e.code === 'ArrowUp') {
                            setSelectedIndex((i) => {
                                const newI = i - 1;
                                return newI < 0 ? filteredItems.length - 1 : newI;
                            });
                        } else if (e.code === 'ArrowDown') {
                            setSelectedIndex((i) => {
                                const newI = i + 1;
                                return newI > (filteredItems.length - 1) ? 0 : newI;
                            });
                        } else if (e.code === 'Enter') {
                            handleSelectTagByIndex(selectedIndex);
                        }
                    }}
                />
            </Box>
            <Divider />
            <List dense sx={{ maxHeight: 300, overflow: 'auto', width: 320 }}>
                {(filteredItems.length > 0 ) ? (
                    (filteredItems.map((i, idx) => (
                        <ListItemButton selected={idx === selectedIndex} key={i.id} onClick={() => handleSelectTag(i)}>
                            <Box sx={{
                                bgcolor: i.color || 'text.secondary',
                                height: 8,
                                width: 8,
                                borderRadius: 5,
                                my: 'auto',
                                mr: 1
                            }} />
                            <ListItemText primary={i.label} primaryTypographyProps={{
                                noWrap: true
                            }} />
                        </ListItemButton>
                    )))
                ) : (
                    (allowAdd ? (
                        (search !== '' ? (
                            <ListItemButton onClick={handleAddTag}>
                                <ListItemText
                                    disableTypography
                                    primary={(
                                        <Typography variant="body2">
                                            {t('Press Enter to add "{{s}}"', {
                                                s: search
                                            })}
                                        </Typography>
                                    )}
                                />
                            </ListItemButton>
                        ) : (
                            <Typography color="text.disabled" sx={{ px: 2, py: 1 }} variant="body2">
                                {t('Enter a tag')}
                            </Typography>
                        ))
                    ) : (
                        <Typography sx={{ fontStyle: 'italic', px: 1 }} color="text.secondary">
                            {t('No tags found')}
                        </Typography>
                    ))
                    
                )}
                
            </List>
        </Popover>
    );
};

export default TagsPopup;