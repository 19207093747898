import { useState } from 'react';
import useToken from 'src/components/app/useToken';
import $api from '../http';
import { useTranslation } from 'react-i18next';

let listeners = [];

const useUser = () => {
  const { i18n } = useTranslation();
  const { logout } = useToken();
  const [user, setUser] = useState();

  const subscribe = (listn) => {
    listeners.push(listn);
  };

  const unsubscribe = (listn) => {
    listeners = listeners.filter((entity) => entity !== listn);
  };

  const notify = (data) => {
    listeners.forEach((listener) => listener(data));
  };

  const setUserData = (data) => {
    let changed = false;
    if (user !== data) {
      changed = true;
    }
    setUser(data);
    if (i18n.language !== data.lang) {
      i18n.changeLanguage(data.lang);
    }
    if (changed) {
      notify(data);
    }
  };

  const getUser = async (reload) => {
    if (user === null || (typeof user === 'undefined') || reload) {
      const data = await $api.get('/user')
        .catch((e) => {
          console.log(e);
          return e;
        })
        .then((result) => (result.data));
      if (!data) {
        logout();
      }
      setUserData(data);
      return data;
    }
    return user;
  };

  return {
    user,
    getUser,
    setUserData,
    subscribe,
    unsubscribe,
    notify
  };
};

export default useUser;
