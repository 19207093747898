import { lazy, Suspense } from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
const Content = lazy(() => import('../../components/account/AccountPlan'));

const AccountPlan = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Account')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
          <Suspense fallback={<LoadingProgress open />}>
            <Content />
          </Suspense>
      </Box>
    </>
  );
};

export default AccountPlan;