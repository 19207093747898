import { Duo, PlaylistAdd, RocketLaunch } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import RestrictedFieldWrapper from "src/components/app/RestrictedFieldWrapper";
import { useAppConext } from "src/components/context/AppContext";
import MeetingDialog from "src/components/meetings/MeetingDialog";
import MeetingsToolbar from "src/components/meetings/MeetingToolbar";
import MeetingsTable from "src/components/meetings/MettingsTable";

const CrmList = lazy(() => import('../../../src/components/crmList'));

const MeetingsPage = () => {
    const { t } = useTranslation();
    const { getFeatureValue, user } = useAppConext();

    return (
        <>
            <PageMeta title={t('Meetings')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <RestrictedFieldWrapper
                    fullWidth
                    containerSx={{ display: 'flex' }}
                    blur
                    badgeType={false}
                    restricted={!getFeatureValue('contacts.enabled')}
                    customContent={(
                        <Box>
                            <Duo sx={{ width: 40, height: 40, color: 'error.main' }} />
                            <Typography color="error" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {t('Meetings section is locked')}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {t('Ulock this section to be able to manage and plan meetings')}
                            </Typography>
                            <Button sx={{ mt: 1 }} variant="outlined" startIcon={<RocketLaunch />} component={NavLink} to="/app/upgrade">
                                {t('Upgrade now')} 
                            </Button>
                        </Box>
                    )}
                >
                    <Suspense fallback={<LoadingProgress open />}>
                        <CrmList
                            expand="contacts,tags,agents"
                            indexUrl="/meeting"
                            deleteUrl="/meeting/delete"
                            DialogComponent={MeetingDialog}
                            TableComponent={MeetingsTable}
                            ToolbarComponent={MeetingsToolbar}
                            accessGranted={user?.permissions?.meetings}
                            noContentOptions={{
                                icon: (<Duo sx={{ width: 40, height: 40, color: 'text.secondary' }} />),
                                title: t('No meetings yet'),
                                content: t('There are no meetings in this workspace'),
                                buttonTitle: t('Create a meeting'),
                                buttonIcon: (<PlaylistAdd />)
                            }}
                        />
                    </Suspense>
                </RestrictedFieldWrapper>
            </Box>
        </>
    );
};

export default MeetingsPage;