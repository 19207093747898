import { KeyboardArrowUp } from "@mui/icons-material";
import { Fab, Zoom } from "@mui/material";

const GoTop = (props) => {
    const { scrolled, onClick } = props;
    return (
        <Zoom in={scrolled}>
            <Fab aria-label="gotop" onClick={onClick} color="success" size="small"
                sx={{
                    position: 'fixed',
                    right: {
                        xs: 80,
                        md: 100
                    },
                    bottom: {
                        xs: 25,
                        md: 30
                    }
                }}
            >
                <KeyboardArrowUp sx={{ color: 'white' }} />
            </Fab>
        </Zoom>
    );
};

export default GoTop;