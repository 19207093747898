import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

const SaveButton = (props) => {
  const {label, ...rest} = props;
  return (
    <LoadingButton
      startIcon={<SaveIcon />}
      loadingPosition="start"
      {...rest}
    >
      {label}
    </LoadingButton>
  )
};

export default SaveButton;
