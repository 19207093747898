import { useEffect, useState } from "react";
import $api from "../http";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { AccessAlarm, AccessTime, Telegram } from "@mui/icons-material";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Box } from "@mui/system";
import { useAppConext } from "../context/AppContext";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);


const AnouncementDialog = () => {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState(0);

    const { t } = useTranslation();
    const { user } = useAppConext();

    const loadContent = () => {
        $api.get('/user/anouncements')
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                    setOpen(true);
                }
            })
    };
    
    useEffect(() => {
        loadContent();
    }, []);

    const submitAnouncement = (id) => {
        setIsSubmitting(true);
        $api.post('/user/submit-anouncement', {
            id: id
        })
            .catch((err) => {

            })
            .finally(() => {
                setIsSubmitting(false);
            })
    };

    if (items.length === 0) {
        return (<></>);
    }

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle>
                {items[currentItemIndex].title}
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <Typography color="error.main">
                    {items[currentItemIndex].content}
                </Typography>
                <List dense sx={{ py: 0}}>
                    {(items[currentItemIndex].event_time) && (
                        <ListItem>
                            <ListItemIcon sx={{ minWidth: 42 }}>
                                <AccessAlarm />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Scheduled event time')}
                                secondary={`${dayjs.unix(items[currentItemIndex].event_time).tz('UTC').format('MMM D YYYY HH:mm')} UTC`}
                            />
                        </ListItem>  
                    )}
                    {(items[currentItemIndex].event_duration) && (
                        <ListItem>
                            <ListItemIcon sx={{ minWidth: 42 }}>
                                <AccessTime />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Event duration')}
                                secondary={dayjs.duration(items[currentItemIndex].event_duration, 'seconds').humanize(false)}
                            />
                        </ListItem>  
                    )}
                </List>
            </DialogContent>
            <DialogActions sx={{ alignItems: 'center' }}>
                {(user?.rootUser === true) && (
                    <Box sx={{ mr: 'auto', display: 'flex', alignItems: 'center' }}>
                        <Button color="info" component="a" target="_blank" href="https://t.me/anychat_one" startIcon={<Telegram sx={{ width: 32, height: 32, color: '#24A1DE' }} />} sx={{
                            py: 0,
                            textTransform:
                            'none',
                            textAlign: 'left',
                            minHeight: 30,
                            lineHeight: '1rem'
                        }}>
                            <Box sx={{ maxWidth: 260 }}>
                                <Typography sx={{ fontSize: '0.8rem' }}>
                                    {t('Keep up with the latest by following our Telegram channel')}
                                </Typography>
                            </Box>
                        </Button>
                    </Box>
                )}
                
                {items.length > 1 ? (
                    <>
                        <Button disabled={currentItemIndex === 0} onClick={() => {
                            setCurrentItemIndex((i) => (i - 1 < 0) ? 0 : (i - 1));
                        }}>
                            {t('Back')}
                        </Button>
                        <LoadingButton loading={isSubmitting} color="primary" variant="contained" disableElevation onClick={() => {
                            submitAnouncement(items[currentItemIndex].id);
                            const nextIndex = currentItemIndex + 1;
                            if (items[nextIndex] !== undefined) {
                                setCurrentItemIndex((old) => old + 1);
                            } else {
                                setOpen(false);
                            }
                        }}>
                            {items[currentItemIndex + 1] === undefined ? t('Ok') : t('Next')}
                        </LoadingButton>
                    </>
                ) : (
                    <LoadingButton loading={isSubmitting} color="primary" variant="contained" disableElevation onClick={() => {
                        submitAnouncement(items[currentItemIndex].id);
                        setOpen(false);
                    }}>
                        {t('Ok')}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AnouncementDialog;