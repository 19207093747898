import { ChatBubbleOutline, RocketLaunch } from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import RestrictedFieldWrapper from 'src/components/app/RestrictedFieldWrapper';
import { useAppConext } from 'src/components/context/AppContext';
const ChatComponent = lazy(() => import('../../components/chat/ChatComponent'));
  
const ChatBotDriven = () => {
    const { t } = useTranslation();
    const { getFeatureValue } = useAppConext();

    return (
      <>
        <PageMeta title={t('Bot-driven chat')} />
        <Box
          sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              height: '100%',
              display: 'flex',
              p: {
                xs: '57px 0 0 0',
                ms: '57px 0 0 0',
                md: 0,
                lg: 0
              },
              position: 'relative'
          }}
        >
          <RestrictedFieldWrapper
              fullWidth
              badgeType={false}
              containerSx={{ display: 'flex' }}
              blur
              restricted={!getFeatureValue('livechat.enabled')}
              customContent={(
                  <Box>
                      <ChatBubbleOutline sx={{ width: 40, height: 40, color: 'error.main' }} />
                      <Typography color="error" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                          {t('Chat section is locked')}
                      </Typography>
                      <Typography color="text.secondary" variant="body2">
                          {t('Ulock this section to be able to manage chat conversations')}
                      </Typography>
                      <Button sx={{ mt: 1 }} variant="outlined" startIcon={<RocketLaunch />} component={NavLink} to="/app/upgrade">
                          {t('Upgrade now')} 
                      </Button>
                  </Box>
              )}
          >
            <Suspense fallback={<LoadingProgress open />}>
              <ChatComponent botDriven baseThreadURL="/app/chat" />
            </Suspense>
          </RestrictedFieldWrapper>
        </Box>
      </>
    );
};
  
export default ChatBotDriven;