import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import MeetingSidebar from "src/components/meetings/MeetingSidebar";
import MeetingTabs from "src/components/meetings/MeetingTabs";
import MeetingAgents from "src/components/meetings/tabs/MeetingAgents";
import MeetingAttachments from "src/components/meetings/tabs/MeetingAttachments";
import MeetingContacts from "src/components/meetings/tabs/MeetingContacts";
import MeetingNotes from "src/components/meetings/tabs/MeetingNotes";
import MeetingTasks from "src/components/meetings/tabs/MeetingTasks";
import MeetingTickets from "src/components/meetings/tabs/MeetingTickets";
import MeetingTimeline from "src/components/meetings/tabs/MeetingTimeline";

const CrmItem = lazy(() => import('../../../src/components/crmItem'));

const MeetingPage = () => {
    const { id, tab } = useParams();
    const { t } = useTranslation();

    return (
        <>
            <PageMeta title={t('Meetings')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <CrmItem
                        url="/meeting/get"
                        itemGuid={id}
                        backUrl="/app/meetings"
                        itemsTitle={t('Meetings')}
                        SidebarComponent={MeetingSidebar}
                        TabsComponent={MeetingTabs}
                        tab={tab || 'notes'}
                        contentComponents={[
                            {
                                tabId: 'notes',
                                component: MeetingNotes
                            },
                            {
                                tabId: 'attachments',
                                component: MeetingAttachments
                            },
                            {
                                tabId: 'agents',
                                component: MeetingAgents
                            },
                            {
                                tabId: 'contacts',
                                component: MeetingContacts
                            },
                            {
                                tabId: 'tickets',
                                component: MeetingTickets
                            },
                            {
                                tabId: 'tasks',
                                component: MeetingTasks
                            },
                            {
                                tabId: 'timeline',
                                component: MeetingTimeline
                            },
                        ]}
                    />
                </Suspense>
            </Box>
        </>
    );
};

export default MeetingPage;