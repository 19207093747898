import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import {
  useNavigate,
  matchPath,
  useLocation,
} from 'react-router-dom';

import {
    Button,
    IconButton,
    Popover,
    Typography,
    Box,
    Badge,
    Tooltip,
    Link,
    Divider,
  } from '@mui/material';
import ConnectionOpenedBadge from '../chat/ConnectionOpenedBadge';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import { Close, Public } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../app/UserAvatar';
import SmallAvatar from '../app/SmallAvatar';
import { decode } from 'html-entities';
import SourceIcon from '../chat/SourceIcon';
import useDayjs from '../app/useDayjs';

const audioFile = new Audio(`${process.env.REACT_APP_API_URL}audio/notif.ogg`);

const ChatPopover = (props) => {
    const { t } = useTranslation();
    const { chatWebSocket, agent } = props;
    const navigate = useNavigate();
    const [inviteOpen, setInviteOpen] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);
    const [invite, setInvite] = useState(null);
    const [latestMessage, setLatestMessage] = useState(null);
    const location = useLocation();
    const [audio] = useState(audioFile);
    
    const { enqueueSnackbar } = useSnackbar();
    const { dayjs } = useDayjs();

    const chatListener = useCallback((socketData) => {
        if (socketData.workspace !== chatWebSocket.getWorkspace()) {
            return false;
        }
        if (socketData.action === 'inviteToThread') {
            setInvite(socketData.thread);
            setLatestMessage(socketData);
            setInviteOpen(true);
            setMessageOpen(true);
            audio.play();
        }
        if (socketData.action === 'message' && (socketData.message?.is_service === 0 || socketData.message?.is_service === 3)) {
            console.log(socketData);
            const matchUrl = agent ? `agent/${socketData.widgetUID}/chat/${socketData.thread}` : `app/chat/${socketData.thread}`;
            const matchBotDriven = agent ? `agent/${socketData.widgetUID}/chat/bot-driven/${socketData.thread}` : `app/chat/bot-driven/${socketData.thread}`;
            if (!matchPath({
                path: matchUrl,
                end: true
            }, location.pathname) && !(matchPath({
                path: matchBotDriven,
                end: true
            }, location.pathname)) && socketData.isChatFlow !== true) {
                console.log(socketData.message.from_guid, chatWebSocket.getUserGuid());
                if (socketData.message.from_guid !== chatWebSocket.getUserGuid()) {
                    if ((socketData.operators && socketData.operators.indexOf(chatWebSocket.getUserGuid()) !== -1) || (!socketData.operators || socketData.operators.length === 0)){
                        setLatestMessage(socketData);
                        setMessageOpen(true);
                        audio.play();
                    }
                }
            } else {
                if (!document.hasFocus() && socketData.isChatFlow !== true) {
                    if ((socketData.operators && socketData.operators.indexOf(chatWebSocket.getUserGuid()) !== -1) || (!socketData.operators || socketData.operators.length === 0)){
                        audio.play();
                    }
                }
            }
        }
        if (socketData.action === 'threadUnassigned') {
            enqueueSnackbar(t("You're unassigned from the thread"), {
                variant: 'warning'
            });
        }
        if (socketData.action === 'threadReassigned' && socketData.thread.assigned_to === chatWebSocket.getUserGuid()) {
            setInvite(socketData.thread);
            enqueueSnackbar(t("You're assigned to the thread"), {
                variant: 'info',
                autoHideDuration: 5000,
                action: (
                    <Button sx={{ color: '#FFF' }} onClick={() => handleJoinThread(socketData.thread.guid, socketData.thread.widget_uid)}>
                        {t('Join the thread')}
                    </Button>
                )
            });
        }
    });

    useEffect(() => {
        chatWebSocket.subscribe(chatListener);
        return () => {
            chatWebSocket.unsubscribe(chatListener);
        };
    }, [chatListener]);

    const handleInviteClose = () => {
        setInviteOpen(false);
        setMessageOpen(false);
    };

    const handleMessageClose = () => {
        setMessageOpen(false);
        setInviteOpen(false);
    };

    const handleJoinThread = async (thread) => {
        chatWebSocket.sendJsonMessage({
            action: 'joinThread',
            thread: thread,
            auth: {
                guid: chatWebSocket.userGuid,
                widget: chatWebSocket.getWidget(),
                token: await chatWebSocket.getToken(),
                workspace: chatWebSocket.getWorkspace()
            }
        });
        handleInviteClose();
        const url = `/app/chat/${thread}`;
        navigate(url, { replace: true });
    };

    const handleReplyThread = (thread) => {
        handleMessageClose();
        const url = `/app/chat/${thread}`;
        navigate(url, { replace: true });
    };

    return (
        <Popover
            open={!!messageOpen}
            sx={{ 
                pointerEvents: 'none'
            }}
            disableAutoFocus
            disableEnforceFocus
            disableScrollLock
            hideBackdrop
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {(latestMessage && latestMessage.message.client && latestMessage.message.client.data) && (
            <Box
                sx={{
                p: 1,
                width: 400,
                pointerEvents: 'all'
                }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ mr: 1 }}>
                        <>
                        {(latestMessage.message.client.online === true) ? (
                            <ConnectionOpenedBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            variant="dot"
                            >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={(
                                    (latestMessage.message.client.data && latestMessage.message.client.data.geoip) && (
                                        <Tooltip title={latestMessage.message.client.data.geoip.names.en}>
                                            <SmallAvatar alt={latestMessage.message.client.data.geoip.names.en} src={`${process.env.REACT_APP_API_URL}img/flags/${latestMessage.message.client.data.geoip.iso_code.toLowerCase()}.svg`} />
                                        </Tooltip>
                                    )
                                )}
                            >
                                <UserAvatar img={latestMessage.message.client.data.img} guid={latestMessage.message.client.guid} name={latestMessage.message.client.displayName || 'Visitor'} />
                            </Badge>
                            </ConnectionOpenedBadge>
                        ) : (
                            <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={(
                                (latestMessage.message.client.data && latestMessage.message.client.data.geoip) && (
                                    <Tooltip title={latestMessage.message.client.data.geoip.names.en}>
                                        <SmallAvatar alt={latestMessage.message.client.data.geoip.names.en} src={`${process.env.REACT_APP_API_URL}img/flags/${latestMessage.message.client.data.geoip.iso_code.toLowerCase()}.svg`} />
                                    </Tooltip>
                                )
                            )}  
                            >
                                <UserAvatar img={latestMessage.message.client.data.img} guid={latestMessage.message.client.guid} name={latestMessage.message.client.displayName || 'Visitor'} />
                            </Badge>
                        )}
                        </>
                    </Box>
                    <IconButton onClick={handleMessageClose} size="small" sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <Close fontSize="small" />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box>
                            <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }}>
                                {latestMessage.message.client.displayName || 'Visitor'}
                            </Typography>
                            </Box>
                            <Box sx={{ mt: 'auto', ml: 'auto', mr: 5 }}>
                            <Tooltip placement="top" arrow title={dayjs.unix(latestMessage.message.created_at).format('YYYY MMM DD HH:mm:ss')}>
                                <span>
                                    <Typography variant="body2" color="text.secondary">
                                        {dayjs.unix(latestMessage.message.created_at).fromNow()}
                                    </Typography>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                    {(latestMessage.message.client.email) && (
                        <Typography variant="body2" color="text.secondary">
                            {latestMessage.message.client.email}
                        </Typography>
                    )}
                        <Box sx={{ mt: 0 }}>
                            <Typography>
                                {decode(latestMessage.message.cleanMessage)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ my: 1}} />
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {(latestMessage.message.client.data && latestMessage.message.client.data.browser) && (
                            <Tooltip position="left" arrow title={`Source: ${latestMessage.message.client.data.browser.location}`}>
                                <IconButton size="small" component={Link} href={latestMessage.message.client.data.browser.location} target="_blank">
                                    <Public />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Box sx={{ ml: 1 }}>
                            <SourceIcon size={24} tooltipPlacement="top" channel={latestMessage.message.source} />
                        </Box>
                    </Box>
                    {(inviteOpen) ? (
                        <Button sx={{ ml: 'auto' }} size="small" onClick={() => handleJoinThread(latestMessage.thread)}>
                            {t('Join thread')}
                        </Button>
                    ) : (
                        <Button sx={{ ml: 'auto' }} onClick={() => handleReplyThread(latestMessage.thread)}>
                            {t('Reply')}
                        </Button>
                    )}
                </Box>
            </Box>
            )}
        </Popover>
    );
};

export default ChatPopover;