import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveButton from 'src/components/app/SaveButton';
import { useSnackbar } from 'notistack';
import $api from '../http';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useAppConext } from '../context/AppContext';

export default function WidgetsDialog({
  open = false,
  onClose,
  onSave,
  saveUrl,
  currentItem
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [config, setConfig] = useState({
    id: null,
    user_id: null,
    name: null,
    widget: null
  });
  const [error, setError] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAppConext();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    setConfig(currentItem);
  }, [currentItem]);

  const handleClose = () => {
    setIsOpen(false);
    setError({});
    onClose();
  };

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setConfig({ ...config, [event.target.name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    let url = saveUrl;
    let method = 'POST';
    if (config.id) {
      url = `${saveUrl}/${config.id}`;
      method = 'PUT';
    }
    $api.request({
      method,
      url,
      data: config
    })
      .catch((e) => {
        if (e.response.status === 422) {
          const errors = {};
          e.response.data.map((item) => {
            errors[item.field] = item.message;
            return errors;
          });
          setError(errors);
          setIsLoading(false);
        } else if(e.response.status === 403) {
          enqueueSnackbar(e.response.data.message, {
              variant: 'error'
          });
        }
        return e;
      })
      .then((res) => {
        if (res?.data) {
          setIsLoading(false);
          onSave(res.data);
          handleClose();
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (
    <div>
      {(Object.keys(config).length !== 0) && (
        <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{config.id ? t('Widget settings') : t('Add new widget')}</DialogTitle>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <TextField
                error={!!error.name}
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label={t('Name')}
                type="text"
                value={config.name}
                onChange={handleChange}
                helperText={error.name}
                fullWidth
                required
              />
              <TextField
                error={!!error.domain}
                margin="dense"
                id="domain"
                name="domain"
                label={t('Domain')}
                type="text"
                multiline
                rows="8"
                value={config.domain}
                onChange={handleChange}
                helperText={error.domain ? error.domain : t('Please specify domains which you will use the widget. Please specify schema (http:// or https://). Example: "https://domain.com". Each domain on new line.')}
                fullWidth
                required
              />
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={config.lock_origin} onChange={handleChange} name="lock_origin" />}
                  label={t('Allow for whitelisted domains only')}
                />
              </FormGroup>
              {(user?.is_agency === 1) && (
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={config.show_credits} onChange={handleChange} name="show_credits" />}
                    label={t('Show agency branding')}
                  />
                </FormGroup>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t('Cancel')}
              </Button>
              &nbsp;
              <SaveButton
                color="primary"
                variant="contained"
                type="submit"
                label={t('Save')}
                loading={isLoading}
              />
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

WidgetsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saveUrl: PropTypes.string,
  currentItem: PropTypes.object
};
