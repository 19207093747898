import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $api from "../http";
import TasksTable from "./TasksTable";
import TaskDialogContent from "./TaskDialogContent";
import { ChevronLeft } from "react-feather";
import { Add, TaskAlt } from "@mui/icons-material";
import NoContent from "../app/NoContent";
import { stringify } from "qs";
import { useSnackbar } from "notistack";
import useDayjs from "../app/useDayjs";

const TaskSelectorDialog = (props) => {
    const {
        open,
        allowSelect,
        allowCreate,
        contact,
        currentThread,
        onSubmit,
        onClose
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isReloading, setIsReloading] = useState(false);
    const [items, setItems] = useState([]);
    const [addMode, setAddMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({});
    const [forbidden, setForbidden] = useState(false);

    const { t } = useTranslation();
    const { dayjs } = useDayjs();
    const { enqueueSnackbar } = useSnackbar();

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        loadContent('', true, page, event.target.value, filter);
    };
    
    const handlePageChange = (event, newPage) => {
        loadContent('', true, newPage, limit, filter);
    };

    const createQueryString = (data) => {
        return Object.keys(data).map(key => {
          let val = data[key]
          if (val !== null && typeof val === 'object') val = createQueryString(val)
            return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
        }).join('&')
    }

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAddMode(false);
            setSelectedItems([]);
        }, 500);
    };

    const handleSubmit = () => {
        onSubmit(selectedItems);
        setTimeout(() => {
            setAddMode(false);
            setSelectedItems([]);
        }, 500);
    };

    const loadContent = (s, searchReload, p, l, filterData = {}) => {
        if (searchReload) {
            setIsReloading(true);
        } else {
            setIsLoading(true);
        }
        const params = stringify({
            s: s,
            expand: 'contacts,tags',
            page: p + 1,
            'per-page': l,
            filter: filterData
        });
        $api.get(`/task?${params}`)
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || t('Error loading data'), {
                    variant: 'error'
                });
                if (err?.response?.status === 403) {
                    setForbidden(true);
                }
            })
            .then((res) => {
                if (res) {
                    setForbidden(false);
                    setItems(res.data);
                    const totalCount = res.headers['x-pagination-total-count'];
                    const currentPage = res.headers['x-pagination-current-page'];
                    const perPage = res.headers['x-pagination-per-page'];

                    setPage(parseInt(currentPage, 10) - 1);
                    setLimit(parseInt(perPage, 10));
                    setTotalItems(parseInt(totalCount, 10));
                }
            })
            .finally(() => {
                setIsReloading(false);
                setIsLoading(false);
            })
    };

    const handleSelectOne = (e, id) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedItems((old) => [...old, id]);
        } else {
            setSelectedItems((old) => old.filter((i) => i !== id));
        }
    };

    const handleSelectAll = (e) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedItems(items.map((i) => i.id));
        } else {
            setSelectedItems([]);
        }
    };

    useEffect(() => {
        if (open) {
            loadContent();
        }
    }, [open]);

    return (
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
            <DialogTitle>
                {(addMode === true) ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                            <IconButton sx={{ mr: 1 }} onClick={() => setAddMode(false)}>
                                <ChevronLeft />
                            </IconButton>
                        </Box>
                        <Typography sx={{ fontWeight: '500', fontSize: '1.1rem' }}>
                            {t('New task')}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '1.1rem' }}>
                            {t('Select tasks')}
                        </Typography>
                        <Box sx={{ ml: 'auto' }}>
                            <Button variant="outlined" onClick={() => setAddMode(true)} startIcon={<Add />}>
                                {t('Create a task')}
                            </Button>
                        </Box>
                    </Box>
                )}
            </DialogTitle>
                {(addMode === false) ? (
                    <>
                        <DialogContent sx={{ p: 0 }}>
                            <Box sx={{ minHeight: 220 }}>
                                {(items.length > 0) ? (
                                    <TasksTable
                                        items={items}
                                        selectedItems={selectedItems}
                                        handleSelectOne={handleSelectOne}
                                        handleSelectAll={handleSelectAll}
                                        handlePageChange={handlePageChange}
                                        handleLimitChange={handleLimitChange}
                                        compactView
                                        totalItems={totalItems}
                                        page={page}
                                        limit={limit}
                                        disablePagination={false}
                                        disableSelection={false}
                                    />
                                ) : (
                                    <NoContent
                                        icon={<TaskAlt sx={{ width: 40, height: 40, color: 'text.secondary' }} />}
                                        title={t('No tasks yet')}
                                        content={t('There are no tasks in this workspace')}
                                    />
                                )}
                                
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                {t('Cancel')}
                            </Button>
                            <Button variant="contained" disableElevation disabled={selectedItems.length === 0} onClick={handleSubmit}>
                                {t('OK')}
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <TaskDialogContent
                        open
                        currentItem={{
                            status: 1,
                            priority: 1,
                            due_date: dayjs().add(1, 'day')
                        }}
                        contact={contact}
                        currentThread={currentThread}
                        onClose={handleClose}
                        onSubmit={(data) => {
                            loadContent();
                            onSubmit([data.id]);
                        }}
                    />
                )}
        </Dialog>
    );
};

export default TaskSelectorDialog;