import { useParams } from 'react-router-dom';
import {
  Box,
} from '@mui/material';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';

const ChatFlow = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <PageMeta title={t('Chat flows')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
          px: 3
        }}
      >
        <Box>
          {t('chat flow')}
          {id}
        </Box>
      </Box>
    </>
  );
};

export default ChatFlow;
