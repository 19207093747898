import { useEffect, useState, useCallback } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  NavLink,
  matchPath
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListItemIcon,
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import useToken from 'src/components/app/useToken';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import InboxIcon from '@mui/icons-material/Inbox';
import LogoutIcon from '@mui/icons-material/Logout';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FeatureRequestDialog from 'src/components/home/FeatureRequestDialog';
import NavItem from '../NavItem';
import { Assistant, BugReport, Business, ConfirmationNumber, Contacts, Duo, KeyboardArrowLeft, KeyboardArrowRight, MonetizationOn, MoreHoriz, People, RocketLaunch, School, TaskAlt } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import useWidget from '../app/useWidget';
import PerfectScrollbar from 'react-perfect-scrollbar';
import pjson from "../../../package.json";
import ChatFlowIcon from '../icons/ChatFlowIcon';
import { useTranslation } from 'react-i18next';
import { useAppConext } from '../context/AppContext';

const DashboardSidebar = ({ onMobileClose, openMobile, chatWebSocket, onCollapse, collapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { logout } = useToken();
  const navigate = useNavigate();
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [featureDialogOpen, setFeatureDialogOpen] = useState(false);
  const [initConfig, setInitConfig] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const widget = useWidget();
  const [moreMenuEl, setMoreMenuEl] = useState(null);
  const { loadBrandInfo, user, plan, getFeatureValue, workspace } = useAppConext();

  useEffect(async () => {
    if (featureDialogOpen) {
      setInitConfig({
        type: 'livechat',
        email: user?.email || '',
        subject: '',
        message: '',
        home_url: '',
        register_url: '',
        api_url: ''
      });
    }
  }, [featureDialogOpen]);

  const handleFeatureRequestClick = () => {
    setFeatureDialogOpen(true);
    handleMoreMenuClose();
  };

  const chatListener = useCallback((socketData) => {
    if (socketData.action === 'updateTotalUnreadCount') {
      setTotalUnreadCount(socketData.result);
    }
  });

  useEffect(() => {
    chatWebSocket.subscribe(chatListener);
    return () => {
      chatWebSocket.unsubscribe(chatListener);
    };
  }, [chatListener]);

  const handleLogout = () => {
    handleMoreMenuClose();
    logout().then(() => {
      loadBrandInfo();
      navigate('/login', { replace: true });
    });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const widgetlistener = useCallback(async(e) => {
    chatWebSocket.sendJsonMessage({
      action: 'getCounts',
      auth: {
        guid: chatWebSocket.getUserGuid(),
        widget: chatWebSocket.getWidget(),
        token: await chatWebSocket.getToken(),
        workspace: chatWebSocket.getWorkspace()
      }
    });
  }, []);

  useEffect(() => {
    widget.subscribe(widgetlistener);
    return () => {
      widget.unsubscribe(widgetlistener);
    };
  }, [widgetlistener]);

  const handleMoreMenuClose = () => {
    setMoreMenuEl(null);
  };

  const handleMoreMenuOpen = (e) => {
    setMoreMenuEl(e.currentTarget);
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        position: 'relative',
        overflowX: 'hidden'
      }}
    >
      <Box sx={{ p: 0 }}>
        <List>
          <NavItem
            href="/app"
            title={t('Dashboard')}
            icon={DashboardIcon}
            isEnd
            collapsed={collapsed}
          />
          {(user?.permissions?.inbox === true) && (
            <NavItem
              href="/app/inbox"
              disabled={user?.permissions?.wsData === false}
              title={t('Inbox')}
              icon={InboxIcon}
              badge={totalUnreadCount?.inboxCount || 0}
              isEnd={false}
              restricted={!getFeatureValue('inbox.enabled')}
              hide={user?.permissions?.upgrade === false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.chat === true) && (
            <NavItem
              href="/app/chat"
              disabled={user?.permissions?.wsData === false}
              title={t('Chat')}
              badge={totalUnreadCount?.count || 0}
              icon={ChatBubbleOutlineIcon}
              isEnd={false}
              restricted={!getFeatureValue('livechat.enabled')}
              hide={user?.permissions?.upgrade === false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.chatFlows === true && user?.role !== 'agent') && (
            <NavItem
              href="/chatbot/flows"
              title={t('Chat flows')}
              icon={ChatFlowIcon}
              isEnd={false}
              restricted={!getFeatureValue('chatflows.enabled')}
              hide={user?.permissions?.upgrade === false}
              collapsed={collapsed}
            />
          )}
          {/*(process.env.REACT_APP_ENV === 'dev' && user?.role !== 'agent') && (
            <NavItem
              href="/chatbot/assistants"
              title={t('AI assistants')}
              icon={Assistant}
              isEnd={false}
              restricted={!getFeatureValue('chatflows.enabled')}
              hide={user?.permissions?.upgrade === false}
              collapsed={collapsed}
            />
          )*/}
          {(user?.permissions?.visitors === true) && (
            <NavItem
              href="/app/visitors"
              disabled={user?.permissions?.wsData === false}
              title={t('Visitors')}
              restricted={false}
              icon={People}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.contacts === true) && (
            <NavItem
              href="/app/contacts"
              disabled={user?.permissions?.wsData === false}
              title={t('Contacts')}
              restricted={!getFeatureValue('contacts.enabled')}
              hide={user?.permissions?.upgrade === false}
              icon={Contacts}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.organizations === true) && (
            <NavItem
              href="/app/organizations"
              title={t('Organizations')}
              disabled={user?.permissions?.wsData === false}
              restricted={!getFeatureValue('organizations.enabled')}
              hide={user?.permissions?.upgrade === false}
              icon={Business}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.tickets === true) && (
            <NavItem
              href="/app/tickets"
              title={t('Tickets')}
              disabled={user?.permissions?.wsData === false}
              badge={totalUnreadCount?.ticketsCount || 0}
              restricted={!getFeatureValue('tickets.enabled')}
              hide={user?.permissions?.upgrade === false}
              icon={ConfirmationNumber}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.tasks === true) && (
            <NavItem
              href="/app/tasks"
              title={t('Tasks')}
              disabled={user?.permissions?.wsData === false}
              badge={totalUnreadCount?.tasksCount || 0}
              restricted={!getFeatureValue('tasks.enabled')}
              hide={user?.permissions?.upgrade === false}
              icon={TaskAlt}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.meetings === true) && (
            <NavItem
              href="/app/meetings"
              title={t('Meetings')}
              disabled={user?.permissions?.wsData === false}
              badge={totalUnreadCount?.meetingsCount || 0}
              restricted={!getFeatureValue('meetings.enabled')}
              hide={user?.permissions?.upgrade === false}
              icon={Duo}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          {(user?.permissions?.settingsKb === true) && (
            <NavItem
              href="/app/knowledge-base"
              title={t('Knowlege base')}
              disabled={user?.permissions?.wsData === false}
              restricted={!getFeatureValue('kb.enabled')}
              hide={user?.permissions?.settingsKb === false}
              icon={School}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
        </List>
      </Box>
      <Divider sx={{ mx: 2 }} />
      <Menu anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} anchorEl={moreMenuEl} open={!!moreMenuEl} onClose={handleMoreMenuClose}>
        {(user?.rootUser === true) && (
          <MenuItem component={NavLink} onClick={handleMoreMenuClose} to="/app/features">
            <ListItemIcon>
              <LightbulbIcon />
            </ListItemIcon>
            {t('Submit a feature')}
          </MenuItem>
        )}
        {(process.env.REACT_APP_ENV === 'dev' && user?.role !== 'agent') && (
          <MenuItem component={NavLink} onClick={handleMoreMenuClose} to="/app/bugs">
            <ListItemIcon>
              <BugReport />
            </ListItemIcon>
            {t('Report a bug')}
          </MenuItem>
        )}
        {(user?.permissions?.docs === true) && (
          <MenuItem component="a" onClick={handleMoreMenuClose} href="https://docs.anychat.one/" target="_blank">
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            {t('Documentation')}
          </MenuItem>
        )}
        {(user?.permissions?.affiliate === true && user?.role !== 'agent') && (
          <MenuItem component={NavLink} selected={!!matchPath({
                path: '/app/affiliate',
                end: false,
            }, location.pathname)} onClick={handleMoreMenuClose} to="/app/affiliate">
            <ListItemIcon>
              <MonetizationOn />
            </ListItemIcon>
            {t('Affiliate program')}
          </MenuItem>
        )}
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('Logout')}
        </MenuItem>
      </Menu>
      <Box sx={{ flexGrow: 1 }} />
      {(plan?.showUpgrade === true && user?.permissions?.upgrade === true && user?.role === 'owner') && (
        (collapsed) ? (
          <List sx={{
            p: 0.2,
            '& .MuiButtonBase-root': {
              bgcolor: 'primary',
              color: 'red'
            }
          }}>
            <NavItem
              href="/app/upgrade"
              title="Upgrade"
              icon={RocketLaunch}
              collapsed={collapsed}
            />
          </List>
        ) : (
          <Box
            sx={{
              backgroundColor: 'background.default',
              m: 0,
              p: 2
            }}
          >
            <Typography
              align="center"
              gutterBottom
              variant="h4"
            >
              {t('Need more?')}
            </Typography>
            <Typography
              align="center"
              variant="body2"
            >
              {t('Upgrade your plan')}
              <br />
              {t('to access more features')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2
              }}
            >
              <Button
                component={NavLink}
                color="primary"
                to="/app/upgrade"
                variant="contained"
                startIcon={<RocketLaunch />}
              >
                {t('Upgrade plan')}
              </Button>
            </Box>
          </Box>
        )
      )}
      <Box sx={{ p: 0, mt: 'auto' }}>
        <List sx={{ p: 0.2}}>
          {(user?.permissions?.wsConfigWrite === true) && (
            <NavItem
              href="/settings"
              title={t('Settings')}
              icon={SettingsIcon}
              isEnd={false}
              collapsed={collapsed}
            />
          )}
          
          <NavItem
            href={false}
            title={t('More')}
            icon={MoreHoriz}
            onClick={handleMoreMenuOpen}
            collapsed={collapsed}
          />
        </List>
      </Box>
      <IconButton onClick={onCollapse} sx={{
        width: 24,
        height: 24,
        borderRadius: '50%',
        position: 'fixed',
        left: collapsed ? 77 : 244,
        top: 67,
        bgcolor: 'background.paper',
        borderColor: 'divider',
        borderWidth: 1,
        borderStyle: 'solid',
        zIndex: 99,
        display: {
          xs: 'none',
          lg: 'inline-flex'
        },
        '&:hover': {
          bgcolor: 'background.paper',
        }
      }}>
        {collapsed ? <KeyboardArrowRight sx={{ fontSize: 18 }} /> : <KeyboardArrowLeft sx={{ fontSize: 18 }} />}
      </IconButton>
      <Box 
        component="a"
        href="?update" 
        sx={{
          width: '100%',
          px: 1,
          bgcolor: 'background.paper',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: 'divider',
          textAlign: 'center',
          cursor: 'pointer',
          '&:hover p': {
            color: 'primary.main'
          }
        }}>
        <Typography sx={{ fontSize: '10px',  }} color="text.secondary">
          v. {pjson.version}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <FeatureRequestDialog
        open={featureDialogOpen}
        onClose={() => setFeatureDialogOpen(false)}
        onSubmit={() => {
          setFeatureDialogOpen(false);
          enqueueSnackbar(t('Your request is sent'), {
              variant: 'success'
          });
        }}
        initConfig={initConfig}
      />
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: collapsed ? 90 : 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: collapsed ? 90 : 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          <PerfectScrollbar>
            {content}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  chatWebSocket: PropTypes.object
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
