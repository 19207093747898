import { Box, Button } from "@mui/material";
import $api from "src/components/http";

const LifeTimeDeal = () => {

    const handlePayClick = () => {
        $api.post('/tariff/new-session-ltd', {
            
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    window.location.assign(res.data.location);
                }
            })
            .finally(() => {

            })
    };

    return (
        <Box>
            <Button onClick={handlePayClick}>
                Pay $39
            </Button>
        </Box>
    );
};

export default LifeTimeDeal;