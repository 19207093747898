import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Checkbox, Chip, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Typography, Avatar, Badge, Tooltip, IconButton, AvatarGroup, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { Check } from "@mui/icons-material";
import { invertColor } from "../app/Tools";
import { grey } from "@mui/material/colors";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import $api from "../http";
import useDayjs from "../app/useDayjs";

const MeetingsTable = (props) => {
    const {
        items,
        selectedItems,
        handleSelectOne,
        handleSelectAll,
        handlePageChange,
        handleLimitChange,
        totalItems,
        page,
        limit,
        disablePagination,
        disableSelection,
        actions,
        compactView = false,
        allowInplaceEdit = false,
        onReload = () => {}
    } = props;
    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    const [statusMenuEl, setStatusMenuEl] = useState(null);
    const [priorityMenuEl, setPriorityMenuEl] = useState(null);
    const [currentItemGuid, setCurrentItemGuid] = useState(null);

    const statusOptions = [
        {
            id: 1,
            label: t('Not started'),
            color: grey['400']
        },
        {
            id: 2,
            label: t('Deferred'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('Re-scheduled'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Ongoing'),
            color: 'error.main'
        },
        {
            id: 5,
            label: t('Complete'),
            color: 'success.main'
        },
        {
            id: 6,
            label: t('Canceled'),
            color: 'secondary.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    const handleUpdatePriority = (priority) => {
        handlePriorityMenuClose();
        $api.post('/meeting/update-priority', {
            guid: currentItemGuid,
            priority: priority
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                }
            })

    };

    const handlePriorityMenuOpen = (e, currentGuid) => {
        if (!allowInplaceEdit) {
            return false;
        }
        setCurrentItemGuid(currentGuid);
        setPriorityMenuEl(e.currentTarget);
    };

    const handlePriorityMenuClose = () => {
        setCurrentItemGuid(null);
        setPriorityMenuEl(null);
    };

    const handleUpdateStatus = (status) => {
        handleStatusMenuClose();
        $api.post('/meeting/update-status', {
            guid: currentItemGuid,
            status: status
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                }
            })

    };

    const handleStatusMenuOpen = (e, currentGuid) => {
        if (!allowInplaceEdit) {
            return false;
        }
        setCurrentItemGuid(currentGuid);
        setStatusMenuEl(e.currentTarget);
    };

    const handleStatusMenuClose = () => {
        setCurrentItemGuid(null);
        setStatusMenuEl(null);
    };

    return (
        <Card>
            <Menu open={!!statusMenuEl} onClose={handleStatusMenuClose} anchorEl={statusMenuEl}>
                {(statusOptions.map((i) => (
                    <MenuItem key={i.id} onClick={() => handleUpdateStatus(i.id)}>
                        <Box sx={{ width: 8, height: 8, borderRadius: 2, bgcolor: i.color, mr: 2 }} />
                        <Typography>
                            {i.label}
                        </Typography>
                    </MenuItem>
                )))}
            </Menu>
            <Menu open={!!priorityMenuEl} onClose={handlePriorityMenuClose} anchorEl={priorityMenuEl}>
                {(priorityOptions.map((i) => (
                    <MenuItem key={i.id} onClick={() => handleUpdatePriority(i.id)}>
                        <Box sx={{ width: 8, height: 8, borderRadius: 2, bgcolor: i.color, mr: 2 }} />
                        <Typography>
                            {i.label}
                        </Typography>
                    </MenuItem>
                )))}
            </Menu>
            <PerfectScrollbar>
            <Box sx={{ minWidth: 900 }}>
                <Table>
                <TableHead sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 3 }}>
                    <TableRow>
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.length === items.length}
                                    color="primary"
                                    indeterminate={
                                        selectedItems.length > 0
                                        && selectedItems.length < items.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                        )}
                    <TableCell>
                        {t('Title')}
                    </TableCell>
                    {(compactView === false) && (
                        <TableCell>
                            {t('Date')}
                        </TableCell>
                    )}
                    {(compactView === false) && (
                        <TableCell>
                            {t('Location')}
                        </TableCell>
                    )}
                    <TableCell>
                        {t('Status')}
                    </TableCell>
                    <TableCell>
                        {t('Priority')}
                    </TableCell>
                    <TableCell>
                        {t('Participants')}
                    </TableCell>
                    <TableCell>
                        {t('Created at')}
                    </TableCell>
                    {(compactView === false) && (
                        <TableCell>
                            {t('Last update')}
                        </TableCell>
                    )}
                    
                    {(Array.isArray(actions)) && (
                        <TableCell />
                    )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                    <TableRow
                        hover
                        key={item.id}
                        selected={selectedItems.indexOf(item.id) !== -1}
                    >
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.indexOf(item.id) !== -1}
                                    onChange={(event) => handleSelectOne(event, item.id)}
                                    value="true"
                                />
                            </TableCell>
                        )}
                        <TableCell sx={{ maxWidth: compactView ? 200 : 300 }}>
                            <Box component={NavLink} to={`/app/meetings/${item.guid}`} color="text.primary">
                                <Typography sx={{ fontWeight: '500' }} variant="body2" noWrap>
                                    {item.title}
                                </Typography>
                                {(compactView === false) ? (
                                    <Typography variant="body2" noWrap color="text.secondary">
                                        {item.description}
                                    </Typography>
                                ) : (
                                    <>
                                        <Box>
                                            <Typography component="span" variant="body2">
                                                {dayjs.unix(item.from_int).format('YYYY MMM DD HH:mm')}
                                            </Typography>
                                            <Typography component="span" color="text.secondary" sx={{ mx: 1 }}>&#x2022;</Typography>
                                            <Typography component="span" variant="body2" color="text.secondary">
                                                {dayjs.duration(item.duration, 'seconds').format('HH:mm')}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="span" color="text.secondary" variant="body2">
                                                {item.channel_title}
                                            </Typography>
                                            <Typography component="span" color="text.secondary" sx={{ mx: 1 }}>&#x2022;</Typography>
                                            <Typography component="span" variant="body2" color="text.secondary">
                                                {item.channel_info}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                                
                                <Box>
                                    {(item.tags?.length > 0) && (
                                        (item.tags?.map((i) => (
                                            <Chip
                                                sx={{
                                                mr: 0.5,
                                                my: '2px',
                                                height: 18,
                                                bgcolor: i.color || '',
                                                color: i.color ? invertColor(i.color, true) : 'text.primary',
                                                '& .MuiSvgIcon-root': {
                                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                                    opacity: 0.4,
                                                    '&:hover': {
                                                        color: i.color ? invertColor(i.color, true) : '#000000',
                                                        opacity: 0.55,
                                                    }
                                                }
                                                }}
                                                label={i.label}
                                                size="small"
                                            />
                                        )))
                                    )}
                                </Box>
                            </Box>
                        </TableCell>
                        {(compactView === false) && (
                            <TableCell>
                                <Typography variant="body2">
                                    {dayjs.unix(item.from_int).format('YYYY MMM DD HH:mm')}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {t('Duration')}: {dayjs.duration(item.duration, 'seconds').format('HH:mm')}
                                </Typography>
                            </TableCell>
                        )}
                        {(compactView === false) && (
                            <TableCell>
                                <Typography variant="body2">
                                    {item.channel_title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {item.channel_info}
                                </Typography>
                            </TableCell>
                        )}
                        <TableCell sx={{ maxWidth: 200 }}>
                            {(item.status === 1 ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: grey['400'] }} />} size="small" label={t('Not started')} variant="outlined" />) : 
                            (item.status === 2) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'info.main' }} />} size="small" label={t('Deferred')} color="info" variant="outlined" />) : 
                            (item.status === 3) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'warning.main' }} />} size="small" label={t('Re-scheduled')} color="warning" variant="outlined" />) : 
                            (item.status === 4) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'error.main' }} />} size="small" label={t('Ongoing')} color="error" variant="outlined" />) : 
                            (item.status === 5) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'success.main' }} />} size="small" label={t('Complete')} color="success" variant="outlined" />) : 
                            (item.status === 6) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'secondary.main' }} />} size="small" label={t('Canceled')} color="secondary" variant="outlined" />) : 
                            (<></>))}
                        </TableCell>
                        <TableCell>
                            {(item.priority === 1 ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'success.main' }} />} size="small" label={t('Low')} color="success" variant="outlined" />) : 
                            (item.priority === 2) ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'warning.main' }} />} size="small" label={t('Middle')} color="warning" variant="outlined" />) : 
                            (item.priority === 3) ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'error.main' }} />} size="small" label={t('High')} color="error" variant="outlined" />) : 
                            (<></>))}
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            <AvatarGroup>
                                {(item.agents?.map((i) => (
                                    <Tooltip title={i.name}>
                                        <Badge
                                            color="success"
                                            invisible={item.assigned_to !== i.guid}
                                            count={1}
                                            badgeContent={(
                                                <Check />
                                            )}
                                            sx={{
                                                '& .MuiBadge-badge': {
                                                    bottom: 8,
                                                    right: 8,
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: 'bacground.paper',
                                                    width: 16,
                                                    height: 16,
                                                    minWidth: 16,
                                                    display: 'flex',
                                                    padding: 0,
                                                    '& svg': {
                                                        display: 'block',
                                                        width: 14,
                                                        height: 14,
                                                    }
                                                },
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <Avatar sx={{ width: 32, height: 32 }} src={i.image} />
                                        </Badge>
                                    </Tooltip>
                                )))}
                                {(item.contacts?.map((i) => (
                                    <Tooltip title={i.name}>
                                        <Avatar sx={{ width: 32, height: 32 }} src={i.img} />
                                    </Tooltip>
                                )))}
                            </AvatarGroup>
                        </TableCell>
                        
                        <TableCell sx={{ width: 120 }}>
                            {dayjs.unix(item.created_at).fromNow()}
                        </TableCell>
                        {(compactView === false) && (
                            <TableCell sx={{ width: 120 }}>
                                {dayjs.unix(item.updated_at).fromNow()}
                            </TableCell>
                        )}
                        {(Array.isArray(actions)) && (
                            <TableCell
                                align="right"
                                sx={{
                                    width: 120
                                }}
                            >
                                {actions.map((i) => (
                                    (i !== null) && (
                                        <Tooltip title={i.tooltip}>
                                            <IconButton onClick={(e) => i.onClick(e, item)}>
                                                {i.icon}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ))}
                            </TableCell>
                        )}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Box>
            {(disablePagination !== true) && (
                <TablePagination
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        bgcolor: 'background.paper',
                        zIndex: 3,
                        borderTopWidth: 1,
                        borderTopColor: 'divider',
                        borderTopStyle: 'solid'
                    }}
                    component="div"
                    count={totalItems}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                />
            )}
            </PerfectScrollbar>
        </Card>
    );
};

export default MeetingsTable;