import { useEffect, useState } from "react";
import $api from "../http";
import { Avatar, Box, Button, Checkbox, Dialog, DialogContent, Divider, Fab, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import NoContent from "../app/NoContent";
import { AttachFile, Cancel, Check, Cloud, CloudUpload, Delete, Download, PlaylistAdd, Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useWorkspace from "../app/useWorkspace";
import Dropzone from "react-dropzone";
import LoadingProgress from "../app/LoadingProgress";
import UserChip from "../app/UserChip";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import { humanFileSize } from "../app/Tools";
import useDayjs from "../app/useDayjs";

export const FileItem = (props) => {
    const { file, uploadUrl, itemGuid, onRemove, uploadAll, onUploadSuccess, onUploadFail } = props;
    const [progress, setProgress] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const workspace = useWorkspace();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const uploadFile = () => {
        setProgress(0);
        const formData = new FormData();
        const cf = file;
        formData.append(`file`, cf);
        formData.append('guid', itemGuid);
        formData.append('workspace', workspace.getWorkspace());
        setError(null);
        setSuccess(false);
        $api.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            }
        })
        .catch((e) => {
            setProgress(100);
            setError(e?.response?.data?.message || t('Could not upload the file!'));
            onUploadFail(e?.response?.data);
            enqueueSnackbar(e?.response?.data?.message || t('Could not upload the file!'), {
                variant: 'error'
            });
            return null;
        })
        .then((data) => {
            if (data) {
                setSuccess(true);
                onUploadSuccess(data);
            }
        });
    };

    useEffect(() => {
        // uploadFile();
    }, [file]);

    useEffect(() => {
        if (uploadAll === true) {
            uploadFile();
        }
    }, [uploadAll]);

    return (
        <Box sx={{ borderRadius: 1, bgcolor: 'background.default', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <LinearProgress width="100%" variant="determinate" value={progress} />
            <Box sx={{ display: 'flex', p: 1 }}>
                <Tooltip title={!!error ? error : success ? t('File uploaded') : null}>
                    <Avatar sx={{
                        mr: 1,
                        my: 'auto',
                        bgcolor: !!error ? 'error.main' : (success) ? 'success.main' : ''
                    }}>
                        {!!error ? <Warning /> : (success) ? <Check /> : <AttachFile />}
                    </Avatar>
                </Tooltip>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <Tooltip title={file.name}>
                        <Typography noWrap>
                            {file.name}
                        </Typography>
                    </Tooltip>
                    <Typography color="text.secondary" variant="body2">
                        {humanFileSize(file.size)}
                    </Typography>
                </Box>
                <Box sx={{ my: 'auto', ml: 1 }}>
                    <IconButton disabled={success} onClick={() => onRemove(file)}>
                        <Cancel />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

const CrmItemAttachments = (props) => {
    const { itemGuid, url, uploadUrl, removeUrl, disableSelection = false, onReload } = props;
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progress, setProgress] = useState(1);
    const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadAll, setUploadAll] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [removeSelectedDialogOpen, setRemoveSelectedDialogOpen] = useState(false);

    const { t } = useTranslation();
    const { dayjs } = useDayjs();
    const { enqueueSnackbar } = useSnackbar();
    const workspace = useWorkspace();

    const handleRemoveItem = (item) => {
        setCurrentItem(item);
        setConfirmDialogOpen(true);
    };

    const handleSelectFiles = (e) => {
        const files = [];
        for (let i in e.target.files) {
            if (!files.includes(e.target.files.item(i))) {
                files.push(e.target.files.item(i));
            }
        }
        setSelectedFiles((old) => [...old, ...files]);
    };

    const handleAcceptFiles = (files) => {
        setSelectedFiles((old) => [...old, ...files]);
    };

    const loadContent = () => {
        setIsLoaded(false);
        $api.get(`${url}?guid=${itemGuid}`)
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                }
            })
            .finally(() => {
                setIsLoaded(true);
            })
    };

    const handleFileRemove = (f) => {
        setSelectedFiles((old) => old.filter((i) => i !== f));
    };

    const handleUploadSuccess = () => {
        setUploadedFilesCount((i) => i + 1);
    };

    const handleUploadFail = () => {
        setUploadAll(false);
    };

    const handleUploadAll = () => {
        setUploadAll(true);
    };

    useEffect(() => {
        if (selectedFiles.length > 0 && uploadedFilesCount === selectedFiles.length) {
            // all files uploaded
            enqueueSnackbar(t('All files uploaded'), {
                variant: 'success'
            });
            setDialogOpen(false);
            setSelectedFiles([]);
            setUploadedFilesCount(0);
            setUploadAll(false);
            onReload();
            loadContent();
        }
    }, [uploadedFilesCount, selectedFiles]);

    useEffect(() => {
        loadContent();
    }, []);

    const handleRemoveSelected = () => {
        setRemoveSelectedDialogOpen(true);
    };

    const handleSelectOne = (e, i) => {
        if (e.target.checked) {
            setSelectedItems((old) => [...old, i]);
        } else {
            setSelectedItems((old) => old.filter((i) => i !== i));
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedItems(items.map((i) => i.guid));
        } else {
            setSelectedItems([]);
        }
    };

    return (
        <Box sx={{ display: 'flex', width: '100%', position: 'relative', height: '100%' }}>
            <LoadingProgress open={!isLoaded} />
            {items.length > 0 ? (
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {(disableSelection !== true) && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedItems.length === items.length}
                                            color="primary"
                                            indeterminate={
                                                selectedItems.length > 0
                                                && selectedItems.length < items.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>
                                    {t('Name')}
                                </TableCell>
                                <TableCell>
                                    {t('Size')}
                                </TableCell>
                                <TableCell>
                                    {t('Uploaded')}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <IconButton disabled={selectedItems.length === 0} onClick={() => handleRemoveSelected()}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(items.map((i) => (
                                <TableRow hover selected={selectedItems.indexOf(i.guid) !== -1} key={i.guid}>
                                    {(disableSelection !== true) && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedItems.indexOf(i.guid) !== -1}
                                                onChange={(event) => handleSelectOne(event, i.guid)}
                                                value="true"
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Box sx={{ display: 'flex' }}>
                                            <Avatar src={i.is_image ? i.url : ''} sx={{ mr: 1 }} variant="rounded">
                                                <AttachFile />
                                            </Avatar>
                                            <Typography sx={{ my: 'auto' }} variant="body2">
                                                {i.filename}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {i.filesize}
                                    </TableCell>
                                    <TableCell>
                                        <UserChip
                                            size="small"
                                            image={i.createdBy.img}
                                            name={i.createdBy.name}
                                            variant="outlined"
                                            color="success"
                                        />
                                        <Typography color="text.secondary" variant="body2">
                                            {dayjs.unix(i.created_at).fromNow()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'right', width: 120 }}>
                                        <IconButton component="a" href={i.link} target="_blank" sx={{ mr: 1 }}>
                                            <Download />
                                        </IconButton>
                                        <IconButton onClick={() => handleRemoveItem(i)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )))}
                        </TableBody>
                    </Table>
                    <Divider />
                </TableContainer>
            ) : (
                (isLoaded) && (
                    <Box sx={{ width: '100%', my: 'auto' }}>
                        <NoContent
                            containerSx={{
                                my: 'auto'
                            }}
                            icon={<AttachFile sx={{ width: 40, height: 40, color: 'text.secondary' }} />}
                            title={t('No attachments')}
                            content={t('No files attached yet')}
                            buttonTitle={t('Add files')}
                            buttonProps={{
                                variant: 'contained',
                                color: 'primary',
                                disableElevation: true,
                                startIcon: <PlaylistAdd />,
                                onClick: () => {
                                    setDialogOpen(true);
                                }
                            }}
                        />
                    </Box>
                )
            )}
            {(items.length > 0) && (
                <Fab sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                }} color="primary" aria-label="Submit a feature" onClick={() => {
                    setDialogOpen(true)
                }}>
                    <PlaylistAdd />
                </Fab>
            )}
            <ConfirmDialog
                open={confirmDialogOpen}
                title={t('Remove attachment?')}
                content={t('Do you really want to remove this attachment?')}
                actionUrl={removeUrl}
                onSubmit={() => {
                    loadContent();
                    onReload();
                    setSelectedItems((old) => old.filter((i) => i !== currentItem?.guid));
                }}
                onClose={() => setConfirmDialogOpen(false)}
                fieldId={currentItem?.guid}
            />
            <ConfirmDialog
                open={removeSelectedDialogOpen}
                title={t('Remove selected attachments?')}
                content={t('Do you really want to remove selected ({{count}}) attachments?', {
                    count: selectedItems.length
                })}
                actionUrl={removeUrl}
                onSubmit={() => {
                    loadContent();
                    onReload();
                    setSelectedItems([]);
                }}
                onClose={() => setRemoveSelectedDialogOpen(false)}
                fieldId={selectedItems}
            />
            <Dialog maxWidth="md" open={dialogOpen} fullWidth>
                <DialogContent>
                    <Dropzone onDrag onDrop={handleAcceptFiles}>
                        {({getRootProps, getInputProps, isFocused, isDragAccept, isDragReject}) => (
                            <Box sx={{
                                borderRadius: 1,
                                borderColor: isDragAccept ? 'success.main' : isDragReject ? 'error.main' : isFocused ? 'info.main' : 'divider',
                                borderWidth: 2,
                                borderStyle: 'dashed',
                                height: 240,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer'
                            }} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Box>
                                    {isDragAccept ? (
                                        <CloudUpload sx={{ height: 64, width: 64, color: 'text.secondary' }} />
                                    ) : (
                                        <Cloud sx={{ height: 64, width: 64, color: 'text.secondary' }} />
                                    )}
                                </Box>
                                <Typography>{t("Drop some files here, or click to select files")}</Typography>
                            </Box>
                        )}
                    </Dropzone>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            {selectedFiles.map((i) => (
                                <Grid item xs={4}>
                                    <FileItem
                                        onRemove={handleFileRemove}
                                        itemGuid={itemGuid}
                                        file={i}
                                        uploadUrl={uploadUrl}
                                        uploadAll={uploadAll}
                                        onUploadSuccess={handleUploadSuccess}
                                        onUploadFail={handleUploadFail}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box sx={{ textAlign: 'right', mt: 1 }}>
                        <Button sx={{ mr: 1 }} onClick={() => {
                            setDialogOpen(false);
                            setSelectedFiles([]);
                            setUploadedFilesCount(0);
                            setUploadAll(false);
                        }}>
                            {t('Cancel')}
                        </Button>
                        <Button variant="contained" disableElevation color="primary" disabled={selectedFiles.length === 0} onClick={handleUploadAll}>
                            {t('Upload')}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default CrmItemAttachments;