import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Grid, IconButton, ListItemAvatar, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import { useAppConext } from "../context/AppContext";
import TagsPopup from "../TagsPopup";
import { Add, Business } from "@mui/icons-material";
import { invertColor } from "../app/Tools";
import ImageUploader from "../app/ImageUploader";

const ContactDialog = (props) => {
    const { open, onClose, contact, currentItem, onSubmit } = props;
    const [config, setConfig] = useState(currentItem);
    const [orgs, setOrgs] = useState([]);
    const [errors, setErrors] = useState({});
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);
    const [assignedAgent, setAssignedAgent] = useState({});
    const [tags, setTags] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const { user } = useAppConext();

    const { t } = useTranslation();

    const handleSubmit = () => {
        setIsSubmit(true);
        setErrors({});
        const data = {
            ...config,
            assigned_to: assignedAgent?.guid || null,
            organizations: orgs.map((i) => i.guid),
            tags: tags.map((i) => i.id)
        };
        $api.post(config.guid ? '/contact/update' : '/contact/create', data)
            .catch((err) => {
                if (err?.response?.status === 422) {
                    setErrors(err.response.data);
                }
            })
            .then((res) => {
                if (res) {
                    onSubmit(res.data);
                }
            })
            .finally(() => {
                setIsSubmit(false);
            })
    };

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    const handleAgentChange = (v) => {
        setConfig((old) => ({
            ...old,
            assigned_to: v?.guid
        }))
    }

    const handleFormFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (open) {
            if (currentItem.guid) {
                setAssignedAgent(currentItem.assignedTo);
                setOrgs(currentItem.organizations);
            } else {
                setOrgs([]);
                setAssignedAgent(user);
            }
            setConfig(currentItem);
            setTags(currentItem.tags || []);
        } else {
            setErrors({});
        }
    }, [open, currentItem, user]);

    const handleImageChange = (name, image) => {
        setConfig({
            ...config,
            data: {
                img: image
            }
        });
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>
                {t('New contact')}
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={3}>
                            <ImageUploader
                                name="image"
                                value={config.image}
                                onChange={handleImageChange}
                                error={!!(errors.image)}
                                helperText={errors.image || null}
                                imageHelperText={t('Ratio 1:1')}
                                aspectRatio={1}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <FormGroup>
                                <TextField
                                    name="name"
                                    label={t('Name')}
                                    required
                                    value={config.name || ''}
                                    error={!!errors?.name}
                                    helperText={errors?.name}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="email"
                                    label={t('Email address')}
                                    value={config.email || ''}
                                    error={!!errors?.email}
                                    helperText={errors?.email}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="phone"
                                    label={t('Phone number')}
                                    value={config.phone || ''}
                                    error={!!errors?.phone}
                                    helperText={errors?.phone}
                                    onChange={handleFormFieldChange}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/organization"
                            idAttr="guid"
                            multiple
                            value={orgs}
                            initialValue={orgs}
                            onChange={(val) => {
                                setOrgs(val);
                            }}
                            renderOption={(p, option) => (
                                <MenuItem {...p} key={option.guid}>
                                    <ListItemAvatar>
                                        <Avatar src={option.image}>
                                            <Business />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={option.title}
                                        secondary={option.email || t('Email not set')}
                                    />
                                </MenuItem>
                            )}
                            renderTags={(values) => (
                                (values.map((v) => (
                                    <Chip
                                        key={v.guid}
                                        sx={{ mr: 0.5 }}
                                        avatar={(
                                            <Avatar src={v.image}>
                                                <Business />
                                            </Avatar>
                                        )}
                                        label={v.title}
                                        onDelete={() => {
                                            setOrgs((old) => old.filter((i) => i.guid !== v.guid))
                                        }}
                                    />
                                )))
                            )}
                            textFieldProps={{
                                label: t('Organizations'),
                                placeholder: t('Search by name, email or phone number')
                            }}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/live-chat-agent"
                            idAttr="guid"
                            getOptionLabel={(option) => option.name || ''}
                            initialValue={user}
                            value={assignedAgent}
                            onChange={(v) => {
                                setAssignedAgent(v);
                            }}
                            textFieldProps={{
                                label: t('Assigned agent'),
                                placeholder: t('Search by name or email')
                            }}
                            renderOption={(p, option) => (
                                <MenuItem {...p} key={option.guid}>
                                    <ListItemAvatar>
                                        <Avatar src={option.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={option.name || t('Agent')}
                                        secondary={option.email || t('Email not set')}
                                    />
                                </MenuItem>
                            )}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            {(tags.length > 0 ) ? (
                                <Box sx={{ my: 'auto' }}>
                                    {(tags.map((i) => (
                                        <Chip
                                            sx={{
                                            mr: 0.5,
                                            my: '2px',
                                            bgcolor: i.color || '',
                                            color: i.color ? invertColor(i.color, true) : 'text.primary',
                                            '& .MuiSvgIcon-root': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.4,
                                                '&:hover': {
                                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                                    opacity: 0.55,
                                                }
                                            }
                                            }}
                                            onDelete={() => handleRemoveTag(i.id)}
                                            label={i.label}
                                            size="small"
                                        />
                                    )))}
                                </Box>
                            ) : (
                                <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                    {t('No tags')}
                                </Typography>
                            )}
                            <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                                <Add />
                            </IconButton>
                        </Box>
                    </FormGroup>
                    <TagsPopup
                        anchorEl={tagsAnchorEl}
                        onClose={() => setTagsAnchorEl(null)}
                        onSelect={handleAddTag}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <LoadingButton loading={isSubmit} onClick={handleSubmit} variant="contained" disableElevation>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ContactDialog;