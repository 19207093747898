import CrmItemAttachments from "src/components/crmItem/CrmItemAttachments";

const TicketAttachments = (props) => {
    const { itemGuid, onReload } = props;

    return (
        <CrmItemAttachments
            itemGuid={itemGuid}
            onReload={onReload}
            uploadUrl="/ticket/upload"
            url="/ticket/attachments"
            removeUrl="/ticket/remove-attachment"
        />
    );
};

export default TicketAttachments;