import { ConfirmationNumber, PlaylistAdd, RocketLaunch, TaskAlt } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import RestrictedFieldWrapper from "src/components/app/RestrictedFieldWrapper";
import { useAppConext } from "src/components/context/AppContext";
import TicketDialog from "src/components/tickets/TicketDialog";
import TicketsTable from "src/components/tickets/TicketsTable";
import TicketsToolbar from "src/components/tickets/TicketsToolbar";

const CrmList = lazy(() => import('../../../src/components/crmList'));

const TicketsPage = () => {
    const { t } = useTranslation();
    const { getFeatureValue, user } = useAppConext();

    return (
        <>
            <PageMeta title={t('Tickets')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <RestrictedFieldWrapper
                    fullWidth
                    containerSx={{ display: 'flex' }}
                    blur
                    badgeType={false}
                    restricted={!getFeatureValue('tickets.enabled')}
                    customContent={(
                        <Box>
                            <ConfirmationNumber sx={{ width: 40, height: 40, color: 'error.main' }} />
                            <Typography color="error" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {t('Tickets section is locked')}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {t('Ulock this section to manage tickets')}
                            </Typography>
                            <Button sx={{ mt: 1 }} variant="outlined" startIcon={<RocketLaunch />} component={NavLink} to="/app/upgrade">
                                {t('Upgrade now')} 
                            </Button>
                        </Box>
                    )}
                >
                    <Suspense fallback={<LoadingProgress open />}>
                        <CrmList
                            expand="contacts,tags"
                            indexUrl="/ticket"
                            deleteUrl="/ticket/delete"
                            DialogComponent={TicketDialog}
                            TableComponent={TicketsTable}
                            ToolbarComponent={TicketsToolbar}
                            accessGranted={user?.permissions?.tickets}
                            noContentOptions={{
                                icon: (<TaskAlt sx={{ width: 40, height: 40, color: 'text.secondary' }} />),
                                title: t('No tickets yet'),
                                content: t('There are no tickets in this workspace'),
                                buttonTitle: t('Create a ticket'),
                                buttonIcon: (<PlaylistAdd />)
                            }}
                        />
                    </Suspense>
                </RestrictedFieldWrapper>
            </Box>
        </>
    );
};

export default TicketsPage;