import {
    Box,
  } from '@mui/material';
  import { lazy, Suspense } from 'react';
  import { useTranslation } from 'react-i18next';
  import LoadingProgress from 'src/components/app/LoadingProgress';
  import PageMeta from 'src/components/app/PageMeta';
  const StartNewThread = lazy(() => import('../../components/chat/StartNewThread'));
  
  const StartNewChat = () => {
    const { t } = useTranslation();
    return (
      <>
        <PageMeta title={t('Start new chat')} />
        <Box
          sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              height: '100%',
              display: 'flex',
              p: {
                  xs: '57px 0 0 0',
                  ms: '57px 0 0 0',
                  md: 0,
                  lg: 0
              },
              position: 'relative'
          }}
        >
          <Suspense fallback={<LoadingProgress open />}>
            <StartNewThread baseThreadURL="/app/chat" />
          </Suspense>
        </Box>
      </>
    );
  };
  
  export default StartNewChat;
  