import { Lock } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import LoadingProgress from "src/components/app/LoadingProgress";
import NoContent from "src/components/app/NoContent";
import PageMeta from "src/components/app/PageMeta";
import { useAppConext } from "src/components/context/AppContext";
const KbViewer = lazy(() => import('src/components/knowledgeBase/KbViewer'));

const KnowledgeBaseViewPage = () => {
    const { t } = useTranslation();
    const { user } = useAppConext();

    if (user?.permissions?.settingsKb === false) {
        return (
            <Box sx={{ p: 2, height: '100%', display: 'flex' }}>
                <NoContent
                    containerSx={{
                        my: 'auto'
                    }}
                    icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                    title={t('Forbidden')}
                    content={t("You're don't have access to this page")}
                />
            </Box>
        );
    }

    return (
        <>
            <PageMeta title={t('Knowledge base')} />
            <Box sx={{ p: 2, height: 'calc(100vh - 66px)' }}>
                <Box sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
                    <Suspense fallback={<LoadingProgress open />}>
                        <KbViewer open />
                    </Suspense>
                </Box>
            </Box>
        </>
    );
};

export default KnowledgeBaseViewPage;