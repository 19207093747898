import { Avatar, Badge, Box, Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Fade, FormGroup, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingProgress from "./app/LoadingProgress";
import $api from "./http";
import { Add, Cancel, Check, Close, Delete, Edit, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowUp, Logout, Search, Workspaces } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppConext } from "./context/AppContext";
import useWorkspace from "./app/useWorkspace";
import ImageUploader from "./app/ImageUploader";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import ConfirmDialog from "./confirmDialog/ConfirmDialog";

let searchTimeout = null;

const WorkspaceSwitcherDialog = (props) => {
    const { open, onClose, chatWebSocket } = props;
    const [myItems, setMyItems] = useState([]);
    const [agencyItems, setAgencyItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedItems, setExpandedItems] = useState([]);
    const { t } = useTranslation();
    const { getFeatureValue, loadUser, user } = useAppConext();
    const { getWorkspace, setWorkspace } = useWorkspace();
    const [switching, setSwitching] = useState(false);
    const [switchingError, setSwitchingError] = useState(false);
    const [manageMode, setManageMode] = useState(false);
    const [config, setConfig] = useState({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); 
    const { enqueueSnackbar } = useSnackbar();
    const [clients, setClients] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [acceptInProgress, setAcceptInProgress] = useState(false);
    const [rejectInProgress, setRejectInProgress] = useState(false);

    const loadContent = (s = '', search = false) => {
        if (search) {
            setSearchLoading(true);
        } else {
            setIsLoading(true);
        }

        $api.get(`/workspace?s=${s}`)
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || t('Error loading workspaces'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    setMyItems(res.data.my);
                    setAgencyItems(res.data.agency);
                }
            })
            .finally(() => {
                setIsLoading(false);
                setSearchLoading(false);
            });
    };

    const handleSwitchWorkspace = (guid) => {
        setSwitching(true);
        setSwitchingError(false);
        $api.get(`/workspace/check-access?guid=${guid}`)
            .catch((err) => {
                setSwitchingError(true);
            })
            .then((res) => {
                if (res?.data?.success === 1) {
                    setWorkspace(guid);
                    loadUser().then(() => {
                        onClose();
                        chatWebSocket.sendJson({
                            action: 'switchWorkspace',
                            auth: chatWebSocket.getAuth()
                        });
                    });
                }
            })
            .finally(() => {
                setSwitching(false);
            });
    };

    const toggleItem = (guid) => {
        setExpandedItems((old) => {
            if (old.includes(guid)) {
                return old.filter((i) => i !== guid);
            }
            return [...old, guid];
        });
    };

    useEffect(() => {
        if (open === true) {
            setManageMode(false);
            loadContent();
        }
    }, [open]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setConfig({
            ...config,
            [name]: value
        });
    };

    const handleImageChange = (name, image) => {
        setConfig({
            ...config,
            [name]: image
        });
    };

    const handleFormSubmit = () => {
        setFormSubmitting(true);
        setErrors({});
        $api.post('/workspace/save', config)
            .catch((err) => {
                if (err?.response?.status === 422) {
                    setErrors(err.response.data);
                }
                enqueueSnackbar(err?.response?.data?.error || t('Error saving workspace'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    setManageMode(false);
                    loadContent();
                    setConfig({
                        user_id: user?.id
                    });
                    enqueueSnackbar(t('Workspace saved'), {
                        variant: 'success'
                    });
                }
            })
            .finally(() => {
                setFormSubmitting(false);
            })
    };

    const loadClients = () => {
        $api.get('/user/get-clients-by-user')
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setClients(res.data);
                }
            })
            .finally(() => {

            })
    };

    useEffect(() => {
        if (manageMode === false) {
            setConfig({
                user_id: user?.id
            });
            setErrors({});
        } else {
            loadClients();
        }
    }, [manageMode]);

    const handleEditItem = (e, item) => {
        e.stopPropagation();
        setConfig(item);
        setManageMode(true);
    };

    const handleDeleteItem = (e, item) => {
        e.stopPropagation();
        setConfig(item);
        setDeleteDialogOpen(true);
    };

    const clearSearchTerm = () => {
        setSearchTerm('');
        loadContent('', true);
    };

    const handleSearchTermChange = (e) => {
        const { value } = e.target;
        setSearchTerm(e.target.value);
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          loadContent(value, true);
        }, 500);
    };

    const handleRejectInvite = (e, item) => {
        e.stopPropagation();
        setRejectInProgress(true);
        $api.post('/workspace/reject-invite', {
            guid: item.guid
        })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || t('Error processing request'), {
                    variant: 'error'
                })
            })
            .then((res) => {
                if (res?.data?.success === 1) {
                    enqueueSnackbar(t('Invite rejected'), {
                        variant: 'success'
                    });
                    loadContent();
                }
            })
            .finally(() => {
                setRejectInProgress(false);
            });
    };

    const handleAcceptInvite = (e, item) => {
        e.stopPropagation();
        setAcceptInProgress(true);
        $api.post('/workspace/accept-invite', {
            guid: item.guid
        })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || t('Error processing request'), {
                    variant: 'error'
                })
            })
            .then((res) => {
                if (res?.data?.success === 1) {
                    enqueueSnackbar(t('Invite accepted'), {
                        variant: 'success'
                    });
                    loadContent();
                }
            })
            .finally(() => {
                setAcceptInProgress(false);
            });
    };

    const leaveWorkspace = (e, item) => {
        e.stopPropagation();
        $api.post('/workspace/leave', {
            guid: item.guid
        })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || t('Error processing request'), {
                    variant: 'error'
                })
            })
            .then((res) => {
                if (res?.data?.success === 1) {
                    enqueueSnackbar(t("You're left workspace"), {
                        variant: 'success'
                    });
                    loadContent();
                }
            })
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle sx={{ position: 'relative', display: 'flex' }}>
                <Tooltip title={t('Back to the list')}>
                    <IconButton onClick={() => setManageMode(false)} size="small" sx={{ mr: 1, display: manageMode ? 'flex' : 'none' }}>
                        <KeyboardArrowLeft />
                    </IconButton>
                </Tooltip>
                <Typography sx={{ my: 'auto', fontSize: '1.1rem', fontWeight: '500' }}>
                    {manageMode ? (config.guid ? t('Edit workspace') : t('Add workspace')) : t('Workspaces')}
                </Typography>
                {(manageMode === false) && (
                    <Box sx={{ ml: 'auto', mr: 2, flex: 1 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder={t('Search')}
                            onChange={handleSearchTermChange}
                            value={searchTerm}
                            sx={{
                                minWidth: 320,
                                pl: 2,
                                '& .MuiInputBase-root': {
                                    pr: 0.4
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {searchLoading ? <CircularProgress size={24} /> : <Search sx={{ color: 'text.secondary' }} />}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton sx={{
                                            display: searchTerm === '' ? 'none' : 'inline-flex'
                                        }} onClick={clearSearchTerm} size="small">
                                            <Cancel sx={{ color: 'text.secondary', opacity: 0.8 }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                )}
                
                <Box sx={{ ml: 'auto', my: 'auto' }}>
                    {(getFeatureValue('workspaces.limit') > 0 || getFeatureValue('workspaces.limit') === 'inf') && (
                        <Tooltip title={t('Add workspace')}>
                            <IconButton onClick={() => setManageMode(true)} disabled={manageMode || ((getFeatureValue('workspaces.limit') !== 'inf') && getFeatureValue('workspaces.limit') <= myItems.length)} size="small" sx={{ ml: 1 }}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton size="small" onClick={onClose} sx={{ ml: 1 }}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ minHeight: 120, position: 'relative' }}>
                <LoadingProgress open={isLoading} />
                <Box sx={{ overflow: 'auto', pt: 2, display: manageMode? 'block' : 'none' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <ImageUploader
                                name="image"
                                value={config.image}
                                onChange={handleImageChange}
                                error={!!(errors.image)}
                                helperText={errors.image || null}
                                imageHelperText="Ratio 1:1"
                                aspectRatio={1}
                            />
                        </Grid>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12} sm={7}>
                            {(getFeatureValue('agency.enabled')) && (
                                <FormGroup sx={{ mb: 2 }}>
                                    <TextField
                                        required
                                        disabled={config.guid !== undefined && config.guid !== null}
                                        label={t('Target account')}
                                        name="user_id"
                                        id="user_id"
                                        value={config.user_id || ''}
                                        error={!!(errors.user_id)}
                                        helperText={errors?.user_id}
                                        onChange={handleFormChange}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                display: 'flex',
                                                '& .MuiListItemAvatar-root': {
                                                    my: 'auto'
                                                },
                                                '& .MuiListItemText-root': {
                                                    my: 0,
                                                    py: 0,
                                                    width: 210
                                                }
                                            }
                                        }}
                                        select
                                    >
                                        <MenuItem value={user.id} label={user.name}>
                                            <ListItemAvatar>
                                                <Avatar src={user.image} alt={user.name} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={t('My account')}
                                                secondary={user.email}
                                            />
                                        </MenuItem>
                                        {(clients.map((i) => (
                                            <MenuItem key={i.id} value={i.id}>
                                                <ListItemAvatar>
                                                    <Avatar src={i.image} alt={i.name} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        noWrap: true
                                                    }}
                                                    secondaryTypographyProps={{
                                                        noWrap: true
                                                    }}
                                                    primary={i.name}
                                                    secondary={i.email}
                                                />
                                            </MenuItem>
                                        )))}
                                    </TextField>
                                </FormGroup>
                            )}
                            <FormGroup>
                                <TextField
                                    required
                                    label={t('Title')}
                                    name="title"
                                    id="title"
                                    value={config.title || ''}
                                    error={!!(errors.title)}
                                    helperText={errors?.title}
                                    onChange={handleFormChange}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 2, mb: 2 }}>
                                <TextField
                                    label={t('Description')}
                                    name="description"
                                    id="description"
                                    value={config.description || ''}
                                    error={!!(errors.description)}
                                    helperText={errors?.description}
                                    multiline
                                    onChange={handleFormChange}
                                />
                            </FormGroup>
                            <Box sx={{ display: 'flex', mt: 'auto' }}>
                                <Button sx={{ ml: 'auto' }} onClick={() => setManageMode(false)}>
                                    {t('Cancel')}
                                </Button>
                                <LoadingButton onClick={handleFormSubmit} loading={formSubmitting} sx={{ ml: 0.5 }} variant="contained" disableElevation>
                                    {t('Save')}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mt: 2, display: manageMode? 'none' : 'block' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ my: 'auto' }}>
                            {t('My workspaces')}
                        </Typography>
                    </Box>
                    <List dense>
                        {(myItems.length === 0) ? (
                            <ListItem>
                                <Typography color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                    {t('No items')}
                                </Typography>
                            </ListItem>
                        ) : (
                            (myItems.map((i) => (
                                <ListItemButton color="warning.main" selected={i.guid === getWorkspace()} sx={{ borderRadius: 1, my: '2px' }} key={i.guid} onClick={() => handleSwitchWorkspace(i.guid)}>
                                    <ListItemAvatar>
                                        <Avatar sx={{
                                            borderWidth: 2,
                                            borderStyle: 'solid',
                                            borderColor: i.guid === getWorkspace() ? 'primary.main' : 'transparent'
                                        }} src={i.image}>
                                            <Workspaces />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={i.title}
                                        secondary={i.role}
                                        secondaryTypographyProps={{
                                            variant: 'body2',
                                            noWrap: true
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        {(i.status !== 1) && (
                                            <>
                                                <Tooltip title={t('Reject invite')}>
                                                    <IconButton disabled={rejectInProgress || i.status === 3} onClick={(e) => handleRejectInvite(e, i)} size="small">
                                                        {rejectInProgress ? <CircularProgress size={24} /> : <Close color={i.status === 2 ? 'error' : ''} />}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t('Accept invite')}>
                                                    <IconButton disabled={acceptInProgress} sx={{ ml: 0.5 }} onClick={(e) => handleAcceptInvite(e, i)} size="small">
                                                        {acceptInProgress ? <CircularProgress size={24} /> : <Check color="success" />}
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}
                                        {(i.role !== 'owner') && (
                                            <Tooltip title={t('Leave workspace')}>
                                                <IconButton sx={{ ml: 0.5 }} onClick={(e) => leaveWorkspace(e, i)} size="small">
                                                    <Logout />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Tooltip title={t('Edit')}>
                                            <IconButton sx={{ ml: 0.5 }} disabled={i.role !== 'owner'} onClick={(e) => handleEditItem(e, i)} size="small">
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                        {(getFeatureValue('workspaces.limit') > 0 || getFeatureValue('workspaces.limit') === 'inf') && (
                                            <Tooltip title={i.guid === getWorkspace() ? t("Active workspace can't be deleted") : t('Delete')}>
                                                <Box sx={{ display: 'inline-block' }}>
                                                    <IconButton disabled={i.guid === getWorkspace() || i.role !== 'owner'} sx={{ ml: 0.5 }} onClick={(e) => handleDeleteItem(e, i)} size="small">
                                                        <Delete />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            )))
                        )}
                    </List>
                    {(getFeatureValue('agency.enabled')) && (
                        <>
                            <Typography sx={{ mt: 2 }}>
                                {t('Agency workspaces')}
                            </Typography>
                            <List dense>
                                {(agencyItems.length === 0) ? (
                                    <ListItem>
                                        <Typography color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                            {t('No items')}
                                        </Typography>
                                    </ListItem>
                                ) : (
                                    (agencyItems.map((i) => (
                                        (i.workspaces.length === 1) ? (
                                            <ListItemButton selected={i.workspaces[0].guid === getWorkspace()} onClick={() => handleSwitchWorkspace(i.workspaces[0].guid)} key={i.guid} sx={{ borderRadius: 1, my: '2px' }}>
                                                <ListItemAvatar>
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        badgeContent={
                                                            <Avatar sx={{
                                                                width: 22,
                                                                height: 22,
                                                                borderWidth: 2, 
                                                                borderStyle: 'solid', 
                                                                borderColor: 'background.paper'
                                                            }} src={i.workspaces[0].image}>
                                                                <Workspaces sx={{
                                                                    width: 14,
                                                                    height: 14,
                                                                    m: 'auto'
                                                                }} />
                                                            </Avatar>
                                                        }
                                                    >
                                                        <Avatar sx={{
                                                            borderWidth: 2,
                                                            borderStyle: 'solid',
                                                            borderColor: i.workspaces[0].guid === getWorkspace() ? 'primary.main' : 'transparent'
                                                        }} src={i.image} />
                                                    </Badge>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={i.name}
                                                    secondary={i.email}
                                                    secondaryTypographyProps={{
                                                        variant: 'body2',
                                                        noWrap: true
                                                    }}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title={t('Edit')}>
                                                        <IconButton onClick={(e) => handleEditItem(e, i.workspaces[0])} size="small">
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItemButton>
                                        ) : (
                                            <ListItem sx={{
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                p: 0,
                                                bgcolor: expandedItems.includes(i.guid) ? 'background.default' : '',
                                                borderRadius: 1
                                            }}>
                                                <ListItemButton onClick={() => toggleItem(i.guid)} sx={{ display: 'flex', width: '100%', borderRadius: 1 }}>
                                                    <ListItemAvatar>
                                                        <Avatar src={i.image} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={i.name}
                                                        secondary={i.email}
                                                    />
                                                    <Box sx={{ my: 'auto', ml: 'auto' }}>
                                                        {expandedItems.includes(i.guid) ? 
                                                        <KeyboardArrowUp sx={{ display: 'block', color: 'text.secondary' }} />
                                                        : <KeyboardArrowDown sx={{ display: 'block', color: 'text.secondary' }} />}
                                                    </Box>
                                                </ListItemButton>
                                                <Collapse in={!!expandedItems.includes(i.guid)} sx={{ width: '100%' }}>
                                                    <List dense sx={{ width: '100%' }}>
                                                        {(i.workspaces.map((ii) => (
                                                            <ListItemButton selected={ii.guid === getWorkspace()} onClick={() => handleSwitchWorkspace(ii.guid)} sx={{ borderRadius: 0, my: '2px' }} key={ii.guid}>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{
                                                                        width: 36,
                                                                        height: 36,
                                                                        ml: '3px',
                                                                        borderWidth: 2,
                                                                        borderStyle: 'solid',
                                                                        borderColor: ii.guid === getWorkspace() ? 'primary.main' : 'transparent'
                                                                    }} src={ii.image}>
                                                                        <Workspaces />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={ii.title}
                                                                    secondaryTypographyProps={{
                                                                        variant: 'body2',
                                                                        noWrap: true
                                                                    }}
                                                                    secondary={ii.description}
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <Tooltip title={t('Edit')}>
                                                                        <IconButton onClick={(e) => handleEditItem(e, ii)} size="small">
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={ii.guid === getWorkspace() ? t("Active workspace can't be deleted") : t('Delete')}>
                                                                        <Box sx={{ display: 'inline-block' }}>
                                                                            <IconButton disabled={ii.guid === getWorkspace()} sx={{ ml: 0.5 }} onClick={(e) => handleDeleteItem(e, ii)} size="small">
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Tooltip>
                                                                </ListItemSecondaryAction>
                                                            </ListItemButton>
                                                        )))}
                                                    </List>
                                                </Collapse>
                                            </ListItem>
                                        )
                                    )))
                                )}
                            </List>
                        </>
                    )}
                </Box>
                <ConfirmDialog
                    open={deleteDialogOpen}
                    title={t('Delete a workspace')}
                    content={t('Do you want to delete workspace? All associated data will be deleted! This action can be undone!')}
                    submitButtonLabel={t('Delete')}
                    actionUrl="/workspace/delete"
                    fieldId={config.guid}
                    onError={() => {
                        enqueueSnackbar(t('Error deleting workspace'), {
                            variant: 'error'
                        });
                    }}
                    onSubmit={(data) => {
                        enqueueSnackbar(t('Workspace deleted'), {
                            variant: 'warning'
                        });
                        loadContent();
                    }}
                    onClose={() => setDeleteDialogOpen(false)}
                />
            </DialogContent>
        </Dialog>
    );
};

export default WorkspaceSwitcherDialog;