let listeners = [];

const useWidget = () => {
  const subscribe = (listn) => {
    listeners.push(listn);
  };

  const unsubscribe = (listn) => {
    listeners = listeners.filter((entity) => entity !== listn);
  };

  const cleanUpListeners = () => {
    listeners = [];
  };

  const getWidget = () => {
    const data = localStorage.getItem('widgetUID');
    return data || null;
  };

  const notify = (uid) => {
    listeners.forEach((listener) => listener(uid));
  };

  const setWidget = (uid) => {
    let changed = false;
    if (uid !== getWidget()) {
      changed = true;
    }
    if (uid) {
      localStorage.setItem('widgetUID', uid);
    } else {
      localStorage.removeItem('widgetUID');
    }
    if (changed) {
      notify(uid);
    }
  };

  return {
    getWidget,
    setWidget,
    subscribe,
    unsubscribe,
    cleanUpListeners,
    notify
  };
};

export default useWidget;
