import { Add, Close, CloseOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Chip, Divider, FormGroup, IconButton, InputAdornment, ListItemAvatar, ListItemText, MenuItem, Popover, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import { invertColor } from "../app/Tools";
import TagsPopup from "../TagsPopup";

const MeetingFilter = (props) => {
    const { anchorEl, onClose, onSubmit } = props;
    const [config, setConfig] = useState({});
    const [assignedAgent, setAssignedAgent] = useState(null);
    const [tags, setTags] = useState([]);
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);

    const { t } = useTranslation();

    const statusOptions = [
        {
            id: 1,
            label: t('Not started'),
            color: grey['400']
        },
        {
            id: 2,
            label: t('Deferred'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('Re-scheduled'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Ongoing'),
            color: 'error.main'
        },
        {
            id: 5,
            label: t('Complete'),
            color: 'success.main'
        },
        {
            id: 6,
            label: t('Canceled'),
            color: 'secondary.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    const handleReset = () => {
        setConfig({});
        setAssignedAgent(null);
        setTags([]);
    };

    const handleSubmit = () => {
        if (tags.length > 0) {
            onSubmit({
                ...config,
                tag_id: tags.map((i) => i.id)
            });
        } else {
            onSubmit(config);
        }
        onClose();
    };

    const handleFieldChange = (e) => {
        const { value, name, checked, type } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    return (
        <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
            <Box sx={{ p: 1, position: 'relative' }}>
                <Typography color="text.primary">
                    {t('Filter')}
                </Typography>
                <IconButton sx={{ position: 'absolute', right: 8, top: 3 }} onClick={onClose} size="small">
                    <Close />
                </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 1, width: 320 }}>
                <FormGroup sx={{ mb: 2 }}>
                    <AsyncAutocomplete
                        url="/live-chat-agent"
                        idAttr="guid"
                        getOptionLabel={(option) => option.name || ''}
                        initialValue={assignedAgent}
                        value={assignedAgent}
                        onChange={(v) => {
                            setAssignedAgent(v);
                            setConfig((old) => {
                                if (v === null) {
                                    const { assigned_to, ...rest } = old;
                                    return rest;
                                }
                                return {
                                    ...old,
                                    assigned_to: v?.guid || null
                                };
                            })
                        }}
                        textFieldProps={{
                            label: t('Responsible agent'),
                            placeholder: t('Search by name or email')
                        }}
                        renderOption={(p, option) => (
                            <MenuItem {...p} key={option.guid}>
                                <ListItemAvatar>
                                    <Avatar src={option.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={option.name || t('Agent')}
                                    secondary={option.email || t('Email not set')}
                                />
                            </MenuItem>
                        )}
                    />
                </FormGroup>
                <FormGroup sx={{ mb: 2 }}>
                    <TextField
                        name="status"
                        value={config.status || ''}
                        onChange={handleFieldChange}
                        label={t('Status')}
                        select
                        InputProps={{
                            endAdornment: (
                                (config?.status !== '' && config?.status !== undefined) && (
                                    <InputAdornment sx={{ mr: 2 }} position="end">
                                        <IconButton
                                            aria-label="copy"
                                            edge="end"
                                            size="small"
                                            onClick={() => setConfig((old) => {
                                                const { status, ...rest } = old;
                                                return rest;
                                            })}
                                        >
                                        <CloseOutlined fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            )
                        }}
                    >
                        {(statusOptions.map((i) => (
                            <MenuItem key={i.id} value={i.id}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: 8, height: 8, bgcolor: i.color || '#DDDDDD', borderRadius: 5, mr: 1, my: 'auto' }} />
                                    {i.label}
                                </Box>
                            </MenuItem>
                        )))}
                    </TextField>
                </FormGroup>
                <FormGroup sx={{ mb: 2 }}>
                    <TextField
                        name="priority"
                        value={config.priority || ''}
                        onChange={handleFieldChange}
                        label={t('Priority')}
                        select
                        InputProps={{
                            endAdornment: (
                                (config?.priority !== '' && config?.priority !== undefined) && (
                                    <InputAdornment sx={{ mr: 2 }} position="end">
                                        <IconButton
                                            aria-label="copy"
                                            edge="end"
                                            size="small"
                                            onClick={() => setConfig((old) => {
                                                const { priority, ...rest } = old;
                                                return rest;
                                            })}
                                        >
                                        <CloseOutlined fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            )
                        }}
                    >
                        {(priorityOptions.map((i) => (
                            <MenuItem key={i.id} value={i.id}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: 8, height: 8, bgcolor: i.color || '#DDDDDD', borderRadius: 5, mr: 1, my: 'auto' }} />
                                    {i.label}
                                </Box>
                            </MenuItem>
                        )))}
                    </TextField>
                </FormGroup>
                <FormGroup>
                    <Box sx={{ display: 'flex' }}>
                        {(tags.length > 0 ) ? (
                            <Box sx={{ my: 'auto' }}>
                                {(tags.map((i) => (
                                    <Chip
                                        sx={{
                                        mr: 0.5,
                                        my: '2px',
                                        bgcolor: i.color || '',
                                        color: i.color ? invertColor(i.color, true) : 'text.primary',
                                        '& .MuiSvgIcon-root': {
                                            color: i.color ? invertColor(i.color, true) : '#000000',
                                            opacity: 0.4,
                                            '&:hover': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.55,
                                            }
                                        }
                                        }}
                                        onDelete={() => handleRemoveTag(i.id)}
                                        label={i.label}
                                        size="small"
                                    />
                                )))}
                            </Box>
                        ) : (
                            <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                {t('No tags')}
                            </Typography>
                        )}
                        <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                            <Add />
                        </IconButton>
                    </Box>
                    <TagsPopup
                        anchorEl={tagsAnchorEl}
                        onClose={() => setTagsAnchorEl(null)}
                        onSelect={handleAddTag}
                        allowAdd={false}
                    />
                </FormGroup>
            </Box>
            <Divider />
            <Box sx={{ p: 1, textAlign: 'right', display: 'flex' }}>
                <Button sx={{ mr: 'auto' }} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button sx={{ ml: 'auto', mr: 0.5 }} onClick={handleReset}>
                    {t('Reset')}
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained" disableElevation>
                    {t('Apply')}
                </Button>
            </Box>
        </Popover>
    );
};

export default MeetingFilter;