import { Delete, Edit, KeyboardArrowDown, PersonOff } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, CircularProgress, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AgentMenu from "../contact/AgentMenu";
import $api from "../http";
import { useSnackbar } from "notistack";
import OrganizationDialog from "./OrganizationDialog";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import { useNavigate } from "react-router";

const OrganizationTabs = (props) => {
    const { tab, onChange, item, onReload } = props;
    const [currentTab, setCurrentTab] = useState(tab);
    const [reassigning, setReassigning] = useState(false);
    const [agentMenuEl, setAgentMenuEl] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    
    const handleTabChange = (newTab) => {
        setCurrentTab(newTab);
        onChange(newTab);
    };

    const handleAgentMenuOpen = (e) => {
        setAgentMenuEl(e.currentTarget)
    };

    const handleAgentMenuClose = () => {
        setAgentMenuEl(null);
    };

    const handleReassing = (guid) => {
        handleAgentMenuClose();
        setReassigning(true);
        $api.post('/organization/reassing', {
            guid: item.guid,
            agent: guid
        })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || t('Error reassinging organization'), {
                    variant: 'error'
                });
                setReassigning(false);
            })
            .then((res) => {
                if (res) {
                    if (guid === null) {
                        enqueueSnackbar(t('Organization un-assigned'), {
                            variant: 'warning'
                        });
                    } else {
                        enqueueSnackbar(t('Organization assigned'), {
                            variant: 'success'
                        });
                    }
                    onReload();
                }
            })
            .finally(() => {
                setReassigning(false);
            })
    };

    const handleEditDialogOpen = () => {
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const handleEditDialogSubmit = () => {
        handleEditDialogClose();
        onReload();
    };

    return (
        <Box sx={{ py: 0, display: 'flex', flexDirection: 'row', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'divider' }}>
            <Box>
                <Tabs onChange={(e, newVal) => handleTabChange(newVal)} value={currentTab}>
                    <Tab
                        value="notes"
                        label={(
                            <Badge
                                sx={{
                                '& .MuiBadge-badge': {
                                    fontSize: '0.65rem',
                                    padding: '0 4px',
                                    height: 15,
                                    right: '-5px',
                                    minWidth: 15
                                }
                                }}
                                badgeContent={item.notesCount}
                                color="secondary">
                                <span>
                                    {t('Notes')}
                                </span>
                            </Badge>
                        )}
                    />
                    <Tab
                        value="attachments"
                        label={(
                            <Badge
                                sx={{
                                '& .MuiBadge-badge': {
                                    fontSize: '0.65rem',
                                    padding: '0 4px',
                                    height: 15,
                                    right: '-5px',
                                    minWidth: 15
                                }
                                }}
                                badgeContent={item.attachmentsCount}
                                color="secondary">
                                <span>
                                    {t('Attachments')}
                                </span>
                            </Badge>
                        )}
                    />
                    <Tab
                        value="contacts"
                        label={(
                            <Badge
                                sx={{
                                '& .MuiBadge-badge': {
                                    fontSize: '0.65rem',
                                    padding: '0 4px',
                                    height: 15,
                                    right: '-5px',
                                    minWidth: 15
                                }
                                }}
                                badgeContent={item.contactsCount}
                                color="secondary">
                                <span>
                                    {t('Contacts')}
                                </span>
                            </Badge>
                        )}
                    />
                    <Tab
                        value="timeline"
                        label={(
                            <Badge
                                sx={{
                                '& .MuiBadge-badge': {
                                    fontSize: '0.65rem',
                                    padding: '0 4px',
                                    height: 15,
                                    right: '-5px',
                                    minWidth: 15
                                }
                                }}
                                color="secondary">
                                <span>
                                    {t('Timeline')}
                                </span>
                            </Badge>
                        )}
                    />
                </Tabs>
            </Box>
            <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', pr: 1 }}>
                {(item.assignedTo !== null) ? (
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={handleAgentMenuOpen}
                        sx={{
                            py: 0,
                            borderRadius: 12,
                            px: '14px'
                        }}
                        startIcon={(
                            reassigning ? <CircularProgress color="background" size={24} /> : <Avatar src={item.assignedTo?.img} sx={{ width: 24, height: 24 }} />
                        )}
                        endIcon={(
                            <KeyboardArrowDown />
                        )}
                    >
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography sx={{ textTransform: 'none', fontSize: '0.8rem', textAlign: 'left', m: 0 }}>
                                {item.assignedTo?.name}
                            </Typography>
                            <Typography sx={{ fontSize: '0.65rem', textTransform: 'none', textAlign: 'left', m: 0 }}>
                                {item.assignedTo?.email}
                            </Typography>
                        </Box>
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={handleAgentMenuOpen}
                        sx={{
                            py: 0,
                            borderRadius: 12,
                            px: '14px'
                        }}
                        startIcon={(
                            reassigning ? <CircularProgress color="background" size={24} /> : <Avatar sx={{ width: 24, height: 24 }}>
                                <PersonOff />
                            </Avatar>
                        )}
                        endIcon={(
                            <KeyboardArrowDown />
                        )}
                    >
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography sx={{ textTransform: 'none', fontSize: '0.8rem', textAlign: 'left', m: 0 }}>
                                {t('Not assigned')}
                            </Typography>
                            <Typography sx={{ fontSize: '0.65rem', textTransform: 'none', textAlign: 'left', m: 0 }}>
                                {t('Click to assign to agent')}
                            </Typography>
                        </Box>
                    </Button>
                )}
                <AgentMenu
                    open={!!agentMenuEl}
                    onClose={handleAgentMenuClose}
                    anchorEl={agentMenuEl}
                    currentAgentGuid={item.assignedTo?.guid}
                    onSelect={handleReassing}
                />
                <Tooltip title={t('Edit organization')}>
                    <IconButton onClick={handleEditDialogOpen} sx={{ ml: 3 }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('Delete organization')}>
                    <IconButton onClick={() => setDeleteDialogOpen(true)} sx={{ ml: 1 }}>
                        <Delete color="error" />
                    </IconButton>
                </Tooltip>
            </Box>
            <OrganizationDialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                onSubmit={handleEditDialogSubmit}
                currentItem={item}
            />
            <ConfirmDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title={t('Remove item?')}
                content={(
                <>
                    <Typography>
                        {t('Do you really want to delete item?')}
                    </Typography>
                    <Typography color="error">
                        {t("This action can't be undone!")}
                    </Typography>
                </>
                )}
                fieldId={item.id}
                onSubmit={(data) => {
                    enqueueSnackbar(t('{{deleted}} item(s) deleted', data), {
                        variant: 'warning'
                    });
                    navigate('/app/organizations')
                }}
                actionUrl="/organization/delete"
            />
        </Box>
    );
};

export default OrganizationTabs;