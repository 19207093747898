import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import CrmItemTimeline from "src/components/crmItem/CrmItemTimeline";

const MeetingTimeline = (props) => {
    const { itemGuid } = props;
    const { t } = useTranslation();

    return (
        <CrmItemTimeline
            itemGuid={itemGuid}
            url="/meeting/get-timeline"
            attributeLabels={{
                subject: t('Title'),
                description: t('Description'),
                priority: t('Priority'),
                status: t('Status'),
                assigned_to: t('Assignee'),
                website: t('Website'),
                phone: t('Phone'),
                channel_title: t('Location'),
                channel_info: t('Location details'),
                duration: t('Duration'),
                from_int: t('Date'),
            }}
            statusOptions={[
                {
                    id: 1,
                    label: t('Not started'),
                    color: grey['400']
                },
                {
                    id: 2,
                    label: t('Deferred'),
                    color: 'info.main'
                },
                {
                    id: 3,
                    label: t('Re-scheduled'),
                    color: 'warning.main'
                },
                {
                    id: 4,
                    label: t('Ongoing'),
                    color: 'error.main'
                },
                {
                    id: 5,
                    label: t('Complete'),
                    color: 'success.main'
                },
                {
                    id: 6,
                    label: t('Canceled'),
                    color: 'secondary.main'
                }
            ]}
            priorityOptions={[
                {
                    id: 1,
                    label: t('Low'),
                    color: 'success.main'
                },
                {
                    id: 2,
                    label: t('Middle'),
                    color: 'warning.main'
                },
                {
                    id: 3,
                    label: t('High'),
                    color: 'error.main'
                },
            ]}
        />
    );
};

export default MeetingTimeline;