import { lazy, Suspense } from 'react';
import { Box } from '@mui/material';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';

const List = lazy(() => import('../../components/settings/assistants'));

const Assistants = () => {
    const { t } = useTranslation();

    return (
        <>
            <PageMeta title={t('Assitants')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <List showFab showBreadcrumbs configUrl="/menu-items" />
                </Suspense>
            </Box>
        </>
    );
};

export default Assistants;