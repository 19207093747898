import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}v1/`;

const $api = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
});

$api.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    const widgetUID = localStorage.getItem('widgetUID');
    const workspace = localStorage.getItem('workspaceGUID');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (widgetUID) {
        if (config.method === 'get' || config.method === 'delete') {
            let finalURL = config.url;
            if (config.url.indexOf('?') !== -1) {
                finalURL = `${config.url}&widget_uid=${widgetUID}`;
            } else if (config.url.indexOf('?') === -1) {
                finalURL = `${config.url}?widget_uid=${widgetUID}`;
            }
            config.url = finalURL;
        } else if(config.method === 'post' || config.method === 'put') {
            config.data.widget_uid = widgetUID;
        }
    }
    if (workspace) {
        if (config.method === 'get' || config.method === 'delete') {
            let finalURL = config.url;
            if (config.url.indexOf('?') !== -1) {
                finalURL = `${config.url}&workspace=${workspace}`;
            } else if (config.url.indexOf('?') === -1) {
                finalURL = `${config.url}?workspace=${workspace}`;
            }
            config.url = finalURL;
        } else if(config.method === 'post' || config.method === 'put') {
            config.data.workspace = workspace;
        }
    }
    return config;
});

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && error.config && !error.config._isRetry) {
        // refresh the token
        originalRequest._isRetry = true;
        try{
            const res = await axios.get(`${API_URL}auth/refresh`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            localStorage.setItem('accessToken', res.data.accessToken);
            return $api.request(originalRequest);
        } catch (e) {
            console.log('Auth error', e);
            const back = encodeURIComponent(window.location.pathname + window.location.search);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('role');
            localStorage.removeItem('tariff');
            window.location.assign(`/login?back=${back}`);
        }
    }
    return Promise.reject(error);
});

export default $api;