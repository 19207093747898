import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Checkbox, Chip, Table, TableBody, TableCell, TableHead, TableRow, Divider, TablePagination, Typography, Avatar, Badge, Tooltip, IconButton, AvatarGroup } from "@mui/material";
import { Box } from "@mui/system";
import { Business } from "@mui/icons-material";
import { invertColor } from "../app/Tools";
import UserChip from "../app/UserChip";
import { NavLink } from "react-router-dom";
import useDayjs from "../app/useDayjs";

const OrganizationsTable = (props) => {
    const {
        items,
        selectedItems,
        handleSelectOne,
        handleSelectAll,
        handlePageChange,
        handleLimitChange,
        totalItems,
        page,
        limit,
        disablePagination,
        disableSelection,
        actions
    } = props;

    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    return (
        <Card>
            <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                <TableHead sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 3 }}>
                    <TableRow>
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.length === items.length}
                                    color="primary"
                                    indeterminate={
                                        selectedItems.length > 0
                                        && selectedItems.length < items.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                        )}
                    <TableCell>
                        {t('Title')}
                    </TableCell>
                    <TableCell>
                        {t('Website')}
                    </TableCell>
                    <TableCell>
                        {t('Phone number')}
                    </TableCell>
                    <TableCell>
                        {t('Assinged to')}
                    </TableCell>
                    <TableCell>
                        {t('Contacts')}
                    </TableCell>
                    <TableCell>
                        {t('Created at')}
                    </TableCell>
                    <TableCell>
                        {t('Last update')}
                    </TableCell>
                    {(Array.isArray(actions)) && (
                        <TableCell />
                    )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                    <TableRow
                        hover
                        key={item.id}
                        selected={selectedItems.indexOf(item.id) !== -1}
                    >
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.indexOf(item.id) !== -1}
                                    onChange={(event) => handleSelectOne(event, item.id)}
                                    value="true"
                                />
                            </TableCell>
                        )}
                        <TableCell sx={{ maxWidth: 300 }}>
                            <Box component={NavLink} to={`/app/organizations/${item.guid}`} sx={{ display: 'flex' }}>
                                <Avatar sx={{ mr: 1, my: 'auto' }} src={item.image}>
                                    <Business />
                                </Avatar>
                                <Box sx={{ my: 'auto' }}>
                                    <Typography color="text.primary" sx={{ my: 'auto' }} noWrap>
                                        {item.title}
                                    </Typography>
                                    
                                    {(item.tags?.length > 0) && (
                                        <Box>
                                            {(item.tags?.map((i) => (
                                                <Chip
                                                    sx={{
                                                    mr: 0.5,
                                                    my: '2px',
                                                    height: 18,
                                                    bgcolor: i.color || '',
                                                    color: i.color ? invertColor(i.color, true) : 'text.primary',
                                                    '& .MuiSvgIcon-root': {
                                                        color: i.color ? invertColor(i.color, true) : '#000000',
                                                        opacity: 0.4,
                                                        '&:hover': {
                                                            color: i.color ? invertColor(i.color, true) : '#000000',
                                                            opacity: 0.55,
                                                        }
                                                    }
                                                    }}
                                                    label={i.label}
                                                    size="small"
                                                />
                                            )))}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            {(item.website !== '' && item.website !== null) ? (
                                <a href={item.website} target="_blank">
                                    {item.website}
                                </a>
                            ) : (
                                <Typography sx={{ fontStyle: 'italic' }} variant="body2" color="text.secondary">
                                    {t('Not set')}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>
                            {(item.phone !== '' && item.phone !== null) ? (
                                <Typography sx={{ fontStyle: 'italic' }} variant="body2" color="text.secondary">
                                    {item.phone}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontStyle: 'italic' }} variant="body2" color="text.secondary">
                                    {t('Not set')}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>
                            {(item.assignedTo !== null) ? (
                                <UserChip
                                    name={item.assignedTo.name}
                                    image={item.assignedTo.img}
                                    color="success"
                                    variant="outlined"
                                />
                            ) : (
                                <UserChip
                                    name={t('Not assigned')}
                                    image='no-img'
                                    variant="outlined"
                                />
                            )}
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            <AvatarGroup sx={{
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32
                                }
                            }}>
                                {(item.contacts?.map((i) => (
                                    <Tooltip title={i.name}>
                                        <Avatar src={i.img} />
                                    </Tooltip>
                                )))}
                            </AvatarGroup>
                        </TableCell>
                        
                        <TableCell sx={{ width: 120 }}>
                            {dayjs.unix(item.created_at).fromNow()}
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            {dayjs.unix(item.updated_at).fromNow()}
                        </TableCell>
                        {(Array.isArray(actions)) && (
                            <TableCell
                                align="right"
                                sx={{
                                    width: 120
                                }}
                            >
                                {actions.map((i) => (
                                    (i !== null) && (
                                        <Tooltip title={i.tooltip}>
                                            <IconButton onClick={(e) => i.onClick(e, item)}>
                                                {i.icon}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ))}
                            </TableCell>
                        )}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Box>
            {(disablePagination !== true) && (
                <>
                    <Divider />
                    <TablePagination
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            bgcolor: 'background.paper',
                            zIndex: 3
                        }}
                        component="div"
                        count={totalItems}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                    />
                </>
            )}
            </PerfectScrollbar>
        </Card>
    );
};

export default OrganizationsTable;