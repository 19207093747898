import { Check } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const PaymentSuccess = () => {
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Paper sx={{ p: 2, maxWidth: 300, margin: 'auto', textAlign: 'center' }} elevation={0}>
                <Box sx={{ mb: 1 }}>
                    <Check color="success" />
                </Box>
                <Typography>
                    Payment success
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <Button component={NavLink} to="/app/account" sx={{ mr: 0.5 }}>
                        Back to account
                    </Button>
                    <Button component={NavLink} to="/app">
                        Dashboard
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default PaymentSuccess;