import PropTypes from 'prop-types';
import useGoogleLogin from 'src/components/login/googleLogin/useGoogleLogin';
// import useGoogleLogin from 'react-google-login';
import useFacebookLogin from 'src/components/app/useFacebookLogin';
import {
  Button,
  Grid,
} from '@mui/material';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import { indigo, pink } from '@mui/material/colors';
import $api from '../http';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAppConext } from '../context/AppContext';
import pjson from "../../../package.json";
import TelegramLoginButton from 'react-telegram-login';

const SocialLogin = ({
  onSuccess,
  onFailure
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getDeviceID } = useAppConext();

  const login = (url, token) => {
    if (!token) {
      return false;
    }
    $api.post(url, {
      token,
      refCode: localStorage.getItem('anychat-ref'),
      timezone: moment.tz.guess(),
      device: {
        type: 'browser',
        userAgent: window?.navigator?.userAgent,
        language: window?.navigator?.language,
        platform: window?.navigator?.platform,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        appVersion: pjson.version
      },
      deviceID: getDeviceID()
    })
      .catch((err) => {
        onFailure(err);
      })
      .then((response) => {
        onSuccess(response);
        return null;
      });
    return true;
  };

  const onGoogleLoginSuccess = (res) => {
    login('/auth/google-login', res.getAuthResponse().id_token);
  };

  const onFacebookLoginSuccess = (res) => login('/auth/facebook-login', res.accessToken);

  const onGoogleLoginFail = (res) => {
    if (res?.error === 'popup_closed_by_user') {

    } else if (res?.error === 'idpiframe_initialization_failed') {
      console.log(`GoogleLogin: ${res.error}`);
    } else {
      enqueueSnackbar(t('Login failed. Please try again later'), {
        variant: 'error'
      });
    }
  };

  const onFacebookLoginFail = (res) => {
    console.log(res);
    enqueueSnackbar(t('Login failed. Please try again later.'), {
      variant: 'error'
    });
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onFailure: onGoogleLoginFail,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT,
    isSignedIn: true,
    accessType: 'offline'
  });

  const scopes = [
    //'business_management',
    //'pages_show_list',
    //'pages_messaging',
    'public_profile',
    'email',
    //'pages_manage_metadata'
];

  const { facebookSignIn } = useFacebookLogin({
    appId: process.env.REACT_APP_FB_PUBLIC_APP_ID,
    scope: scopes.join(','),
    onSuccess: onFacebookLoginSuccess,
    onFailure: onFacebookLoginFail
  });

  const handleTelegramResponse = (res) => {
    $api.post('/auth/telegram-login', {
      ...res,
      device: {
        type: 'browser',
        userAgent: window?.navigator?.userAgent,
        language: window?.navigator?.language,
        platform: window?.navigator?.platform,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        appVersion: pjson.version
      },
      deviceID: getDeviceID()
    })
      .catch((err) => {
        onFailure(err);
      })
      .then((res) => {
        if (res) {
          onSuccess(res);
        }
      })
  };

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <Button
          color="primary"
          fullWidth
          startIcon={<FacebookIcon />}
          onClick={facebookSignIn}
          size="large"
          variant="contained"
          sx={{
            bgcolor: 'background.paper',
            color: indigo[600],
            '&:hover': {
              bgcolor: 'background.paper',
            }
          }}
        >
          {t('Login with Facebook')}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <Button
          fullWidth
          type="button"
          startIcon={<GoogleIcon />}
          onClick={signIn}
          size="large"
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: pink[600],
            '&:hover': {
              bgcolor: 'background.paper',
            }
          }}
        >
          {t('Login with Google')}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TelegramLoginButton dataOnauth={handleTelegramResponse} botName={process.env.REACT_APP_AUTH_TELEGRAM_BOT} />
      </Grid>
    </Grid>
  );
};

SocialLogin.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func
};

export default SocialLogin;
