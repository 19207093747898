import { Box, Container } from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';

const PromptList = lazy(() => import('../../components/settings/prompt/PromptList'));

const PromptMessages = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Prompt messages')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: 2
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 0 }}>
            <Suspense fallback={<LoadingProgress open />}>
              <PromptList configUrl="/prompts?filter[type]=1" />
            </Suspense>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PromptMessages;
