import { Cancel, Delete, Edit, FilterList, Refresh, Search } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, InputAdornment, Paper, SvgIcon, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import $api from "../http";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import { PlusSquare } from "react-feather";
import ContactsFilter from "./ContactsFilter";
import ContactsBulkEditDialog from "./ContactsBulkEditDialog";
import ExportDialog from "../app/ExportDialog";

let searchTimeout = null;

const ContactsToolbar = (props) => {
    const { totalCount, onReload, isLoading, selectedCount, onFilter, selectedItems, onClearSelectedItems, onCreate } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [editDialogAnchorEl, setEditDialogAnchorEl] = useState(null);
    const [filter, setFilter] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [exportInProgress, setExportInProgress] = useState(false);
    const [exportStart, setExportStart] = useState(false);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleSearchTermChange = (e) => {
        const val = e.target.value;
        setSearchTerm(val);
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          onReload(val, true, filter);
        }, 500);
    };

    const handleExportDialogClose = () => {
      setExportDialogOpen(false);
    };

    const handleExportToCSV = () => {
      setExportStart(true);
      setExportDialogOpen(true);
    };

    const handleFilterOpen = (e) => {
      setFilterAnchorEl(e.target);
    };

    const handleFilterClose = () => {
      setFilterAnchorEl(null);
    };

    const handleFilterSubmit = (data) => {
      console.log(data);
      setFilter(data);
      onFilter(searchTerm, true, data);
    };
    
    const clearSearchTerm = () => {
        setSearchTerm('');
        onReload('', true, filter);
    };

    const handleEditDialogSubmit = (params) => {
      $api.post('/contact/bulk-update', {
        id: selectedItems,
        ...params
      })
        .catch((err) => {

        })
        .then((res) => {
          enqueueSnackbar(t('Selected items updated'), {
            variant: 'success'
          });
          setEditDialogAnchorEl();
          onReload(searchTerm, true, filter);
        })
        .finally(() => {

        })
    };

    const handleEditDialogOpen = (e) => {
      setEditDialogAnchorEl(e.target);
    };

    const handleEditDialogClose = () => {
      setEditDialogAnchorEl(null);
    };

    const handleDeleteSelected = () => {
      setDeleteDialogOpen(true);
    };

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <ExportDialog
                  open={exportDialogOpen}
                  onClose={handleExportDialogClose}
                  url="/contact/export"
                  start={exportStart}
                  selected={selectedItems}
                  onStart={() => setExportInProgress(true)}
                  onSuccess={() => {
                    setExportDialogOpen(true);
                    setExportInProgress(false);
                  }}
                />
                <ContactsFilter
                  anchorEl={filterAnchorEl}
                  onClose={handleFilterClose}
                  onSubmit={handleFilterSubmit}
                />
                <ContactsBulkEditDialog
                  anchorEl={editDialogAnchorEl}
                  onClose={handleEditDialogClose}
                  onSubmit={handleEditDialogSubmit}
                />
                <ConfirmDialog
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  title={t('Remove selected items?')}
                  content={(
                    <>
                      <Typography>
                        {t('Do you really want to delete {{count}} selected item(s)?', {
                          count: selectedItems.length
                        })}
                      </Typography>
                      <Typography color="error">
                        {t("This action can't be undone!")}
                      </Typography>
                    </>
                  )}
                  fieldId={selectedItems}
                  onSubmit={(data) => {
                    onReload(searchTerm, true, filter);
                    onClearSelectedItems();
                    enqueueSnackbar(t('{{deleted}} item(s) deleted', data), {
                      variant: 'warning'
                    });
                  }}
                  actionUrl="/contact/delete"
                />
                <Paper sx={{ pr: 1, pl: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }} elevation={0}>
                  <Box sx={{ display: 'flex' }}>
                    {(isLoading) ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Typography>
                            {selectedCount > 0 ? `${selectedCount}/` : ''}{totalCount} {t('contacts')}
                        </Typography>
                    )}
                  </Box>
                    <Box
                      sx={{
                        padding: '5px 0',
                        ml: 'auto',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 'auto', mr: 2, flex: 1 }}>
                          <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              placeholder={t('Search')}
                              onChange={handleSearchTermChange}
                              value={searchTerm}
                              sx={{
                                  minWidth: 320,
                                  '& .MuiInputBase-root': {
                                      pr: 0.4
                                  }
                              }}
                              InputProps={{
                                  startAdornment: (
                                  <InputAdornment position="start">
                                      {searchLoading ? <CircularProgress size={24} /> : <Search sx={{ color: 'text.secondary' }} />}
                                  </InputAdornment>
                                  ),
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <IconButton sx={{
                                              display: searchTerm === '' ? 'none' : 'inline-flex'
                                          }} onClick={clearSearchTerm} size="small">
                                              <Cancel sx={{ color: 'text.secondary', opacity: 0.8 }} />
                                          </IconButton>
                                      </InputAdornment>
                                  )
                              }}
                          />
                      </Box>
                      <Tooltip title={t('Edit selected')}>
                        <IconButton
                          onClick={handleEditDialogOpen}
                          disabled={selectedCount === 0}
                          sx={{ mr: 1 }}
                          aria-label="refresh"
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Delete selected')}>
                        <IconButton
                          disabled={selectedCount === 0}
                          sx={{ mr: 1 }}
                          onClick={handleDeleteSelected}
                          aria-label="refresh"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Add contact')}>
                          <IconButton
                            onClick={onCreate}
                            sx={{ mr: 1 }}
                            aria-label="filter"
                          >
                            <PlusSquare />
                          </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Filter')}>
                          <IconButton
                            onClick={handleFilterOpen}
                            sx={{ mr: 1 }}
                            aria-label="filter"
                          >
                            <FilterList color={Object.keys(filter).length > 0 ? 'info' : ''} />
                          </IconButton>
                      </Tooltip>
                      <Tooltip title={exportInProgress ? t('Export in progress...') : t('Export to CSV')}>
                        <Box sx={{ display: 'inline-flex' }}>
                          <IconButton
                            sx={{
                              width: 40,
                              height: 40,
                              mr: 1
                            }}
                            onClick={handleExportToCSV}
                            disabled={totalCount === 0 || exportInProgress}
                          >
                            {exportInProgress ? <CircularProgress size={20} /> : <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 512 512">
                              <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM200 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40zm133.1 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H333.1c-7.2 0-13.1 5.9-13.1 13.1c0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2c0 24.9-20.2 45.1-45.1 45.1H304c-8.8 0-16-7.2-16-16s7.2-16 16-16h42.9c7.2 0 13.1-5.9 13.1-13.1c0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2c0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V368c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V368c0-8.8 7.2-16 16-16z"/>
                            </SvgIcon>}
                          </IconButton>
                        </Box>
                      </Tooltip>
                      <Tooltip title={t('Reload data')}>
                        <IconButton
                          aria-label="refresh"
                          onClick={() => onReload(searchTerm, true, filter)}
                        >
                          <Refresh />
                        </IconButton>
                      </Tooltip>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default ContactsToolbar;