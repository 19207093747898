import { Box, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProgressLine = (props) => {
    const { item, compactView } = props;
    const { t } = useTranslation();

    const getProgressColor = (progress) => {
        if (progress < 40) {
            return 'error';
        }
        if (progress < 60) {
            return 'warning';
        }
        if (progress < 80) {
            return 'info';
        }
        return 'success';
    };

    return (
        <Tooltip arrow title={(
            <>
                <Box>
                    <Typography sx={{ mr: 0.5 }} variant="caption">
                        {t('Ready')}:
                    </Typography>
                    <Typography variant="caption">
                        {item.progress}%
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ mr: 0.5 }} variant="caption">
                        {t('Tasks ready: {{complete}} of {{total}}', {
                            complete: item.completeTasksCount,
                            total: item.tasksCount
                        })}
                    </Typography>
                </Box>
            </>
        )}>
            <Box>
                {(compactView === false) && (
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2">
                            {item.progress}%
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 'auto' }}>
                            {item.completeTasksCount}/{item.tasksCount}
                        </Typography>
                    </Box>
                )}
                <LinearProgress
                    sx={{
                        mt: '2px',
                        height: 8,
                        borderRadius: 1
                    }}
                    color={getProgressColor(item.progress)}
                    variant="determinate"
                    value={item.progress}
                />
            </Box>
        </Tooltip>
    );
};

export default ProgressLine;