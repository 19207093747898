import { PropTypes } from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import $api from 'src/components/http';
import { useNavigate } from 'react-router';
import PageMeta from 'src/components/app/PageMeta';

const useStyles = makeStyles({
  root: {
    '& p': {
      marginBottom: 20
    },
    '& h1': {
      fontSize: 35,
      marginBottom: 15
    },
    '& h2': {
      marginBottom: 15,
      marginTop: 15,
      fontWeight: 400,
      fontSize: 29
    },
    '& h3': {
      marginBottom: 15,
      marginTop: 15,
      fontWeight: 400,
      fontSize: 22
    },
  }
});

const ContentPage = (props) => {
  const {
    contentUrl,
    title
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const loadContent = () => {
    if (window.location.hostname !== process.env.REACT_APP_HOSTNAME) {
      navigate('/login', { replace: true });
      return false;
    }
    $api.get(contentUrl)
      .catch((e) => {
        setError('Error loading content of the page');
        return e;
      })
      .then((res) => {
        setIsLoading(false);
        setContent(res.data);
      });
  };

  useEffect(() => {
    loadContent();
  }, [contentUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [content]);

  if (isLoading) {
    return (
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return error;
  }

  return (
    <>
      <PageMeta title={title} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className={classes.root}
            >
              <Typography component="div" dangerouslySetInnerHTML={{ __html: content }} color="text.primary" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

ContentPage.propTypes = {
  contentUrl: PropTypes.string,
  title: PropTypes.string
};

export default ContentPage;
