import { Box } from '@mui/material';
import PageMeta from 'src/components/app/PageMeta';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
import { useAppConext } from 'src/components/context/AppContext';
import NoContent from 'src/components/app/NoContent';
import { useTranslation } from 'react-i18next';
import { Warning } from '@mui/icons-material';
import { useParams } from 'react-router';

const NewPricing = lazy(() => import('./upgrade/NewPricing'));
const AgencyPricing = lazy(() => import('./upgrade/agency/AgencyPricing'));

const Upgrade = () => {
  const { t } = useTranslation();
  const { user } = useAppConext();
  const { setId } = useParams();

  if ((user?.role !== 'owner' && user?.role !== undefined) || user?.permissions?.upgrade === false) {
    return (
      <Box sx={{ display: 'flex', height: '100%' }}>
        <NoContent
          containerSx={{
            my: 'auto'
          }}
          icon={<Warning sx={{ width: 40, height: 40, color: 'text.secondary' }} />}
          title={t('Page is not found')}
          content={t("The page you're looking for is not exist")}
        />
      </Box>
    );
  }

  return (
    <>
      <PageMeta title="Upgrade your plan" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: {
              xs: 1,
              md: 2
          },
        }}
      >
        <Box sx={{ mt: 5, mx: {
            xs: 1,
            ms: 1,
            md: 5
        } }}>
          {user?.rootUser !== undefined && (
            (user?.rootUser === true && setId === undefined) ? (
              <Suspense fallback={<LoadingProgress open />}>
                <NewPricing
                  cancelURL="/app/upgrade?canceled=true&session_id={CHECKOUT_SESSION_ID}"
                  successURL="/app/payment-success?session_id={CHECKOUT_SESSION_ID}"
                  redirect={false}
                />
              </Suspense>
            ) : (
              <Suspense fallback={<LoadingProgress open />}>
                <AgencyPricing
                  cancelURL="/app/upgrade?canceled=true&session_id={CHECKOUT_SESSION_ID}"
                  successURL="/app/payment-success?session_id={CHECKOUT_SESSION_ID}"
                  redirect={false}
                />
              </Suspense>
            )
          )}
        </Box>
      </Box>
    </>
  );
}

export default Upgrade;
