import {
    Box
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
const Price = lazy(() => import('../../components/settings/agency/AgencyPrice'));

const AgencyPrice = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageMeta title={t('Pricing & plans')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: 2
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <Price configUrl="/config/get" saveUrl="/config/save" modelName="AgencyConfig" />
                </Suspense>
            </Box>
        </>
    );
};

export default AgencyPrice;