import { ButtonBase } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ClickToCopy = (props) => {
    const {
        children,
        tooltip,
        tooltipProps,
        color = 'text.primary',
        fontSize = '0.875rem'
    } = props;
    const [tooltipText, setTooltipText] = useState(tooltip);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleCopy = () => {
        navigator.clipboard.writeText(children).then(() => {
            setTooltipText(t('Copied to clipboard'));
        }, () => {
            enqueueSnackbar(t('Not been copied to clipboard. Please use browser functionality.'), {
                variant: 'error'
            });
        });
    };

    return (
        <Tooltip {...tooltipProps} onOpen={() => setTooltipText(tooltip)} title={tooltipText}>
            <ButtonBase
                sx={{
                    borderBottomWidth: 1,
                    borderBottomStyle: 'dashed',
                    borderBottomColor: 'divider',
                    color: color,
                    fontSize: fontSize
                }} disableRipple disableTouchRipple onClick={handleCopy}>
                {children}
            </ButtonBase>
        </Tooltip>
    );
};

export default ClickToCopy;