import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import TicketSidebar from "src/components/tickets/TicketSidebar";
import TicketTabs from "src/components/tickets/TicketTabs";
import TicketAttachments from "src/components/tickets/tabs/TicketAttachments";
import TicketContacts from "src/components/tickets/tabs/TicketContacts";
import TicketNotes from "src/components/tickets/tabs/TicketNotes";
import TicketTasks from "src/components/tickets/tabs/TicketTasks";
import TicketTimeline from "src/components/tickets/tabs/TicketTimeline";

const CrmItem = lazy(() => import('../../../src/components/crmItem'));

const TicketPage = () => {
    const { id, tab } = useParams();
    const { t } = useTranslation();

    return (
        <>
            <PageMeta title={t('Tickets')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <CrmItem
                        url="/ticket/get"
                        itemGuid={id}
                        itemTitleKey="subject"
                        itemsTitle={t('Tickets')}
                        backUrl="/app/tickets"
                        SidebarComponent={TicketSidebar}
                        TabsComponent={TicketTabs}
                        tab={tab || 'notes'}
                        contentComponents={[
                            {
                                tabId: 'notes',
                                component: TicketNotes
                            },
                            {
                                tabId: 'attachments',
                                component: TicketAttachments
                            },
                            {
                                tabId: 'contacts',
                                component: TicketContacts
                            },
                            {
                                tabId: 'tasks',
                                component: TicketTasks
                            },
                            {
                                tabId: 'timeline',
                                component: TicketTimeline
                            }
                        ]}
                    />
                </Suspense>
            </Box>
        </>
    );
};

export default TicketPage;