import { Box, experimentalStyled } from "@mui/material";
import { useTheme } from "@mui/styles";

export const DashboardLayoutWrapper = (props) => {
    const { children, collapsed } = props;
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                flex: '1 1 auto',
                overflow: 'hidden',
                minHeight: '100%',
                pt: {
                    xs: '56px',
                    sm: '64px'
                },
                [theme.breakpoints.up('lg')]: {
                    paddingLeft: collapsed ? '89px' : '256px'
                }
            }}
        >
            {children}
        </Box>
    );
};

export const DashboardLayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

export const DashboardLayoutContent = experimentalStyled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    position: 'relative'
});

export const DashboardLayoutRoot = experimentalStyled('div')(
    ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        width: '100%',
        height: '100%'
    })
);