import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Typography
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Error from 'src/components/app/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import $api from '../http';

const WixConnect = () => {
  const [loadError, setLoadError] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const loadContent = () => {
    const data = {};
    const params = new URLSearchParams(window.location.search);
    if (params.has('hmac')) {
      data.hmac = params.get('hmac');
    }
    if (params.has('shop')) {
      data.shop = params.get('shop');
    }
    if (params.has('timestamp')) {
      data.timestamp = params.get('timestamp');
    }
    if (params.has('host')) {
      data.host = params.get('host');
    }
    if (params.has('code')) {
      data.code = params.get('code');
    }
    if (params.has('session')) {
      data.session = params.get('session');
    }
    if (params.has('instanceId')) {
      data.instanceId = params.get('instanceId');
    }
    if (params.has('state')) {
      data.state = params.get('state');
    }
    $api.post('/auth/wix-connect', data)
      .catch((err) => {
        setIsLoaded(true);
        setLoadError(err);
        return null;
      })
      .then((response) => {
        setIsLoaded(true);
        if (response) {
          console.log(response.data);
        }
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (loadError && Object.keys(loadError).length !== 0) {
    return (
      <Error error={loadError} />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={4} />
          <Grid item md={4}>
            <Card sx={{ position: 'relative', minHeight: '209px' }}>
              {((!isLoaded) && (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginLeft: '-20px',
                    marginTop: '-20px'
                  }}
                />
              )) || (
                <>
                  <CardHeader
                    align="center"
                    title="Shopify is connected"
                  />
                  <Divider />
                  <CardContent>
                    <Box align="center">
                      <CheckCircleIcon fontSize="large" color="primary" />
                    </Box>
                    <Box align="center">
                      <Typography>
                        Shopify store is connected successfully
                      </Typography>
                    </Box>
                    <Box align="center" sx={{ pt: 2 }}>
                      <Button
                        component={NavLink}
                        to="/app"
                        variant="outlined"
                      >
                        Dashboard
                      </Button>
                    </Box>
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WixConnect;
