import { Avatar, Badge, Box, Tooltip, Typography } from "@mui/material";
import SourceIcon from "./SourceIcon";
import { useTranslation } from "react-i18next";
import UserAvatar from "../app/UserAvatar";
import useDayjs from "../app/useDayjs";

const ThreadMiniCard = (props) => {
    const { thread } = props;

    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    return (
        <Box component="a" href={`/app/chat/${thread.guid}`} target="_blank" sx={{ display: 'flex', bgcolor: 'background.default', borderRadius: 1, p: 1, mb: '2px' }}>
            <Box sx={{ mr: 2, my: 'auto' }}>
                {(thread?.assignedTo !== null) ? (
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <Avatar sx={{ width: 22, height: 22, borderWidth: 2, borderStyle: 'solid', borderColor: 'background.paper' }} alt={thread.assignedTo.name} src={thread.assignedTo.img} />
                        }
                    >
                        <UserAvatar
                            name={thread.client?.name || t('Visitor')}
                            src={thread.client.img}
                            guid={thread.client.guid}
                            deleted={false}
                        />
                    </Badge>
                ) : (
                    <UserAvatar
                        name={thread.client?.name || t('Visitor')}
                        src={thread.client.img}
                        guid={thread.client.guid}
                        deleted={false}
                    />
                )}
            </Box>
            <Box sx={{ overflow: 'hidden' }}>
                <Typography color="text.primary">
                    {thread.client.name || t('Visitor')}
                </Typography>
                <Typography color="text.primary" noWrap sx={{ overflow: "hidden" }}>
                    <Typography noWrap variant="body2" component="span" sx={{ mr: 0.5 }}>
                        {thread.lastMessageFrom.name || t('Visitor')}:
                    </Typography>
                    <Typography noWrap variant="body2" color="text.secondary" component="span">
                        {thread.lastMessage}
                    </Typography>
                </Typography>
            </Box>
            <Box sx={{ ml: 'auto', my: 'auto', textAlign: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Tooltip arrow placement="right" title={dayjs.unix(thread.lastMessageTime).format('MMM DD YYYY, HH:mm')}>
                    <Typography sx={{ fontSize: '0.7rem', mb: '2px' }} color="text.secondary">
                        {dayjs.unix(thread.lastMessageTime).format('HH:mm')}
                    </Typography>
                </Tooltip>
                <SourceIcon tooltipPlacement="right" channel={thread.source} />
            </Box>
        </Box>
    );
};

export default ThreadMiniCard;