import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const decodeParamForKey = (paramString, key) => {
  const s = encodeURIComponent(key).replace(/[.+*]/g, '\\$&');
  decodeURIComponent(
    paramString.replace(new RegExp(`^(?:.*[&\\?]${s}(?:\\=([^&]*))?)?.*`, 'i'), '$1')
  );
};

const getParamsFromObject = (params) => {
  const s = Object.keys(params)
    .map((param) => `${param}=${encodeURIComponent(params[param])}`)
    .join('&');
  return `?${s}`;
};

const getIsMobile = () => {
  let isMobile = false;

  try {
    isMobile = !!((window.navigator && window.navigator.standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
  } catch (ex) {
    // continue regardless of error
  }

  return isMobile;
};

const useFacebookLogin = ({
  redirectUri = (typeof window !== 'undefined') ? window.location.href : '/',
  scope = 'public_profile,email',
  returnScopes = true,
  xfbml = true,
  cookie = true,
  authType = '',
  fields = 'email,picture,first_name,last_name',
  version = '19.0',
  configId = null,
  language = 'en_US',
  disableMobileRedirect = true,
  isMobile = getIsMobile(),
  onFailure = null,
  state = 'facebookdirect',
  responseType = 'code',
  isDisabled,
  appId,
  autoLoad,
  onClick,
  onSuccess,
  extras = ''
}) => {
  const [isSDKLoaded, setIsSDKLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const isRedirectedFromFb = () => {
    const params = window.location.search;
    return (
      decodeParamForKey(params, 'state') === 'facebookdirect' && (decodeParamForKey(params, 'code')
      || decodeParamForKey(params, 'granted_scopes'))
    );
  };

  const responseApi = (authResponse) => {
    onSuccess(authResponse);
  };

  const checkLoginState = (response) => {
    console.log(response);
    setIsProcessing(false);
    if (response.authResponse) {
      responseApi(response.authResponse);
    } else if (onFailure) {
      onFailure({ status: response.status });
    } else {
      onSuccess({ status: response.status });
    }
  };

  const checkLoginAfterRefresh = (response) => {
    if (response.status === 'connected') {
      checkLoginState(response);
    } else {
      window.FB.login((loginResponse) => checkLoginState(loginResponse), true);
    }
  };

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        version: `v${version}`,
        appId,
        xfbml,
        cookie,
      });
      setIsSDKLoaded(true);
      if (autoLoad || isRedirectedFromFb()) {
        window.FB.getLoginStatus(checkLoginAfterRefresh);
      }
    };
  };

  const loadSdkAsynchronously = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = `https://connect.facebook.net/${language}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  useEffect(() => {
    if (window.FB !== undefined) {
      delete window.FB;
    }
    setFbAsyncInit();
    if (document.getElementById('facebook-jssdk') === null) {
      loadSdkAsynchronously();
    } else {
      // reload
      document.getElementById('facebook-jssdk').remove();
      loadSdkAsynchronously();
      setIsSDKLoaded(true);
    }
    let fbRoot = document.getElementById('fb-root');
    if (!fbRoot) {
      fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';
      document.body.appendChild(fbRoot);
    }
  }, []);

  useEffect(() => {
    if (isSDKLoaded && autoLoad) {
      window.FB.getLoginStatus(checkLoginAfterRefresh);
    }
  }, [isSDKLoaded, autoLoad]);

  function facebookSignIn(e) {
    if (!isSDKLoaded || isProcessing || isDisabled) {
      return;
    }
    setIsProcessing(true);

    if (typeof onClick === 'function') {
      onClick(e);
      if (e.defaultPrevented) {
        setIsProcessing(false);
        return;
      }
    }

    const params = {
      client_id: appId,
      redirect_uri: redirectUri,
      state,
      return_scopes: returnScopes,
      scope,
      response_type: responseType,
      auth_type: authType,
      extras: extras
    };
    if (isMobile && !disableMobileRedirect) {
      window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
    } else {
      if (!window.FB) {
        if (onFailure) {
          onFailure({ status: 'facebookNotLoaded' });
        }
        return;
      }

      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected' && params.auth_type !== 'reauthorize') {
          setIsProcessing(false);
          checkLoginState(response);
        } else {
          window.FB.login(checkLoginState, {
            scope,
            return_scopes: returnScopes,
            // config_id: configId,
            auth_type: params.auth_type,
            extras: params.extras
          });
        }
      });
    }
  }

  return { facebookSignIn };
};

useFacebookLogin.propTypes = {
  isDisabled: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  xfbml: PropTypes.bool,
  cookie: PropTypes.bool,
  authType: PropTypes.string,
  scope: PropTypes.string,
  state: PropTypes.string,
  responseType: PropTypes.string,
  returnScopes: PropTypes.bool,
  redirectUri: PropTypes.string,
  autoLoad: PropTypes.bool,
  disableMobileRedirect: PropTypes.bool,
  isMobile: PropTypes.bool,
  fields: PropTypes.string,
  version: PropTypes.string,
  language: PropTypes.string,
  onClick: PropTypes.func,
  onFailure: PropTypes.func,
};

export default useFacebookLogin;
