import { useTranslation } from "react-i18next";
import CrmItemTimeline from "src/components/crmItem/CrmItemTimeline";

const OrganizationTimeline = (props) => {
    const { itemGuid } = props;
    const { t } = useTranslation();

    return (
        <CrmItemTimeline
            itemGuid={itemGuid}
            url="/organization/get-timeline"
            attributeLabels={{
                subject: t('Title'),
                description: t('Description'),
                priority: t('Priority'),
                status: t('Status'),
                assigned_to: t('Assignee'),
                website: t('Website'),
                phone: t('Phone'),
                fax: t('Fax')
            }}
            statusOptions={[
                
            ]}
            priorityOptions={[
                
            ]}
        />
    );
};

export default OrganizationTimeline;