import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import { SnackbarProvider } from 'notistack';

ReactDOM.render((
  <BrowserRouter>
    <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
      <App />
    </SnackbarProvider>
  </BrowserRouter>
), document.getElementById('root'));

const params = new URLSearchParams(window.location.search);
if (params.has('update')) {
  serviceWorkerRegistration.update();
  console.log('ServiceWorker update');
  if (localStorage.getItem('needReload') === 1 || localStorage.getItem('needReload') === '1'){
    localStorage.setItem('needReload', 0);
    setTimeout(() => {
      console.log('One more reload');
      window.location.reload(true);
    }, 2000);
  }
}
serviceWorkerRegistration.register();