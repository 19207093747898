import { Box, Container } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import PageMeta from 'src/components/app/PageMeta';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';

const NewPricing = lazy(() => import('./upgrade/NewPricing'));

const Pricing = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.hostname !== process.env.REACT_APP_HOSTNAME) {
      navigate('/login', { replace: true });
      return false;
    }
  }, []);
  
  return (
    <>
      <PageMeta title="Pricing" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          pb: 3
        }}
      >
        <Box sx={{ mb: 5 }}>
            <Container sx={{ mt: 2 }}>
              <Suspense fallback={<LoadingProgress open />}>
                <NewPricing
                  cancelURL="/pricing?canceled=true&session_id={CHECKOUT_SESSION_ID}"
                  successURL="/pricing?session_id={CHECKOUT_SESSION_ID}"
                  redirect
                />
              </Suspense>
            </Container>
        </Box>
      </Box>
    </>
  );
};

export default Pricing;
