import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Tooltip
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import TabPanel from 'src/components/tabPanel/TabPanel';
import { lazy, Suspense } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { useAppConext } from 'src/components/context/AppContext';
import RestrictedFieldWrapper from 'src/components/app/RestrictedFieldWrapper';
import WidgetsMenu from 'src/components/widget/WidgetsMenu';
import { useOutletContext } from 'react-router';
import NoContent from 'src/components/app/NoContent';
import { Lock } from '@mui/icons-material';

const PromptConfig = lazy(() => import('../../components/settings/prompt/PromptConfig'));
const PromptList = lazy(() => import('../../components/settings/prompt/PromptList'));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PromptSettings = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const { user, getFeatureValue } = useAppConext();
  const { chatWebSocket } = useOutletContext();
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if ((user?.permissions?.settingsPrompts === false)) {
    return (
        <Box sx={{ height: 'calc(100vh - 164px)', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <NoContent
                containerSx={{
                    my: 'auto'
                }}
                icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                title={t('Forbidden')}
                content={t("You're don't have access to this page")}
            />
        </Box>
    );
  }

  return (
    <>
      <PageMeta title={t('Prompt settings')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: {
            xs: 1,
            md: 2
          }
        }}
      >
        <Box sx={{ pt: 0, mb: 2 }}>
          <Suspense fallback={<LoadingProgress open />}>
            <RestrictedFieldWrapper restricted={!getFeatureValue('prompts.enabled')} disabled fullWidth>
              <PromptList configUrl="/prompts?filter[type]=1" />
            </RestrictedFieldWrapper>
          </Suspense>
        </Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <RestrictedFieldWrapper restricted={!getFeatureValue('prompts.enabled')} disabled fullWidth>
              <Card>
                <CardHeader
                  title={t('Prompt settings')}
                  sx={{
                    '& .MuiCardHeader-action': {
                      my: 'auto'
                    }
                  }}
                  action={(
                    <>
                      <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'inline-flex', mr: 1 }}>
                        <WidgetsMenu chatWebSocket={chatWebSocket} configUrl="/widgets?includeAgency=0" onAddWidget={null} />
                      </Box>
                      {(user?.permissions?.docs) && (
                        <Tooltip title={t('Help')}>
                          <IconButton component="a" href="https://docs.anychat.one/multichannel-contact-button/customization/prompt-messages" target="_blank">
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                />
                <Divider />
                <CardContent sx={{ p: 0, pb: '0 !important' }}>
                    <Tabs
                      sx={{ mb: 2 }}
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="disabled tabs example"
                    >
                      <Tab label={t('Desktop')} {...a11yProps(0)} />
                      <Tab label={t('Mobile')} {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Box sx={{ px: 2 }}>
                        <Suspense fallback={<LoadingProgress open />}>
                          <PromptConfig configUrl="/config/get" saveUrl="/config/save" modelName="PromptConfig" />
                        </Suspense>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box sx={{ px: 2 }}>
                        <Suspense fallback={<LoadingProgress open />}>
                          <PromptConfig configUrl="/config/get" saveUrl="/config/save" modelName="PromptMobileConfig" isMobile />
                        </Suspense>
                      </Box>
                    </TabPanel>
                </CardContent>
              </Card>
            </RestrictedFieldWrapper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PromptSettings;
