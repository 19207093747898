import {
  Box
} from '@mui/material';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
const WidgetsList = lazy(() => import('src/components/widget/WidgetsList'));

const Widgets = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageMeta title={t('Widgets')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: 2
        }}
      >
        <Suspense fallback={<LoadingProgress open />}>
          <WidgetsList showFab showBreadcrumbs configUrl="/widgets" />
        </Suspense>
      </Box>
    </>
  );
  
};

export default Widgets;
