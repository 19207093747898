const TokenProvider = (props) => {
  let listeners = [];

  const {
    localStorageKey,
    onUpdateToken,
    onHydratation
  } = props;

  const getTokenInternal = () => {
    return localStorage.getItem(localStorageKey);
  };

  const notify = () => {
    const token = getTokenInternal();
    listeners.forEach((list) => list(token));
  };

  const subscribe = (listn) => {
    listeners.push(listn);
  };

  const unsubscribe = (listn) => {
    listeners = listeners.filter((entity) => entity !== listn);
  };

  const isLoggedIn = () => {
    const token = getTokenInternal();
    if (onHydratation) onHydratation(token);
    return !!token;
  };

  const jwtExp = (token) => {
    if (!(typeof token === 'string')) return null;
    const tokenParts = token.split('.');
    if (tokenParts.length < 2) return null;

    try {
      const jwt = JSON.parse(atob(tokenParts[1]));
      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const getExpire = (token) => {
    if (!token) return null;

    return jwtExp(token);
  };

  const isExpired = (exp) => {
    if (!exp) return false;
    return Date.now() > exp;
  };

  const setToken = (token) => {
    if (token) {
      localStorage.setItem(localStorageKey, token);
    } else {
      localStorage.removeItem(localStorageKey);
    }
    notify();
  };

  const checkExpiry = async () => {
    const token = getTokenInternal();
    if (token && isExpired(getExpire(token))) {
      // request for new tokens, if applicable
      const data = onUpdateToken ? await onUpdateToken(token) : null;
      if (data && data.auth) {
        setToken(data.auth);
      } else {
        setToken(null);
      }
    }
  };

  const getToken = () => {
    return getTokenInternal();
  };

  return {
    getToken,
    setToken,
    notify,
    isLoggedIn,
    subscribe,
    unsubscribe,
  };
};

export default TokenProvider;
