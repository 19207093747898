import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import TaskSidebar from "src/components/tasks/TaskSidebar";
import TaskTabs from "src/components/tasks/TaskTabs";
import TaskAttachments from "src/components/tasks/tabs/TaskAttachments";
import TaskContacts from "src/components/tasks/tabs/TaskContacts";
import TaskNotes from "src/components/tasks/tabs/TaskNotes";
import TaskTimeline from "src/components/tasks/tabs/TaskTimeline";

const CrmItem = lazy(() => import('../../../src/components/crmItem'));

const TaskPage = () => {
    const { id, tab } = useParams();
    const { t } = useTranslation();

    return (
        <>
            <PageMeta title={t('Tasks')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <CrmItem
                        url="/task/get"
                        itemGuid={id}
                        itemTitleKey="subject"
                        itemsTitle={t('Tasks')}
                        backUrl="/app/tasks"
                        SidebarComponent={TaskSidebar}
                        TabsComponent={TaskTabs}
                        tab={tab || 'notes'}
                        contentComponents={[
                            {
                                tabId: 'notes',
                                component: TaskNotes
                            },
                            {
                                tabId: 'attachments',
                                component: TaskAttachments
                            },
                            {
                                tabId: 'contacts',
                                component: TaskContacts
                            },
                            {
                                tabId: 'timeline',
                                component: TaskTimeline
                            }
                        ]}
                    />
                </Suspense>
            </Box>
        </>
    );
};

export default TaskPage;