import { AttachMoney, RocketLaunch } from '@mui/icons-material';
import {
    Box,
    Button,
    Typography
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import RestrictedFieldWrapper from 'src/components/app/RestrictedFieldWrapper';
import { useAppConext } from 'src/components/context/AppContext';
const Prices = lazy(() => import('../../components/settings/agency/AgencyPrices'));
const Addons = lazy(() => import('../../components/settings/agency/AgencyAddons'));
const KeyFeatures = lazy(() => import('../../components/settings/agency/AgencyKeyFeatures'));

const AgencyPrices = () => {
    const { t } = useTranslation();
    const { getFeatureValue } = useAppConext();

    return (
        <>
            <PageMeta title={t('Pricing & plans')} />
            <Box
                sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                px: {
                    xs: 1,
                    md: 2
                },
                py: 2
                }}
            >
                <RestrictedFieldWrapper
                    fullWidth
                    containerSx={{ display: 'flex' }}
                    blur
                    badgeType={false}
                    restricted={getFeatureValue('agency.planBuilder') !== true}
                    customContent={(
                        <Box sx={{ px: 1 }}>
                            <AttachMoney sx={{ width: 40, height: 40, color: 'error.main' }} />
                            <Typography color="error" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {t('Plan builder section is locked')}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {t('Ulock this section to be able to manage your plans and charge your clients')}
                            </Typography>
                            <Button sx={{ mt: 1 }} variant="outlined" startIcon={<RocketLaunch />} component={NavLink} to="/app/upgrade">
                                {t('Upgrade now')} 
                            </Button>
                        </Box>
                    )}
                >
                    <Suspense fallback={<LoadingProgress open />}>
                        <Prices configUrl="/config/get" saveUrl="/config/save" modelName="AgencyConfig" />
                        <Addons configUrl="/config/get" saveUrl="/config/save" modelName="AgencyConfig" />
                        <KeyFeatures configUrl="/config/get" saveUrl="/config/save" modelName="AgencyConfig" />
                    </Suspense>
                </RestrictedFieldWrapper>
            </Box>
        </>
    );
};

export default AgencyPrices;