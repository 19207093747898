import { useTranslation } from "react-i18next";
import CrmItemTimeline from "src/components/crmItem/CrmItemTimeline";

const TicketTimeline = (props) => {
    const { itemGuid } = props;
    const { t } = useTranslation();

    return (
        <CrmItemTimeline
            itemGuid={itemGuid}
            url="/ticket/get-timeline"
            attributeLabels={{
                subject: t('Title'),
                description: t('Description'),
                priority: t('Priority'),
                status: t('Status'),
                assigned_to: t('Assignee'),
                access: t('Public access')
            }}
            statusOptions={[
                {
                    id: 1,
                    label: t('New'),
                    color: 'error.main'
                },
                {
                    id: 2,
                    label: t('Waiting on contact'),
                    color: 'info.main'
                },
                {
                    id: 3,
                    label: t('Waiting on us'),
                    color: 'warning.main'
                },
                {
                    id: 4,
                    label: t('Closed'),
                    color: 'success.main'
                }
            ]}
            priorityOptions={[
                {
                    id: 1,
                    label: t('Low'),
                    color: 'success.main'
                },
                {
                    id: 2,
                    label: t('Middle'),
                    color: 'warning.main'
                },
                {
                    id: 3,
                    label: t('High'),
                    color: 'error.main'
                },
            ]}
        />
    );
};

export default TicketTimeline;