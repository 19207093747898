import { Bolt, Mood } from "@mui/icons-material";
import { Box, CircularProgress, Divider, IconButton, InputAdornment, ListItemText, Menu, MenuItem, Popover, TextField, Tooltip } from "@mui/material";
import Picker from "emoji-picker-react";
import { useMemo, useRef, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { isEmpty } from "./Tools";

const EmojiTextField = (props) => {
    const { emoji, onEmojiInsert, ai = true, ...rest } = props;
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [selectionEnd, setSelectionEnd] = useState(null);
    const [aiMenuAnchorEl, setAiMenuAnchorEl] = useState(null);
    const [aiLoading, setAiLoading] = useState(false);
    const [showDefaultApiKeyDialog, setShowDefaultApiKeyDialog] = useState(false);
    const inputRef = useRef();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleEmojiInsert = (emoji) => {
        let value = rest.value || '';
        if (selectionEnd !== null) {
            value = value.substring(0, selectionEnd) + emoji.emoji + value.substring(selectionEnd, value.length);
        } else {
            value = `${value}${emoji.emoji}`;
        }
        if (onEmojiInsert) {
            setTimeout(() => {
                inputRef.current.focus();
                setSelectionEnd((i) => i + 3);
            });
            onEmojiInsert(value);
        }
        handleEmojiClose();
    };

    const handleEmojiOpen = (e, t) => {
        setPopoverAnchor(e.currentTarget);
    };

    const handleEmojiClose = () => {
        setPopoverAnchor(null);
    };

    const handleCloseAiMenu = () => {
        setAiMenuAnchorEl(null);
    };

    const handleAiMenuItemClick = (aiTask) => {
        handleCloseAiMenu();
        setAiLoading(true);
        $api.post('/ai/text-task', {
          task: aiTask,
          text: inputRef.current.value,
          // thread: currentThread.thread.guid
        }, {
          timeout: 60000
        })
          .catch((err) => {
            enqueueSnackbar(t('Error sending request'), {
              variant: 'error'
            });
            return null;
          })
          .then((res) => {
            if (res) {
                setShowDefaultApiKeyDialog(res?.data?.isDefaultApiKey);
                if (res.data.success === true) {
                    inputRef.current.value = res.data.content;
                    if (rest.onChange !== undefined) {
                        rest.onChange({
                            target: {
                                name: rest?.name,
                                value: res.data.content
                            }
                        })
                    }
                } else {
                    enqueueSnackbar(res.data.content || t('Error generating content'), {
                        variant: 'error'
                    });
                }
            }
          })
          .finally(() => {
            setAiLoading(false);
          })
    };

    const memoAiMenu = useMemo(() => (
        <Menu
            open={!!aiMenuAnchorEl}
            anchorEl={aiMenuAnchorEl}
            onClose={handleCloseAiMenu}
        >
            <MenuItem onClick={() => handleAiMenuItemClick('rewrite')}>
                <ListItemText>
                    {t('Rewrite')}
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleAiMenuItemClick('expand')}>
                <ListItemText>
                    {t('Expand')}
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleAiMenuItemClick('shorten')}>
                <ListItemText>
                    {t('Shorten')}
                </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleAiMenuItemClick('tone:friendly')}>
                <ListItemText>
                    {t('Friendly tone')}
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleAiMenuItemClick('tone:heartfelt')}>
                <ListItemText>
                    {t('Heartfelt tone')}
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleAiMenuItemClick('tone:professional')}>
                <ListItemText>
                    {t('Professional tone')}
                </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleAiMenuItemClick('correct')}>
                <ListItemText>
                    {t('Correct spelling')}
                </ListItemText>
            </MenuItem>
        </Menu>
    ), [aiMenuAnchorEl]);

    return (
        <>
            <Popover keepMounted={false} onClose={handleEmojiClose} anchorEl={popoverAnchor} open={!!popoverAnchor}>
                <Picker
                    pickerStyle={{ width: '100%' }}
                    onEmojiClick={handleEmojiInsert}
                    disableAutoFocus
                    groupNames={{ smileys_people: 'PEOPLE' }}
                    emojiStyle="native"
                    native
                />
            </Popover>
            {(memoAiMenu)}
            <TextField
                onSelect={(e) => {
                    if (e.target.selectionStart !== undefined) {
                        setSelectionEnd(e.target.selectionStart);
                    }
                }}
                inputProps={{
                    ref: inputRef
                }}
                InputProps={(emoji || ai) ? {
                    endAdornment: (
                        <>
                            <InputAdornment position="end">
                                {(emoji) && (
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        disabled={rest.disabled}
                                        onClick={(e) => handleEmojiOpen(e)}
                                    >
                                        <Mood />
                                    </IconButton>
                                )}
                                {(ai) && (
                                        <Tooltip title={t('AI assistant')}>
                                            <Box component="span" sx={{ display: 'inline-flex', ml: 0.5 }}>
                                                <IconButton
                                                    edge="end"
                                                    size="small"
                                                    disabled={rest.disabled || isEmpty(rest.value) || aiLoading}
                                                    onClick={(e) => setAiMenuAnchorEl(e.target)}
                                                >
                                                    {aiLoading ? <CircularProgress size={24} /> : <Bolt /> }
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    
                                )}
                            </InputAdornment>
                        </>
                    )
                } : {}}
                {...rest}
            />
        </>
    );
};

export default EmojiTextField;