const useRole = () => {
    const admin = 'admin';
    const agent = 'agent';

    const getRole = () => {
        const data = localStorage.getItem('role');
        return data || null;
    };

    const setRole = (newRole) => {
        if (newRole) {
            localStorage.setItem('role', newRole);
        } else {
            localStorage.removeItem('role');
        }
    };

    const isAgent = () => getRole() === agent;
    const isAdmin = () => getRole() === admin || getRole() === null;

    return {
        getRole,
        setRole,
        isAgent,
        isAdmin
    };
};

export default useRole;