import { Box, Chip, Divider, IconButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Skeleton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TagsPopup from "../TagsPopup";
import { useEffect, useState } from "react";
import $api from "../http";
import { invertColor } from "../app/Tools";
import { AddCircle, CopyAll, Lock, LockOpen } from "@mui/icons-material";
import UserChip from "../app/UserChip";
import { useSnackbar } from "notistack";
import ProgressLine from "./ProgressLine";
import ThreadMiniCard from "../chat/ThreadMiniCard";
import useDayjs from "../app/useDayjs";

const TicketSidebar = (props) => {
    const {
        item,
        allowInplaceEdit = true,
        onReload = () => {}
    } = props;
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);
    const { t } = useTranslation();
    const { dayjs } = useDayjs();
    const { enqueueSnackbar } = useSnackbar();

    const [statusMenuEl, setStatusMenuEl] = useState(null);
    const [priorityMenuEl, setPriorityMenuEl] = useState(null);
    const [currentItemGuid, setCurrentItemGuid] = useState(null);
    const [accessMenuEl, setAccessMenuEl] = useState(false);
    const [threads, setThreads] = useState([]);
    const [threadsLoading, setThreadsLoading] = useState(true);

    const statusOptions = [
        {
            id: 1,
            label: t('New'),
            color: 'error.main'
        },
        {
            id: 2,
            label: t('Waiting on contact'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('Waiting on us'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Closed'),
            color: 'success.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    const loadThreads = () => {
        setThreadsLoading(true);
        $api.get(`/ticket/get-threads?guid=${item.guid}`)
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setThreads(res.data);
                }
            })
            .finally(() => {
                setThreadsLoading(false);
            })
    };

    const handleUpdatePriority = (priority) => {
        handlePriorityMenuClose();
        $api.post('/ticket/update-priority', {
            guid: currentItemGuid,
            priority: priority
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                }
            })

    };

    const handlePriorityMenuOpen = (e, currentGuid) => {
        if (!allowInplaceEdit) {
            return false;
        }
        setCurrentItemGuid(currentGuid);
        setPriorityMenuEl(e.currentTarget);
    };

    const handlePriorityMenuClose = () => {
        setCurrentItemGuid(null);
        setPriorityMenuEl(null);
    };

    const handleUpdateStatus = (status) => {
        handleStatusMenuClose();
        $api.post('/ticket/update-status', {
            guid: currentItemGuid,
            status: status
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                }
            })

    };

    const handleStatusMenuOpen = (e, currentGuid) => {
        if (!allowInplaceEdit) {
            return false;
        }
        setCurrentItemGuid(currentGuid);
        setStatusMenuEl(e.currentTarget);
    };

    const handleStatusMenuClose = () => {
        setCurrentItemGuid(null);
        setStatusMenuEl(null);
    };

    const handleAddTag = (tag) => {
        $api.post('/ticket/add-tag', {
          guid: item?.guid,
          tagId: tag.id
        })
          .catch((err) => {
    
          })
          .then((res) => {
            onReload();
          })
          .finally(() => {
            
          })
    };

    const handleRemoveTag = (id) => {
        $api.post('/ticket/remove-tag', {
            guid: item?.guid,
            tagId: id
        })
          .catch((err) => {
    
          })
          .then((res) => {
            onReload();
          })
          .finally(() => {
            
          })
    };

    const handleOpenTagsPopup = (e) => {
        setTagsAnchorEl(e.currentTarget);
    };

    const handleAccessMenuOpen = (e) => {
        setAccessMenuEl(e.currentTarget);
    };

    const handleAccessMenuClose = (e) => {
        setAccessMenuEl(null);
    };

    const handleAccessChange = (newState) => {
        handleAccessMenuClose();
        $api.post('/ticket/set-access', {
            guid: item.guid,
            access: newState ? 1 : 0
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    onReload();
                }
            })
    };

    const handleCopyPublicUrl = () => {
        const text = item.publicUrl;
        navigator.clipboard.writeText(text).then(() => {
            enqueueSnackbar(t('URL is copied to clipboard'), {
              variant: 'success'
            });
        }, () => {
            enqueueSnackbar(t('Error copying to clipboard'), {
              variant: 'error'
            });
        });
    };

    useEffect(() => {
        loadThreads();
    }, [item?.guid]);

    return (
        <Box sx={{ p: 2, position: 'relative' }}>
            <Menu open={!!statusMenuEl} onClose={handleStatusMenuClose} anchorEl={statusMenuEl}>
                {(statusOptions.map((i) => (
                    <MenuItem key={i.id} onClick={() => handleUpdateStatus(i.id)}>
                        <Box sx={{ width: 8, height: 8, borderRadius: 2, bgcolor: i.color, mr: 2 }} />
                        <Typography>
                            {i.label}
                        </Typography>
                    </MenuItem>
                )))}
            </Menu>
            <Menu open={!!priorityMenuEl} onClose={handlePriorityMenuClose} anchorEl={priorityMenuEl}>
                {(priorityOptions.map((i) => (
                    <MenuItem key={i.id} onClick={() => handleUpdatePriority(i.id)}>
                        <Box sx={{ width: 8, height: 8, borderRadius: 2, bgcolor: i.color, mr: 2 }} />
                        <Typography>
                            {i.label}
                        </Typography>
                    </MenuItem>
                )))}
            </Menu>
            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ borderRadius: 1, bgcolor: 'text.secondary', px: 2, py: 1, position: 'relative' }}>
                        <Box sx={{ width: 12, height: 12, bgcolor: 'background.paper', borderRadius: '50%', position: 'absolute', top: '50%', left: -6, mt: '-6px' }} />
                        <Box sx={{ width: 12, height: 12, bgcolor: 'background.paper', borderRadius: '50%', position: 'absolute', top: '50%', right: -6, mt: '-6px' }} />
                        <Typography sx={{ fontSize: '1rem', textAlign: 'center', color: '#FFFFFF' }}>
                            #{item.number}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ borderRadius: 3, bgcolor: 'background.default', position: 'absolute', top: 10, right: 10 }}>
                <IconButton onClick={handleAccessMenuOpen} centerRipple={false} sx={{
                    borderRadius: item.access === 1 ? '20px 0 0 20px' : '20px'
                }}>
                    {item.access === 1 ? <LockOpen fontSize="small" /> : <Lock fontSize="small" />}
                </IconButton>
                {(item.access === 1) && (
                    <IconButton onClick={handleCopyPublicUrl} centerRipple={false} sx={{ borderRadius: '0 20px 20px 0' }}>
                        <CopyAll fontSize="small" />
                    </IconButton>
                )}
            </Box>
            <Menu open={!!accessMenuEl} onClose={handleAccessMenuClose} anchorEl={accessMenuEl}>
                <MenuItem selected={item.access === 0} onClick={() => handleAccessChange(false)}>
                    <ListItemIcon>
                        <Lock color="error" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('Disable public access')}
                    />
                </MenuItem>
                <MenuItem selected={item.access === 1} onClick={() => handleAccessChange(true)}>
                    <ListItemIcon>
                        <LockOpen color="success" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('Enable public access')}
                    />
                </MenuItem>
            </Menu>
            <Typography sx={{ fontSize: '1.2rem', textAlign: 'center' }}>
                {item.subject}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {item.description}
            </Typography>
            
            {(item.progress !== null) && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <ProgressLine
                        item={item}
                        compactView={false}
                    />
                </>
            )}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Status')}
                </Typography>
                <Typography sx={{ ml: 'auto', color: 'text.primary'}}>
                    {(item.status === 1 ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'error.main' }} />} size="small" label={t('New')} color="error" variant="outlined" />) : 
                    (item.status === 2) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'info.main' }} />} size="small" label={t('Waiting on contact')} color="info" variant="outlined" />) : 
                    (item.status === 3) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'warning.main' }} />} size="small" label={t('Waiting on us')} color="warning" variant="outlined" />) : 
                    (item.status === 4) ? (<Chip onClick={(e) => handleStatusMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'success.main' }} />} size="small" label={t('Closed')} color="success" variant="outlined" />) : 
                    (<></>))}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Priority')}
                </Typography>
                <Typography sx={{ ml: 'auto', color: 'text.primary'}}>
                    {(item.priority === 1 ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'success.main' }} />} size="small" label={t('Low')} color="success" variant="outlined" />) : 
                    (item.priority === 2) ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'warning.main' }} />} size="small" label={t('Middle')} color="warning" variant="outlined" />) : 
                    (item.priority === 3) ? (<Chip onClick={(e) => handlePriorityMenuOpen(e, item.guid)} icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: 'error.main' }} />} size="small" label={t('High')} color="error" variant="outlined" />) : 
                    (<></>))}
                </Typography>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Created by')}
                </Typography>
                <Box sx={{ ml: 'auto' }}>
                    <UserChip
                        size="small"
                        image={item.createdBy?.img}
                        name={item.createdBy?.name || t('System')}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Created at')}
                </Typography>
                <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                    {dayjs.unix(item.created_at).fromNow()}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Last update')}
                </Typography>
                <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                    {dayjs.unix(item.updated_at).fromNow()}
                </Typography>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{
                    mb: 1,
                    pl: 0,
                    py: 0.5,
                    position: 'relative'
                }}>
                    <ListItemText
                        primaryTypographyProps={{
                            sx: {
                                textTransform: 'uppercase'
                            }
                        }}
                        primary={t('Tags')}
                    />
                    <ListItemSecondaryAction sx={{ right: 0 }}>
                        <Tooltip title={t('Add a tag')}>
                            <IconButton onClick={handleOpenTagsPopup} size="small">
                                <AddCircle fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction> 
                </Box>
                {(item.tags && item.tags.length > 0) ? (
                    <Box sx={{  }}>
                        {(item.tags.map((i) => (
                            <Chip
                                sx={{
                                mr: 0.5,
                                my: '2px',
                                bgcolor: i.color || '',
                                color: i.color ? invertColor(i.color, true) : 'text.primary',
                                '& .MuiSvgIcon-root': {
                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                    opacity: 0.4,
                                    '&:hover': {
                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                    opacity: 0.55,
                                    }
                                }
                                }}
                                onDelete={() => handleRemoveTag(i.id, item.guid)}
                                label={i.label}
                                size="small"
                            />
                        )))}
                    </Box>
                ) : (
                    <Typography color="text.secondary" fontStyle="italic">
                        {t('No tags')}
                    </Typography>
                )}
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ width: '100%' }}>
                    <Box sx={{
                        mb: 1,
                        pl: 0,
                        py: 0.5,
                        position: 'relative'
                    }}>
                        <ListItemText
                            primaryTypographyProps={{
                                sx: {
                                    textTransform: 'uppercase'
                                }
                            }}
                            primary={t('Related chats')}
                        />
                    </Box>
                    
                    {(threadsLoading === true) ? (
                        <Box sx={{ mb: '2px', display: 'flex', p: 1, bgcolor: 'background.default', borderRadius: 1 }}>
                            <Skeleton variant="circular" sx={{ width: 40, height: 40, mr: 2, my: 'auto' }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Skeleton sx={{ width: '50%', height: 22 }} />
                                <Box>
                                    <Skeleton sx={{ mr: 1, width: '30%', display: 'inline-flex', height: 18 }} />
                                    <Skeleton sx={{ width: '60%', display: 'inline-flex', height: 18 }} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', my: 'auto' }}>
                                <Skeleton sx={{ width: 30 }} />
                                <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
                            </Box>
                        </Box>
                    ) : (
                        (threads.length > 0) ? (
                            (threads.map((i) => (
                                <ThreadMiniCard key={i.guid} thread={i} />
                            )))
                        ) : (
                            <Typography color="text.secondary" fontStyle="italic">
                                {t('No chats')}
                            </Typography>
                        )
                    )}
                </Box>
                <TagsPopup
                    anchorEl={tagsAnchorEl}
                    onClose={() => setTagsAnchorEl(null)}
                    onSelect={handleAddTag}
                />
            </Box>
        </Box>
    );
};

export default TicketSidebar;