import React, { useState, useEffect } from 'react';
import { Box, Button, FormHelperText, Paper, Typography } from '@mui/material';  
import ImageUploading from 'react-images-uploading';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { Add, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const OneImage = (props) => {
  const { image, index, multiple, onImageRemove, aspectRatio, error, t } = props;
  return (
    <Box
      sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          borderWidth: 1,
          borderColor: error ? 'error.main' : '#bdbdbd',
          borderStyle: multiple ? 'dashed' : 'none',
          borderRadius: multiple ? 1 : 0, 
          padding: multiple ? '2px' : 0,
          width: '100%',
          '& img': {
              width: '100%',
              height: 'auto',
              display: 'block',
              margin: 'auto',
              aspectRatio: aspectRatio
          }
      }}
    >
      {(image.data_url && (
        <>
          <img
            src={image.data_url}
            alt="Image"
            style={{ 
              borderRadius: 8
            }}
          />
          {(multiple === true) && (
            <IconButton
              color="error"
              title={t('Remove image')}
              size="small"
              aria-label="delete"
              onClick={() => {
                onImageRemove(index);
              }}
              sx={{
                  position: 'absolute',
                  bottom: 2,
                  right: 2
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </>
      ))}
    </Box>
  );
};

const ImageUploader = ({
  error,
  helperText,
  name,
  value,
  onChange,
  aspectRatio = 1,
  multiple = false,
  currentStep = 0,
  imageHelperText,
  disabled = false
}) => {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  const handleImageChange = (image) => {
    if (image && image[0] && image[0].file.size > 1240000) {
      alert('File is too large');
      setCurrentImage(null);
      onChange(name, null);
    } else {
      setCurrentImage(image);
      onChange(name, image);
    }
  };

  const handleNext = () => {
    setStep((i) => i + 1);
  };

  const handlePrev = () => {
    setStep((i) => i === 0 ? i : i - 1);
  };

  useEffect(() => {
    if (value === null || value === undefined) {
      setCurrentImage(null);
    } else if (Array.isArray(value)) {
      const newValue = value.map((i) => ((typeof i === 'object') ? i : {
        data_url: i,
        file: {}
      }));
      setCurrentImage(newValue);
    } else if (typeof value === 'object') {
      setCurrentImage(value);
    } else {
      setCurrentImage([{
        data_url: value,
        file: {}
      }]);
    }
  }, [value]);

  useEffect(() => {
    if (currentImage === null || currentImage.length === 0) {
      setStep(0);
    }
  }, [currentImage]);

  return (
    <ImageUploading
      value={currentImage}
      onChange={handleImageChange}
      dataURLKey="data_url"
      multiple={multiple}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemove,
        onImageRemoveAll,
        isDragging,
        dragProps,
      }) => (
        <>
          <Box sx={{
              borderWidth: multiple ? 0 : 1,
              borderColor: error ? 'error.main' : '#bdbdbd',
              borderStyle: multiple ? 'none' : 'dashed',
              borderRadius: multiple ? 0 : 1, 
              padding: multiple ? 0 : '2px',
              textAlign: 'center',
              position: 'relative',
              display: 'flex'
          }}>
            {(currentImage?.length > 0 && multiple) && (
              <>
                <IconButton
                  disabled={step === 0}
                  sx={{
                    margin: 'auto',
                    position: 'absolute',
                    left: -14,
                    borderColor: 'divider',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    zIndex: 1,
                    display: step === 0 ? 'none' : 'inline-flex',
                    top: '50%',
                    width: 28,
                    height: 28,
                    marginTop: '-14px',
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.default',
                    }
                  }} size="small" onClick={handlePrev}>
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  disabled={step === (currentImage.length)}
                  sx={{
                    position: 'absolute',
                    display: step === (currentImage.length) ? 'none' : 'inline-flex',
                    right: -14,
                    borderColor: 'divider',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    zIndex: 1,
                    top: '50%',
                    width: 28,
                    height: 28,
                    marginTop: '-14px',
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.default',
                    }
                  }} size="small" onClick={handleNext}>
                  {step === (currentImage.length - 1) ? <Add /> : <KeyboardArrowRight />}
                </IconButton>
              </>
            )}
            
            {(!currentImage && multiple === false) && (
              <>
                <Button
                    title={t('Upload image')}
                    disableElevation
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      aspectRatio: aspectRatio || 'auto',
                    }}
                >
                    <CloudUploadIcon sx={{ width: 42, height: 42 }} />
                    <Typography sx={{ fontSize: '0.75rem' }}>
                      {t('Upload image')}
                    </Typography>
                    <Typography sx={{ fontSize: '0.7rem', mt: 1 }} color="text.secondary">
                        {imageHelperText}
                    </Typography>
                </Button>
              </>
            )}
            {(currentImage && multiple === false) && (
              <IconButton
                color="error"
                title={t('Remove image')}
                size="small"
                disabled={disabled}
                aria-label="delete"
                onClick={() => {
                  onImageRemoveAll();
                  handleImageChange(null);
                }}
                sx={{
                    position: 'absolute',
                    bottom: 2,
                    right: 2,
                    zIndex: 1
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
            {(multiple === true && imageList.length > 0) && (
              <Paper sx={{
                position: 'absolute',
                right: 5,
                top: 5,
                zIndex: 2,
                boxShadow: '',
                borderRadius: 0.5,
                py: 0,
                px: 0.5,
                opacity: 0.8,
              }}>
                <Typography variant="body2">
                  {Math.min(step + 1, imageList.length)}/{imageList.length}
                </Typography>
              </Paper>
            )}
            {(multiple === true) ? (
              <SwipeableViews style={{ width: '100%' }} index={step}>
                {imageList.map((image, index) => (
                  <OneImage aspectRatio={aspectRatio} key={index} error={error} t={t} onImageRemove={onImageRemove} image={image} index={index} multiple={multiple} />
                ))}
                <Box
                  sx={{
                      height: '100%',
                      position: 'relative',
                      display: 'flex',
                      borderWidth: 1,
                      borderColor: error ? 'error.main' : '#bdbdbd',
                      borderStyle: 'dashed',
                      borderRadius: 1, 
                      padding: '2px',
                  }}
                >
                  <Button
                      title={t('Upload image')}
                      disableElevation
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        aspectRatio: aspectRatio || 'auto',
                      }}
                  >
                      <CloudUploadIcon sx={{ width: 42, height: 42 }} />
                      <Typography sx={{ fontSize: '0.75rem' }}>
                        {t('Upload image')}
                      </Typography>
                      <Typography sx={{ fontSize: '0.7rem', mt: 1 }} color="text.secondary">
                          {imageHelperText}
                      </Typography>
                  </Button>
                </Box>
              </SwipeableViews>
            ) : (
              <>
                {imageList.map((image, index) => (
                  <OneImage key={index} error={error} t={t} onImageRemove={onImageRemove} image={image} index={index} multiple={multiple} />
                ))}
              </>
            )}
          </Box>
          {(!!helperText) && (
            <FormHelperText error={error}>
              {helperText}
            </FormHelperText>
          )}
        </>
      )}
    </ImageUploading>
  );
};

export default ImageUploader;
