import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SaveButton from 'src/components/app/SaveButton';
import $api from '../http';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = (props) => {
  const { t } = useTranslation();
  const {
    title,
    content,
    actionUrl,
    data,
    open,
    onSubmit,
    onClose,
    onError = () => {},
    formId,
    fieldId,
    submitButtonLabel = 'remove',
    deleteButtonIcon = null,
    method = 'post'
  } = props;
  const [isLoading, setIsLoading] = useState();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const finalData = data || {
      formId,
      id: fieldId
    };
    $api.request({
      method: method,
      url: actionUrl,
      data: finalData
    })
      .catch((err) => {
        setIsLoading(false);
        onError(err);
        return null;
      })
      .then((response) => {
        if (response) {
          setIsLoading(false);
          onSubmit(response.data);
          handleClose();
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ borderBottom: '0 none' }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div" id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ borderTop: '0 none' }}>
        <SaveButton
          onClick={handleSubmit}
          color="error"
          variant="contained"
          disableElevation
          startIcon={deleteButtonIcon ? deleteButtonIcon : <Delete />}
          autoFocus
          loading={isLoading}
          label={submitButtonLabel}
        />
        <Button variant="outlined" onClick={handleClose} color="primary">
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
