import { DarkMode, Flare, LightMode, Logout, Settings, Workspaces } from "@mui/icons-material";
import { Avatar, AvatarGroup, Badge, Box, Button, CircularProgress, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useToken from "../app/useToken";
import useWidget from "../app/useWidget";
import { useColorModeContext } from "../context/ColorModeContextProvider";
import { useAppConext } from "../context/AppContext";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import WorkspaceSwitcherDialog from "../WorkspaceSwitcherDialog";

const UserMenu = (props) => {
    const { onChangeStatus, chatWebSocket } = props;
    const [menuElAnchor, setMenuElAnchor] = useState(null);
    const { t } = useTranslation();
    const { getToken, logout } = useToken();
    const widget = useWidget();
    const [currentStatus, setCurrentStatus] = useState(1);
    const { toggleColorMode, currentMode } = useColorModeContext();
    const { loadBrandInfo, user, workspace } = useAppConext();
    const [openWorkspaceDialog, setOpenWorkspaceDialog] = useState(false);
    const navigate = useNavigate();

    const handleOpenMenu = (e) => {
        setMenuElAnchor(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuElAnchor(null);
    };

    const handleChangeStatus = async(newStatus) => {
        setCurrentStatus(newStatus);
        localStorage.setItem('online-status', newStatus);
        chatWebSocket.sendJson({
          action: 'updateOnlineStatus',
          status: newStatus,
          widget: localStorage.getItem('widgetUID'),
          auth: chatWebSocket.getAuth()
        });
    };

    const chatListener = (socketData) => {
      if (socketData.action === 'auth' || socketData.action === 'updateOnlineStatus') {
        if (socketData.auth.workspace === chatWebSocket.getWorkspace()) {
          setCurrentStatus(socketData.onlineStatus);
        }
      }
    };

    const widgetListener = async(data) => {
      chatWebSocket.sendJson({
        action: 'updateOnlineStatus',
        widget: data,
        auth: chatWebSocket.getAuth()
      });
    };

    useEffect(() => {
      setCurrentStatus(parseInt(localStorage.getItem('online-status')));
      chatWebSocket.subscribe(chatListener);
      widget.subscribe(widgetListener);
      return () => {
        chatWebSocket.unsubscribe(chatListener);
        widget.unsubscribe(widgetListener);
      }
    }, []);

    const handleLogout = () => {
        handleCloseMenu();
        logout().then(() => {
          loadBrandInfo();
          navigate('/login', { replace: true });
        });
    };

    const getStatusColor = () => {
        return currentStatus === 0 ? 'error.light' :
            currentStatus === 1 ? 'warning.light' : 
            currentStatus === 2 ? 'success.light' :
            '';
    };

    const handleWorkspaceDialogOpen = () => {
        handleCloseMenu();
        setOpenWorkspaceDialog(true);
    };

    const handleWorkspaceDialogClose = () => {
        setOpenWorkspaceDialog(false);
    };

    if (user === null) {
        return (
            <Box sx={{ height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={24} sx={{ color: 'background.paper' }} />
            </Box>
        );
    }

    return (
        <>
            <WorkspaceSwitcherDialog chatWebSocket={chatWebSocket} open={openWorkspaceDialog} onClose={handleWorkspaceDialogClose} />
            <Box sx={{ mr: '-20px' }}>
                <IconButton onClick={handleWorkspaceDialogOpen} sx={{ ml: 1, p: 0.5, position: 'relative' }}>
                    <Avatar src={workspace?.image} sx={{
                        width: 32,
                        height: 32,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'background.paper'
                    }}>
                        <Workspaces />
                    </Avatar>
                </IconButton>
                <IconButton onClick={handleOpenMenu} sx={{ left: -20, p: 0.5, position: 'relative' }}>
                    <Badge
                        variant="dot"
                        invisible={false}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                bgcolor: getStatusColor(),
                                bottom: 4,
                                right: 4,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: 'background.paper'
                            }
                        }}
                    >
                        <Avatar src={user.image} sx={{
                            width: 32,
                            height: 32,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'background.paper'
                        }} />
                    </Badge>
                </IconButton>
            </Box>
            <Menu
                anchorEl={menuElAnchor}
                onClose={handleCloseMenu}
                open={!!menuElAnchor}
                sx={{
                    
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: 280,
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem component={NavLink} to={'/app/account'} onClick={(e) => {handleCloseMenu(); e.stopPropagation()}}>
                    <ListItemIcon sx={{ minWidth: '46px !important' }}>
                        <Avatar sx={{
                            width: '40px !important',
                            height: '40px !important'
                        }} src={user.image} />
                    </ListItemIcon>
                    <ListItemText
                        primary={user.name}
                        secondary={user.email}
                    />
                </MenuItem>
                <Box sx={{ px: 2, pt: 0.5, pb: 1 }}>
                    <Button sx={{ borderRadius: 5 }} startIcon={<Workspaces />} fullWidth variant="outlined" size="small" onClick={handleWorkspaceDialogOpen}>
                        {t('Switch workspace')}
                    </Button>
                </Box>
                <Divider />
                <MenuItem selected={currentStatus === 0} onClick={() => { handleChangeStatus(0); handleCloseMenu()} }>
                    <ListItemIcon>
                        <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: 12, height: 12, bgcolor: 'error.light', borderRadius: '50%', border: '1px solid #FFF' }} />
                        </Box>
                    </ListItemIcon>
                    <ListItemText primary={t('Offline')} />
                </MenuItem>
                <MenuItem selected={currentStatus === 1} onClick={() => { handleChangeStatus(1); handleCloseMenu()} }>
                    <ListItemIcon>
                        <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: 12, height: 12, bgcolor: 'warning.light', borderRadius: '50%', border: '1px solid #FFF' }} />
                        </Box>
                    </ListItemIcon>
                    <ListItemText primary={t('Default')} />
                </MenuItem>
                <MenuItem selected={currentStatus === 2} onClick={() => { handleChangeStatus(2); handleCloseMenu()} }>
                    <ListItemIcon>
                        <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: 12, height: 12, bgcolor: 'success.light', borderRadius: '50%', border: '1px solid #FFF' }} />
                        </Box>
                    </ListItemIcon>
                    <ListItemText primary={t('Always online')} />
                </MenuItem>
                <Divider />
                <MenuItem selected={currentMode === 'light'} onClick={() => { toggleColorMode('light'); handleCloseMenu()} }>
                    <ListItemIcon>
                        <LightMode />
                    </ListItemIcon>
                    <ListItemText primary={t('Light mode')} />
                    </MenuItem>
                    <MenuItem selected={currentMode === 'dark'} onClick={() => { toggleColorMode('dark'); handleCloseMenu()} }>
                    <ListItemIcon>
                        <DarkMode />
                    </ListItemIcon>
                    <ListItemText primary={t('Dark mode')} />
                    </MenuItem>
                    <MenuItem selected={currentMode === 'auto'} onClick={() => { toggleColorMode('auto'); handleCloseMenu()} }>
                    <ListItemIcon>
                        <Flare />
                    </ListItemIcon>
                    <ListItemText primary={t('System')} />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('Logout')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;