import { Suspense, lazy } from "react";
import LoadingProgress from "src/components/app/LoadingProgress";

const TicketPage = lazy(() => import('./public/ticket/TicketPage'));

const PublicTicketPage = () => {
    return (
        <Suspense fallback={<LoadingProgress open />}>
            <TicketPage />
        </Suspense>
    );
};

export default PublicTicketPage;