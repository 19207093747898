import { Box } from '@mui/material';
import PageMeta from 'src/components/app/PageMeta';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
import { useTranslation } from 'react-i18next';

const OnlineVisitors = lazy(() => import('../../src/components/visitors/'));

const Visitors = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageMeta title={t('Online visitors')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <OnlineVisitors />
                </Suspense>
            </Box>
        </>
    );
};

export default Visitors;