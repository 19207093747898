import { Business, PlaylistAdd, RocketLaunch } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import RestrictedFieldWrapper from "src/components/app/RestrictedFieldWrapper";
import { useAppConext } from "src/components/context/AppContext";
import OrganizationDialog from "src/components/organizations/OrganizationDialog";
import OrganizationToolbar from "src/components/organizations/OrganizationToolbar";
import OrganizationsTable from "src/components/organizations/OrganizationsTable";

const CrmList = lazy(() => import('../../../src/components/crmList'));

const OrganizationsPage = () => {
    const { t } = useTranslation();
    const { getFeatureValue, user } = useAppConext();

    return (
        <>
            <PageMeta title={t('Organizations')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <RestrictedFieldWrapper
                    fullWidth
                    containerSx={{ display: 'flex' }}
                    blur
                    badgeType={false}
                    restricted={!getFeatureValue('organizations.enabled')}
                    customContent={(
                        <Box>
                            <Business sx={{ width: 40, height: 40, color: 'error.main' }} />
                            <Typography color="error" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {t('Organizations section is locked')}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {t('Ulock this section to be able to manage organizations')}
                            </Typography>
                            <Button sx={{ mt: 1 }} variant="outlined" startIcon={<RocketLaunch />} component={NavLink} to="/app/upgrade">
                                {t('Upgrade now')} 
                            </Button>
                        </Box>
                    )}
                >
                    <Suspense fallback={<LoadingProgress open />}>
                        <CrmList
                            expand="contacts,tags"
                            indexUrl="/organization"
                            deleteUrl="/organization/delete"
                            DialogComponent={OrganizationDialog}
                            TableComponent={OrganizationsTable}
                            ToolbarComponent={OrganizationToolbar}
                            accessGranted={user?.permissions?.organizations}
                            noContentOptions={{
                                icon: (<Business sx={{ width: 40, height: 40, color: 'text.secondary' }} />),
                                title: t('No organizations yet'),
                                content: t('There are no organizations in this workspace'),
                                buttonTitle: t('Create an organization'),
                                buttonIcon: (<PlaylistAdd />)
                            }}
                        />
                    </Suspense>
                </RestrictedFieldWrapper>
            </Box>
        </>
    );
};

export default OrganizationsPage;