import { Dialog, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

import TaskDialogContent from "./TaskDialogContent";

const TaskDialog = (props) => {
    const { open, onClose, contact, currentItem, currentThread, onSubmit } = props;

    const { t } = useTranslation();

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>
                {currentItem.guid ? t('Edit task') : t('New task')}
            </DialogTitle>
            <TaskDialogContent
                open={open}
                onClose={onClose}
                contact={contact}
                currentItem={currentItem}
                currentThread={currentThread}
                onSubmit={onSubmit}
            />
        </Dialog>
    );
};

export default TaskDialog;