import { useState, useEffect } from 'react';
import TokenProvider from 'src/components/app/TokenProvider';
import useWidget from 'src/components/app/useWidget';
import useTariff from 'src/components/app/useTariff';
import useGoogleLogout from 'src/components/login/googleLogin/useGoogleLogout';
import axios from 'axios';
import useRole from './useRole';
import { v4 as uuidv4 } from 'uuid';
import useWorkspace from './useWorkspace';

// const clientId = '671291669137-f9vng7qnjgcgeh0prt181a5qtvgs201l.apps.googleusercontent.com';
const clientId = '671291669137-jkmmh97bir55l6o6oemtjuq172gga7ve.apps.googleusercontent.com';

const useToken = () => {
  const widget = useWidget();
  const workspace = useWorkspace();
  const { setRole } = useRole();
  const { setTariff, setIsAgency } = useTariff();
  const [sessionExpired, setSessionExpired] = useState(false);

  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const getDeviceID = () => {
    let currentID = localStorage.getItem('deviceID');
    if (currentID !== null && currentID !== undefined && currentID !== '') {
        return currentID;
    }
    currentID = uuidv4();
    localStorage.setItem('deviceID', currentID);
    return currentID;
};

  async function updateToken(token) {
    if (token.refreshToken) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}v1/auth/update-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            refreshToken: token.refreshToken
          })
        });
        if (response.status === 200) {
          setSessionExpired(false);
          return response.json();
        }
        console.log('Update token failure');
        setSessionExpired(true);
        return null;
      } catch (err) {
        console.log('Update token failure', err);
      }
    }
    return null;
  }

  const { setToken, getToken, isLoggedIn } = TokenProvider({
    localStorageKey: 'accessToken',
    accessTokenKey: 'token',
    accessTokenExpireKey: null
  });

  const internalLogout = async () => {
    const authToken = await getToken();
    if (authToken) {
      instance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    }
    console.log('logout');
    return instance.post('/auth/logout', {
      deviceID: getDeviceID()
    })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        widget.cleanUpListeners();
        widget.setWidget(null);
        workspace.cleanUpListeners();
        workspace.setWorkspace(null);
        setToken(null);
        setTariff(null);
        setRole(null);
        setIsAgency(false);
      });
  };

  const { signOut } = useGoogleLogout({
    onLogoutSuccess: () => internalLogout(),
    onFailure: () => {
      console.log('Google logout failure');
    },
    clientId
  });

  const logout = () => {
    signOut();
    if (window.FB) {
      window.FB.getLoginStatus(() => internalLogout());
    }
    if (window.anychat) {
      window.anychat.resetUserData();
    }
    return internalLogout();
  };

  useEffect(() => {
    if (sessionExpired) {
      logout().then(() => {
        console.log('Logout');
      });
    }
  }, [signOut]);

  const login = (newTokens) => {
    setToken(newTokens);
  };

  return {
    isLoggedIn,
    getToken,
    login,
    logout
  };
};

export default useToken;
