import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  NavLink
} from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import { RocketLaunch } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const UpgradePlanDialog = (props) => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    requiredPlan,
    requiredFeature,
    content
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent align="center">
        {(content !== undefined && content !== null) ? (
          <>
            {content}
          </>
        ) : (
          <>
            <Typography
              sx={{
                pb: 1
              }}
            >
              {t('This feature is not available in your current tariff plan.')}
            </Typography>
            <Typography
              sx={{
                pb: 1
              }}
            >
              {t('Required plan is')}&nbsp;
              <b>{requiredPlan}</b>
              {(requiredFeature !== null && requiredFeature !== undefined) && (
                <>
                  &nbsp;+&nbsp;<b>{requiredFeature}</b>
                </>
              )}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('Close')}
        </Button>
        &nbsp;
        <Button
          component={NavLink}
          onClick={handleClose}
          color="primary"
          to="/app/upgrade"
          variant="contained"
          startIcon={<RocketLaunch />}
        >
          {t('Upgrade plan')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpgradePlanDialog.propTypes = {
  requiredPlan: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default UpgradePlanDialog;
