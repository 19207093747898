import {
    Box,
  } from '@mui/material';
  import { lazy, Suspense } from 'react';
  import { useTranslation } from 'react-i18next';
  import LoadingProgress from 'src/components/app/LoadingProgress';
  import PageMeta from 'src/components/app/PageMeta';
  
  const KbSettings = lazy(() => import('../../components/settings/KbSettings'));
  
  const KbSettingsPage = () => {
      const { t } = useTranslation();
      return (
          <>
              <PageMeta title={t('Knowledge base settings')} />
              <Box
                  sx={{
                      backgroundColor: 'background.default',
                      minHeight: '100%',
                      px: {
                          xs: 1,
                          md: 2
                      },
                      py: {
                          xs: 1,
                          md: 2
                      }
                  }}
              >
                  <Suspense fallback={<LoadingProgress open />}>
                    <KbSettings configUrl="/config/get" saveUrl="/config/save" modelName="KbConfig" />
                  </Suspense>
              </Box>
          </>
      );
  };
  
  export default KbSettingsPage;