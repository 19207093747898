import { Avatar, Box, Button, Chip, Divider, FormGroup, IconButton, ListItemAvatar, ListItemText, MenuItem, Popover, Typography } from "@mui/material";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import { Add, Close } from "@mui/icons-material";
import TagsPopup from "../TagsPopup";
import { invertColor } from "../app/Tools";

const OrganizationBulkEditDialog = (props) => {
    const { anchorEl, onClose, onSubmit } = props;
    const [config, setConfig] = useState({});
    const [tags, setTags] = useState([]);
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);

    const { t } = useTranslation();

    const handleSubmit = () => {
        onSubmit({
            ...config,
            tags: tags.map((i) => i.id)
        });
        handleClose();
    };

    const handleClose = () => {
        onClose();
    };

    const statusOptions = [
        {
            id: 1,
            label: t('Not started'),
            color: grey['400']
        },
        {
            id: 2,
            label: t('Deferred'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('Re-scheduled'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Ongoing'),
            color: 'error.main'
        },
        {
            id: 5,
            label: t('Complete'),
            color: 'success.main'
        },
        {
            id: 6,
            label: t('Canceled'),
            color: 'secondary.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    const getDurationList = () => {
        const start = 300;
        const res = [];
        let i = start;
        let step = 300;
        while (i < 86400) {
            res.push(i);
            if (i < 900) {
                step = 300;
            } else {
                step = 900;
            }
            i = i + step;
        }
        return res;
    };

    const handleFormFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (anchorEl !== null) {
            setConfig({});
            setTags([]);
        }
    }, [anchorEl]);

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    const isFilterEmpty = () => {
        let empty = false;
        if (Object.keys(config).length === 0 && tags.length === 0) {
            empty = true;
        }
        const x = Object.keys(config).filter((i) => config[i] !== null);
        empty = x.length === 0;
        return empty && tags.length === 0;
    };

    return (
        <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
            <Box sx={{ p: 1, position: 'relative' }}>
                <Typography color="text.primary">
                    {t('Mass update')}
                </Typography>
                <IconButton sx={{ position: 'absolute', right: 8, top: 3 }} onClick={onClose} size="small">
                    <Close />
                </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 1, minWidth: 320 }}>
                <FormGroup>
                    <AsyncAutocomplete
                        url="/live-chat-agent"
                        idAttr="guid"
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(v) => {
                            if (v !== null) {
                                setConfig((old) => ({
                                    ...old,
                                    agent: v?.guid || null
                                }));
                            } else {
                                setConfig((old) => {
                                    const {agent, ...rest} = old;
                                    return rest;
                                });
                            }
                        }}
                        textFieldProps={{
                            label: t('Assigned agent'),
                            placeholder: t('Search by name or email')
                        }}
                        renderOption={(p, option) => (
                            <MenuItem {...p} key={option.guid}>
                                <ListItemAvatar>
                                    <Avatar src={option.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={option.name || t('Agent')}
                                    secondary={option.email || t('Email not set')}
                                />
                            </MenuItem>
                        )}
                    />
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                        {(tags.length > 0 ) ? (
                            <Box sx={{ my: 'auto' }}>
                                {(tags.map((i) => (
                                    <Chip
                                        sx={{
                                        mr: 0.5,
                                        my: '2px',
                                        bgcolor: i.color || '',
                                        color: i.color ? invertColor(i.color, true) : 'text.primary',
                                        '& .MuiSvgIcon-root': {
                                            color: i.color ? invertColor(i.color, true) : '#000000',
                                            opacity: 0.4,
                                            '&:hover': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.55,
                                            }
                                        }
                                        }}
                                        onDelete={() => handleRemoveTag(i.id)}
                                        label={i.label}
                                        size="small"
                                    />
                                )))}
                            </Box>
                        ) : (
                            <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                {t('No tags')}
                            </Typography>
                        )}
                        <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                            <Add />
                        </IconButton>
                    </Box>
                </FormGroup>
                <TagsPopup
                    anchorEl={tagsAnchorEl}
                    onClose={() => setTagsAnchorEl(null)}
                    onSelect={handleAddTag}
                />
            </Box>
            <Divider />
            <Box sx={{ p: 1, textAlign: 'right' }}>
                <Button sx={{ mr: 0.5 }} onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button disabled={isFilterEmpty()} onClick={handleSubmit} color="primary" variant="contained" disableElevation>
                    {t('Apply')}
                </Button>
            </Box>
        </Popover>
    );
};

export default OrganizationBulkEditDialog;