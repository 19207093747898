import { LoadingButton } from "@mui/lab";
import { Alert, Avatar, Box, Button, Chip, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, IconButton, ListItemAvatar, ListItemText, MenuItem, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import { useAppConext } from "../context/AppContext";
import TagsPopup from "../TagsPopup";
import { Add, ChatBubble, Check, Close } from "@mui/icons-material";
import { invertColor } from "../app/Tools";

const TicketDialogContent = (props) => {
    const { open, onClose, contact, currentItem, currentThread, onSubmit } = props;
    const [config, setConfig] = useState(currentItem);
    const [contacts, setContacts] = useState([]);
    const [assignedAgent, setAssignedAgent] = useState({});
    const [errors, setErrors] = useState({});
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);
    const [tags, setTags] = useState([]);
    const [includeThread, setIncludeThread] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const { user } = useAppConext();

    const { t } = useTranslation();

    const handleSubmit = () => {
        setIsSubmit(true);
        setErrors({});
        const data = {
            ...config,
            contacts: contacts.map((i) => i.guid),
            assigned_to: assignedAgent?.guid || null,
            tags: tags.map((i) => i.id)
        };
        $api.post( config.guid ? '/ticket/update' : '/ticket/create', {
            ...data,
            thread: (includeThread && currentThread !== undefined) ? currentThread.guid : undefined
        })
            .catch((err) => {
                if (err?.response?.status === 422) {
                    setErrors(err.response.data);
                }
            })
            .then((res) => {
                if (res) {
                    onSubmit(res.data);
                }
            })
            .finally(() => {
                setIsSubmit(false);
            })
    };

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    const handleAgentChange = (v) => {
        setConfig((old) => ({
            ...old,
            assigned_to: v?.guid
        }))
    }

    const handleFormFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
        }));
    };

    useEffect(() => {
        if (open) {
            if (currentItem.guid) {
                setContacts(currentItem.contacts);
                setAssignedAgent(currentItem.assignedTo);
            } else if (contact) {
                setContacts([contact]);
                setAssignedAgent(user);
            } else {
                setContacts([]);
                setAssignedAgent(user);
            }
            setConfig(currentItem);
            setTags(currentItem.tags || []);
        } else {
            setErrors({});
        }
    }, [open, contact, currentItem, user]);

    const statusOptions = [
        {
            id: 1,
            label: t('New'),
            color: 'error.main'
        },
        {
            id: 2,
            label: t('Waiting on contact'),
            color: 'info.main'
        },
        {
            id: 3,
            label: t('Waiting on us'),
            color: 'warning.main'
        },
        {
            id: 4,
            label: t('Closed'),
            color: 'success.main'
        }
    ];

    const priorityOptions = [
        {
            id: 1,
            label: t('Low'),
            color: 'success.main'
        },
        {
            id: 2,
            label: t('Middle'),
            color: 'warning.main'
        },
        {
            id: 3,
            label: t('High'),
            color: 'error.main'
        },
    ];

    return (
        <>
            <DialogContent>
                <Box>
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/contact"
                            idAttr="guid"
                            multiple
                            value={contacts}
                            onChange={(val) => {
                                setContacts(val);
                            }}
                            textFieldProps={{
                                label: t('Contacts'),
                                placeholder: t('Search by name, email or phone number')
                            }}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <AsyncAutocomplete
                            url="/live-chat-agent"
                            idAttr="guid"
                            getOptionLabel={(option) => option.name || ''}
                            initialValue={user}
                            value={assignedAgent}
                            onChange={(v) => {
                                setAssignedAgent(v);
                            }}
                            textFieldProps={{
                                label: t('Assigned agent'),
                                placeholder: t('Search by name or email')
                            }}
                            renderOption={(p, option) => (
                                <MenuItem {...p} key={option.guid}>
                                    <ListItemAvatar>
                                        <Avatar src={option.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={option.name || t('Agent')}
                                        secondary={option.email || t('Email not set')}
                                    />
                                </MenuItem>
                            )}
                        />
                    </FormGroup>
                    {(currentThread !== undefined) && (
                        <FormGroup sx={{ mt: 2 }}>
                            <Box sx={{
                                bgcolor: 'background.default',
                                opacity: includeThread ? 1 : 0.6,
                                borderColor: includeThread ? 'success.light' : 'background.default',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                display: 'flex',
                                p: 1,
                                borderRadius: 1
                            }}>
                                <Avatar sx={{ height: 40, width: 40, mr: 1, my: 'auto' }}>
                                    <ChatBubble />
                                </Avatar>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography>
                                        {t('Attach current chat')}
                                    </Typography>
                                    <Typography color="text.secondary" variant="body2">
                                        {t('Attach current chat to this ticket')}
                                    </Typography>
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                    <IconButton onClick={() => setIncludeThread((i) => !i)} size="small">
                                        {includeThread ? <Close /> : <Check />}
                                    </IconButton>
                                </Box>
                            </Box>
                        </FormGroup>
                    )}
                    <FormGroup sx={{ mt: 2 }}>
                        <FormControlLabel
                            control={<Switch checked={!!config.access} onChange={handleFormFieldChange} name="access" />}
                            label={t('Enable public access')}
                        />
                        {(config.access === 1) ? (
                            <Alert sx={{ '& .MuiAlert-icon': { my: 'auto' } }} severity="warning">
                                <Typography variant="body2">
                                    {t('Assigned contacts will be able to access this ticket with special page, reply to messages, attach files, etc...')}
                                </Typography>
                                <Typography variant="body2">
                                    {t('They also will receive ticket-related emails (such as status change, new message, etc...).')}
                                </Typography>
                            </Alert>
                        ) : (
                            <Alert sx={{ '& .MuiAlert-icon': { my: 'auto' } }} severity="info">
                                <Typography variant="body2">
                                    {t('Assigned contacts will not access this ticket in any way.')}
                                </Typography>
                                <Typography variant="body2">
                                    {t("They also won't receive any ticket-related emails.")}
                                </Typography>
                            </Alert>
                        )}
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="subject"
                            label={t('Title')}
                            required
                            value={config.subject || ''}
                            error={!!errors?.subject}
                            helperText={errors?.subject}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <TextField
                            name="description"
                            label={t('Description')}
                            multiline
                            value={config.description || ''}
                            error={!!errors?.description}
                            helperText={errors?.description}
                            rows={8}
                            onChange={handleFormFieldChange}
                        />
                    </FormGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="status"
                                    label={t('Status')}
                                    value={config.status || ''}
                                    error={!!errors?.status}
                                    helperText={errors?.status}
                                    select
                                    onChange={handleFormFieldChange}
                                >
                                    {(statusOptions.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ width: 8, height: 8, bgcolor: i.color || '#DDDDDD', borderRadius: 5, mr: 1, my: 'auto' }} />
                                                {i.label}
                                            </Box>
                                        </MenuItem>
                                    )))}
                                </TextField>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup sx={{ mt: 2 }}>
                                <TextField
                                    name="priority"
                                    label={t('Priority')}
                                    value={config.priority || ''}
                                    error={!!errors?.priority}
                                    helperText={errors?.priority}
                                    select
                                    onChange={handleFormFieldChange}
                                >
                                    {(priorityOptions.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ width: 8, height: 8, bgcolor: i.color || '#DDDDDD', borderRadius: 5, mr: 1, my: 'auto' }} />
                                                {i.label}
                                            </Box>
                                        </MenuItem>
                                    )))}
                                </TextField>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <FormGroup sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            {(tags.length > 0 ) ? (
                                <Box sx={{ my: 'auto' }}>
                                    {(tags.map((i) => (
                                        <Chip
                                            sx={{
                                            mr: 0.5,
                                            my: '2px',
                                            bgcolor: i.color || '',
                                            color: i.color ? invertColor(i.color, true) : 'text.primary',
                                            '& .MuiSvgIcon-root': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.4,
                                                '&:hover': {
                                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                                    opacity: 0.55,
                                                }
                                            }
                                            }}
                                            onDelete={() => handleRemoveTag(i.id)}
                                            label={i.label}
                                            size="small"
                                        />
                                    )))}
                                </Box>
                            ) : (
                                <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                    {t('No tags')}
                                </Typography>
                            )}
                            <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                                <Add />
                            </IconButton>
                        </Box>
                    </FormGroup>
                    <TagsPopup
                        anchorEl={tagsAnchorEl}
                        onClose={() => setTagsAnchorEl(null)}
                        onSelect={handleAddTag}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <LoadingButton loading={isSubmit} onClick={handleSubmit} variant="contained" disableElevation>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default TicketDialogContent;