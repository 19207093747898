import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";

const WorkspaceInviteProcess = lazy(() => import('../../src/components/WorkspaceInviteProcess'));

const WorkspaceInviteAccept = (props) => {
    const { accept } = props;
    const { t } = useTranslation();
    return (
        <>
            <PageMeta title={t('Accept workspace invite')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <WorkspaceInviteProcess accept={accept} />
                </Suspense>
            </Box>
        </>
    );
};

export default WorkspaceInviteAccept;