import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import './animate.css';

const useStyles = makeStyles({
  root: {
    borderRight: '1px solid',
    animation: 'blink infinite 1.2s',
    paddingRight: 2
  }
});

const TextTyping = ({
  messages,
  loop = true,
  pause = 1000,
  finalPause = 3000,
  typingSpeed = 100,
  deletingSpeed = 30,
  onMessageStart = () => {},
  onMessageComplete = () => {},
  onFinalMessage = () => {},
  onFinalMessageComplete = () => {}
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState('');
  const classes = useStyles();

  let i = null;
  let t = null;
  let pos = 0;

  const deleteString = () => {
    const msg = messages[currentIndex];
    if (pos === -1) {
      clearInterval(i);
      if ((currentIndex) < (messages.length - 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (loop) {
        setCurrentIndex(0);
      }
    } else {
      const m = msg.substr(0, pos);
      setCurrentMessage(m);
    }
  };

  const deleting = () => {
    i = setInterval(() => {
      pos--;
      deleteString(pos);
    }, deletingSpeed);
  };

  const typeString = () => {
    const msg = messages[currentIndex];
    if (pos > msg.length) {
      clearInterval(i);
      onMessageComplete(currentIndex);
      if (currentIndex === messages.length - 1) {
        onFinalMessageComplete();
      }
      t = setTimeout(() => {
        deleting();
      }, (currentIndex === messages.length - 1) ? finalPause : pause);
    } else {
      const m = msg.substr(0, pos);
      setCurrentMessage(m);
    }
  };

  const typing = (index) => {
    setCurrentIndex(index);
    onMessageStart(index);
    if (index === messages.length - 1) {
      onFinalMessage();
    }
    i = setInterval(() => {
      pos++;
      typeString(pos);
    }, typingSpeed);
  };

  useEffect(() => {
    setCurrentIndex(0);
    return () => {
      clearInterval(i);
      clearTimeout(t);
    };
  }, []);

  useEffect(() => {
    typing(currentIndex);
  }, [currentIndex]);

  return (
    <span className={classes.root}>
      { currentMessage }
    </span>
  );
};

TextTyping.propTypes = {
  messages: PropTypes.array,
  loop: PropTypes.bool,
  pause: PropTypes.number,
  finalPause: PropTypes.number,
  typingSpeed: PropTypes.number,
  deletingSpeed: PropTypes.number,
  onFinalMessage: PropTypes.func,
  onFinalMessageComplete: PropTypes.func,
  onMessageStart: PropTypes.func,
  onMessageComplete: PropTypes.func
};

export default TextTyping;
