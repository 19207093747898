import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField
} from '@mui/material';
import SaveButton from 'src/components/app/SaveButton';
import $api from '../http';
import { Send } from '@mui/icons-material';

const typeSelectOptions = [
  {
    value: 'livechat',
    label: 'LiveChat widget integration'
  },
  {
    value: 'service',
    label: 'Service integration'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

const FeatureRequestDialog = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    initConfig
  } = props;
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState('');
  const [config, setConfig] = useState(initConfig);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (initConfig !== null) {
      setConfig(initConfig);
    }
  }, [initConfig]);

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setConfig({ ...config, [event.target.name]: value });
  };

  const handleClose = () => {
    setError({});
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    $api.post('/feature-requests', config)
      .catch((e) => {
        if (e && e.response && e.response.status === 422) {
          const err = {};
          e.response.data.map((i) => {
            err[i.field] = i.message;
            return i;
          });
          setError(err);
        }
        setIsLoading(false);
        return e;
      })
      .then((res) => {
        setIsLoading(false);
        if (res && res.data) {
          setError({});
          onSubmit(res.data);
        }
      });
  };

  if (config === null) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        New feature request
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Fill out the form below to submit new feature.
          </DialogContentText>
          <Grid sx={{ pt: 2 }} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={!!error.type}
                autoFocus
                id="type"
                name="type"
                label="Subject"
                select
                SelectProps={{ native: true }}
                value={config.type}
                onChange={handleChange}
                helperText={error.type}
                fullWidth
                required
              >
                {typeSelectOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            {(config.type === 'other') && (
              <Grid
                item
                xs={12}
              >
                <TextField
                  error={!!error.subject}
                  helperText={error.subject}
                  fullWidth
                  required
                  label="Subject"
                  name="subject"
                  onChange={handleChange}
                  value={config.subject}
                />
              </Grid>
            )}
            {(config.type === 'livechat' || config.type === 'service') && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={!!error.home_url}
                    helperText={error.home_url}
                    fullWidth
                    required
                    label="Home URL"
                    name="home_url"
                    onChange={handleChange}
                    value={config.home_url}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={!!error.register_url}
                    helperText={error.register_url}
                    fullWidth
                    label="Sign up URL"
                    name="register_url"
                    onChange={handleChange}
                    value={config.register_url}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={!!error.api_url}
                    helperText={error.api_url}
                    fullWidth
                    label="API documentation URL"
                    name="api_url"
                    onChange={handleChange}
                    value={config.api_url}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
            >
              <TextField
                error={!!error.message}
                helperText={error.message}
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={6}
                onChange={handleChange}
                value={config.message}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                error={!!error.email}
                helperText={error.email}
                fullWidth
                label="Your email address"
                name="email"
                required
                onChange={handleChange}
                value={config.email}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          &nbsp;
          <SaveButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            autoFocus
            startIcon={<Send />}
            loading={isLoading}
            label="Submit"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

FeatureRequestDialog.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  initConfig: PropTypes.object
};

export default FeatureRequestDialog;
