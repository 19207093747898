import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import { Button, ListItem, Badge, Box, Typography, SvgIcon } from '@mui/material';
import { orange } from '@mui/material/colors';

const NavItem = ({
  href,
  icon: Icon,
  badge,
  title,
  isEnd,
  backgroundColor,
  collapsed,
  disabled,
  restricted = false,
  hide = false,
  ...rest
}) => {
  const location = useLocation();

  const active = href ? !!matchPath({
    path: href,
    end: isEnd,
  }, location.pathname) : false;

  const getButtonComponent = () => {
    if (href === false) {
      return null;
    } else {
      if (href.indexOf('http') === 0) {
        return "a";
      } else {
        return RouterLink;
      }
    }
  };

  if (restricted === true && hide === true) {
    return <></>;
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        position: 'relative',
        overflow: 'hidden'
      }}
      {...rest}
    >
        <Button
          component={getButtonComponent()}
          disabled={disabled || false}
          sx={{
            color: 'text.secondary',
            borderRadius: 1,
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            lineHeight: 1.5,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            mx: 1,
            my: 0.2,
            minWidth: 0,
            
            flexDirection: !collapsed ? 'row' : 'column',
            ...(active && {
              color: 'primary.active',
              bgcolor: 'rgba(32, 101, 209, 0.08)'
            }),
            ...(active && backgroundColor && {
              backgroundColor
            }),
            '& svg': {
              mr: collapsed ? 'auto' : 1,
              ml: collapsed ? 'auto' : 0
            },
            '& .MuiBadge-root': {
              mr: collapsed ? 'auto' : 0,
              ml: collapsed ? 'auto' : 0
            }
          }}
          to={href || ''}
          href={getButtonComponent() === 'a' ? href : null}
          target={getButtonComponent() === 'a' ? '_blank' : '_self'}
        >
          {Icon && (
            <Badge
              badgeContent={badge}
              color="secondary"
              max={99}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                display: collapsed ? 'block' : 'inline-flex',
                position: collapsed ? 'relative' : 'static',
                '& .MuiBadge-badge': {
                  height: 15,
                  minWidth: 15,
                  lineHeight: 15,
                  fontSize: 10,
                  padding: '0 4px',
                  left: collapsed ? '' : 'auto',
                  right: collapsed ? '' : 10,
                  top: collapsed ? '' : 0,
                  bottom: collapsed ? '' : 0,
                  my: collapsed ? '' : 'auto',
                  transform: collapsed ? '' : (badge === 0 || badge === undefined) ? 'scale(0)' : 'scale(1)'
                }
              }}
            >
              <Icon sx={{ fontSize: collapsed ? 28 : 24 }} />
            </Badge>
          )}
          {(title !== null) && (
            (!collapsed) ? (
              <>
                {title}
              </>
            ) : (
              <Box sx={{ fontSize: '0.65rem', textAlign: 'center', fontWeight: 400 }}>
                {title}
              </Box>
            )
          )}
          {(restricted === true) && (
            <SvgIcon viewBox='0 0 24 24' sx={{ color: orange.A700, position: 'absolute', top: collapsed ? 5 : 9, right: 5 }}>
              <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
            </SvgIcon>
          )}
        </Button>
    </ListItem>
  );
};

export default NavItem;
