import { PersonOff } from "@mui/icons-material";
import { Avatar, Tooltip } from "@mui/material";

const UserAvatar = (props) => {
    const { img = '', guid, name, tooltip, deleted, ...rest } = props;
    
    const stringToColor = (string) => {
        let hash = 0;
        let i;
        let color = '#';

        try{
            /* eslint-disable no-bitwise */
            for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }
        
            for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.substr(-2);
            }
            /* eslint-enable no-bitwise */
        } catch (e) {
            return '#DDDDDD';
        }
      
        return color;
    }
      
    const stringAvatar = (guid, name) => {
        let children = '';
        try {
            children = name.split(' ')[0][0];
        } catch(err) {}
        
        return {
          sx: {
            bgcolor: stringToColor(guid),
          },
          children: children
        };
    }

    return tooltip ? (
        <Tooltip title={name}>
            {(deleted === true) ? (
                <Avatar alt={name} {...rest}>
                    <PersonOff />
                </Avatar>
            ) : (
                <Avatar alt={name} src={img} {...stringAvatar(guid, name)} {...rest} />
            )}
        </Tooltip>
    ) : (
        (deleted === true) ? (
            <Avatar alt={name} {...rest}>
                <PersonOff />
            </Avatar>
        ) : (
            <Avatar alt={name} src={img} {...stringAvatar(guid, name)} {...rest} />
        )
    )
};

export default UserAvatar;