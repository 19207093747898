import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { useTranslation } from "react-i18next";
dayjs.extend(relativeTime);
dayjs.extend(duration);

const useDayjs = () => {
    const { i18n } = useTranslation();
    const langMap = {
        ua: 'uk',
        vn: 'vi',
        jp: 'ja',
        cn: 'zh',
        'ru-RU': 'ru'
    };
    
    const lang = langMap[i18n.language] ?? i18n.language;
    try {
        require(`dayjs/locale/${lang}.js`);
        dayjs.locale(lang);
    } catch {}

    return {
        dayjs
    };
};

export default useDayjs;