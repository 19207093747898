import { Add, Close } from "@mui/icons-material";
import { Avatar, Box, Button, Checkbox, Chip, Divider, FormControl, FormGroup, IconButton, InputLabel, ListItemAvatar, ListItemText, MenuItem, OutlinedInput, Popover, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncAutocomplete from "../app/AsyncAutocomplete";
import TagsPopup from "../TagsPopup";
import { invertColor } from "../app/Tools";
import ChatIntegrationIcon from "../chat/ChatIntegrationIcon";

const ChannelChip = (props) => (
    <Chip
        avatar={<Avatar sx={{ bgcolor: 'transparent' }}><ChatIntegrationIcon cn={props.className} size={16} /></Avatar>}
        label={props.title}
        sx={{ mr: 0.5 }}
        size="small"
    />
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const sources = [
    {
        name: 'manual',
        title: 'Manually created',
        className: 'AnyChatLiveChat'
    },
    {
        name: 'email',
        title: 'Email',
        className: 'AnyChatEmail'
    },
    {
        name: 'form',
        title: 'Form',
        className: 'AnyChatForm'
    },
    {
        name: 'livechat',
        title: 'LiveChat',
        className: 'AnyChatLiveChat'
    },
    {
        name: [
            'whatsapp',
            'whatsappqr'
        ],
        title: 'WhatsApp',
        className: 'LiveChatWhatsApp'
    },
    {
        name: 'messenger',
        title: 'Messenger',
        className: 'LiveChatMessenger'
    },
    {
        name: 'instagram',
        title: 'Instagram',
        className: 'LiveChatInstagram'
    },
    {
        name: 'googlebc',
        title: 'Google Business Chat',
        className: 'LiveChatGoogleBC'
    },
    {
        name: 'telegram',
        title: 'Telegram',
        className: 'LiveChatTelegram'
    },
    {
        name: 'viber',
        title: 'Viber',
        className: 'LiveChatViber'
    },
    {
        name: 'line',
        title: 'Line',
        className: 'LiveChatLine'
    },
];

const ContactsFilter = (props) => {
    const { anchorEl, onClose, onSubmit } = props;
    const [config, setConfig] = useState({});
    const [agent, setAgent] = useState(null);
    const [org, setOrg] = useState(null);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);

    const { t } = useTranslation();

    const handleReset = () => {
        setConfig({});
        setAgent(null);
        setTags([]);
        setSelectedChannels([]);
    };

    const handleSubmit = () => {
        let data = null;
        if (tags.length > 0) {
            data = {
                ...config,
                tag_id: tags.map((i) => i.id),
            };
        } else {
            data = {
                ...config,
            };
        }
        if (selectedChannels.length > 0) {
            data.source = selectedChannels;
        }
        onSubmit(data);
        onClose();
    };

    useEffect(() => {
        setConfig((old) => {
            if (agent === null) {
                const { assigned_to, ...rest } = old;
                return rest;
            } else {
                return {
                    ...old,
                    assigned_to: agent.guid
                }
            }
        });
    }, [agent]);

    useEffect(() => {
        setConfig((old) => {
            if (org === null) {
                const { organization_guid, ...rest } = old;
                return rest;
            } else {
                return {
                    ...old,
                    organization_guid: org.guid
                }
            }
        });
    }, [org]);

    const handleFieldChange = (e) => {
        const { value, name, checked, type } = e.target;
        setConfig((old) => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleAddTag = (item) => {
        setTags((old) => {
            const e = old.filter((i) => i.id === item.id);
            if (e.length) {
                return old.filter((i) => i.id !== item.id);
            }
            return [...old, item];
        });
    };

    const handleRemoveTag = (id) => {
        setTags((old) => old.filter((i) => i.id !== id));
    };

    return (
        <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
            <Box sx={{ p: 1, position: 'relative' }}>
                <Typography color="text.primary">
                    {t('Filter')}
                </Typography>
                <IconButton sx={{ position: 'absolute', right: 8, top: 3 }} onClick={onClose} size="small">
                    <Close />
                </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 1, width: 320 }}>
                <FormGroup>
                    <AsyncAutocomplete
                        url="/live-chat-agent"
                        idAttr="guid"
                        getOptionLabel={(option) => option.name || ''}
                        value={agent || null}
                        initialValue={agent || null}
                        onChange={(v) => {
                            console.log(v);
                            setAgent(v);
                        }}
                        textFieldProps={{
                            label: t('Assigned to agent'),
                            placeholder: t('Search by name or email')
                        }}
                        renderOption={(p, option) => (
                            <MenuItem {...p} key={option.guid}>
                                <ListItemAvatar>
                                    <Avatar src={option.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={option.name || t('Agent')}
                                    secondary={option.email || t('Email not set')}
                                />
                            </MenuItem>
                        )}
                    />
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                    <AsyncAutocomplete
                        url="/organization"
                        idAttr="guid"
                        value={org || null}
                        initialValue={org || null}
                        onChange={(v) => {
                            console.log(v);
                            setOrg(v);
                        }}
                        textFieldProps={{
                            label: t('Organization'),
                            placeholder: t('Search by name')
                        }}
                        getOptionLabel={(option) => option.title}
                        renderOption={(p, option) => (
                            <MenuItem {...p} key={option.guid}>
                                <ListItemAvatar>
                                    <Avatar src={option.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={option.title}
                                    secondary={option.email || t('Email not set')}
                                />
                            </MenuItem>
                        )}
                    />
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                    <FormControl>
                        <InputLabel id="demo-multiple-checkbox-label">{t('Source')}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            name="channel"
                            value={selectedChannels || []}
                            onChange={(e) => {
                                const {name, value} = e.target;
                                console.log(value);
                                setSelectedChannels(value);
                            }}
                            input={<OutlinedInput label={t('Source')} />}
                            renderValue={(selected) => {
                                const items = sources.filter((i) => selected.indexOf(i.name) !== -1);
                                const res = [];
                                if (items && items.length > 0) {
                                    res.push(items.map((ii) => (
                                        <ChannelChip key={ii.id} {...ii} />
                                    )))
                                }
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {res}
                                    </Box>
                                );
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                      width: 250,
                                    },
                                },
                            }}
                        >
                            {sources.map((i) => (
                                <MenuItem key={i.name} value={i.name}>
                                    <Checkbox checked={!!(selectedChannels?.indexOf(i.name) !== -1)} />
                                    <ListItemAvatar sx={{ ml: 1, minWidth: 28, my: 'auto', display: 'flex', '& svg': { my: 'auto' } }}>
                                        <ChatIntegrationIcon cn={i.className} size={16} />
                                    </ListItemAvatar>
                                    <ListItemText primary={i.title} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                        {(tags.length > 0 ) ? (
                            <Box sx={{ my: 'auto' }}>
                                {(tags.map((i) => (
                                    <Chip
                                        sx={{
                                        mr: 0.5,
                                        my: '2px',
                                        bgcolor: i.color || '',
                                        color: i.color ? invertColor(i.color, true) : 'text.primary',
                                        '& .MuiSvgIcon-root': {
                                            color: i.color ? invertColor(i.color, true) : '#000000',
                                            opacity: 0.4,
                                            '&:hover': {
                                                color: i.color ? invertColor(i.color, true) : '#000000',
                                                opacity: 0.55,
                                            }
                                        }
                                        }}
                                        onDelete={() => handleRemoveTag(i.id)}
                                        label={i.label}
                                        size="small"
                                    />
                                )))}
                            </Box>
                        ) : (
                            <Typography sx={{ fontStyle: 'italic', my: 'auto' }} color="text.secondary">
                                {t('No tags')}
                            </Typography>
                        )}
                        <IconButton sx={{ ml: 1, my: 'auto' }} onClick={(e) => setTagsAnchorEl(e.target)}>
                            <Add />
                        </IconButton>
                    </Box>
                    <TagsPopup
                        anchorEl={tagsAnchorEl}
                        onClose={() => setTagsAnchorEl(null)}
                        onSelect={handleAddTag}
                        allowAdd={false}
                    />
                </FormGroup>
            </Box>
            <Divider />
            <Box sx={{ p: 1, textAlign: 'right', display: 'flex' }}>
                <Button sx={{ mr: 'auto' }} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button sx={{ ml: 'auto', mr: 0.5 }} onClick={handleReset}>
                    {t('Reset')}
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained" disableElevation>
                    {t('Apply')}
                </Button>
            </Box>
        </Popover>
    );
};

export default ContactsFilter;