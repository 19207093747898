import { Avatar, Box, Chip, Divider, IconButton, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddCircle, Fax, Link, Phone } from "@mui/icons-material";
import UserChip from "../app/UserChip";
import TagsPopup from "../TagsPopup";
import $api from "../http";
import { useState } from "react";
import { invertColor } from "../app/Tools";
import useDayjs from "../app/useDayjs";

const OrganizationSidebar = (props) => {
    const { item, onReload } = props;
    const [tagsAnchorEl, setTagsAnchorEl] = useState(null);

    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    const listItems = [
        {
            id: 'website',
            label: t('Website'),
            link: true,
            icon: <Link sx={{ color: "text.secondary" }} />
        },
        {
            id: 'phone',
            label: t('Phone'),
            icon: <Phone sx={{ color: "text.secondary" }} />
        },
        {
            id: 'fax',
            label: t('Fax'),
            icon: <Fax sx={{ color: "text.secondary" }} />
        }
    ];

    const handleAddTag = (tag) => {
        $api.post('/organization/add-tag', {
          guid: item?.guid,
          tagId: tag.id
        })
          .catch((err) => {
    
          })
          .then((res) => {
            onReload();
          })
          .finally(() => {
            
          })
    };

    const handleRemoveTag = (id) => {
        $api.post('/organization/remove-tag', {
            guid: item?.guid,
            tagId: id
        })
          .catch((err) => {
    
          })
          .then((res) => {
            onReload();
          })
          .finally(() => {
            
          })
    };

    const handleOpenTagsPopup = (e) => {
        setTagsAnchorEl(e.currentTarget);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box sx={{ position: 'relative' }}>
                    <Avatar sx={{ width: 72, height: 72 }} src={item?.image} />
                </Box>
            </Box>
            <Typography sx={{ fontSize: '1.2rem', textAlign: 'center' }}>
                {item.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {item.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
            {(listItems).map((i) => (
                <Box key={i.id} sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                    <Box sx={{ mr: 1 }}>
                        {i.icon}
                    </Box>
                    <Typography sx={{ mr: 1 }}>
                        {i.label}
                    </Typography>
                    <Typography sx={{ ml: 'auto', fontStyle: (!!item[i.id]) ? 'normal' : 'italic', color: (!!item[i.id]) ? 'text.primary' : 'text.secondary' }}>
                        {(!!i.link && !!item[i.id]) ? (
                            <a href={item[i.id]} target="_blank">
                                {item[i.id]}
                            </a>
                        ) : (item[i.id] || t('Not set'))}
                    </Typography>
                </Box>
            ))}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Created by')}
                </Typography>
                <Box sx={{ ml: 'auto' }}>
                    <UserChip
                        size="small"
                        image={item.createdBy?.img}
                        name={item.createdBy?.name || t('System')}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Created at')}
                </Typography>
                <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                    {dayjs.unix(item.created_at).fromNow()}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', my: 0.5 }}>
                <Typography sx={{ mr: 1 }}>
                    {t('Last update')}
                </Typography>
                <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                    {dayjs.unix(item.updated_at).fromNow()}
                </Typography>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{
                    mb: 1,
                    pl: 0,
                    py: 0.5,
                    position: 'relative'
                }}>
                    <ListItemText
                        primaryTypographyProps={{
                            sx: {
                                textTransform: 'uppercase'
                            }
                        }}
                        primary={t('Tags')}
                    />
                    <ListItemSecondaryAction sx={{ right: 0 }}>
                        <Tooltip title={t('Add a tag')}>
                            <IconButton onClick={handleOpenTagsPopup} size="small">
                                <AddCircle fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction> 
                </Box>
                {(item.tags && item.tags.length > 0) ? (
                    <Box sx={{  }}>
                        {(item.tags.map((i) => (
                            <Chip
                                sx={{
                                mr: 0.5,
                                my: '2px',
                                bgcolor: i.color || '',
                                color: i.color ? invertColor(i.color, true) : 'text.primary',
                                '& .MuiSvgIcon-root': {
                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                    opacity: 0.4,
                                    '&:hover': {
                                    color: i.color ? invertColor(i.color, true) : '#000000',
                                    opacity: 0.55,
                                    }
                                }
                                }}
                                onDelete={() => handleRemoveTag(i.id, item.guid)}
                                label={i.label}
                                size="small"
                            />
                        )))}
                    </Box>
                ) : (
                    <Typography color="text.secondary" fontStyle="italic">
                        {t('No tags')}
                    </Typography>
                )}
                <TagsPopup
                    anchorEl={tagsAnchorEl}
                    onClose={() => setTagsAnchorEl(null)}
                    onSelect={handleAddTag}
                />
            </Box>
        </Box>
    );
};

export default OrganizationSidebar;