import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Typography
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Error from 'src/components/app/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import useTariff from 'src/components/app/useTariff';
import useUser from 'src/components/app/useUser';
import $api from '../http';

const ShopifyCharge = () => {
  const [loadError, setLoadError] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { getUser } = useUser();
  const { setTariff } = useTariff();
  const [paymentStatus, setPaymentStatus] = useState(false);

  const loadContent = () => {
    const data = {};
    const params = new URLSearchParams(window.location.search);
    if (params.has('charge_id')) {
      data.charge_id = params.get('charge_id');
    }
    setIsLoaded(false);
    $api.post('/shopify/charge', data)
      .catch((err) => {
        setIsLoaded(true);
        setLoadError(err);
        return null;
      })
      .then(async (res) => {
        if (res && res.data) {
          if (res.data.status === 1) {
            const u = await getUser(true);
            setTariff(u.tariff);
          }
          setPaymentStatus(res.data.status);
        }
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (loadError && Object.keys(loadError).length !== 0) {
    return (
      <Error error={loadError} />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={4} />
          <Grid item md={4}>
            <Card sx={{ position: 'relative', minHeight: '209px' }}>
              {((!isLoaded) && (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginLeft: '-20px',
                    marginTop: '-20px'
                  }}
                />
              )) || (
                <>
                  <CardHeader
                    align="center"
                    title="Shopify payment accepted"
                  />
                  <Divider />
                  <CardContent>
                    <Box sx={{ textAlign: 'center' }}>
                      {(paymentStatus === 0) && (
                        <PendingIcon fontSize="large" color="secondary" />
                      )}
                      {(paymentStatus === 1) && (
                        <CheckCircleIcon fontSize="large" color="primary" />
                      )}
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      {(paymentStatus === 0) && (
                        <Typography>
                          Payment is pending
                        </Typography>
                      )}
                      {(paymentStatus === 1) && (
                        <Typography>
                          Payment successfull
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ textAlign: 'center', pt: 2 }}>
                      {(paymentStatus === 0) && (
                        <Button
                          onClick={loadContent}
                          variant="outlined"
                        >
                          Reload
                        </Button>
                      )}
                      {(paymentStatus === 1) && (
                        <Button
                          component={NavLink}
                          to="/app"
                          variant="outlined"
                        >
                          Dashboard
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ShopifyCharge;
