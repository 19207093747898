import { React } from 'react';
import Login from 'src/components/login/Login';
import useToken from 'src/components/app/useToken';

const LoginPage = () => {
  const { setToken } = useToken();

  return (
    <Login setToken={setToken} />
  );
};

export default LoginPage;
