let listeners = [];

const useTariff = () => {
  const free = 'free';
  const pro = 'pro';
  const multisite = 'multisite';

  const subscribe = (listn) => {
    listeners.push(listn);
  };

  const unsubscribe = (listn) => {
    listeners = listeners.filter((entity) => entity !== listn);
  };

  const isAgency = () => {
    const data = localStorage.getItem('isAgency');
    return data === 1 || data === '1';
  };

  const setIsAgency = (is_agency) => {
    localStorage.setItem('isAgency', !!is_agency ? 1 : 0);
    notify();
  };

  const getTariff = () => {
    const data = localStorage.getItem('tariff');
    return data || null;
  };

  const notify = () => {
    const tariff = getTariff();
    listeners.forEach((listener) => listener(tariff));
  };

  const setTariff = (newTariff) => {
    let changed = false;
    if (newTariff !== getTariff()) {
      changed = true;
    }
    if (newTariff) {
      localStorage.setItem('tariff', newTariff);
    } else {
      localStorage.removeItem('tariff');
    }
    if (changed) {
      notify();
    }
  };

  const isFree = () => getTariff() === free || getTariff() === null;
  const isPro = () => getTariff() === pro;
  const isMultisite = () => getTariff() === multisite;

  return {
    isFree,
    isPro,
    isMultisite,
    setTariff,
    getTariff,
    isAgency,
    setIsAgency,
    subscribe,
    unsubscribe,
    notify
  };
};

export default useTariff;
