import { Helmet } from "react-helmet";
import { useAppConext } from "../context/AppContext";

const PageMeta = (props) => {
    const { title } = props;
    const { brandInfo } = useAppConext();
    return (
        <Helmet>
            <title>
                {title} {(brandInfo && brandInfo.title) ? ` | ${brandInfo.title}` : ''}
            </title>
            {(brandInfo && brandInfo.favicon) && (
                <link rel="shortcut icon" href={brandInfo.favicon} />
            )}
        </Helmet>
    );
};

export default PageMeta;