import { Lock, RocketLaunch, School } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LoadingProgress from "src/components/app/LoadingProgress";
import NoContent from "src/components/app/NoContent";
import PageMeta from "src/components/app/PageMeta";
import RestrictedFieldWrapper from "src/components/app/RestrictedFieldWrapper";
import { useAppConext } from "src/components/context/AppContext";
const PageBuilder = lazy(() => import('src/components/pageBuilder'));

const KnowledgeBasePage = () => {
    const { t } = useTranslation();
    const { user, getFeatureValue } = useAppConext();

    if (user?.permissions?.settingsKb === false) {
        return (
            <Box sx={{ p: 2, height: '100%', display: 'flex' }}>
                <NoContent
                    containerSx={{
                        my: 'auto'
                    }}
                    icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                    title={t('Forbidden')}
                    content={t("You're don't have access to this page")}
                />
            </Box>
        );
    }
    
    return (
        <>
            <PageMeta title={t('Knowledge base')} />
            <Box sx={{ p: 2, height: 'calc(100vh - 66px)' }}>
                {(user?.role === 'agent') ? (
                    <Box sx={{ p: 2, height: '100%', display: 'flex' }}>
                        <NoContent
                            containerSx={{
                                my: 'auto'
                            }}
                            icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                            title={t('Forbidden')}
                            content={t("You're don't have access to this page")}
                        />
                    </Box>
                ) : (
                    <RestrictedFieldWrapper
                        fullWidth
                        badgeType={false}
                        containerSx={{ display: 'flex' }}
                        blur
                        restricted={!getFeatureValue('kb.enabled')}
                        customContent={(
                            <Box sx={{ px: 2 }}>
                                <School sx={{ width: 40, height: 40, color: 'error.main' }} />
                                <Typography color="error" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    {t('Knowledge base section is locked')}
                                </Typography>
                                <Typography color="text.secondary" variant="body2">
                                    {t('Ulock this section to be able to manage knowledge base')}
                                </Typography>
                                <Button sx={{ mt: 1 }} variant="outlined" startIcon={<RocketLaunch />} component={NavLink} to="/app/upgrade">
                                    {t('Upgrade now')} 
                                </Button>
                            </Box>
                        )}
                    >
                        <Suspense fallback={<LoadingProgress open />}>
                            <PageBuilder />
                        </Suspense>
                    </RestrictedFieldWrapper>
                )}
            </Box>
        </>
    );
};

export default KnowledgeBasePage;