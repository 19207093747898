import { Box, Paper, Typography } from "@mui/material";
import pjson from "../../package.json";
import PageMeta from "src/components/app/PageMeta";
import { useTranslation } from "react-i18next";

const Version = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageMeta
                title={t('App version')}
            />
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Typography>
                        Current app version: {pjson.version}
                    </Typography>
                </Paper>
            </Box>
        </>
    );
};

export default Version;