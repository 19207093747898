import {
  Box,
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';

const AgencyClients = lazy(() => import('../../components/agency/AgencyClients'));

const Agency = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageMeta title={t('Agency')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: 2
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <AgencyClients />
                </Suspense>
            </Box>
        </>
    );
};
export default Agency;