import { KeyboardArrowUp } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useEffect, useState } from "react";

const ScrollTop = (props) => {
    const {
       color = '',
       size = 'small',
       offset = 200,
        ...rest
    } = props;
    const [showScrollTop, setShowScrollTop] = useState(false);

    const handleScroll = () => {
        setShowScrollTop(window.scrollY > offset);
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Fab color={color} size={size} onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }} sx={{
            display: showScrollTop ? 'inline-flex' : 'none',
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1,
            ...rest
        }}>
            <KeyboardArrowUp />
        </Fab>
    );
};

export default ScrollTop;