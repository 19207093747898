import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';

const DepartmentsList = lazy(() => import('../../components/departments/index'));

const Departments = () => {
    const { t } = useTranslation();
    return (
        <>
        <PageMeta title={t('Live chat agents')} />
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                px: {
                    xs: 1,
                    md: 2
                },
                py: {
                    xs: 1,
                    md: 2
                }
            }}
        >
            <Suspense fallback={<LoadingProgress open />}>
                <DepartmentsList showBreadcrumbs />
            </Suspense>
        </Box>
        </>
    );
};

export default Departments;