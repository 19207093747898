import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CodeIcon from '@mui/icons-material/Code';
import DnsIcon from '@mui/icons-material/Dns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useWidget from 'src/components/app/useWidget';
import UpgradePlanDialog from 'src/components/app/UpgradePlanDialog';
import WidgetsDialog from './WidgetsDialog';
import $api from '../http';
import { Box, ListItemText } from '@mui/material';
import { Business } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppConext } from '../context/AppContext';
import { LoadingButton } from '@mui/lab';

const WidgetsMenu = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [config, setConfig] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
  const { user, getFeatureValue } = useAppConext();
  const [unreadCount, setUnreadCount] = useState({});

  const { configUrl, onAddWidget, chatWebSocket } = props;

  const {
    setWidget,
    getWidget,
    subscribe,
    unsubscribe
  } = useWidget();

  const chatListener = (socketData) => {
    if (socketData.action === 'updateTotalUnreadCount') {
      setUnreadCount(socketData.result);
    }
  };

  useEffect(() => {
    chatWebSocket.subscribe(chatListener);
    return () => {
      chatWebSocket.unsubscribe(chatListener);
    };
  }, [chatListener]);

  const loadContent = () => {
    $api.get(configUrl)
      .catch((e) => {
        if (e.response && e.response.data) {
          setError(e.response.data);
        } else {
          setError(e);
        }
        setIsLoaded(true);
        return null;
      })
      .then((result) => {
        if (result && result.data) {
          setConfig(result.data);
        }
        setIsLoaded(true);
      });
  };

  const listener = useCallback(() => {
    loadContent();
  }, []);

  useEffect(() => {
    subscribe(listener);
    return () => {
      unsubscribe(listener);
    };
  }, [listener]);

  useEffect(() => {
    loadContent();
    return () => {
      unsubscribe(listener);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (event, uid) => {
    setWidget(uid);
    handleClose();
  };

  const handleClickAddItem = () => {
    if (getFeatureValue('widgets.limit') === 'inf' || (getFeatureValue('widgets.limit') > config.length)) {
      setDialogOpen(true);
    } else {
      setUpgradeDialogOpen(true);
    }
    handleClose();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveDialog = (data) => {
    onAddWidget(data);
    setWidget(data.uid);
    loadContent();
  };

  if (error) {
    return (
      <div>
        {error.message}
      </div>
    );
  }
  return (
    <Box sx={{ width: '100%' }}>
      <WidgetsDialog
        open={dialogOpen}
        saveUrl="/widgets"
        onSave={handleSaveDialog}
        onClose={handleCloseDialog}
        currentItem={{
          id: null,
          type: 1,
          message: '',
          active: 1,
          name: '',
          domain: ''
        }}
      />
      <UpgradePlanDialog requiredPlan="Multisite" open={upgradeDialogOpen} title={t('Upgrade to Multisite')} onClose={() => setUpgradeDialogOpen(false)} />
      <LoadingButton
        loading={!isLoaded}
        disableElevation
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        type="button"
        fullWidth
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
      >
        {(Array.isArray(config) && (
          config.map((item) => {
            if (item.uid === getWidget()) {
              return item.name;
            }
            return null;
          })
        ))}
      </LoadingButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(Array.isArray(config) && (
          config.map((item) => (
            <MenuItem
              key={item.id}
              selected={item.uid === getWidget()}
              onClick={(event) => handleClickMenuItem(event, item.uid)}
            >
              <ListItemIcon>
                {item.isMy ? <DnsIcon /> : <Business />}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                secondary={(item.isMy ? '' : item.email)}
              />
              {(item.isMy === 1 && unreadCount !== undefined) && (
                <Box sx={{ ml: 1 }}>
                  <Box sx={{
                    width: 16,
                    height: 16,
                    lineHeight: '16px',
                    bgcolor: 'primary.main',
                    borderRadius: 5,
                    fontSize: '0.7rem',
                    color: '#FFF',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: unreadCount[item.uid]?.count > 0 ? 'flex' : 'none'
                  }}>
                    {unreadCount[item.uid]?.count || 0}
                  </Box>
                </Box>
              )}
            </MenuItem>
          ))
        ))}
        <Divider />
        {(user?.permissions?.upgrade === true && onAddWidget !== null) && (
          <MenuItem
            onClick={(event) => handleClickAddItem(event)}
          >
            <ListItemIcon>
              <AddCircleIcon />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t('Add new widget')}
            </Typography>
          </MenuItem>
        )}
        
        <MenuItem
          component={NavLink}
          to="/settings/widgets"
          onClick={handleClose}
        >
          <ListItemIcon>
            <CodeIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('Manage widgets')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

WidgetsMenu.propTypes = {
  configUrl: PropTypes.string,
  onAddWidget: PropTypes.func
};

export default WidgetsMenu;
