import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Hidden,
  LinearProgress,
  ToggleButtonGroup,
  ToggleButton,
  Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import { Lock, SpeakerNotes, SpeakerNotesOff, Wifi, WifiOff } from '@mui/icons-material';
import { lazy, Suspense } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { useAppConext } from 'src/components/context/AppContext';
import RestrictedFieldWrapper from 'src/components/app/RestrictedFieldWrapper';
import WidgetsMenu from 'src/components/widget/WidgetsMenu';
import { useOutletContext } from 'react-router';
import NoContent from 'src/components/app/NoContent';

const LiveChatWidgetConfig = lazy(() => import('../../components/chat/LiveChatWidgetConfig'));

const ColorModeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const LiveChatWidget = () => {
  const { t } = useTranslation();
  const [previewLoading, setPreviewLoading] = useState(true);
  const [previewMode, setPreviewMode] = useState('online');
  const [previewConversation, setPreviewConversation] = useState('1');
  const { user, getFeatureValue } = useAppConext();
  const [colorMode, setColorMode] = useState('light');
  const { chatWebSocket } = useOutletContext();

  const scrollHandler = () => {
    return false;
  };

  useEffect(() => {
    if (document.getElementById('arcu-layout-content') !== null) {
      document.getElementById('arcu-layout-content').addEventListener('scroll', scrollHandler);
    }
    if (document.getElementById('anychat-livechat-root') !== null) {
      document.getElementById('anychat-livechat-root').remove();
    }
    window.addEventListener('resize', scrollHandler);
    scrollHandler();
    return () => {
      if (document.getElementById('arcu-layout-content') !== null) {
        document.getElementById('arcu-layout-content').removeEventListener('scroll', scrollHandler);
      }
      if (document.getElementById('anychat-livechat-root') !== null) {
        document.getElementById('anychat-livechat-root').remove();
      }
      window.removeEventListener('resize', scrollHandler);
      if (window.anychatWidget) {
        window.anychatWidget.mainButton = false;
      }
    };
  }, []);

  const handlePreviewColorModeChange = (e) => {
    setColorMode(e.target.checked ? 'dark' : 'light');
  };

  const handlePreviewOnlineModeChange = (event, newValue) => {
    setPreviewMode(newValue);
  };

  const handlePreviewConversationChange = (event, newValue) => {
    setPreviewConversation(newValue);
  };


  if ((user?.permissions?.settingsLiveChatSettings === false)) {
      return (
          <Box sx={{ height: 'calc(100vh - 164px)', display: 'flex', flexDirection: 'column', position: 'relative' }}>
              <NoContent
                  containerSx={{
                      my: 'auto'
                  }}
                  icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                  title={t('Forbidden')}
                  content={t("You're don't have access to this page")}
              />
          </Box>
      );
  }

  return (
    <>
        <PageMeta title={t('LiveChat widget')} />
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            px: {
                xs: 1,
                md: 2
            },
            py: {
              xs: 1,
              md: 2
            }
          }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={8}
              xl={8}
              md={12}
              xs={12}
            >
              <RestrictedFieldWrapper restricted={!getFeatureValue('livechat.enabled')} fullWidth>
                <Card>
                  <CardHeader
                    title={t('LiveChat widget settings')}
                    sx={{
                      '& .MuiCardHeader-action': {
                        my: 'auto'
                      }
                    }}
                    action={(
                      <>
                        <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'inline-flex', mr: 1 }}>
                          <WidgetsMenu chatWebSocket={chatWebSocket} configUrl="/widgets?includeAgency=0" onAddWidget={null} />
                        </Box>
                        {(user?.permissions?.docs) && (
                          <Tooltip title={t('Help')}>
                            <IconButton component="a" href="https://docs.anychat.one/live-chat-widget" target="_blank">
                              <HelpIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  />
                  <Divider />
                  <CardContent>
                    <Suspense fallback={<LoadingProgress open />}>
                      <LiveChatWidgetConfig
                        onLoad={scrollHandler}
                        previewMode={previewMode}
                        previewConversation={previewConversation}
                        onPreviewLoadingChange={(e) => setPreviewLoading(e)}
                        configUrl="/config/get"
                        saveUrl="/config/save"
                        modelName="LiveChatConfig"
                        colorMode={colorMode}
                      />
                    </Suspense>
                  </CardContent>
                </Card>
              </RestrictedFieldWrapper>
            </Grid>
            <Hidden lgDown>
              <Grid item xl={4} lg={4} md={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                  <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <CardHeader
                      title={t('Widget preview')}
                      sx={{
                        '& .MuiCardHeader-action': {
                          my: 'auto'
                        }
                      }}
                      action={(
                        <Box>
                          <Box sx={{ display: 'inline-flex' }}>
                            <ColorModeSwitch onChange={handlePreviewColorModeChange} />
                          </Box>
                          <ToggleButtonGroup exclusive onChange={handlePreviewOnlineModeChange} value={previewMode} size="small">
                            <ToggleButton value="online">
                              <Wifi />
                            </ToggleButton>
                            <ToggleButton value="offline">
                              <WifiOff />
                            </ToggleButton>
                          </ToggleButtonGroup>
                          <ToggleButtonGroup exclusive onChange={handlePreviewConversationChange} value={previewConversation} sx={{ ml: 1, display: previewMode === 'online' ? '' : 'none' }} size="small">
                            <ToggleButton value="1">
                              <SpeakerNotes />
                            </ToggleButton>
                            <ToggleButton value="0">
                              <SpeakerNotesOff />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      )}
                    />
                    <Divider />
                    <CardContent
                      sx={{
                        position: 'relative',
                        flex: 1,
                        overflow: 'hidden',
                      }}
                    >
                      {(previewLoading) && (
                        <Box
                          sx={{
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0
                          }}
                        >
                          <LinearProgress />
                        </Box>
                      )}
                      <Box id="arcontactus-livechat-preview" sx={{
                        translate: 0,
                        width: 'calc(100% - 32px)',
                        right: '20px !important',
                        bottom: '20px !important',
                        position: 'fixed',
                        top: 'auto !important',
                        '& form .MuiButtonBase-root': {
                          pointerEvents: 'none'
                        },
                        '& .MuiInputBase-root': {
                          pointerEvents: 'none'
                        },
                        '& label': {
                          pointerEvents: 'none'
                        }
                      }}>
                        <div id="anychat-livechat-root" />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </>
    );
};

export default LiveChatWidget;
