import {
  Box,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';

const SettingsView = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Settings')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          height: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: {
            xs: 1,
            md: 2
          }
        }}
      >
        <Card sx={{ height: '100%' }}>
          <CardContent sx={{ height: '100%' }}>
            <Box sx={{ textAlign: 'center', minHeight: '100%', alignItems: 'center', display: 'flex', width: '100%' }}>
              <Box sx={{ m: 'auto' }}>
                <Settings sx={{ color: 'grey.700', width: 64, height: 64 }} />
                <Typography sx={{ fontSize: '1.4rem' }}>
                  {t('Settings')}
                </Typography>
                <Typography>
                  {t('You can manage your settings in this section')}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default SettingsView;
