import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Typography,
} from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import useToken from 'src/components/app/useToken';
import useWidget from 'src/components/app/useWidget';
import useTariff from 'src/components/app/useTariff';
import Error from 'src/components/app/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import $api from '../http';

const ShopifyConnect = () => {
  const { login, isLoggedIn } = useToken();
  const { setWidget } = useWidget();
  const { setTariff } = useTariff();
  const [authTokens, setAuthTokens] = useState(null);
  const [currentWidget, setCurrentWidget] = useState(null);
  const [currentTariff, setCurrentTariff] = useState(null);
  const navigate = useNavigate();
  const [loadError, setLoadError] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const loadContent = () => {
    const data = {};
    const params = new URLSearchParams(window.location.search);
    if (params.has('hmac')) {
      data.hmac = params.get('hmac');
    }
    if (params.has('shop')) {
      data.shop = params.get('shop');
    }
    if (params.has('timestamp')) {
      data.timestamp = params.get('timestamp');
    }
    if (params.has('host')) {
      data.host = params.get('host');
    }
    if (params.has('code')) {
      data.code = params.get('code');
    }
    if (params.has('session')) {
      data.session = params.get('session');
    }
    if (params.has('locale')) {
      data.locale = params.get('locale');
    }
    if (params.has('state')) {
      data.state = params.get('state');
    }
    $api.post('/auth/shopify-connect', data)
      .catch((err) => {
        setIsLoaded(true);
        setLoadError(err);
        return null;
      })
      .then((response) => {
        setIsLoaded(true);
        if (response) {
          if (response.data.auth.token) {
            setAuthTokens(response.data.auth);
            setCurrentWidget(response.data.widgetUID);
            setCurrentTariff(response.data.tariff);
            if (isLoggedIn()) {
              const back = new URLSearchParams(window.location.search).get('back');
              if (back) {
                navigate(decodeURIComponent(back), { replace: true });
              } else {
                navigate('/app', { replace: true });
              }
            }
          }
        }
      });
  };

  useEffect(() => {
    if (authTokens) {
      login(authTokens);
    }
  }, [authTokens]);

  useEffect(() => {
    if (currentWidget) {
      setWidget(currentWidget);
    }
  }, [currentWidget]);

  useEffect(() => {
    if (currentTariff) {
      setTariff(currentTariff);
    }
  }, [currentTariff]);

  useEffect(() => {
    loadContent();
  }, []);

  if (loadError && Object.keys(loadError).length !== 0) {
    return (
      <Error error={loadError} />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={4} />
          <Grid item md={4}>
            <Card sx={{ position: 'relative', minHeight: '209px' }}>
              {((!isLoaded) && (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginLeft: '-20px',
                    marginTop: '-20px'
                  }}
                />
              )) || (
                <>
                  <CardHeader
                    align="center"
                    title="Shopify is connected"
                  />
                  <Divider />
                  <CardContent>
                    <Box sx={{ textAlign: 'center' }}>
                      <CheckCircleIcon fontSize="large" color="primary" />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography>
                        Shopify store is connected successfully
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center', pt: 2 }}>
                      <Button
                        component={NavLink}
                        to="/app"
                        variant="outlined"
                      >
                        Dashboard
                      </Button>
                    </Box>
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ShopifyConnect;
