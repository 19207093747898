import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import LoadingProgress from 'src/components/app/LoadingProgress';
import Error from 'src/components/app/Error';
import $api from 'src/components/http';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';

const NewPassword = () => {
  const { t } = useTranslation();
  const [config, setConfig] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [loadingError, setLoadingError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setConfig({ ...config, [event.target.name]: value });
  };

  const loadContent = (t) => {
    $api.get(`/auth/new-password?token=${t}`)
      .catch((err) => {
        if (err.response.status === 422 && err.response.data && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: 'error'
          });
          setIsLoading(false);
        }
        setLoadingError(true);
        return err;
      })
      .then((response) => {
        if (response.data && response.data.success) {
          setIsLoading(false);
          setLoadingError(false);
        }
      });
  };

  useEffect(() => {
    const q = new URLSearchParams(document.location.search);
    const t = q.get('token');
    setConfig({ ...config, token: t });
    loadContent(t);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    $api.post(`/auth/new-password?token=${config.token}`, config)
      .catch((err) => {
        if (err.response.status === 422) {
          setErrors(err.response.data);
        } 
        enqueueSnackbar('Error saving new password!', {
          variant: 'error'
        });
        return null;
      })
      .then((response) => {
        setIsSubmitting(false);
        if (response && response.data.success) {
          enqueueSnackbar('Password successfully set! Now you can login using new password!', {
            variant: 'success'
          });
        }
        return null;
      });
  };

  return (
    <>
      <PageMeta title="Reset password" />
      <LoadingProgress open={isLoading} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          height: '100%',
          my: 'auto',
          justifyContent: 'center',
          pb: 3,
          pt: 3
        }}
      >
        <Container maxWidth="sm">
          {(loadingError) && (
            <Box sx={{ mb: 3 }}>
              <Error error={{
                message: (
                  <>
                    <Typography variant="h4" component="div">
                      Error loading token
                    </Typography>
                    <Typography>
                      Token not found or link expired
                    </Typography>
                  </>
                )
              }} />
            </Box>
          )}
          {(!loadingError && !isLoading) && (
            <form onSubmit={handleSubmit} noValidate>
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  {t('Reset password')}
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  {t('Set new account password')}
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    error={!!errors.password}
                    fullWidth
                    helperText={errors.password}
                    label={t('New password')}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={config.password}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    error={!!errors.password2}
                    fullWidth
                    helperText={errors.password2}
                    label={t('Confirm password')}
                    name="password2"
                    onChange={handleChange}
                    type="password"
                    value={config.password2}
                    variant="outlined"
                    required
                  />
                </Grid>
              </Grid>
              <Box sx={{ py: 2 }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t('Set new password')}
                </LoadingButton>
              </Box>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                {t('New password is set?')}
                {' '}
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="h6"
                >
                  {t('Sign in')}
                </Link>
              </Typography>
            </form>
          )}
        </Container>
      </Box>
    </>
  );
};

export default NewPassword;
