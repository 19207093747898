import { Box, SvgIcon, Tooltip, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useAppConext } from "../context/AppContext";
import { useColorModeContext } from "../context/ColorModeContextProvider";

const RestrictedFieldWrapper = (props) => {
    const {
        children,
        restricted,
        fullWidth,
        badgeType = 'label',
        badgePosition = 'right',
        fade = true,
        disabled = false,
        size = 24,
        blur = false,
        containerSx = {},
        customContent = null
    } = props;
    const { user } = useAppConext();
    const { t } = useTranslation();
    const { currentMode } = useColorModeContext();
    if (user?.permissions?.upgrade === false && restricted === true) {
        return null;
    }
    return (
        <Box sx={{
            display: 'inline-flex',
            position: 'relative',
            width: fullWidth ? '100%' : 'auto',
            ...containerSx
        }}>
            {(restricted) && (
                (badgeType !== false) && (
                    (badgeType === 'label') ? (
                        <Tooltip title={t('Upgrade to use this feature')}>
                            <Typography sx={{
                                position: 'absolute',
                                top: -9,
                                right: badgePosition === 'right' ? 0 : 'auto',
                                left: badgePosition === 'left' ? 0 : 'auto',
                                zIndex: 1,
                                bgcolor: orange.A700,
                                color: '#FFF',
                                px: 1,
                                py: 0.2,
                                lineHeight: 1.2,
                                borderRadius: 1,
                                fontSize: '0.7rem',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: 'background.paper'
                            }}>
                                {t('upgrade')}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Box sx={{
                            bgcolor: 'background.paper',
                            position: 'absolute', top: '50%', right: 5, mt: '-12px',
                            width: size, height: size,
                            zIndex: 1,
                            borderRadius: 5
                        }}>
                            <SvgIcon viewBox='0 0 24 24' sx={{ width: '100%', height: '100%', color: orange.A700 }}>
                                <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
                            </SvgIcon>
                        </Box>
                    )
                )
            )}
            {(customContent !== undefined && customContent !== null && restricted) && (
                <Box sx={{ bgcolor: currentMode === 'light' ? '#FFFFFFAA' : '#0a1929DD', borderRadius: 1, zIndex: 1, top: 0, bottom: 0, right: 0, left: 0, position: 'absolute', m: 'auto', maxWidth: 400, height: 200, display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    {customContent}
                </Box>
            )}
            <Box sx={{
                opacity: (restricted && fade) ? 0.4 : 1,
                width: fullWidth ? '100%' : 'auto',
                pointerEvents: (restricted ?? disabled) ? 'none' : 'auto',
                filter: (restricted && blur) ? 'blur(4px)' : ''
            }}>
                {children}
            </Box>
        </Box>
    );
};

export default RestrictedFieldWrapper;