import {
  Box,
} from '@mui/material';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';

const WidgetCodeConfig = lazy(() => import('src/components/widgetCode/WidgetCodeConfig'));

const WidgetCode = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Apps and plugins')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: 2
        }}
      >
        <Suspense fallback={<LoadingProgress open />}>
          <WidgetCodeConfig configUrl="/config/get-widget-code" pluginsUrl="/plugins" />
        </Suspense>
      </Box>
    </>
  );
};

export default WidgetCode;
