import {
  Box,
} from '@mui/material';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';

const Affiliate = lazy(() => import('../../../src/components/Affiliate'));

const AffiliatePage = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageMeta title={t('Affiliate program')} />
            <Box
            sx={{
                backgroundColor: 'background.default',
                px: {
                    xs: 1,
                    md: 2
                },
                py: 1
            }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <Affiliate />
                </Suspense>
            </Box>
        </>
    );
};

export default AffiliatePage;