import {
  Box,
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';

const IntegrationsConfig = lazy(() => import('../../components/settings/integrations/IntegrationsConfig'));

const General = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Integrations settings')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: {
            xs: 1,
            md: 2
          }
        }}
      >
        <Suspense fallback={<LoadingProgress open />}>
          <IntegrationsConfig configUrl="/config/get" saveUrl="/config/save" modelName="IntegrationsConfig" />
        </Suspense>
      </Box>
    </>
  );
};

export default General;
