import * as React from 'react';
import { Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BoltIcon from '@mui/icons-material/Bolt';
import useToken from 'src/components/app/useToken';
import Logo from '../Logo';
import WidgetsMenu from '../widget/WidgetsMenu';
import { useSnackbar } from 'notistack';
import { RocketLaunch } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppConext } from '../context/AppContext';
import UserMenu from './UserMenu';
import UserNotifications from './UserNotifications';
import SupportChat from './SupportChat';
import CrmMenu from './CrmMenu';

const DashboardNavbar = ({ onQuickSetup, onMobileNavOpen, brandInfo, chatWebSocket, ...rest }) => {
  const { t } = useTranslation();
  const { logout } = useToken();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { loadBrandInfo, user, plan, modules } = useAppConext();

  const handleNewWidget = (data) => {
    enqueueSnackbar(t('Widget is added'), {
        variant: 'success'
    });
  };

  const handleLogout = () => {
    logout().then(() => {
      loadBrandInfo();
      navigate('/login', { replace: true });
    });
  };

  return (
    <>
      <AppBar
        elevation={0}
        color="navbar"
        enableColorOnDark
        {...rest}
      >
        <Toolbar sx={{
          minHeight: {
            xs: '56px !important',
            sm: '64px !important'
          }
        }}>
          <RouterLink to="/app">
            <Logo
              logo={brandInfo?.headerLogo || '/static/logo.svg'}
              style={{ marginTop: 'auto', marginBottom: 'auto', display: 'block', minWidth: 52 }}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          {(plan?.showUpgrade === true && user?.permissions?.upgrade === true && user?.role === 'owner') && (
            <>
              <Hidden smDown>
                <Button
                  component={NavLink}
                  to="/app/upgrade"
                  startIcon={<RocketLaunch />}
                  variant="outlined"
                  sx={{
                    color: 'primary.main',
                    borderColor: '#FFFFFF88',
                    background: '#FFF',
                    marginRight: 1,
                    '&:hover': {
                      color: '#FFF',
                      borderColor: '#FFF'
                    }
                  }}
                >
                  {t('Upgrade')}
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton color="inherit" component={NavLink} to="/app/upgrade">
                  <BoltIcon />
                </IconButton>
              </Hidden>
            </>
          )}
          <CrmMenu />
          <SupportChat />
          {(modules.notifications === true) && (
            <UserNotifications chatWebSocket={chatWebSocket} />
          )}
          <UserMenu chatWebSocket={chatWebSocket} />
          <Hidden lgUp>
            <IconButton sx={{ ml: 1 }} color="inherit" onClick={onMobileNavOpen} size="medium">
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  onQuickSetup: PropTypes.func,
};

export default DashboardNavbar;
