import { ContactSupport } from "@mui/icons-material";
import { Box, IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { useAppConext } from "../context/AppContext";

const SupportChat = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { supportWidgetUID } = useAppConext();

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (supportWidgetUID === null) {
        return null;
    }

    return (
        <>
            <IconButton onClick={handleOpenMenu} sx={{ ml: 1, p: 0.5, height: 40, width: 40 }}>
                <ContactSupport sx={{
                    color: 'primary.contrastText'
                }} />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                open={!!anchorEl}
                keepMounted
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      maxWidth: 400,
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: '#536DFE',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ p: 0, height: 600, width: 400 }}>
                    <iframe style={{ width: '100%', height: '100%', border: '0 none' }} border="0" src={`${process.env.REACT_APP_API_URL}embed/${supportWidgetUID}`} />
                </Box>
            </Popover>
        </>
    );
};

export default SupportChat;