import { DarkMode, Flare, LightMode } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { ColorModeContext } from "../context/ColorModeContext";
import { useTranslation } from "react-i18next";
import { useColorModeContext } from "../context/ColorModeContextProvider";

const ModeSelector = (props) => {
    const { t } = useTranslation();
    const { toggleColorMode, currentMode } = useColorModeContext();
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
          <Tooltip title={t('Display mode')}>
            <IconButton {...props} variant="outlined" sx={{ color: 'navbar.contrastText' }} onClick={handleClick}>
                {(currentMode === 'light') ? <LightMode /> : (currentMode === 'dark' ? <DarkMode /> : <Flare />)}
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock
          >
            <MenuItem selected={currentMode === 'light'} onClick={() => { toggleColorMode('light'); handleClose()} }>
              <ListItemIcon>
                <LightMode />
              </ListItemIcon>
              <ListItemText primary={t('Light mode')} />
            </MenuItem>
            <MenuItem selected={currentMode === 'dark'} onClick={() => { toggleColorMode('dark'); handleClose()} }>
              <ListItemIcon>
                <DarkMode />
              </ListItemIcon>
              <ListItemText primary={t('Dark mode')} />
            </MenuItem>
            <MenuItem selected={currentMode === 'auto'} onClick={() => { toggleColorMode('auto'); handleClose()} }>
              <ListItemIcon>
                <Flare />
              </ListItemIcon>
              <ListItemText primary={t('System')} />
            </MenuItem>
          </Menu>
        </>
    );
};

export default ModeSelector;