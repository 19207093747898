import {
  Backdrop,
  Box,
  CircularProgress
} from '@mui/material';

const LoadingProgress = (props) => {
  const { backdrop, ...other } = props;
  if (!backdrop) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          rigth: 0,
          left: 0,
          display: 'flex',
          width: '100%',
          pointerEvents: 'none',
          opacity: other.open ? 1 : 0,
          zIndex: (theme) => theme.zIndex.drawer + 1 
        }}
        {...other}
      >
        <CircularProgress sx={{ m: 'auto' }} color="primary" />
      </Box>
    );
  }
  return (
    <Backdrop
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.1)',
        color: '#444',
        position: 'absolute',
        top: 0,
        bottom: 0,
        rigth: 0,
        left: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1 
      }}
      {...other}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default LoadingProgress;
