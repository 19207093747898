const Logo = (props) => {
  const { logo, ...rest } = props;
  return (
    <img
      alt="Logo"
      src={logo}
      height="52"
      {...rest}
    />
  );
}

export default Logo;
