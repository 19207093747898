import { Box } from '@mui/material';
import PageMeta from 'src/components/app/PageMeta';
import { Suspense, lazy } from 'react';
import LoadingProgress from 'src/components/app/LoadingProgress';

const UpgradePlan = lazy(() => import('../components/app/UpgradePlan'));

const OldUpgrade = () => (
  <>
    <PageMeta title="Upgrade your plan" />
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: {
            xs: 1,
            md: 2
        },
      }}
    >
      <Box sx={{ mt: 5, mx: 5 }}>
        <Suspense fallback={<LoadingProgress open />}>
          <UpgradePlan />
        </Suspense>
      </Box>
    </Box>
  </>
);

export default OldUpgrade;
