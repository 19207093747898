import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Checkbox, Chip, Table, TableBody, TableCell, TableHead, TableRow, Divider, TablePagination, Typography, Avatar, Badge, Tooltip, IconButton, AvatarGroup } from "@mui/material";
import { Box } from "@mui/system";
import Moment from 'react-moment';
import { Business, ChatBubble, ConfirmationNumber, Dns, Duo, Groups, TaskAlt } from "@mui/icons-material";
import SourceIcon from "../chat/SourceIcon";
import { NavLink } from "react-router-dom";
import { invertColor } from "../app/Tools";
import UserChip from "../app/UserChip";
import { useAppConext } from "../context/AppContext";
import ClickToCopy from "../app/ClickToCopy";
import useDayjs from "../app/useDayjs";

const ContactsTable = (props) => {
    const {
        items,
        selectedItems,
        handleSelectOne,
        handleSelectAll,
        handlePageChange,
        handleLimitChange,
        totalItems,
        page,
        limit,
        disablePagination,
        disableSelection,
        compactView = false,
        actions
    } = props;
    const { user } = useAppConext();
    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    return (
        <Card>
            <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                <TableHead sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 3 }}>
                    <TableRow>
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.length === items.length}
                                    color="primary"
                                    indeterminate={
                                        selectedItems.length > 0
                                        && selectedItems.length < items.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                        )}
                    
                    <TableCell>
                        {t('Name')}
                    </TableCell>
                    <TableCell>
                        {t('Source')}
                    </TableCell>
                    <TableCell>
                        {t('Contacts')}
                    </TableCell>
                    <TableCell>
                        {t('Assigned to')}
                    </TableCell>
                    <TableCell>
                        {t('Last contact')}
                    </TableCell>
                    <TableCell>
                        {t('Created at')}
                    </TableCell>
                    {(Array.isArray(actions)) && (
                        <TableCell />
                    )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                    <TableRow
                        hover
                        key={item.id}
                        selected={selectedItems.indexOf(item.id) !== -1}
                    >
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.indexOf(item.id) !== -1}
                                    onChange={(event) => handleSelectOne(event, item.id)}
                                    value="true"
                                />
                            </TableCell>
                        )}
                        <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            right: 32
                                        }
                                    }}
                                    badgeContent={
                                        <AvatarGroup max={2} sx={{
                                            '& .MuiAvatar-root': {
                                                width: 18,
                                                height: 18,
                                                fontSize: '0.75rem',
                                                borderWidth: 1, borderStyle: 'solid', borderColor: 'background.paper'
                                            },
                                            '& .MuiAvatar-root:last-child': {
                                                ml: -1
                                            }
                                        }}>
                                            {(item.organizations?.map((i) => (
                                                <Tooltip title={i.title}>
                                                    <Avatar alt={i.title} src={i.image}>
                                                        <Business sx={{ width: 14, height: 14 }} />
                                                    </Avatar>
                                                </Tooltip>
                                            )))}
                                        </AvatarGroup>
                                    }
                                >
                                    <Avatar component={NavLink} to={`/app/contacts/${item.guid}`} sx={{ mr: compactView ? 2 : 4 }} src={item.img} />
                                </Badge>
                                <Box>
                                    <Typography noWrap component={NavLink} to={`/app/contacts/${item.guid}`} sx={{
                                        fontStyle: item.name ? 'normal' : 'italic',
                                        color: item.name ? 'text.primary' : 'text.secondary',
                                    }}>
                                        {item.name || 'No name'}
                                    </Typography>
                                    
                                    {(item.source === 'telegram') && (
                                        (!!item.data?.tgUsername) ? (
                                            <Typography variant="body2">
                                                <a href={`https://t.me/${item.data.tgUsername}`} target="_blank" >@{item.data.tgUsername}</a>
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2" color="text.secondary">
                                                {item.data.tgId}
                                            </Typography>
                                        )
                                    )}
                                    {(item.source === 'whatsapp' || item.source === 'whatsappqr') && (
                                        (!!item.data?.waPhoneNumber) && (
                                            <Typography variant="body2">
                                                <a href={`https://wa.me/${item.data.waPhoneNumber.replace(/[\s()-]+/ig, '')}`} target="_blank" >{item.data.waPhoneNumber}</a>
                                            </Typography>
                                        )
                                    )}
                                    {(item.email) ? (
                                        <Typography variant="body2" color="text.secondary" sx={{
                                            fontStyle: (item.email) ? 'normal' : 'italic'
                                        }}>
                                            <ClickToCopy tooltip={t('Click to copy')}>
                                                {item.email}
                                            </ClickToCopy>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" color="text.secondary" sx={{
                                            fontStyle: 'italic'
                                        }}>
                                            {t('No email set')}
                                        </Typography>
                                    )}
                                    
                                    {(item.phone && !['whatsapp', 'whatsappqr'].includes(item.source)) && (
                                        <Typography variant="body2" color="text.secondary">
                                            {item.phone}
                                        </Typography>
                                    )}
                                    <Box>
                                        {(item.tags?.length > 0) && (
                                            (item.tags?.map((i) => (
                                                <Chip
                                                    sx={{
                                                    mr: 0.5,
                                                    my: '2px',
                                                    height: 18,
                                                    bgcolor: i.color || '',
                                                    color: i.color ? invertColor(i.color, true) : 'text.primary',
                                                    '& .MuiSvgIcon-root': {
                                                        color: i.color ? invertColor(i.color, true) : '#000000',
                                                        opacity: 0.4,
                                                        '&:hover': {
                                                            color: i.color ? invertColor(i.color, true) : '#000000',
                                                            opacity: 0.55,
                                                        }
                                                    }
                                                    }}
                                                    label={i.label}
                                                    size="small"
                                                />
                                            )))
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <SourceIcon rounded={false} chip size={18} channel={item.source} />
                        </TableCell>
                        <TableCell>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                {(item.threadsCount > 0 && user?.permissions?.chat === true) && (
                                    <Box component={NavLink} to={`/app/contacts/${item.guid}/chats`} sx={{ mx: 1 }}>
                                        <Tooltip title={t('Chat conversations')}>
                                            <Badge sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.65rem',
                                                    minWidth: 16,
                                                    height: 16,
                                                    px: '4px'
                                                }
                                            }} size="small" color="info" badgeContent={item.threadsCount}>
                                                <ChatBubble sx={{ color: 'text.secondary' }} />
                                            </Badge>
                                        </Tooltip>
                                    </Box>
                                )}
                                {(item.formsCount > 0 && user?.permissions?.inbox === true) && (
                                    <Box component={NavLink} to={`/app/contacts/${item.guid}/forms`} sx={{ mx: 1 }}>
                                        <Tooltip title={t('Contacts via forms')}>
                                            <Badge sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.65rem',
                                                    minWidth: 16,
                                                    height: 16,
                                                    px: '4px'
                                                }
                                            }} color="warning" badgeContent={item.formsCount }>
                                                <Dns sx={{ color: 'text.secondary' }} />
                                            </Badge>
                                        </Tooltip>
                                    </Box>
                                )}
                                {(item.ticketsCount > 0 && user?.permissions?.tickets === true) && (
                                    <Box component={NavLink} to={`/app/contacts/${item.guid}/tickets`} sx={{ mx: 1 }}>
                                        <Tooltip title={t('Tickets')}>
                                            <Badge sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.65rem',
                                                    minWidth: 16,
                                                    height: 16,
                                                    px: '4px'
                                                }
                                            }} size="small" color="success" badgeContent={item.ticketsCount}>
                                                <ConfirmationNumber sx={{ color: 'text.secondary' }} />
                                            </Badge>
                                        </Tooltip>
                                    </Box>
                                )}
                                {(item.tasksCount > 0 && user?.permissions?.tasks === true) && (
                                    <Box component={NavLink} to={`/app/contacts/${item.guid}/tasks`} sx={{ mx: 1 }}>
                                        <Tooltip title={t('Tasks')}>
                                            <Badge sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.65rem',
                                                    minWidth: 16,
                                                    height: 16,
                                                    px: '4px'
                                                }
                                            }} size="small" color="success" badgeContent={item.tasksCount}>
                                                <TaskAlt sx={{ color: 'text.secondary' }} />
                                            </Badge>
                                        </Tooltip>
                                    </Box>
                                )}
                                {(item.meetingsCount > 0 && user?.permissions?.meetings === true) && (
                                    <Box component={NavLink} to={`/app/contacts/${item.guid}/meetings`} sx={{ mx: 1 }}>
                                        <Tooltip title={t('Meetings')}>
                                            <Badge sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.65rem',
                                                    minWidth: 16,
                                                    height: 16,
                                                    px: '4px'
                                                }
                                            }} size="small" color="success" badgeContent={item.meetingsCount}>
                                                <Duo sx={{ color: 'text.secondary' }} />
                                            </Badge>
                                        </Tooltip>
                                    </Box>
                                )}
                                {(item.relatedCount > 0) && (
                                    <Box component={NavLink} to={`/app/contacts/${item.guid}/related`} sx={{ mx: 1 }}>
                                        <Tooltip title={t('Related contacts')}>
                                            <Badge sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.65rem',
                                                    minWidth: 16,
                                                    height: 16,
                                                    px: '4px'
                                                }
                                            }} color="warning" badgeContent={item.relatedCount }>
                                                <Groups sx={{ color: 'text.secondary' }} />
                                            </Badge>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            {(item.assignedTo !== null) ? (
                                <UserChip
                                    name={item.assignedTo.name}
                                    image={item.assignedTo.img}
                                    color="success"
                                    variant="outlined"
                                    size={compactView ? 'small' : 'medium'}
                                />
                            ) : (
                                <UserChip
                                    name={t('Not assigned')}
                                    image='no-img'
                                    variant="outlined"
                                    size={compactView ? 'small' : 'medium'}
                                />
                            )}
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            {(item.last_contacted_at !== null && item.last_contacted_at !== 0) ? (
                                dayjs.unix(item.last_contacted_at).fromNow()
                            ) : (
                                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                    {t('Not set')}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            <Moment unix format="YYYY MMM DD HH:mm" tz={Intl.DateTimeFormat().resolvedOptions().timeZone}>
                                {item.created_at}
                            </Moment>
                        </TableCell>
                        {(Array.isArray(actions)) && (
                            <TableCell
                                align="right"
                                sx={{
                                    width: 120
                                }}
                            >
                                {actions.map((i) => (
                                    (i !== null) && (
                                        <Tooltip title={i.tooltip}>
                                            <IconButton onClick={(e) => i.onClick(e, item)}>
                                                {i.icon}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ))}
                            </TableCell>
                        )}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Box>
            {(disablePagination !== true) && (
                <>
                    <Divider />
                    <TablePagination
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            bgcolor: 'background.paper',
                            zIndex: 3
                        }}
                        component="div"
                        count={totalItems}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                    />
                </>
            )}
            </PerfectScrollbar>
        </Card>
    );
};

export default ContactsTable;