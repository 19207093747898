import { useEffect, useState } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import {
  experimentalStyled,
  Divider,
  Typography,
  Container,
  Grid,
  Fade,
} from '@mui/material';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import moment from 'moment';
import GoTop from './GoTop';
import { Box } from '@mui/system';
import $api from '../http';
import LoadingProgress from '../app/LoadingProgress';
import { useAppConext } from '../context/AppContext';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%'
  })
);

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  background: '#F4F6F8'
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'block',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto'
});
const MainLayoutFooter = experimentalStyled('div')({
  flex: '1 1 auto',
  overflow: 'auto'
});

const MainLayout = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showGoTop, setShowGoTop] = useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { brandInfo, brandingLoaded } = useAppConext();
  
  const loadSDK = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = `${process.env.REACT_APP_API_URL}widget/9952ca93-1b44-4400-b5c3-b439b86b0f5a`;
      js.async = true;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'contactus-jssdk');
    $api.get('/auth/get-current-user')
      .catch((err) => {
        console.log(err);
        return null;
      })
      .then((res) => {
        if (res !== null && res.data && res.data.loggedIn === 1) {
          setTimeout(() => {
            if (window.anychat) {
              window.anychat.updateUserData(res.data.name, res.data.email);
            }
          }, 1000);
        }
      });
  };

  const unloadSDK = () => {
    if (document.getElementById('contactus-jssdk')) {
      document.getElementById('contactus-jssdk').remove();
    }
    if (document.getElementById('contactus-jssdk-css')) {
      document.getElementById('contactus-jssdk-css').remove();
    }
    if (document.getElementById('anychat-livechat-root')) {
      document.getElementById('anychat-livechat-root').remove();
    }
    if (document.getElementById('anychat-container')) {
      document.getElementById('anychat-container').remove();
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      setScrolled(window.scrollY > 64);
      setShowGoTop(window.scrollY > 100);
    };
    if (window.location.hostname !== process.env.REACT_APP_HOSTNAME) {
      
    } else {
      loadSDK();
    }
    document.addEventListener('scroll', scrollHandler);
    return () => {
      if (window.contactUs) {
        window.contactUs.destroy();
      }
      document.removeEventListener('scroll', scrollHandler);
      unloadSDK();
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <MainLayoutRoot>
        {(window.location.hostname === process.env.REACT_APP_HOSTNAME) && (
          <>
            <MainNavbar brandInfo={brandInfo} scrolled={scrolled} onMobileNavOpen={() => setMobileNavOpen(true)} />
            <MainSidebar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
          </>
        )}
        <LoadingProgress open={window.location.hostname !== process.env.REACT_APP_HOSTNAME && !brandingLoaded} />
        <Fade in={window.location.hostname === process.env.REACT_APP_HOSTNAME || brandingLoaded}>
          <MainLayoutWrapper>
            <MainLayoutContainer>
              <MainLayoutContent id="arcu-main-layout-content">
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',  
                  minHeight: {
                    xs: 'calc(100vh - 177px)',
                    sm: 'calc(100vh - 177px)',
                    md: 'calc(100vh - 177px)',
                    lg: 'calc(100vh - 182px)'
                  },
                  backgroundColor: 'background.default'
                }}>
                  <Outlet context={{
                    brandInfo
                  }} />
                </Box>
                <Divider />
                <GoTop scrolled={showGoTop} onClick={handleScrollTop} />
                <MainLayoutFooter className="main-footer">
                  <Container>
                    <Grid
                      container
                      sx={{
                        pt: 3,
                        pb: 3
                      }}
                    >
                      <Grid item md={3} xs={12} />
                      <Grid item md={6} xs={12} align="center">
                        <Typography color="text.primary">
                          {(window.location.hostname === process.env.REACT_APP_HOSTNAME) ? (
                            <>
                              &copy;{moment().year()}, AnyChat LLC&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                              <NavLink to="/terms-of-service">Terms Of Serivce</NavLink>
                              &nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                              &nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                              <NavLink to="/dpa">DPA</NavLink>
                              &nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                              <NavLink to="/gdpr">GDPR</NavLink>
                            </>
                          ) : (
                            <>
                              &copy;{moment().year()}, {brandInfo.title}
                            </>
                          )}
                        </Typography>
                        {(window.location.hostname === process.env.REACT_APP_HOSTNAME) ? (
                          <>
                            <Typography variant="body2" color="text.secondary">
                              1021 E Lincolnway Suite #6532, Cheyenne, Wyoming 82001, US
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem', mt: 1 }}>
                              Google Play and the Google Play logo are trademarks of Google LLC.
                            </Typography>
                          </>
                        ) : (
                          ((brandInfo.customLine !== null && brandInfo.customLine !== undefined && brandInfo.customLine !== '') && (
                            <Typography variant="body2" color="text.secondary">
                              {brandInfo.customLine}
                            </Typography>
                          ))
                        )}
                      </Grid>
                      <Grid item md={3} xs={12} />
                    </Grid>
                  </Container>
                </MainLayoutFooter>
              </MainLayoutContent>
            </MainLayoutContainer>
          </MainLayoutWrapper>
        </Fade>
      </MainLayoutRoot>
    </>
  );
};

export default MainLayout;
