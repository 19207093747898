import { AttachFile, East, Flag, Person, PersonAdd, PersonRemove, Update } from "@mui/icons-material";
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Box, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import $api from "src/components/http";
import { useTranslation } from "react-i18next";
import UserChip from "src/components/app/UserChip";
import useDayjs from "../app/useDayjs";


const CrmItemTimeline = (props) => {
    const { itemGuid, attributeLabels, statusOptions, priorityOptions, url } = props;
    const [items, setItems] = useState([]);
    
    const { t } = useTranslation();
    const { dayjs } = useDayjs();

    const loadContent = () => {
        const finalUrl = `${url}?guid=${itemGuid}`
        $api.get(finalUrl)
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                }
            })
            .finally(() => {

            })
    };

    useEffect(() => {
        loadContent()
    }, []);

    const getIcon = (eventName) => {
        switch (eventName) {
            case 'create':
                return <Flag />;
            case 'addContact':
            case 'addAgent':
                return <PersonAdd />;
            case 'removeContact':
            case 'removeAgent':
                return <PersonRemove />;
            case 'addAttachment':
            case 'removeAttachment':
                return <AttachFile />;    
            default:
                return <Update />;
        }
    };

    const getLabel = (eventName) => {
        switch (eventName) {
            case 'create':
                return t('Item created');
            case 'update':
                return t('Item updated');
            case 'addContact':
                return t('Contact(s) added');
            case 'removeContact':
                return t('Contact(s) removed');
            case 'addAgent':
                return t('Agent(s) added');
            case 'removeAgent':
                return t('Agent(s) removed');
            case 'addAttachment':
                return t('File(s) attached');
            case 'removeAttachment':
                return t('File(s) removed');
            default:
                return '';
        }
    };

    const getStatusLabel = (val) => {
        const s = statusOptions.filter((i) => i.id === val);
        if (s.length === 1) {
            return (
                <Chip
                    icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: s[0].color }} />}
                    size="small"
                    variant="outlined"
                    label={s[0].label}
                    sx={{
                        borderColor: s[0].color
                    }}
                />
            )
        }
        return val;
    };

    const getPriorityLabel = (val) => {
        const s = priorityOptions.filter((i) => i.id === val);
        if (s.length === 1) {
            return (
                <Chip
                    icon={<Box sx={{ ml: '8px !important', width: 8, height: 8, borderRadius: 5, bgcolor: s[0].color }} />}
                    size="small"
                    variant="outlined"
                    label={s[0].label}
                    sx={{
                        borderColor: s[0].color
                    }}
                />
            )
        }
        return val;
    };

    const getContent = (item) => {
        if (item.event_name === 'create') {
            return (<UserChip
                size="small"
                color="success"
                variant="outlined"
                image={item.createdBy?.img}
                name={item.createdBy?.name}
            />);
        }
        const res = [];
        for (let i in item.eventData) {
            if (item.eventData[i].type === 'text') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                                {item.eventData[i].old || t('NONE')}
                            </Typography>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Typography variant="body2">
                                {item.eventData[i].new || t('NONE')}
                            </Typography>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'user') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Box>
                                <UserChip
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        opacity: 0.5
                                    }}
                                    image={item.eventData[i].old?.img}
                                    name={item.eventData[i].old?.name || t('Not assigned')}
                                />
                            </Box>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Box>
                                <UserChip
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    image={item.eventData[i].new?.img}
                                    name={item.eventData[i].new?.name || t('Not assigned')}
                                />
                            </Box>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'users') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {(item.eventData[i].items.map((i) => (
                                <UserChip
                                    size="small"
                                    variant="outlined"
                                    defaultIcon={<Person sx={{ width: 18, height: 18 }} />}
                                    image={i.img}
                                    sx={{ mr: 0.5, my: 0.5 }}
                                    name={i.name || t('Not set')}
                                />
                            )))}
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'status') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Box sx={{ opacity: 0.5 }}>
                                {getStatusLabel(item.eventData[i].old)}
                            </Box>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Box>
                                {getStatusLabel(item.eventData[i].new)}
                            </Box>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'priority') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Box sx={{ opacity: 0.5 }}>
                                {getPriorityLabel(item.eventData[i].old)}
                            </Box>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Box>
                                {getPriorityLabel(item.eventData[i].new)}
                            </Box>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'attachment') {
                res.push(
                    (item.eventData[i].items.map((ii) => (
                        <Box sx={{ mb: 0.5 }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', textDecoration: item.event_name === 'removeAttachment' ? 'line-through' : 'none' }}>
                                {ii.file}
                            </Box>
                            <Typography variant="body2">
                                {ii.size}
                            </Typography>
                        </Box>
                    )))
                );
            } else if (item.eventData[i].type === 'date') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                                {dayjs.unix(item.eventData[i].old).format('YYYY MMM DD')}
                            </Typography>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Typography variant="body2">
                                {dayjs.unix(item.eventData[i].new).format('YYYY MMM DD')}
                            </Typography>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'time') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                                {dayjs.unix(item.eventData[i].old).format('YYYY MMM DD HH:mm')}
                            </Typography>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Typography variant="body2">
                                {dayjs.unix(item.eventData[i].new).format('YYYY MMM DD HH:mm')}
                            </Typography>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'duration') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                                {dayjs.duration(item.eventData[i].old, 'seconds').format('HH:mm')}
                            </Typography>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Typography variant="body2">
                                {dayjs.duration(item.eventData[i].new, 'seconds').format('HH:mm')}
                            </Typography>
                        </Box>
                    </Box>
                );
            } else if (item.eventData[i].type === 'onoff') {
                res.push(
                    <Box sx={{ mb: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: 1, my: 'auto' }} variant="body2" color="text.primary">
                                {attributeLabels[i] || i}:
                            </Typography>
                            <Box>
                                <Chip
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        opacity: 0.5
                                    }}
                                    icon={<Box sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: 2,
                                        bgcolor: item.eventData[i].old ? 'success.main' : 'error.main',
                                        marginLeft: '8px !important'
                                    }} />}
                                    color={item.eventData[i].old ? 'success' : 'error'}
                                    label={item.eventData[i].old ? t('On') : t('Off')}
                                />
                            </Box>
                            <East fontSize="0.875rem" sx={{ mx: 1, my: 'auto' }} />
                            <Box>
                                <Chip
                                    size="small"
                                    color={item.eventData[i].new ? 'success' : 'error'}
                                    variant="outlined"
                                    icon={<Box sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: 2,
                                        bgcolor: item.eventData[i].new ? 'success.main' : 'error.main',
                                        marginLeft: '8px !important'
                                    }} />}
                                    label={item.eventData[i].new ? t('On') : t('Off')}
                                />
                            </Box>
                        </Box>
                    </Box>
                );
            }
        }
        return res;
    };

    return (
        <Box>
            <Timeline sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}>
                {(items.map((i, idx) => (
                    <TimelineItem sx={{ minHeight: 92 }}>
                        <TimelineOppositeContent sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography sx={{ fontWeight: '500' }}>
                                {getLabel(i.event_name)}
                            </Typography>
                            <Typography variant="body2">
                                {dayjs.unix(i.created_at).format('MMM D YYYY, HH:mm')}
                            </Typography>
                            <Typography sx={{ fontSize: '0.8rem' }} color="text.secondary">
                                {t('by')}
                                <Typography variant="span" color="text.primary" sx={{ mx: 0.5 }}>
                                    {i.createdBy?.name}
                                </Typography>
                                {dayjs.unix(i.created_at).fromNow()}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                                {getIcon(i.event_name)}
                            </TimelineDot>
                            <TimelineConnector sx={{ opacity: (idx === items.length - 1) ? 0 : 1 }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: 1, px: 2, display: 'flex', flex: 3, flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="body2" color="text.secondary">
                                {getContent(i)}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                )))}
            </Timeline>
        </Box>
    );
};

export default CrmItemTimeline;