import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import PageMeta from 'src/components/app/PageMeta';

const Roadmap = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.location.hostname !== process.env.REACT_APP_HOSTNAME) {
            navigate('/login', { replace: true });
            return false;
        }
    }, []);
    
    return (
        <>
            <PageMeta title="Development roadmap" />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    mb: 0
                }}
            >
                <Container sx={{ py: 3 }}>
                    <Typography variant="h1" color="text.primary" sx={{ textAlign: 'center', mb: 4 }}>
                        Development roadmap
                    </Typography>
                    <Typography variant="h2" color="text.primary" sx={{ textAlign: 'center', mb: 4 }}>
                        2024
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2024 Q1
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Workspaces
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Workspaces allow you to manage your data in more efficient way.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    CRM
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Allows you to account and manage your clients and contacts.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Ticketing system
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Support tool teams use to track, manage, organize, and prioritize customer requests.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Knowledge base
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Allows you to create documentation articles.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2024 Q2
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    IMAP Mail client
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Allows you to receive emails directly into your Anychat's inbox.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'warning.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Whitelabel iOS and Android app
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    You will be able to set your own branding to mobile apps.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Agency plans builder
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    You will be able to make a custom plans for your agency clients and connect your Stripe account to collect payments.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'warning.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Public API
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Public API allows you to integrate with other tools.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2024 Q3
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'warning.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Advanced office time
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Will allows you to set extended office time and set live chat behavoiur depends on office time.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'warning.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Customer Satisfaction Score
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Customer Feedback & Survey Solution.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'grey.600', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    E-Commerce integrations
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Allows you to send products cards via live chat. Customer will be able to add those product to cart directly in live chat widget.
                                </Typography>
                            </Paper>
                            
                            
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2024 Q4
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'grey.600', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Advanced chat routing
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Adding departments to agents' hierarchy. 
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'grey.600', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Chat analytics
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Adding chat analytics and metrics.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Typography variant="h2" color="text.primary" sx={{ textAlign: 'center', mb: 4, mt: 4 }}>
                        2023
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2023 Q1
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Freshworks CRM integration
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    This integration will allows you to send leads from AnyChat forms to Freshworks CRM.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Bidirectional telegram integration
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    This feature will allow you to reply to live chat messages with a telegram chatbot.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Chat agents
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    With this feature, you will be able to add agents to your account. Those agents will handle all incoming messages (from live chat and forms). Each agent will be able to log in with their own email and password.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2023 Q2
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Agency plan
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    This plan will allow you to create sub accounts with Pro plan for clients.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Multilanguage support
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    This feature will allows you to configure the widget for several languages. 
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Single inbox 
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    This feature will allow you to receive messages from all major messengers 
                                    (like Facebook Messenger, WhatsApp, Instagram, Telegram) to your AnyChat inbox and reply 
                                    to those messengers from AnyChat inbox.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2023 Q3
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Chatbots 
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    This feature will allow you to add a chatbot to the live chat.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    iOS mobile app
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    The mobile app for the iOS platform.
                                </Typography>
                            </Paper>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    Android mobile app
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    The mobile app for the Android platform.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ p: 1 }}>
                            <Typography variant="h4" color="text.primary" sx={{ mb: 2, textAlign: 'center' }}>
                                2023 Q4
                            </Typography>
                            <Paper elevation={0} sx={{ p: 1, borderLeft: 4, borderLeftColor: 'success.light', mb: 2 }}>
                                <Typography sx={{ pb: 1 }}>
                                    CRM
                                </Typography>
                                <Divider />
                                <Typography variant="body2" sx={{ pt: 1 }} color="text.secondary">
                                    Allows you to account and manage your clients and contacts.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Roadmap;