import { Box } from "@mui/material";
import { Outlet } from "react-router";

const MinimalLayout = () => {
    return (
        <Box sx={{ height: '100%', bgcolor: 'background.default' }}>
            <Outlet />
        </Box>
    );
};

export default MinimalLayout;