import { ChatBubble, Notifications } from "@mui/icons-material";
import { Avatar, Badge, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import $api from "../http";
import NoContent from "../app/NoContent";
import useDayjs from "../app/useDayjs";

const UserNotifications = (props) => {
    const { chatWebSocket } = props;
    const [menuElAnchor, setMenuElAnchor] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const [items, setItems] = useState([]);
    const { t } = useTranslation();
    const { dayjs } = useDayjs();
    const navigate = useNavigate();

    const handleOpenMenu = (e) => {
        setMenuElAnchor(e.currentTarget);
        
    };

    const readNotification = (item) => {
        $api.post('/notification/read', {
            id: item.guid
        })
            .catch((err) => {

            })
            .then(() => {
                setItems((old) => (old.map((i) => {
                    if (i.guid === item.guid) {
                        i.read = 1;
                    }
                    return i
                })));
                setUnreadCount((i) => i - 1);
            });
    };

    const handleCloseMenu = () => {
        setMenuElAnchor(null);
    };

    const chatListener = (socketData) => {
        if (socketData?.auth?.workspace === chatWebSocket.getWorkspace()) {
            if (socketData.action === 'updateNotifications') {
                setItems(socketData.notifications);
            }

            if (socketData.action === 'updateTotalUnreadCount') {
                setUnreadCount(socketData.result.notifications);
            }

            if (socketData.action === 'newNotification') {
                setItems((old) => [socketData.notification, ...old]);
                setUnreadCount((i) => i + 1);
            }
        }
    };

    const handleItemClick = (item) => {
        handleCloseMenu();
        readNotification(item);
        if (item.url) {
            navigate(item.url, { replace: true });
        }
    };

    useEffect(() => {
        chatWebSocket.subscribe(chatListener);

        return () => {
            chatWebSocket.unsubscribe(chatListener);
        };
    }, []);

    return (
        <>
            <IconButton onClick={handleOpenMenu} sx={{ ml: 1, p: 0.5, height: 40, width: 40 }}>
                <Badge
                    variant="dot"
                    invisible={unreadCount === 0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiBadge-badge': {
                            bgcolor: 'error.main',
                            bottom: 4,
                            right: 4,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'background.paper'
                        }
                    }}
                >
                    <Notifications sx={{
                        color: 'primary.contrastText'
                    }} />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={menuElAnchor}
                onClose={handleCloseMenu}
                open={!!menuElAnchor}
                sx={{
                    '& .MuiList-root': {
                        maxHeight: 400,
                        overflow: 'auto'
                    }
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      minWidth: 360,
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {(items.length > 0) ? (
                    (items.map((item) => (
                        <MenuItem key={item.guid} onClick={() => handleItemClick(item)}>
                            <ListItemIcon sx={{ minWidth: '46px !important' }}>
                                <Avatar sx={{
                                    width: '40px !important',
                                    height: '40px !important'
                                }} src={item.client?.image || item.icon}>
                                    {item.type === 'thread' ? <ChatBubble /> : <Notifications />}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={(
                                    <>
                                        <Box sx={{ display: 'flex', maxWidth: 300 }}>
                                            <Typography noWrap sx={{ mr: 1 }} color="text.primary">
                                                {item.title}
                                            </Typography>
                                            <Typography sx={{ ml: 'auto', my: 'auto', textAlign: 'right', fontSize: '0.8rem' }} variant="body2" color="text.secondary">
                                                {dayjs.unix(item.created_at).fromNow()}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                                secondary={(
                                    <>
                                        <Box sx={{ maxWidth: 300 }}>
                                            <Typography noWrap>
                                                {(item?.client) && (
                                                    <Typography component="span" sx={{ fontWeight: '500', mr: 1 }} variant="body2" noWrap>
                                                        {item.client?.name || 'Visitor'}
                                                    </Typography>
                                                )}
                                                <Typography component="span" variant="body2" color="text.secondary">
                                                    {item.content}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            />
                            <Box sx={{ minWidth: 20 }}>
                                {(item.read === 0) && (
                                    <Box sx={{ borderRadius: 10, width: 8, height: 8, my: 'auto', ml: 'auto', bgcolor: 'primary.main' }} />
                                )}
                            </Box>
                        </MenuItem>
                    )))
                ) : (
                    <Box sx={{ px: 2 }}>
                        <NoContent
                            icon={<Notifications sx={{ width: 48, height: 48, mb: 1, color: 'grey.700' }} />}
                            title={t('No notifications')}
                            content={t('You have no notifications yet')}
                        />
                    </Box>
                )}
                
            </Menu>
        </>
    );
};

export default UserNotifications;