import {
  Box
} from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
const AgencyConfig = lazy(() => import('../../components/settings/agency/AgencyConfig'));


const AgencySettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('Agency settings')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: {
              xs: 1,
              md: 2
          },
          py: 2
        }}
      >
        <Suspense fallback={<LoadingProgress open />}>
          <AgencyConfig configUrl="/config/get" saveUrl="/config/save" modelName="AgencyConfig" />
        </Suspense>
      </Box>
    </>
  );
};

export default AgencySettings;