import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useParams } from "react-router";
import LoadingProgress from "src/components/app/LoadingProgress";

const Contact = lazy(() => import('../../../src/components/contact'));

const ContactPage = () => {
    const { id, tab } = useParams();
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <Contact tab={tab} id={id} />
                </Suspense>
            </Box>
        </>
    );
};

export default ContactPage;