import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import $api from "../http";
import { useTranslation } from "react-i18next";
import useWidget from "../app/useWidget";
import useToken from "../app/useToken";

export const AppContext = React.createContext();

export const useAppConext = () => {
    return useContext(AppContext);
};

const AppContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [brandInfo, setBrandInfo] = useState({});
    const [brandingLoaded, setBrandingLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [appVersion, setAppVersion] = useState(null);
    const [features, setFeatures] = useState({});
    const [domains, setDomains] = useState({});
    const [plan, setPlan] = useState({
        plan: 'free',
        planLabel: 'Free',
        isFree: true,
        start: 0,
        end: 'inf'
    });
    const [workspace, setWorkspace] = useState(null);
    const [userLoaded, setUserLoaded] = useState(false);
    const [modules, setModules] = useState({});
    const [supportWidgetUID, setSupportWidgetUID] = useState(null);
    const [showAgreements, setShowAgreements] = useState([]);
    const widget = useWidget();
    const token = useToken();
    

    const isLoggedIn = () => {
        return !!(localStorage.getItem('accessToken'));
    };

    const loadUser = async () => {
        setUserLoaded(false);
        if (isLoggedIn()) {
            return $api.get('/user/bootstrap')
                .catch((err) => {
                    token.logout();
                })
                .then((res) => {
                    setAppVersion(res.data.appVersion);
                    setUser(res.data.user);
                    setFeatures(res.data.features);
                    setPlan({
                        plan: res.data.plan,
                        planLabel: res.data.planLabel,
                        start: res.data.planStart,
                        end: res.data.planEnd,
                        isFree: res.data.isFree,
                        showUpgrade: res.data.showUpgrade
                    });
                    setSupportWidgetUID(res.data.supportWidgetUID);
                    setWorkspace(res.data.defaultWorkspace);
                    setModules(res.data.modules);
                    setDomains(res.data.domains);
                    setShowAgreements(res.data.showAgreements);
                    widget.setWidget(res.data.defaultWidget.uid);
                })
                .finally(() => {
                    setUserLoaded(true);
                });
        }
    };

    const loadBrandInfo = () => {
        return $api.get('/user/brand')
            .catch(() => {
                return null;
            })
            .then((res) => {
                if (res) {
                    setBrandInfo(res.data);
                    i18n.changeLanguage(res.data.lang)
                }
            })
            .finally(() => {
                setBrandingLoaded(true);
            });
    }

    const getDeviceID = () => {
        let currentID = localStorage.getItem('deviceID');
        if (currentID !== null && currentID !== undefined && currentID !== '') {
            return currentID;
        }
        currentID = uuidv4();
        localStorage.setItem('deviceID', currentID);
        return currentID;
    };

    const isFree = () => {
        return plan?.isFree;
    };

    const isPro = () => {
        return user?.tariff === 'pro';
    };

    const isMultisite = () => {
        return user?.tariff === 'multisite';
    };

    const isUltimate = () => {
        return user?.tariff === 'ultimate';
    };

    const isAgency = () => {
        return getFeatureValue('agency.enabled');
    };

    const isUnlimitedAgency = () => {
        return getFeatureValue('agency.enabled') && (getFeatureValue('agency.clientLimit') === 999);
    };

    const getTariff = () => {
        return user?.tariff;
    };

    const getFeatureValue = (feature) => {
        return features[feature]?.value !== undefined ? features[feature]?.value : null;
    };

    useEffect(() => {
        getDeviceID();
        loadBrandInfo();
        loadUser();
    }, []);

    return (
        <AppContext.Provider value={{
            appVersion,
            getDeviceID,
            brandInfo,
            brandingLoaded,
            loadBrandInfo,
            loadUser,
            user,
            userLoaded,
            isFree,
            isPro,
            isMultisite,
            isUltimate,
            isAgency,
            getTariff,
            isUnlimitedAgency,
            plan,
            workspace,
            getFeatureValue,
            supportWidgetUID,
            modules,
            domains,
            showAgreements
        }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;