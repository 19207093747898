import React, {
    useEffect,
    useState,
  } from 'react';
  import {
    Outlet
  } from 'react-router-dom';
import useChatWebSocket from 'src/components/app/useChatWebSocket';
import OneSignal from 'react-onesignal';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ChatPopover from './ChatPopover';
import { DashboardLayoutWrapper, DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutRoot } from './DashboardLayoutWrapper';
import LoadingProgress from '../app/LoadingProgress';
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet';
import $api from '../http';
import { Box, Button, Fade, Typography } from '@mui/material';
import SettingsSidebar from 'src/components/layouts/SettingsSidebar';
import { useAppConext } from '../context/AppContext';
import { NewReleases, Refresh } from '@mui/icons-material';
import pjson from "../../../package.json";
import AgreementsDialog from './AgreementsDialog';
import AnouncementDialog from './AnouncementDialog';
  
const SettingsLayout = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const chatWebSocket = useChatWebSocket();
    const [initialStepperOpen, setInitialStepperOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const { brandInfo, user, userLoaded, appVersion } = useAppConext();
    const theme = useTheme();
  
    const handleOneSignalInit = () => {
      OneSignal.init({
        appId: brandInfo.onesignalAppId,
        allowLocalhostAsSecureOrigin: true
      }).then(() => {
        OneSignal.setExternalUserId(`${user.id}`);
        OneSignal.on('subscriptionChange', (isSubscribed) => {
          console.log("The user's subscription state is now:", isSubscribed);
          OneSignal.getUserId((userId) => {
            console.log('OneSignal User ID:', userId);
            $api.post('/user/update-web-push', {
              id: userId,
              appId: brandInfo.onesignalAppId
            });
          });
        });
        OneSignal.showNativePrompt().then(() => {
          console.log('OneSignal prompt');
        });
      });
    }

    useEffect(() => {
      if (user !== null) {
        handleOneSignalInit();
      }
    }, [brandInfo, user]);
  
    const handleCollapse = () => {
      setCollapsed((c) => {
        localStorage.setItem('anychat-sidebar', !c === true ? 1 : 0);
        return !c;
      });
    };
  
    useEffect(() => {
      if (localStorage.getItem('anychat-sidebar') === '1') {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }, []);

    const handleVersionUpdate = () => {
      localStorage.setItem('needReload', 1);
      setTimeout(() => {
        window.location.assign("?update");
      }, 500);
    };

    if (userLoaded && appVersion !== null && appVersion !== pjson.version) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <NewReleases sx={{ width: 56, height: 56, mb: 1, color: 'error.main' }} />
          <Typography sx={{ fontSize: '1.4rem' }}>
            New version of dashboard is available
          </Typography>
          <Typography color="text.secondary">
            Your current version (<b>{pjson.version}</b>) is behind of actual version (<b>{appVersion}</b>).
          </Typography>
          <Typography color="error" sx={{ mb: 2 }}>
            Please update to avoid issues.
          </Typography>
          <Button startIcon={<Refresh />} size="large" variant="contained" disableElevation onClick={handleVersionUpdate}>
            Update
          </Button>
          <Typography sx={{ mt: 2 }} variant="body2" color="text.disabled">
            Please clear your browser cache manually if the "Update" button doesn't help.
          </Typography>
        </Box>
      );
    } else if (userLoaded && (appVersion === pjson.version || appVersion === null)) {
      localStorage.setItem('needReload', 0);
    }
  
    return (
      <>
        <Helmet>
          <meta name="theme-color" content={theme.palette.mode === 'dark' ? '#1a237e' : '#536dfe'} media="(prefers-color-scheme: dark)" />
          <meta name="theme-color" content={theme.palette.mode === 'dark' ? '#1a237e' : '#536dfe'} media="(prefers-color-scheme: light)" />
        </Helmet>
        <DashboardLayoutRoot>
          <LoadingProgress open={!userLoaded} />
          <AnouncementDialog />
          <Fade in={userLoaded}>
            <Box sx={{ width: '100%', height: '100%' }}>
              <DashboardNavbar chatWebSocket={chatWebSocket} brandInfo={brandInfo} onQuickSetup={() => setInitialStepperOpen(true)} onMobileNavOpen={() => setMobileNavOpen(true)} />
                <DashboardSidebar
                  onMobileClose={() => setMobileNavOpen(false)}
                  openMobile={isMobileNavOpen}
                  chatWebSocket={chatWebSocket}
                  collapsed={collapsed}
                  onCollapse={handleCollapse}
                />
              <DashboardLayoutWrapper collapsed={collapsed}>
                <DashboardLayoutContainer>
                  <DashboardLayoutContent id="arcu-layout-content">
                    <Box sx={{ display: 'flex', height: '100%' }}>
                        <SettingsSidebar chatWebSocket={chatWebSocket} />
                        <Box sx={{
                          flexGrow: 1,
                          position: 'relative',
                          overflowX: 'hidden',
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'auto'
                          }
                        }}>
                            <Outlet context={{
                                brandInfo,
                                chatWebSocket
                            }} />
                            <AgreementsDialog />
                        </Box>
                    </Box>
                  </DashboardLayoutContent>
                </DashboardLayoutContainer>
              </DashboardLayoutWrapper>
            </Box>
          </Fade>
          <ChatPopover agent={false} chatWebSocket={chatWebSocket} />
        </DashboardLayoutRoot>
      </>
    );
  };
  
  export default SettingsLayout;
  