import { Box, Button, Chip, Collapse, Divider, Fab, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingProgress from "../app/LoadingProgress";
import $api from "../http";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import useDayjs from "../app/useDayjs";
import { KeyboardArrowDown, KeyboardArrowUp, Receipt } from "@mui/icons-material";
import { useAppConext } from "../context/AppContext";
import NoContent from "../app/NoContent";
import ScrollTop from "../app/ScrollTop";

const AccountBilling = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [promoCodeItems, setPromoCodeItems] = useState([]);
    const [items, setItems] = useState([]);
    const [paymentItems, setPaymentItems] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const { t } = useTranslation();
    const { user } = useAppConext();
    const { dayjs } = useDayjs();

    const loadContent = () => {
        $api.get('/subscription')
            .catch((err) => {

            })
            .then((res) => {
                setPromoCodeItems(res.data.codes);
                // setItems(res.data.subscriptions);
                setPaymentItems(res.data.stripePayments);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        loadContent();
    }, []);

    return (
        <>
            <LoadingProgress open={isLoading} />
            <ScrollTop />
            <Paper elevation={0} sx={{ py: 2, mb: 2 }}>
                <Typography sx={{ px: 2 }}>
                    {t('Payment history')}
                </Typography>
                {(isLoading === false && paymentItems.length === 0) ? (
                    <>
                        <Divider sx={{ mt: 2 }} />
                        <NoContent
                            containerSx={{
                                my: 'auto'
                            }}
                            icon={<Receipt sx={{ width: 40, height: 40, color: 'text.secondary' }} />}
                            title={t('No items')}
                            content={t("You don't have any records here")}
                        />
                    </>
                ) : (
                    ((isLoading === false && paymentItems.length > 0) && (
                        <PerfectScrollbar>
                            <Box sx={{ minWidth: 500, mt: 2 }}>
                                <Table sx={{ borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: 'divider' }}>
                                <TableHead>
                                    <TableRow>
                                    <TableCell>
                                        {t('Reference')}
                                    </TableCell>
                                    <TableCell>
                                        {t('Payment gateway')}
                                    </TableCell>
                                    <TableCell>
                                        {t('Billing period')}
                                    </TableCell>
                                    <TableCell>
                                        {t('Price')}
                                    </TableCell>
                                    <TableCell>
                                        {t('Refund')}
                                    </TableCell>
                                    <TableCell>
                                        {t('Created at')}
                                    </TableCell>
                                    <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paymentItems.map((item) => (
                                        <>
                                            <TableRow
                                                hover
                                                key={item.id}
                                            >
                                                <TableCell onClick={() => {
                                                    setExpanded((old) => {
                                                        if (old.includes(item.id)) {
                                                            return old.filter((i) => i !== item.id);
                                                        } else {
                                                            return [
                                                                ...old,
                                                                item.id
                                                            ];
                                                        }
                                                    });
                                                }}>
                                                    <Typography variant="body2" color="text.primary">
                                                        {item.data.number}
                                                        {(item.data.livemode === false) && (
                                                            <>
                                                                <Typography sx={{ mx: 2 }} component="span" variant="body2" color="text.secondary">
                                                                    &bull;
                                                                </Typography>
                                                                <Typography component="span" variant="body2" color="text.secondary">
                                                                    {t('test')}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '0.75rem' }} color="text.secondary">
                                                        {item.data.id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {'Stripe'}
                                                </TableCell>
                                                <TableCell>
                                                    {item.data.period || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {`${(item.data.amount_paid / 100).toFixed(2)}`} {item.data.currency.toUpperCase()}
                                                </TableCell>
                                                <TableCell>
                                                    {item.refunded === 0 ? (
                                                        <Typography variant="body2" color="text.secondary">-</Typography>
                                                    ) : (
                                                        <Typography variant="body2" color="error.main">
                                                            {`${(item.refunded / 100).toFixed(2)}`} {item.data.currency.toUpperCase()}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs.unix(item.created_at).format('MMM D YYYY HH:mm')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title={t('Download invoice')}>
                                                        <IconButton component="a" href={item.data.invoice_pdf} target="_blank">
                                                            <Receipt />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <IconButton onClick={() => {
                                                        setExpanded((old) => {
                                                            if (old.includes(item.id)) {
                                                                return old.filter((i) => i !== item.id);
                                                            } else {
                                                                return [
                                                                    ...old,
                                                                    item.id
                                                                ];
                                                            }
                                                        });
                                                    }}>
                                                        <KeyboardArrowUp sx={{
                                                            transition: '0.2s all',
                                                            transform: expanded.includes(item.id) ? '' : 'rotate(180deg)'
                                                        }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            
                                            <TableRow>
                                                <TableCell sx={{ p: 0, borderBottomWidth: 0 }} colSpan={7}>
                                                    <Collapse in={expanded.includes(item.id)}>
                                                        <Box sx={{ pl: 4, borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'divider' }}>
                                                            <Table sx={{ borderLeftColor: 'divider', borderLeftStyle: 'solid', borderLeftWidth: 1 }}>
                                                                <TableHead>
                                                                    <TableRow sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'divider' }}>
                                                                        <TableCell sx={{ py: 1 }}>
                                                                            {t('Description')}
                                                                        </TableCell>
                                                                        <TableCell sx={{ py: 1 }}>
                                                                            {t('Qty')}
                                                                        </TableCell>
                                                                        <TableCell sx={{ py: 1 }}>
                                                                            {t('Amount')}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {(item.lines.map((line) => (
                                                                        <TableRow hover key={line.id}>
                                                                            <TableCell>
                                                                                {line.description}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {line.qty}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {`${(line.amount / 100).toFixed(2)}`} {line.currency.toUpperCase()}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                                </Table>
                            </Box>
                        </PerfectScrollbar>
                    ))
                )}
            </Paper>
            {(user?.rootUser === true) && (
                <Paper elevation={0} sx={{ py: 2, mb: 2 }}>
                    <Typography sx={{ px: 2 }}>
                        {t('Used promo-codes')}
                    </Typography>
                    {(isLoading === false && promoCodeItems.length === 0) ? (
                        <>
                            <Divider sx={{ mt: 2 }} />
                            <NoContent
                                containerSx={{
                                    my: 'auto'
                                }}
                                icon={<Receipt sx={{ width: 40, height: 40, color: 'text.secondary' }} />}
                                title={t('No items')}
                                content={t("You don't have any records here")}
                            />
                        </>
                    ) : (
                        ((isLoading === false && promoCodeItems.length > 0) && (
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 500, mt: 2 }}>
                                    <Table sx={{
                                        borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: 'divider',
                                        borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'divider'    
                                    }}>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>
                                            {t('Promo code')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Type')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Tariff')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Created at')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Used at')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Status')}
                                        </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {promoCodeItems.map((item) => (
                                            <TableRow
                                                hover
                                                key={item.id}
                                            >
                                                <TableCell>
                                                    {item.promoCode.code}
                                                </TableCell>
                                                <TableCell>
                                                    {item.promoCode.type}
                                                </TableCell>
                                                <TableCell>
                                                    {item.promoCode.tariff}
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs.unix(item.promoCode.created_at_int).format('MMM D YYYY, HH:mm')}
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs.unix(item.created_at_int).format('MMM D YYYY, HH:mm')}
                                                </TableCell>
                                                <TableCell>
                                                    {(item.refund === 0) ? (
                                                        <Chip size="small" label="active" color="primary" />
                                                    ) : (
                                                        <Chip size="small" label="revoked" color="error" />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        ))
                    )}
                </Paper>
            )}
        </>
        
    );
};

export default AccountBilling;