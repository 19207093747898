import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';

const ConversationsConfig = lazy(() => import('../../components/settings/ConversationsConfig'));

const ConversationsSettings = () => {
    const { t } = useTranslation();
    return (
        <>
        <PageMeta title={t('Conversations settings')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <ConversationsConfig configUrl="/config/get" saveUrl="/config/save" modelName="ConversationsConfig" />
                </Suspense>
            </Box>
        </>
    );
};

export default ConversationsSettings;