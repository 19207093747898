import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import LoadingProgress from "src/components/app/LoadingProgress";
import PageMeta from "src/components/app/PageMeta";
import OrganizationSidebar from "src/components/organizations/OrganizationSidebar";
import OrganizationTabs from "src/components/organizations/OrganizationTabs";
import OrganizationAttachments from "src/components/organizations/tabs/OrganizationAttachments";
import OrganizationContacts from "src/components/organizations/tabs/OrganizationContacts";
import OrganizationNotes from "src/components/organizations/tabs/OrganizationNotes";
import OrganizationTimeline from "src/components/organizations/tabs/OrganizationTimeline";

const CrmItem = lazy(() => import('../../../src/components/crmItem'));

const OrganizationPage = () => {
    const { id, tab } = useParams();
    const { t } = useTranslation();

    return (
        <>
            <PageMeta title={t('Organizations')} />
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    px: {
                        xs: 1,
                        md: 2
                    },
                    py: {
                        xs: 1,
                        md: 2
                    }
                }}
            >
                <Suspense fallback={<LoadingProgress open />}>
                    <CrmItem
                        url="/organization/get"
                        itemGuid={id}
                        itemsTitle={t('Organizations')}
                        backUrl="/app/organizations"
                        SidebarComponent={OrganizationSidebar}
                        TabsComponent={OrganizationTabs}
                        tab={tab || 'notes'}
                        contentComponents={[
                            {
                                tabId: 'notes',
                                component: OrganizationNotes
                            },
                            {
                                tabId: 'attachments',
                                component: OrganizationAttachments
                            },
                            {
                                tabId: 'contacts',
                                component: OrganizationContacts
                            },
                            {
                                tabId: 'timeline',
                                component: OrganizationTimeline
                            }
                        ]}
                    />
                </Suspense>
            </Box>
        </>
    );
};

export default OrganizationPage;