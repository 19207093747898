import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Container,
  Hidden
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import useToken from 'src/components/app/useToken';
import { indigo } from '@mui/material/colors';
import Logo from '../Logo';
import useRole from '../app/useRole';
import { Settings } from '@mui/icons-material';
import ModeSelector from './ModeSelector';
import { useAppConext } from '../context/AppContext';

const MainNavbar = ({ onMobileNavOpen, scrolled, brandInfo, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useToken();
  const { isAgent } = useRole();
  const { loadBrandInfo } = useAppConext();

  const useStyles = makeStyles(() => ({
    menu: {
      paddingTop: '8px',
      paddingBottom: '8px',
      '&.active': {
        borderBottomColor: '#FFF',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        paddingBottom: '6px',
      }
    },
    btnInverse: {
      backgroundColor: '#FFFFFF !important',
      color: indigo.A500,
      marginRight: '10px !important'
    }
  }));

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout().then(() => {
      loadBrandInfo();
      navigate('/', { replace: true });
    });
  };

  const handleSupportClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.contactUs.toggleMenu();
  };

  return (
    <AppBar
      elevation={scrolled ? 8 : 0}
      color="navbar"
      enableColorOnDark
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundImage: 'none',
        backdropFilter: 'blur(8px)',
        bgcolor: scrolled ? 'navbar.scrolled' : 'navbar',
      }}
      {...rest}
    >
      <Toolbar sx={{ height: 64 }}>
        <Container maxWidth="lg"
          sx={{
            display: 'flex',
            px: {
              xs: 0,
              md: 2
            }
          }}
        >
          <Box>
            <RouterLink to="/">
              <Logo
                logo="/static/logo-long.svg"
                style={{ marginTop: 'auto', marginBottom: 'auto', display: 'block', minWidth: 52 }}
              />
            </RouterLink>
          </Box>
          <Hidden mdUp>
            <Box sx={{ flexGrow: 0 }} />
          </Hidden>
          <Hidden mdDown>
            <Box sx={{ flexGrow: 0, textAlign: 'center', ml: 3, pt: 1 }}>
              <Button
                className={classes.menu}
                color="inherit"
                component={NavLink}
                to="/"
                sx={{ ml: 0.5 }}
              >
                Home
              </Button>
              <Button
                className={classes.menu}
                color="inherit"
                component="a"
                target="_blank"
                href="https://docs.anychat.one"
                sx={{ ml: 0.5 }}
              >
                Docs
              </Button>
              <Button
                className={classes.menu}
                color="inherit"
                component={NavLink}
                to="/pricing"
                sx={{ ml: 0.5 }}
              >
                Pricing
              </Button>
              <Button
                className={classes.menu}
                color="inherit"
                onClick={handleSupportClick}
                sx={{ ml: 0.5 }}
              >
                Support
              </Button>
              <Button
                className={classes.menu}
                color="inherit"
                to="/faq"
                component={NavLink}
                sx={{ ml: 0.5 }}
              >
                FAQ
              </Button>
              <Button
                className={classes.menu}
                color="inherit"
                component={NavLink}
                to="/roadmap"
                sx={{ ml: 0.5 }}
              >
                Roadmap
              </Button>
            </Box>
          </Hidden>
          <Box sx={{ my: 'auto', ml: 'auto', mr: 1 }}>
            <ModeSelector size="small" />
          </Box>
          <Box sx={{ my: 'auto' }}>
            {(!isLoggedIn()) && (
              <>
                <Hidden lgDown>
                  <Button
                    className={classes.btnInverse}
                    component={NavLink}
                    to="/register"
                  >
                    Get started free
                  </Button>
                </Hidden>
                <Button
                  className={classes.menu}
                  color="inherit"
                  component={NavLink}
                  to="/login"
                  sx={{ borderRadius: '4px important' }}
                >
                  Sign In
                </Button>
              </>
            )}
            {(isLoggedIn()) && (
              <>
                <IconButton
                  aria-label="user menu"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleClick}
                  style={{
                    padding: 5
                  }}
                  size="large"
                >
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl}
                  onClose={handleClose}
                  disableScrollLock
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  <MenuItem
                    component={NavLink}
                    to="/app/account"
                    key="account"
                  >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Account
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to="/app"
                    key="dashboard"
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Dashboard
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to="/app/inbox"
                    key="inbox"
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Inbox
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to="/app/chat"
                    key="chat"
                  >
                    <ListItemIcon>
                      <ChatBubbleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>
                      LiveChat
                    </ListItemText>
                  </MenuItem>
                  {(!isAgent()) && (
                    <MenuItem
                      component={NavLink}
                      to="/settings"
                      key="Settings"
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText>
                        Settings
                      </ListItemText>
                    </MenuItem>
                  )}
                  
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Logout
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Hidden mdUp>
            <Box style={{ paddingTop: 10, marginLeft: 10 }}>
              <IconButton
                color="inherit"
                onClick={onMobileNavOpen}
                style={{
                  padding: 5
                }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  scrolled: PropTypes.bool,
};

export default MainNavbar;
