import { useEffect } from "react";
import { useParams } from "react-router";
import PageMeta from "src/components/app/PageMeta";

const DedicatedLiveChatPage = () => {
    const { id } = useParams();
    const url = `${process.env.REACT_APP_API_URL}embed/${id}?r=${window.location}&embed=1&ifr=1`;

    const sendToIframe = (data) => {
        document.getElementById('anychat-frame').contentWindow.postMessage(data, url);
    };

    const updateCurrentAuthData = (data) => {
        const oldData = getStorageData();
        if (data.optional === true) {
            if (oldData === null || oldData.clientGuid === null) {
                localStorage.setItem(getStorageKey(), JSON.stringify({
                    ...oldData,
                    ...data
                }));
            }
        } else {
            localStorage.setItem(getStorageKey(), JSON.stringify({
                ...oldData,
                ...data
            }));
        }
    };

    const changeThread = (guid) => {
        const oldData = getStorageData();
        localStorage.setItem(getStorageKey(), JSON.stringify({
            ...oldData,
            threadGuid: guid
        }));
    };

    const getStorageKey = () => {
        return `anychat::${id}`;
    };

    const getStorageData = () => {
        const data = localStorage.getItem(getStorageKey());
        if (data === null) {
            return {
                clientGuid: null,
                threadGuid: null
            };
        }
        return JSON.parse(data);
    };

    const messageListenter = (e) => {
        if (e.data?.action === 'anychat.init') {
            const data = getStorageData();
            sendToIframe({
                action: 'setCurrentAuthData',
                location: window.location.toString(),
                auth: {
                    guid: data.clientGuid,
                    threadGuid: data.threadGuid
                }
            });
        }
        if (e.data.action === 'anychat.updateAuth') {
            updateCurrentAuthData({
                clientGuid: e.data.clientGuid,
                threadGuid: e.data.threadGuid,
                optional: e.data.optional
            });
        }
        if (e.data.action === 'anychat.changeThread') {
            changeThread(e.data.threadGuid);
        }
    };

    useEffect(() => {
        window.addEventListener('message', messageListenter);
        return () => {
            window.removeEventListener('message', messageListenter);
        };
    }, []);

    return (
        <>
            <PageMeta title="Live chat" />
            <iframe
                width="100%"
                height="100%"
                id="anychat-frame"
                style={{
                    padding: 0,
                    margin: 0,
                    border: '0 none',
                    minHeight: '100vh',
                    display: 'block'
                }}
                src={url}
            />
        </>
    );
};

export default DedicatedLiveChatPage;