import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Checkbox, Chip, Table, TableBody, TableCell, TableHead, TableRow, Divider, TablePagination, Typography, Avatar, Tooltip, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Moment from 'react-moment';
import { useOutletContext } from "react-router-dom";
import { invertColor } from "../app/Tools";
import { useEffect, useState } from "react";
import ConnectionOpenedBadge from "../chat/ConnectionOpenedBadge";

const TeamTable = (props) => {
    const {
        items,
        selectedItems,
        handleSelectOne,
        handleSelectAll,
        handlePageChange,
        handleLimitChange,
        totalItems,
        page,
        limit,
        disablePagination,
        disableSelection,
        compactView = false,
        actions
    } = props;

    const {chatWebSocket} = useOutletContext();
    const [onlineOperators, setOnlineOperators] = useState([]);
    
    const { t } = useTranslation();


    const chatListener = (socketData) => {
        if (socketData.action === 'onlineOperators' && socketData.workspace === chatWebSocket.getWorkspace()) {
            setOnlineOperators(socketData.operators);
        }
    };

    const checkOnline = () => {
        chatWebSocket.sendJson({
            action: 'getOnlineOperators',
            auth: chatWebSocket.getAuth()
        });
    };

    useEffect(() => {
        checkOnline();
        chatWebSocket.subscribe(chatListener);
        return () => {
            chatWebSocket.unsubscribe(chatListener);
        };
    }, []);

    return (
        <Card>
            <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                <TableHead sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 3 }}>
                    <TableRow>
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.length === items.length}
                                    color="primary"
                                    indeterminate={
                                        selectedItems.length > 0
                                        && selectedItems.length < items.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                        )}
                    
                    <TableCell>
                        {t('Name')}
                    </TableCell>
                    <TableCell>
                        {t('Role')}
                    </TableCell>
                    <TableCell>
                        {t('Departments')}
                    </TableCell>
                    <TableCell>
                        {t('Created at')}
                    </TableCell>
                    <TableCell>
                        {t('Last login')}
                    </TableCell>
                    {(Array.isArray(actions)) && (
                        <TableCell />
                    )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                    <TableRow
                        hover
                        key={item.id}
                        selected={selectedItems.indexOf(item.id) !== -1}
                    >
                        {(disableSelection !== true) && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedItems.indexOf(item.id) !== -1}
                                    onChange={(event) => handleSelectOne(event, item.id)}
                                    value="true"
                                />
                            </TableCell>
                        )}
                        <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                <Box sx={{ mr: 2 }}>
                                    {(onlineOperators.includes(item.guid) === true) ? (
                                        <ConnectionOpenedBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            <Avatar sx={{ my: 'auto' }} src={item.image} />
                                        </ConnectionOpenedBadge>
                                    ) : (
                                        <Avatar sx={{ my: 'auto' }} src={item.image} />
                                    )}
                                </Box>
                                <Box>
                                    <Typography noWrap sx={{
                                        fontStyle: item.name ? 'normal' : 'italic',
                                        color: item.name ? 'text.primary' : 'text.secondary',
                                    }}>
                                        {item.name || 'No name'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{
                                        fontStyle: (item.email) ? 'normal' : 'italic'
                                    }}>
                                        {item.email || 'No email set'}
                                    </Typography>
                                    <Box>
                                        {(item.tags?.length > 0) && (
                                            (item.tags?.map((i) => (
                                                <Chip
                                                    sx={{
                                                    mr: 0.5,
                                                    my: '2px',
                                                    height: 18,
                                                    bgcolor: i.color || '',
                                                    color: i.color ? invertColor(i.color, true) : 'text.primary',
                                                    '& .MuiSvgIcon-root': {
                                                        color: i.color ? invertColor(i.color, true) : '#000000',
                                                        opacity: 0.4,
                                                        '&:hover': {
                                                            color: i.color ? invertColor(i.color, true) : '#000000',
                                                            opacity: 0.55,
                                                        }
                                                    }
                                                    }}
                                                    label={i.label}
                                                    size="small"
                                                />
                                            )))
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            {item.role}
                        </TableCell>
                        <TableCell>

                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            <Moment format="YYYY MMM DD HH:mm" tz={Intl.DateTimeFormat().resolvedOptions().timeZone}>
                                {item.created_at}
                            </Moment>
                        </TableCell>
                        <TableCell sx={{ width: 120 }}>
                            <Moment format="YYYY MMM DD HH:mm" tz={Intl.DateTimeFormat().resolvedOptions().timeZone}>
                                {item.last_login}
                            </Moment>
                        </TableCell>
                        {(Array.isArray(actions)) && (
                            <TableCell
                                align="right"
                                sx={{
                                    width: 120
                                }}
                            >
                                {actions.map((i) => (
                                    (i !== null) && (
                                        <Tooltip title={i.tooltip}>
                                            <IconButton onClick={(e) => i.onClick(e, item)}>
                                                {i.icon}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ))}
                            </TableCell>
                        )}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Box>
            {(disablePagination !== true) && (
                <>
                    <Divider />
                    <TablePagination
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            bgcolor: 'background.paper',
                            zIndex: 3
                        }}
                        component="div"
                        count={totalItems}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                    />
                </>
            )}
            </PerfectScrollbar>
        </Card>
    );
};

export default TeamTable;