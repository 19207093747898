import { Box, Divider, Fab, Tooltip } from "@mui/material";
import { Add, ConfirmationNumber, Delete, PlaylistAdd, TaskAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import LoadingProgress from "src/components/app/LoadingProgress";
import NoContent from "src/components/app/NoContent";
import $api from "src/components/http";
import { useSnackbar } from "notistack";
import TicketsTable from "src/components/tickets/TicketsTable";
import TicketSelectorDialog from "src/components/tickets/TicketSelectorDialog";

const MeetingTickets = (props) => {
    const { itemGuid, onReload } = props;
    const [items, setItems] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const loadContent = () => {
        setIsLoading(true);
        $api.get(`/meeting/tickets?guid=${itemGuid}`)
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleRemoveRelated = (e, item) => {
        $api.post('/meeting/delete-ticket', {
            guid: itemGuid,
            ticketGuid: item.guid
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    loadContent();
                    onReload();
                }
            })
            .finally(() => {

            })
    };

    useEffect(() => {
        loadContent();
    }, [itemGuid]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogSubmit = (items) => {
        $api.post('/meeting/add-ticket', {
            guid: itemGuid,
            id: items
        })
            .catch((err) => {
                enqueueSnackbar(t('Error adding tickets'), {
                    variant: 'error'
                });
            })
            .then((res) => {
                if (res) {
                    loadContent();
                    onReload();
                    handleDialogClose();
                    enqueueSnackbar(t('Tickets(s) added to this ticket'), {
                        variant: 'success'
                    });
                }
            })
            .finally(() => {

            })
    };

    return (
        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', width: '100%', position: 'relative' }}>
            <LoadingProgress open={isLoading} />
            {(items.length > 0) ? (
                <>
                    <TicketsTable
                        items={items}
                        selectedItems={[]}
                        totalItems={items.length}
                        disablePagination
                        disableSelection
                        compactView
                        onReload={loadContent}
                        onChange={onReload}
                        allowInplaceEdit
                        actions={[
                            {
                                tooltip: t('Remove from related'),
                                icon: <Delete />,
                                onClick: handleRemoveRelated
                            }
                        ]}
                    />
                    <Divider />
                    <Tooltip arrow placement="left" title={t('Add a ticket')}>
                        <Fab onClick={handleDialogOpen} color="primary" sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20
                        }}>
                            <Add />
                        </Fab>
                    </Tooltip>
                </>
            ) : (
                ((isLoading === false) && (
                    <NoContent
                        icon={<ConfirmationNumber sx={{ width: 40, height: 40, color: 'text.secondary' }} />}
                        title={t('No tickets')}
                        content={t('There are no tickets related to this meeting')}
                        containerSx={{
                            my: 'auto'
                        }}
                        buttonTitle={t('Add a ticket')}
                        buttonProps={{
                            variant: 'contained',
                            color: 'primary',
                            disableElevation: true,
                            startIcon: <PlaylistAdd />,
                            onClick: handleDialogOpen
                        }}
                    />
                ))
            )}
            <TicketSelectorDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onSubmit={handleDialogSubmit}
            />
        </Box>
    );
};

export default MeetingTickets;