import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useParams
} from 'react-router-dom';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
const ChatFlowEditor = lazy(() => import('../../components/chatflow/ChatFlowEditor'));

const ChatFlowEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <>
      <PageMeta title={t('Chat flow editor')} />
      <Suspense fallback={<LoadingProgress open />}>
        <ChatFlowEditor id={id} />
      </Suspense>
    </>
  );
};

export default ChatFlowEdit;
