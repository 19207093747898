import { useState, useEffect } from 'react';
import {
  Button,
  Snackbar,
  SnackbarContent
} from '@mui/material';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useAppConext } from '../context/AppContext';

const GdprBanner = () => {
  const { t } = useTranslation();
  const { brandInfo, brandingLoaded } = useAppConext();
  const cookies = new Cookies();

  const [state, setState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  
  const [cookieText, setCookieText] = useState('');

  const { vertical, horizontal, open } = state;

  const handleAccept = () => {
    cookies.set('gdpr', '1', {
      path: '/',
      // domain: '.anychat.one',
      maxAge: 31536000,
      secure: true
    });
    setState({ ...state, open: false });
  };

  const handleDeny = () => {
    cookies.set('gdpr', '2', {
      path: '/',
      // domain: '.anychat.one',
      maxAge: 31536000,
      secure: true
    });
    setState({ ...state, open: false });
  };

  useEffect(() => {
    if (brandingLoaded) {
      if (brandInfo?.cookieConsent !== null && brandInfo?.cookieConsent !== undefined && brandInfo?.cookieConsent !== '') {
        setCookieText(brandInfo.cookieConsent);
      } else {
        setCookieText(t('We use cookies to personalise content, to provide social media features and to analyse our traffic.'));
      }
    }
  }, [brandingLoaded, brandInfo]);

  useEffect(() => {
    let o = true;
    if (cookies.get('gdpr')) {
      o = false;
    }
    setState({
      open: o,
      vertical: 'bottom',
      horizontal: 'center',
    });
  }, []);

  const action = (
    <>
      <Button
        type="button"
        color="primary"
        onClick={handleAccept}
      >
        {t('Accept')}
      </Button>
      <Button
        type="button"
        color="secondary"
        onClick={handleDeny}
      >
        {t('Deny')}
      </Button>
    </>
  );

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <SnackbarContent
        message={cookieText}
        action={action}
      />
    </Snackbar>
  );
};

export default GdprBanner;
