import { Dialog, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import TicketDialogContent from "./TicketDialogContent";

const TicketDialog = (props) => {
    const { open, onClose, contact, currentItem, currentThread, onSubmit } = props;

    const { t } = useTranslation();

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>
                {currentItem.guid ? t('Edit ticket') : t('New ticket')}
            </DialogTitle>
            <TicketDialogContent
                open={open}
                onClose={onClose}
                contact={contact}
                currentItem={currentItem}
                currentThread={currentThread}
                onSubmit={onSubmit}
            />
        </Dialog>
    );
};

export default TicketDialog;