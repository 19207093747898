import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { isEmpty } from "./Tools";

const NoContent = (props) => {
    const { icon, title, content, center, buttonTitle, buttonProps, containerSx = {}, titleProps = {} } = props;
    return (
        <Box sx={{
            textAlign: 'center',
            height: center ? 'calc(100vh - 100px)' : 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            py: center ? 0 : 6,
            ...containerSx
        }}>
            <Box sx={{ my: 'auto' }}>
                {(icon !== undefined && icon !== null) && (
                    <Box>
                        {icon}
                    </Box>
                )}
                {(!isEmpty(title)) && (
                    <Typography variant="h1" color="text.primary" {...titleProps}>
                        {title}
                    </Typography>
                )}
                {(!isEmpty(content)) && (
                    <Typography color="text.secondary">
                        {content}
                    </Typography>
                )}
                {(buttonTitle !== undefined) && (
                    <Box sx={{ mt: 3 }}>
                        <LoadingButton {...buttonProps}>
                            {buttonTitle}
                        </LoadingButton>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default NoContent;