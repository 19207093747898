import { useEffect, useState } from 'react';
import Error from 'src/components/app/Error';
import {
  Box,
  Container,
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SaveButton from 'src/components/app/SaveButton';
import $api from '../http';
import PageMeta from '../app/PageMeta';

const useStyles = makeStyles({
  button: {
    height: '56px'
  }
});

const ShopifyInstall = () => {
  const [loadError, setLoadError] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [domain, setDomain] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const classes = useStyles();

  const loadContent = () => {
    const data = {};
    const params = new URLSearchParams(window.location.search);
    if (params.has('hmac')) {
      data.hmac = params.get('hmac');
    }
    if (params.has('shop')) {
      data.shop = params.get('shop');
    }
    if (params.has('timestamp')) {
      data.timestamp = params.get('timestamp');
    }
    if (params.has('host')) {
      data.host = params.get('host');
    }
    if (params.has('session')) {
      data.session = params.get('session');
    }
    if (params.has('locale')) {
      data.locale = params.get('locale');
    }
    if (data.hmac && data.shop) {
      setShowForm(false);
      $api.post('/auth/shopify', data)
        .catch((err) => {
          setLoadError(err);
          return null;
        })
        .then((response) => {
          if (response && response.data) {
            window.location.assign(response.data.authURL);
          }
        });
    } else {
      setShowForm(true);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  const handleChange = (event) => {
    setDomain(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);
    $api.post('/auth/get-shopify-url', {
      domain
    })
      .catch((err) => {
        if (err.response && err.response.status && err.response.status === 422) {
          setErrors(err.response.data);
        } else {
          setLoadError(err);
        }
        setIsSaving(false);
        return null;
      })
      .then((response) => {
        if (response && response.data) {
          window.location.assign(response.data.authURL);
        }
        setIsSaving(false);
      });
  };

  if (loadError && Object.keys(loadError).length !== 0) {
    return (
      <Error error={loadError} />
    );
  }

  return (
    <>
      <PageMeta title="Shopify connect" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid item lg={2} />
            <Grid
              item
              lg={8}
              md={12}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Shopify connect"
                />
                <Divider />
                <CardContent>
                  {(showForm) && (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item lg={8}>
                          <TextField
                            error={!!errors.domain}
                            helperText={errors.domain}
                            fullWidth
                            autoFocus
                            required
                            label="Domain"
                            name="domain"
                            onChange={handleChange}
                            value={domain}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item lg={4}>
                          <SaveButton
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            type="submit"
                            label="Connect"
                            startIcon={<CompareArrowsIcon />}
                            loading={isSaving}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ShopifyInstall;
