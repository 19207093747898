function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export const makeid = (length = 10) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
};

export const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

export const humanFileSize = (size, unit = '') => {
    if ((!unit && size >= 1 << 30) || unit === "GB")
        return (size / (1 << 30)).toFixed(2) + "GB";
    if ((!unit && size >= 1 << 20) || unit === "MB")
        return (size / (1 << 20)).toFixed(2) + "MB";
    if ((!unit && size >= 1 << 10) || unit === "KB")
        return (size / (1 << 10)).toFixed(2) + "KB";
    return size.toFixed(0) + " bytes";
}

export const slugify = (str) => {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-') // remove consecutive hyphens
      .replace(/-/g, ' ')
      .trim()
      .replace(/\s+/g, '-')
}

export const isEmpty = (val) => {
    if (val === null || val == undefined || val === 0 || val === '') {
        return true;
    }
    if (typeof val === 'number') {
        return false;
    }

    return !(Object.keys(val) || val).length;
};

export const maskValue = (val, maskWith = '*') => {
    return `${val}`.replaceAll(/./ig, maskWith);
};

export const sortObject = (obj, prop, order = 'desc') => {
    let sortable = [];
    for (var k in obj) {
        sortable.push([k, obj[k]]);
    }

    sortable.sort(function(a, b) {
        if (order === 'desc') {
            return b[1][prop] - a[1][prop];    
        }
        return a[1][prop] - b[1][prop];
    });

    let newObj = {};

    sortable.forEach((i) => {
        newObj[i[0]] = i[1];
    });

    return newObj;
};