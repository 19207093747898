import { Autocomplete, Avatar, Chip, CircularProgress, ListItemAvatar, ListItemText, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import $api from "../http";
import { useTranslation } from "react-i18next";

let searchTimeout = null;

const AsyncAutocomplete = (props) => {
    const { url, textFieldProps, onChange, initialValue, idAttr, ...rest } = props;
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(rest.value);

    const { t } = useTranslation();

    const loadItems = (s) => {
        setIsLoading(true);
        $api.post(`${url}?s=${s}`, {
            s: s,
            limit: 10
        })
            .catch((err) => {

            })
            .then((res) => {
                if (res) {
                    setItems(res.data);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleSelectItem = (e, item) => {
        setSelected(item);
    };

    const handleUnselectItem = (id) => {
        setSelected((old) => old.filter((i) => i[idAttr] !== id));
    };

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    useEffect(() => {
        loadItems(searchTerm);
    }, []);

    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            loadItems(searchTerm);
        }, 500);
    }, [searchTerm]);

    return (
        <Autocomplete
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={selected}
            onChange={handleSelectItem}
            isOptionEqualToValue={(option, value) => option[idAttr] === value[idAttr]}
            filterOptions={(options, params) => items}
            renderOption={(p, option) => (
                <MenuItem {...p} key={option[idAttr]}>
                    <ListItemAvatar>
                        <Avatar src={option.img} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={option.name || t('Visitor')}
                        secondary={option.email || t('Email not set')}
                    />
                </MenuItem>
            )}
            getOptionLabel={(option) => option.name || t('Visitor')}
            getOptionKey={(option) => option[idAttr]}
            renderTags={(values) => (
                (values.map((v) => (
                    <Chip
                        key={v.guid}
                        sx={{ mr: 0.5 }}
                        avatar={<Avatar src={v.img} />}
                        label={v.name || t('Visitor')}
                        onDelete={() => handleUnselectItem(v[idAttr])}
                    />
                )))
            )}
            options={items}
            loading={isLoading}
            filterSelectedOptions
            {...rest}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    {...textFieldProps}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AsyncAutocomplete;